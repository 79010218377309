<svg
  viewBox="0 0 290 434"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_558_11109)">
    <path
      d="M202.273 306.688C200.443 306.688 198.956 307.943 198.956 309.489V337.497H165.602C163.771 337.497 162.285 338.753 162.285 340.299C162.285 341.846 163.771 343.101 165.602 343.101H202.014C202.333 343.101 202.641 343.062 202.932 342.99C204.449 342.732 205.591 341.6 205.591 340.245V309.491C205.591 307.945 204.104 306.69 202.273 306.69V306.688Z"
      fill="#3A3B3A"
    />
    <path
      d="M209.582 103.754C207.751 103.754 206.265 105.009 206.265 106.556V134.564H172.911C171.08 134.564 169.594 135.819 169.594 137.366C169.594 138.912 171.08 140.167 172.911 140.167H209.322C209.641 140.167 209.95 140.128 210.24 140.056C211.757 139.798 212.899 138.666 212.899 137.311V106.558C212.899 105.011 211.413 103.756 209.582 103.756V103.754Z"
      fill="#1D1743"
    />
    <path
      d="M187.846 108.936H194.274V177.566C194.274 179.365 192.546 180.824 190.417 180.824H187.846V108.936Z"
      fill="#1D1743"
    />
    <path
      d="M138.859 175.359V180.789H187.845V175.359H138.859Z"
      fill="#1D1743"
    />
    <path
      d="M116.48 232.121V237.551H69.269C67.1399 237.551 65.4119 236.091 65.4119 234.293V232.121H116.48Z"
      fill="#1D1743"
    />
    <path
      d="M71.8797 190.748H65.4512V232.122H71.8797V190.748Z"
      fill="#1D1743"
    />
    <path
      d="M131.572 244.316V249.746H84.3608C82.2317 249.746 80.5037 248.287 80.5037 246.488V244.316H131.572Z"
      fill="#1D1743"
    />
    <path d="M86.9715 202.943H80.543V244.317H86.9715V202.943Z" fill="#1D1743" />
    <path
      d="M247.395 300.928C245.564 300.928 244.077 302.183 244.077 303.729V331.738H210.723C208.893 331.738 207.406 332.993 207.406 334.539C207.406 336.086 208.893 337.341 210.723 337.341H247.135C247.454 337.341 247.762 337.302 248.053 337.23C249.57 336.972 250.712 335.84 250.712 334.485V303.732C250.712 302.185 249.225 300.93 247.395 300.93V300.928Z"
      fill="#1D1743"
    />
    <path
      d="M201.195 214.053H176.741V206.597H197.338C199.467 206.597 201.195 208.056 201.195 209.855V214.053Z"
      fill="#1D1743"
    />
    <path
      d="M193.559 209.082H175.219V201.626H189.701C191.831 201.626 193.559 203.086 193.559 204.884V209.082Z"
      fill="#1D1743"
    />
    <path
      d="M186.645 203.697H168.305V196.241H182.787C184.917 196.241 186.645 197.701 186.645 199.499V203.697Z"
      fill="#1D1743"
    />
    <path
      d="M93.3923 106.41H113.989V113.866H89.5352V109.668C89.5352 107.87 91.2632 106.41 93.3923 106.41Z"
      fill="#1D1743"
    />
    <path
      d="M101.033 101.441H115.515V108.897H97.1758V104.699C97.1758 102.901 98.9038 101.441 101.033 101.441Z"
      fill="#1D1743"
    />
    <path
      d="M107.947 96.0566H122.429V103.513H104.09V99.3144C104.09 97.5161 105.818 96.0566 107.947 96.0566Z"
      fill="#1D1743"
    />
    <path
      d="M70.9872 312.416H220.12V411.238C220.12 414.235 217.24 416.667 213.691 416.667H70.9872C67.4386 416.667 64.5586 414.235 64.5586 411.238V317.846C64.5586 314.848 67.4386 312.416 70.9872 312.416Z"
      fill="#362C7B"
    />
    <path
      d="M269.617 211.422H116.239C112.689 211.422 109.811 213.853 109.811 216.852V310.244C109.811 313.242 112.689 315.673 116.239 315.673H269.617C273.168 315.673 276.046 313.242 276.046 310.244V216.852C276.046 213.853 273.168 211.422 269.617 211.422Z"
      fill="#362C7B"
    />
    <path
      d="M173.062 111.73H17.8739C14.3235 111.73 11.4453 114.161 11.4453 117.16V210.552C11.4453 213.551 14.3235 215.982 17.8739 215.982H173.062C176.613 215.982 179.491 213.551 179.491 210.552V117.16C179.491 114.161 176.613 111.73 173.062 111.73Z"
      fill="#362C7B"
    />
    <path
      d="M271.931 11.6035H119.839C116.288 11.6035 113.41 14.0344 113.41 17.0332V110.425C113.41 113.424 116.288 115.855 119.839 115.855H271.931C275.481 115.855 278.36 113.424 278.36 110.425V17.0332C278.36 14.0344 275.481 11.6035 271.931 11.6035Z"
      fill="#362C7B"
    />
    <path
      d="M68.531 308.029H109.828V315.485H64.6738V311.287C64.6738 309.489 66.4018 308.029 68.531 308.029Z"
      fill="#1D1743"
    />
    <path
      d="M118.628 7.72656H273.731C277.28 7.72656 280.16 10.159 280.16 13.1562V15.3628H112.199V13.1562C112.199 10.159 115.079 7.72656 118.628 7.72656Z"
      fill="#1D1743"
    />
    <path
      d="M114.951 416.465H170.011V421.894C170.011 424.892 167.131 427.324 163.582 427.324H121.38C117.831 427.324 114.951 424.892 114.951 421.894V416.465Z"
      fill="#1D1743"
    />
    <g filter="url(#filter0_di_558_11109)">
      <path
        d="M124.591 358.258H159.82C161.239 358.258 162.391 359.231 162.391 360.43V416.464H122.02V360.43C122.02 359.231 123.172 358.258 124.591 358.258Z"
        fill="#FEECF4"
      />
    </g>
    <g filter="url(#filter1_d_558_11109)">
      <path
        d="M123.82 335.419V336.866C123.82 339.445 126.296 341.535 129.349 341.535H160.977C164.031 341.535 166.506 339.445 166.506 336.866V335.419C166.506 332.84 164.031 330.75 160.977 330.75H129.349C126.296 330.75 123.82 332.84 123.82 335.419Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_d_558_11109)">
      <path
        d="M117.904 305.568V307.014C117.904 309.593 120.38 311.684 123.433 311.684H155.061C158.115 311.684 160.59 309.593 160.59 307.014V305.568C160.59 302.989 158.115 300.898 155.061 300.898H123.433C120.38 300.898 117.904 302.989 117.904 305.568Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter3_d_558_11109)">
      <path
        d="M123.82 275.718V277.164C123.82 279.743 126.296 281.834 129.349 281.834H160.977C164.031 281.834 166.506 279.743 166.506 277.164V275.718C166.506 273.139 164.031 271.049 160.977 271.049H129.349C126.296 271.049 123.82 273.139 123.82 275.718Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter4_d_558_11109)">
      <path
        d="M117.904 245.865V247.311C117.904 249.89 120.38 251.98 123.433 251.98H155.061C158.115 251.98 160.59 249.89 160.59 247.311V245.865C160.59 243.286 158.115 241.195 155.061 241.195H123.433C120.38 241.195 117.904 243.286 117.904 245.865Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter5_d_558_11109)">
      <path
        d="M126.9 216.107V217.553C126.9 220.132 129.376 222.223 132.429 222.223H164.058C167.111 222.223 169.586 220.132 169.586 217.553V216.107C169.586 213.528 167.111 211.437 164.058 211.437H132.429C129.376 211.437 126.9 213.528 126.9 216.107Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter6_d_558_11109)">
      <path
        d="M120.988 186.257V187.704C120.988 190.282 123.464 192.373 126.517 192.373H158.145C161.199 192.373 163.674 190.282 163.674 187.704V186.257C163.674 183.678 161.199 181.588 158.145 181.588H126.517C123.464 181.588 120.988 183.678 120.988 186.257Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter7_d_558_11109)">
      <path
        d="M126.9 156.404V157.85C126.9 160.429 129.376 162.52 132.429 162.52H164.058C167.111 162.52 169.586 160.429 169.586 157.85V156.404C169.586 153.825 167.111 151.734 164.058 151.734H132.429C129.376 151.734 126.9 153.825 126.9 156.404Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter8_d_558_11109)">
      <path
        d="M120.988 126.554V128C120.988 130.579 123.464 132.67 126.517 132.67H158.145C161.199 132.67 163.674 130.579 163.674 128V126.554C163.674 123.975 161.199 121.884 158.145 121.884H126.517C123.464 121.884 120.988 123.975 120.988 126.554Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter9_d_558_11109)">
      <path
        d="M126.9 96.6536V98.1C126.9 100.679 129.376 102.77 132.429 102.77H164.058C167.111 102.77 169.586 100.679 169.586 98.1V96.6536C169.586 94.0747 167.111 91.9841 164.058 91.9841H132.429C129.376 91.9841 126.9 94.0747 126.9 96.6536Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter10_d_558_11109)">
      <path
        d="M120.988 66.804V68.2504C120.988 70.8293 123.464 72.9199 126.517 72.9199H158.145C161.199 72.9199 163.674 70.8293 163.674 68.2504V66.804C163.674 64.2251 161.199 62.1345 158.145 62.1345H126.517C123.464 62.1345 120.988 64.2251 120.988 66.804Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter11_d_558_11109)">
      <path
        d="M126.9 36.9524V38.3989C126.9 40.9778 129.376 43.0684 132.429 43.0684H164.058C167.111 43.0684 169.586 40.9778 169.586 38.3989V36.9524C169.586 34.3735 167.111 32.2829 164.058 32.2829H132.429C129.376 32.2829 126.9 34.3735 126.9 36.9524Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter12_d_558_11109)">
      <path
        d="M86.3855 406.695H98.5613C101.615 406.695 104.09 404.605 104.09 402.026L104.09 345.775C104.09 343.196 101.615 341.105 98.5613 341.105H86.3855C83.3322 341.105 80.857 343.196 80.857 345.775V402.026C80.857 404.605 83.3322 406.695 86.3855 406.695Z"
        fill="#CA74E9"
      />
      <mask
        id="mask0_558_11109"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="80"
        y="341"
        width="25"
        height="69"
      >
        <path
          d="M86.3109 409.178H98.5742C101.65 409.178 104.143 407.011 104.143 404.338L104.143 346.038C104.143 343.365 101.65 341.199 98.5742 341.199H86.3109C83.2356 341.199 80.7426 343.365 80.7426 346.038L80.7426 404.338C80.7426 407.011 83.2356 409.178 86.3109 409.178Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_558_11109)">
        <path
          d="M100.581 390.554C100.249 390.392 99.8608 390.337 99.4443 390.37C98.9248 388.45 97.7805 387.114 96.4485 387.114C95.942 387.114 95.4662 387.312 95.0368 387.657C94.4325 386.419 93.5171 385.631 92.4937 385.631C91.4702 385.631 90.5548 386.419 89.9505 387.657C89.5211 387.314 89.0454 387.114 88.5388 387.114C87.2274 387.114 86.1011 388.409 85.5688 390.281C85.124 390.237 84.7151 390.287 84.3628 390.459C82.7865 391.228 82.9845 394.01 84.8051 396.673C86.3222 398.892 88.4848 400.321 90.0765 400.295V400.478H95.3505V400.36C96.878 400.132 98.7731 398.768 100.141 396.766C101.962 394.103 102.16 391.321 100.583 390.552L100.581 390.554Z"
          fill="#185E45"
        />
        <path
          d="M82.1593 398.229H104.531C105.95 398.229 107.102 399.202 107.102 400.4V401.486C107.102 404.483 104.222 406.916 100.674 406.916H86.0165C82.4679 406.916 79.5879 404.483 79.5879 401.486V400.4C79.5879 399.202 80.7399 398.229 82.1593 398.229Z"
          fill="#1D1743"
        />
      </g>
    </g>
    <g filter="url(#filter13_d_558_11109)">
      <path
        d="M189.413 406.734H201.589C204.642 406.734 207.117 404.644 207.117 402.065V345.814C207.117 343.235 204.642 341.144 201.589 341.144H189.413C186.36 341.144 183.884 343.235 183.884 345.814V402.065C183.884 404.644 186.36 406.734 189.413 406.734Z"
        fill="#CA74E9"
      />
      <mask
        id="mask1_558_11109"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="183"
        y="341"
        width="25"
        height="66"
      >
        <path
          d="M189.413 406.734H201.589C204.642 406.734 207.117 404.644 207.117 402.065V345.814C207.117 343.235 204.642 341.144 201.589 341.144H189.413C186.36 341.144 183.884 343.235 183.884 345.814V402.065C183.884 404.644 186.36 406.734 189.413 406.734Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_558_11109)">
        <path
          d="M203.636 392.228C203.304 392.065 202.916 392.011 202.499 392.044C201.98 390.124 200.835 388.788 199.503 388.788C198.997 388.788 198.521 388.986 198.091 389.331C197.487 388.093 196.572 387.305 195.548 387.305C194.525 387.305 193.61 388.093 193.005 389.331C192.576 388.988 192.1 388.788 191.594 388.788C190.282 388.788 189.156 390.082 188.623 391.955C188.179 391.911 187.77 391.961 187.417 392.133C185.841 392.902 186.039 395.684 187.86 398.346C189.377 400.566 191.54 401.995 193.131 401.969V402.151H198.405V402.034C199.933 401.806 201.828 400.442 203.196 398.44C205.016 395.777 205.214 392.995 203.638 392.226L203.636 392.228Z"
          fill="#185E45"
        />
        <path
          d="M184.314 399.023H206.685C208.104 399.023 209.256 399.996 209.256 401.195V402.281C209.256 405.278 206.376 407.711 202.828 407.711H188.171C184.622 407.711 181.742 405.278 181.742 402.281V401.195C181.742 399.996 182.894 399.023 184.314 399.023Z"
          fill="#1D1743"
        />
      </g>
    </g>
    <g filter="url(#filter14_di_558_11109)">
      <path
        d="M255.893 224.938H257.608C260.661 224.938 263.137 227.03 263.137 229.608V293.374C263.137 295.952 260.661 298.043 257.608 298.043H255.893C252.841 298.043 250.365 295.952 250.365 293.374V229.608C250.365 227.03 252.841 224.938 255.893 224.938ZM229.793 233.661V297.426C229.793 300.004 232.27 302.096 235.322 302.096H237.037C240.089 302.096 242.566 300.004 242.566 297.426V233.661C242.566 231.083 240.089 228.991 237.037 228.991H235.322C232.27 228.991 229.793 231.083 229.793 233.661ZM208.45 229.58V293.345C208.45 295.923 210.927 298.015 213.979 298.015H215.694C218.746 298.015 221.223 295.923 221.223 293.345V229.58C221.223 227.002 218.746 224.91 215.694 224.91H213.979C210.927 224.91 208.45 227.002 208.45 229.58ZM187.725 234.627V298.393C187.725 300.971 190.201 303.062 193.253 303.062H194.968C198.021 303.062 200.497 300.971 200.497 298.393V234.627C200.497 232.049 198.021 229.958 194.968 229.958H193.253C190.201 229.958 187.725 232.049 187.725 234.627Z"
        fill="white"
      />
      <mask
        id="mask2_558_11109"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="187"
        y="224"
        width="77"
        height="80"
      >
        <path
          d="M255.893 224.938H257.608C260.661 224.938 263.137 227.03 263.137 229.608V293.374C263.137 295.952 260.661 298.043 257.608 298.043H255.893C252.841 298.043 250.365 295.952 250.365 293.374V229.608C250.365 227.03 252.841 224.938 255.893 224.938ZM229.793 233.661V297.426C229.793 300.004 232.27 302.096 235.322 302.096H237.037C240.089 302.096 242.566 300.004 242.566 297.426V233.661C242.566 231.083 240.089 228.991 237.037 228.991H235.322C232.27 228.991 229.793 231.083 229.793 233.661ZM208.45 229.58V293.345C208.45 295.923 210.927 298.015 213.979 298.015H215.694C218.746 298.015 221.223 295.923 221.223 293.345V229.58C221.223 227.002 218.746 224.91 215.694 224.91H213.979C210.927 224.91 208.45 227.002 208.45 229.58ZM187.725 234.627V298.393C187.725 300.971 190.201 303.062 193.253 303.062H194.968C198.021 303.062 200.497 300.971 200.497 298.393V234.627C200.497 232.049 198.021 229.958 194.968 229.958H193.253C190.201 229.958 187.725 232.049 187.725 234.627Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_558_11109)">
        <path
          opacity="0.5"
          d="M264.417 199.644L157.08 230.4L174.36 273.421L281.697 242.664L264.417 199.644Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter15_di_558_11109)">
      <path
        d="M255.038 22.4811H256.753C259.805 22.4811 262.281 24.5726 262.281 27.1506V90.9164C262.281 93.4943 259.805 95.5858 256.753 95.5858H255.038C251.985 95.5858 249.509 93.4943 249.509 90.9164V27.1506C249.509 24.5726 251.985 22.4811 255.038 22.4811ZM228.938 31.2033V94.969C228.938 97.547 231.414 99.6385 234.466 99.6385H236.181C239.234 99.6385 241.71 97.547 241.71 94.969V31.2033C241.71 28.6253 239.234 26.5338 236.181 26.5338H234.466C231.414 26.5338 228.938 28.6253 228.938 31.2033ZM207.595 27.1224V90.8881C207.595 93.4661 210.071 95.5576 213.123 95.5576H214.839C217.891 95.5576 220.367 93.4661 220.367 90.8881V27.1246C220.367 24.5466 217.891 22.4551 214.839 22.4551H213.123C210.071 22.4551 207.595 24.5466 207.595 27.1246V27.1224ZM186.869 32.1698V95.9355C186.869 98.5135 189.345 100.605 192.398 100.605H194.113C197.165 100.605 199.641 98.5135 199.641 95.9355V32.1698C199.641 29.5918 197.165 27.5003 194.113 27.5003H192.398C189.345 27.5003 186.869 29.5918 186.869 32.1698Z"
        fill="white"
      />
      <mask
        id="mask3_558_11109"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="186"
        y="22"
        width="77"
        height="79"
      >
        <path
          d="M255.038 22.9811H256.753C259.611 22.9811 261.781 24.9246 261.781 27.1506V90.9164C261.781 93.1424 259.611 95.0858 256.753 95.0858H255.038C252.179 95.0858 250.009 93.1424 250.009 90.9164V27.1506C250.009 24.9246 252.179 22.9811 255.038 22.9811ZM229.438 94.969V31.2033C229.438 28.9773 231.608 27.0338 234.466 27.0338H236.181C239.04 27.0338 241.21 28.9773 241.21 31.2033V94.969C241.21 97.1951 239.04 99.1385 236.181 99.1385H234.466C231.608 99.1385 229.438 97.1951 229.438 94.969ZM208.095 90.8881V27.1246C208.095 24.8985 210.265 22.9551 213.123 22.9551H214.839C217.697 22.9551 219.867 24.8985 219.867 27.1246V90.8881C219.867 93.1142 217.697 95.0576 214.839 95.0576H213.123C210.265 95.0576 208.095 93.1142 208.095 90.8881ZM187.369 95.9355V32.1698C187.369 29.9438 189.539 28.0003 192.398 28.0003H194.113C196.971 28.0003 199.141 29.9438 199.141 32.1698V95.9355C199.141 98.1616 196.971 100.105 194.113 100.105H192.398C189.539 100.105 187.369 98.1616 187.369 95.9355Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask3_558_11109)">
        <path
          opacity="0.5"
          d="M263.544 -2.80741L156.207 27.9492L173.487 70.9696L280.824 40.213L263.544 -2.80741Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter16_di_558_11109)">
      <path
        d="M33.9742 197.967H32.259C29.2068 197.967 26.7305 195.875 26.7305 193.297V129.532C26.7305 126.954 29.2068 124.862 32.259 124.862H33.9742C37.0265 124.862 39.5028 126.954 39.5028 129.532V193.297C39.5028 195.875 37.0265 197.967 33.9742 197.967ZM47.3019 133.584V197.35C47.3019 199.928 49.7782 202.02 52.8305 202.02H54.5456C57.5979 202.02 60.0742 199.928 60.0742 197.35V133.584C60.0742 131.006 57.5979 128.915 54.5456 128.915H52.8305C49.7782 128.915 47.3019 131.006 47.3019 133.584ZM68.6448 129.503V193.269C68.6448 195.847 71.1211 197.939 74.1734 197.939H75.8885C78.9408 197.939 81.4171 195.847 81.4171 193.269V129.503C81.4171 126.925 78.9408 124.834 75.8885 124.834H74.1734C71.1211 124.834 68.6448 126.925 68.6448 129.503ZM89.3705 134.551V198.317C89.3705 200.895 91.8468 202.986 94.8991 202.986H96.6142C99.6665 202.986 102.143 200.895 102.143 198.317V134.551C102.143 131.973 99.6665 129.881 96.6142 129.881H94.8991C91.8468 129.881 89.3705 131.973 89.3705 134.551Z"
        fill="white"
      />
      <mask
        id="mask4_558_11109"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="26"
        y="124"
        width="77"
        height="79"
      >
        <path
          d="M33.9742 197.467H32.259C29.4004 197.467 27.2305 195.523 27.2305 193.297V129.532C27.2305 127.306 29.4004 125.362 32.259 125.362H33.9742C36.8328 125.362 39.0028 127.306 39.0028 129.532V193.297C39.0028 195.523 36.8328 197.467 33.9742 197.467ZM47.8019 197.35V133.584C47.8019 131.358 49.9719 129.415 52.8305 129.415H54.5456C57.4042 129.415 59.5742 131.358 59.5742 133.584V197.35C59.5742 199.576 57.4042 201.52 54.5456 201.52H52.8305C49.9719 201.52 47.8019 199.576 47.8019 197.35ZM69.1448 193.269V129.503C69.1448 127.277 71.3147 125.334 74.1734 125.334H75.8885C78.7471 125.334 80.9171 127.277 80.9171 129.503V193.269C80.9171 195.495 78.7471 197.439 75.8885 197.439H74.1734C71.3147 197.439 69.1448 195.495 69.1448 193.269ZM89.8705 198.317V134.551C89.8705 132.325 92.0405 130.381 94.8991 130.381H96.6142C99.4728 130.381 101.643 132.325 101.643 134.551V198.317C101.643 200.543 99.4728 202.486 96.6142 202.486H94.8991C92.0405 202.486 89.8705 200.543 89.8705 198.317Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask4_558_11109)">
        <path
          opacity="0.5"
          d="M104.991 103.273L-2.3457 134.029L14.9345 177.05L122.272 146.293L104.991 103.273Z"
          fill="white"
        />
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_di_558_11109"
      x="88.0195"
      y="314.258"
      width="108.371"
      height="126.205"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="-10" />
      <feGaussianBlur stdDeviation="17" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="-6" />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.983333 0 0 0 0 0.667847 0 0 0 0 0.810567 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="shape"
        result="effect2_innerShadow_558_11109"
      />
    </filter>
    <filter
      id="filter1_d_558_11109"
      x="73.8203"
      y="284.75"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter2_d_558_11109"
      x="67.9043"
      y="254.898"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter3_d_558_11109"
      x="73.8203"
      y="225.049"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter4_d_558_11109"
      x="67.9043"
      y="195.195"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter5_d_558_11109"
      x="76.9004"
      y="165.438"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter6_d_558_11109"
      x="70.9883"
      y="135.588"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter7_d_558_11109"
      x="76.9004"
      y="105.734"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter8_d_558_11109"
      x="70.9883"
      y="75.8848"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter9_d_558_11109"
      x="76.9004"
      y="45.9844"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter10_d_558_11109"
      x="70.9883"
      y="16.1348"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter11_d_558_11109"
      x="76.9004"
      y="-13.7168"
      width="142.686"
      height="110.785"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="25" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 0 0.945098 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter12_d_558_11109"
      x="55.7422"
      y="316.105"
      width="73.4004"
      height="115.811"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter13_d_558_11109"
      x="158.885"
      y="316.145"
      width="73.2324"
      height="115.59"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
    </filter>
    <filter
      id="filter14_di_558_11109"
      x="153.725"
      y="190.91"
      width="143.412"
      height="146.152"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="17" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.983333 0 0 0 0 0.667847 0 0 0 0 0.810567 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="shape"
        result="effect2_innerShadow_558_11109"
      />
    </filter>
    <filter
      id="filter15_di_558_11109"
      x="152.869"
      y="-11.5449"
      width="143.412"
      height="146.15"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="17" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.983333 0 0 0 0 0.667847 0 0 0 0 0.810567 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="shape"
        result="effect2_innerShadow_558_11109"
      />
    </filter>
    <filter
      id="filter16_di_558_11109"
      x="-7.26953"
      y="90.834"
      width="143.412"
      height="146.152"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="17" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.984314 0 0 0 0 0.666667 0 0 0 0 0.811765 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_558_11109"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_558_11109"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.983333 0 0 0 0 0.667847 0 0 0 0 0.810567 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="shape"
        result="effect2_innerShadow_558_11109"
      />
    </filter>
    <clipPath id="clip0_558_11109">
      <rect width="289.013" height="434" fill="white" />
    </clipPath>
  </defs>
</svg>
