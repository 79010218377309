<svg viewBox="0 0 1763 798" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1746.86 685.863H1653.85C1650.81 685.863 1648.35 688.325 1648.35 691.362V791.902C1648.35 794.939 1650.81 797.401 1653.85 797.401H1746.86C1749.89 797.401 1752.35 794.939 1752.35 791.902V691.362C1752.35 688.325 1749.89 685.863 1746.86 685.863Z"
    fill="#332C88"
  />
  <path
    d="M1752.35 546.958V742.122C1752.35 745.158 1749.89 747.62 1746.86 747.62H1653.85C1650.82 747.62 1648.35 745.158 1648.35 742.122V546.932C1648.35 545.532 1648.88 544.188 1649.84 543.17L1695.99 493.978C1698.15 491.676 1701.8 491.661 1703.98 493.948L1750.84 543.167C1751.81 544.188 1752.35 545.547 1752.35 546.958H1752.35Z"
    fill="#332C88"
  />
  <path
    d="M1648.35 554.114C1645.97 554.114 1643.58 553.209 1641.76 551.397C1638.1 547.755 1638.08 541.83 1641.71 538.166L1693.71 485.766C1697.35 482.098 1703.28 482.079 1706.94 485.717C1710.61 489.359 1710.62 495.284 1706.99 498.948L1654.99 551.348C1653.16 553.19 1650.76 554.114 1648.35 554.114Z"
    fill="#242B6B"
  />
  <path
    d="M1752.71 554.114C1750.31 554.114 1747.91 553.193 1746.08 551.348L1694.08 498.948C1690.44 495.28 1690.46 489.355 1694.13 485.717C1697.79 482.075 1703.71 482.098 1707.35 485.766L1759.35 538.166C1762.99 541.834 1762.97 547.759 1759.3 551.397C1757.48 553.208 1755.09 554.114 1752.71 554.114Z"
    fill="#242B6B"
  />
  <path
    d="M1720.7 544.758H1680.37C1676.53 544.758 1673.42 547.875 1673.42 551.72V573.728C1673.42 577.572 1676.53 580.689 1680.37 580.689H1720.7C1724.55 580.689 1727.66 577.572 1727.66 573.728V551.72C1727.66 547.875 1724.55 544.758 1720.7 544.758Z"
    fill="#FEAC18"
  />
  <path
    d="M1720.7 592.291H1680.37C1676.53 592.291 1673.42 595.408 1673.42 599.253V621.261C1673.42 625.106 1676.53 628.222 1680.37 628.222H1720.7C1724.55 628.222 1727.66 625.106 1727.66 621.261V599.253C1727.66 595.408 1724.55 592.291 1720.7 592.291Z"
    fill="#FEAC18"
  />
  <path
    d="M1720.7 640.948H1680.37C1676.53 640.948 1673.42 644.065 1673.42 647.91V669.918C1673.42 673.763 1676.53 676.88 1680.37 676.88H1720.7C1724.55 676.88 1727.66 673.763 1727.66 669.918V647.91C1727.66 644.065 1724.55 640.948 1720.7 640.948Z"
    fill="#FEAC18"
  />
  <path
    d="M1720.7 689.605H1680.37C1676.53 689.605 1673.42 692.722 1673.42 696.567V718.575C1673.42 722.42 1676.53 725.537 1680.37 725.537H1720.7C1724.55 725.537 1727.66 722.42 1727.66 718.575V696.567C1727.66 692.722 1724.55 689.605 1720.7 689.605Z"
    fill="#FEAC18"
  />
  <path
    d="M1720.7 737.14H1680.37C1676.53 737.14 1673.42 740.257 1673.42 744.101V766.109C1673.42 769.954 1676.53 773.071 1680.37 773.071H1720.7C1724.55 773.071 1727.66 769.954 1727.66 766.109V744.101C1727.66 740.257 1724.55 737.14 1720.7 737.14Z"
    fill="#242B6B"
  />
  <path
    d="M1680.37 737.14H1720.71C1724.55 737.14 1727.66 740.257 1727.66 744.101V797.4H1673.42V744.101C1673.42 740.261 1676.54 737.14 1680.38 737.14H1680.37Z"
    fill="#FEAC18"
  />
  <path
    d="M1499.35 526.958V722.122C1499.35 725.158 1496.89 727.62 1493.86 727.62H1400.85C1397.82 727.62 1395.35 725.158 1395.35 722.122V526.932C1395.35 525.532 1395.88 524.188 1396.84 523.17L1442.99 473.978C1445.15 471.676 1448.8 471.661 1450.98 473.948L1497.84 523.167C1498.81 524.188 1499.35 525.547 1499.35 526.958H1499.35Z"
    fill="#332C88"
  />
  <path
    d="M1395.35 534.114C1392.97 534.114 1390.58 533.209 1388.76 531.397C1385.1 527.755 1385.08 521.83 1388.71 518.166L1440.71 465.766C1444.35 462.098 1450.28 462.079 1453.94 465.717C1457.61 469.359 1457.62 475.284 1453.99 478.948L1401.99 531.348C1400.16 533.19 1397.76 534.114 1395.35 534.114Z"
    fill="#242B6B"
  />
  <path
    d="M1499.71 534.114C1497.31 534.114 1494.91 533.193 1493.08 531.348L1441.08 478.948C1437.44 475.28 1437.46 469.355 1441.13 465.717C1444.79 462.075 1450.71 462.098 1454.35 465.766L1506.35 518.166C1509.99 521.834 1509.97 527.759 1506.3 531.397C1504.48 533.208 1502.09 534.114 1499.71 534.114Z"
    fill="#242B6B"
  />
  <path
    d="M1467.7 524.756H1427.37C1423.53 524.756 1420.42 527.873 1420.42 531.718V553.726C1420.42 557.57 1423.53 560.687 1427.37 560.687H1467.7C1471.55 560.687 1474.66 557.57 1474.66 553.726V531.718C1474.66 527.873 1471.55 524.756 1467.7 524.756Z"
    fill="#FEAC18"
  />
  <path
    d="M1467.7 572.291H1427.37C1423.53 572.291 1420.42 575.408 1420.42 579.253V601.261C1420.42 605.106 1423.53 608.222 1427.37 608.222H1467.7C1471.55 608.222 1474.66 605.106 1474.66 601.261V579.253C1474.66 575.408 1471.55 572.291 1467.7 572.291Z"
    fill="#FEAC18"
  />
  <path
    d="M1468.82 620.199H1428.5C1424.65 620.199 1421.54 623.316 1421.54 627.161V649.169C1421.54 653.014 1424.65 656.131 1428.5 656.131H1468.82C1472.67 656.131 1475.78 653.014 1475.78 649.169V627.161C1475.78 623.316 1472.67 620.199 1468.82 620.199Z"
    fill="#FEAC18"
  />
  <path
    d="M1428.5 667.358H1468.83C1472.67 667.358 1475.79 670.476 1475.79 674.32V727.618H1421.54V674.32C1421.54 670.48 1424.66 667.358 1428.5 667.358H1428.5Z"
    fill="#FEAC18"
  />
  <path
    d="M954.368 241.836H928.179C925.715 241.836 923.719 243.834 923.719 246.298V276.643C923.719 279.107 925.715 281.105 928.179 281.105H954.368C956.832 281.105 958.828 279.107 958.828 276.643V246.298C958.828 243.834 956.832 241.836 954.368 241.836Z"
    fill="#52399A"
  />
  <path
    d="M941.771 144.409H779.637C777.076 144.409 775 146.486 775 149.049V276.466C775 279.028 777.076 281.105 779.637 281.105H941.771C944.332 281.105 946.408 279.028 946.408 276.466V149.049C946.408 146.486 944.332 144.409 941.771 144.409Z"
    fill="#37246B"
  />
  <path
    d="M941.769 129H840.746C838.185 129 836.109 131.077 836.109 133.639V221.456C836.109 224.019 838.185 226.096 840.746 226.096H941.769C944.33 226.096 946.407 224.019 946.407 221.456V133.639C946.407 131.077 944.33 129 941.769 129Z"
    fill="#37246B"
  />
  <path
    d="M932.342 233.386H789.065C786.602 233.386 784.605 235.383 784.605 237.848V245.659C784.605 248.123 786.602 250.121 789.065 250.121H932.342C934.806 250.121 936.802 248.123 936.802 245.659V237.848C936.802 235.383 934.806 233.386 932.342 233.386Z"
    fill="#FF3883"
  />
  <path
    d="M932.342 258.24H789.065C786.602 258.24 784.605 260.238 784.605 262.702V270.513C784.605 272.977 786.602 274.975 789.065 274.975H932.342C934.806 274.975 936.802 272.977 936.802 270.513V262.702C936.802 260.238 934.806 258.24 932.342 258.24Z"
    fill="#FF3883"
  />
  <path
    d="M933.005 182.685H789.727C787.264 182.685 785.268 184.682 785.268 187.147V194.957C785.268 197.422 787.264 199.419 789.727 199.419H933.005C935.468 199.419 937.464 197.422 937.464 194.957V187.147C937.464 184.682 935.468 182.685 933.005 182.685Z"
    fill="#FF3883"
  />
  <path
    d="M933.005 207.538H789.727C787.264 207.538 785.268 209.536 785.268 212V219.811C785.268 222.275 787.264 224.273 789.727 224.273H933.005C935.468 224.273 937.464 222.275 937.464 219.811V212C937.464 209.536 935.468 207.538 933.005 207.538Z"
    fill="#FF3883"
  />
  <path
    d="M933.005 157.83H789.727C787.264 157.83 785.268 159.828 785.268 162.292V170.103C785.268 172.567 787.264 174.565 789.727 174.565H933.005C935.468 174.565 937.464 172.567 937.464 170.103V162.292C937.464 159.828 935.468 157.83 933.005 157.83Z"
    fill="#FF3883"
  />
  <path
    d="M1133.55 265.578H1107.19C1104.71 265.578 1102.7 267.589 1102.7 270.07V300.614C1102.7 303.094 1104.71 305.105 1107.19 305.105H1133.55C1136.03 305.105 1138.04 303.094 1138.04 300.614V270.07C1138.04 267.589 1136.03 265.578 1133.55 265.578Z"
    fill="#562F88"
  />
  <path
    d="M1120.87 167.511H957.668C955.09 167.511 953 169.602 953 172.181V300.436C953 303.015 955.09 305.106 957.668 305.106H1120.87C1123.44 305.106 1125.53 303.015 1125.53 300.436V172.181C1125.53 169.602 1123.44 167.511 1120.87 167.511Z"
    fill="#43246B"
  />
  <path
    d="M1120.87 152H1019.18C1016.6 152 1014.51 154.091 1014.51 156.67V245.064C1014.51 247.643 1016.6 249.734 1019.18 249.734H1120.87C1123.44 249.734 1125.53 247.643 1125.53 245.064V156.67C1125.53 154.091 1123.44 152 1120.87 152Z"
    fill="#43246B"
  />
  <path
    d="M1111.38 257.072H967.157C964.678 257.072 962.668 259.083 962.668 261.564V269.426C962.668 271.906 964.678 273.917 967.157 273.917H1111.38C1113.86 273.917 1115.87 271.906 1115.87 269.426V261.564C1115.87 259.083 1113.86 257.072 1111.38 257.072Z"
    fill="#FF3883"
  />
  <path
    d="M1111.38 282.09H967.157C964.678 282.09 962.668 284.101 962.668 286.581V294.443C962.668 296.924 964.678 298.935 967.157 298.935H1111.38C1113.86 298.935 1115.87 296.924 1115.87 294.443V286.581C1115.87 284.101 1113.86 282.09 1111.38 282.09Z"
    fill="#FF3883"
  />
  <path
    d="M1112.04 206.037H967.825C965.346 206.037 963.336 208.048 963.336 210.529V218.391C963.336 220.871 965.346 222.882 967.825 222.882H1112.04C1114.52 222.882 1116.53 220.871 1116.53 218.391V210.529C1116.53 208.048 1114.52 206.037 1112.04 206.037Z"
    fill="#FF3883"
  />
  <path
    d="M1112.04 231.055H967.825C965.346 231.055 963.336 233.066 963.336 235.546V243.408C963.336 245.889 965.346 247.9 967.825 247.9H1112.04C1114.52 247.9 1116.53 245.889 1116.53 243.408V235.546C1116.53 233.066 1114.52 231.055 1112.04 231.055Z"
    fill="#FF3883"
  />
  <path
    d="M1112.04 181.02H967.825C965.346 181.02 963.336 183.03 963.336 185.511V193.373C963.336 195.854 965.346 197.864 967.825 197.864H1112.04C1114.52 197.864 1116.53 195.854 1116.53 193.373V185.511C1116.53 183.03 1114.52 181.02 1112.04 181.02Z"
    fill="#FF3883"
  />
  <path
    d="M1014.95 313.673H985.32C982.533 313.673 980.273 315.933 980.273 318.722V353.057C980.273 355.845 982.533 358.105 985.32 358.105H1014.95C1017.74 358.105 1020 355.845 1020 353.057V318.722C1020 315.933 1017.74 313.673 1014.95 313.673Z"
    fill="#52399A"
  />
  <path
    d="M1000.7 203.436H817.247C814.349 203.436 812 205.786 812 208.685V352.856C812 355.755 814.349 358.105 817.247 358.105H1000.7C1003.6 358.105 1005.95 355.755 1005.95 352.856V208.685C1005.95 205.786 1003.6 203.436 1000.7 203.436Z"
    fill="#37246B"
  />
  <path
    d="M1000.7 186H886.391C883.494 186 881.145 188.35 881.145 191.249V290.613C881.145 293.512 883.494 295.863 886.391 295.863H1000.7C1003.6 295.863 1005.94 293.512 1005.94 290.613V191.249C1005.94 188.35 1003.6 186 1000.7 186Z"
    fill="#37246B"
  />
  <path
    d="M990.032 304.111H827.915C825.128 304.111 822.869 306.372 822.869 309.16V317.998C822.869 320.786 825.128 323.047 827.915 323.047H990.032C992.819 323.047 995.078 320.786 995.078 317.998V309.16C995.078 306.372 992.819 304.111 990.032 304.111Z"
    fill="#FF3883"
  />
  <path
    d="M990.032 332.233H827.915C825.128 332.233 822.869 334.494 822.869 337.282V346.12C822.869 348.908 825.128 351.169 827.915 351.169H990.032C992.819 351.169 995.078 348.908 995.078 346.12V337.282C995.078 334.494 992.819 332.233 990.032 332.233Z"
    fill="#FF3883"
  />
  <path
    d="M990.78 246.743H828.663C825.876 246.743 823.617 249.004 823.617 251.792V260.63C823.617 263.418 825.876 265.679 828.663 265.679H990.78C993.567 265.679 995.826 263.418 995.826 260.63V251.792C995.826 249.004 993.567 246.743 990.78 246.743Z"
    fill="#FF3883"
  />
  <path
    d="M990.78 274.865H828.663C825.876 274.865 823.617 277.126 823.617 279.914V288.752C823.617 291.54 825.876 293.801 828.663 293.801H990.78C993.567 293.801 995.826 291.54 995.826 288.752V279.914C995.826 277.126 993.567 274.865 990.78 274.865Z"
    fill="#FF3883"
  />
  <path
    d="M990.78 218.621H828.663C825.876 218.621 823.617 220.882 823.617 223.67V232.508C823.617 235.296 825.876 237.556 828.663 237.556H990.78C993.567 237.556 995.826 235.296 995.826 232.508V223.67C995.826 220.882 993.567 218.621 990.78 218.621Z"
    fill="#FF3883"
  />
  <circle cx="1039.09" cy="250.143" r="24.1433" fill="#185E45" />
  <circle cx="1051.86" cy="266.904" r="24.1433" fill="#069461" />
  <ellipse cx="1023.72" cy="256.927" rx="22.3475" ry="22.5471" fill="#185E45" />
  <rect
    x="1027.71"
    y="291.048"
    width="18.3569"
    height="45.4932"
    rx="9.17845"
    fill="url(#paint0_linear_909_5)"
  />
  <circle cx="1051.86" cy="296.834" r="24.1433" fill="#069461" />
  <ellipse cx="1015.14" cy="285.46" rx="24.1433" ry="23.9438" fill="#D9D9D9" />
  <ellipse cx="1015.14" cy="285.46" rx="24.1433" ry="23.9438" fill="#069461" />
  <rect
    x="865.908"
    y="343.679"
    width="25.9541"
    height="64.3211"
    rx="12.9771"
    fill="url(#paint1_linear_909_5)"
  />
  <circle cx="902.864" cy="329.292" r="34.1353" fill="#069461" />
  <circle cx="869.012" cy="305.594" r="34.1353" fill="#069461" />
  <circle cx="848.135" cy="335.499" r="34.1353" fill="#D9D9D9" />
  <circle cx="848.135" cy="335.499" r="34.1353" fill="#069461" />
  <path
    d="M1451.69 318.469C1449.1 318.469 1447 316.368 1447 313.778V279.691C1447 277.102 1449.1 275 1451.69 275C1454.28 275 1456.38 277.102 1456.38 279.691V313.778C1456.38 316.368 1454.28 318.469 1451.69 318.469Z"
    fill="#242B6B"
  />
  <path
    d="M1456.87 311.285H1301.79C1297.23 311.285 1293.53 314.986 1293.53 319.551V359.051C1293.53 363.616 1297.23 367.317 1301.79 367.317H1456.87C1461.43 367.317 1465.13 363.616 1465.13 359.051V319.551C1465.13 314.986 1461.43 311.285 1456.87 311.285Z"
    fill="#2F3389"
  />
  <path
    d="M1470.33 314.77H1288.69L1338.43 280.826H1420.58L1470.33 314.77Z"
    fill="#242B6B"
  />
  <path
    d="M1470.33 319.46H1288.69C1286.63 319.46 1284.82 318.119 1284.21 316.151C1283.6 314.184 1284.35 312.052 1286.05 310.895L1335.79 276.951C1336.57 276.419 1337.49 276.135 1338.43 276.135H1420.58C1421.52 276.135 1422.44 276.419 1423.22 276.951L1472.97 310.895C1474.67 312.055 1475.41 314.188 1474.81 316.151C1474.2 318.119 1472.38 319.46 1470.33 319.46H1470.33ZM1303.88 310.078H1455.13L1419.13 285.517H1339.88L1303.88 310.078H1303.88Z"
    fill="#242B6B"
  />
  <path
    d="M1367.48 328.773H1390.55C1393.76 328.773 1396.37 331.378 1396.37 334.59V367.317H1361.67V334.59C1361.67 331.382 1364.28 328.773 1367.49 328.773H1367.48Z"
    fill="#FEAC18"
  />
  <path
    d="M1441.13 328.773H1417.55C1414.48 328.773 1411.99 331.264 1411.99 334.337V352.997C1411.99 356.07 1414.48 358.561 1417.55 358.561H1441.13C1444.2 358.561 1446.69 356.07 1446.69 352.997V334.337C1446.69 331.264 1444.2 328.773 1441.13 328.773Z"
    fill="#FEAC18"
  />
  <path
    d="M1340.17 328.773H1316.59C1313.52 328.773 1311.03 331.264 1311.03 334.337V352.997C1311.03 356.07 1313.52 358.561 1316.59 358.561H1340.17C1343.24 358.561 1345.73 356.07 1345.73 352.997V334.337C1345.73 331.264 1343.24 328.773 1340.17 328.773Z"
    fill="#FEAC18"
  />
  <path
    d="M1185.09 382.469C1182.5 382.469 1180.4 380.368 1180.4 377.778V343.691C1180.4 341.102 1182.5 339 1185.09 339C1187.68 339 1189.78 341.102 1189.78 343.691V377.778C1189.78 380.368 1187.68 382.469 1185.09 382.469Z"
    fill="#2F246B"
  />
  <path
    d="M1378.44 374.285H1171.79C1167.23 374.285 1163.53 377.986 1163.53 382.551V422.051C1163.53 426.616 1167.23 430.317 1171.79 430.317H1378.44C1383.01 430.317 1386.71 426.616 1386.71 422.051V382.551C1386.71 377.986 1383.01 374.285 1378.44 374.285Z"
    fill="#45399A"
  />
  <path
    d="M1391.71 377.77H1158.69L1208.43 343.826H1341.96L1391.71 377.77Z"
    fill="#2F246B"
  />
  <path
    d="M1391.71 382.46H1158.69C1156.63 382.46 1154.82 381.119 1154.21 379.151C1153.6 377.184 1154.35 375.052 1156.05 373.895L1205.79 339.951C1206.57 339.419 1207.49 339.135 1208.43 339.135H1341.96C1342.9 339.135 1343.82 339.419 1344.6 339.951L1394.35 373.895C1396.05 375.055 1396.79 377.188 1396.18 379.151C1395.58 381.119 1393.76 382.46 1391.7 382.46H1391.71ZM1173.88 373.078H1376.51L1340.51 348.517H1209.88L1173.88 373.078H1173.88Z"
    fill="#2F246B"
  />
  <path
    d="M1237.48 391.773H1260.55C1263.76 391.773 1266.37 394.378 1266.37 397.59V430.317H1231.67V397.59C1231.67 394.382 1234.27 391.773 1237.48 391.773H1237.48Z"
    fill="#FEAC18"
  />
  <path
    d="M1289.06 391.773H1312.13C1315.33 391.773 1317.94 394.378 1317.94 397.59V430.317H1283.24V397.59C1283.24 394.382 1285.85 391.773 1289.06 391.773H1289.06Z"
    fill="#FEAC18"
  />
  <path
    d="M1363.64 391.773H1340.07C1337 391.773 1334.51 394.264 1334.51 397.337V415.997C1334.51 419.07 1337 421.561 1340.07 421.561H1363.64C1366.71 421.561 1369.2 419.07 1369.2 415.997V397.337C1369.2 394.264 1366.71 391.773 1363.64 391.773Z"
    fill="#FEAC18"
  />
  <path
    d="M1210.17 391.773H1186.59C1183.52 391.773 1181.03 394.264 1181.03 397.337V415.997C1181.03 419.07 1183.52 421.561 1186.59 421.561H1210.17C1213.24 421.561 1215.73 419.07 1215.73 415.997V397.337C1215.73 394.264 1213.24 391.773 1210.17 391.773Z"
    fill="#FEAC18"
  />
  <path
    d="M1622.86 654.27H1529.85C1526.81 654.27 1524.35 656.731 1524.35 659.768V760.308C1524.35 763.345 1526.81 765.807 1529.85 765.807H1622.86C1625.89 765.807 1628.35 763.345 1628.35 760.308V659.768C1628.35 656.731 1625.89 654.27 1622.86 654.27Z"
    fill="#452C8B"
  />
  <path
    d="M1628.35 465.958V661.122C1628.35 664.158 1625.89 666.62 1622.86 666.62H1529.85C1526.82 666.62 1524.35 664.158 1524.35 661.122V465.932C1524.35 464.532 1524.88 463.188 1525.84 462.17L1571.99 412.978C1574.15 410.676 1577.8 410.661 1579.98 412.948L1626.84 462.167C1627.81 463.188 1628.35 464.547 1628.35 465.958H1628.35Z"
    fill="#452C8B"
  />
  <path
    d="M1524.35 473.114C1521.97 473.114 1519.58 472.209 1517.76 470.397C1514.1 466.755 1514.08 460.83 1517.71 457.166L1569.71 404.766C1573.35 401.098 1579.28 401.079 1582.94 404.717C1586.61 408.359 1586.62 414.284 1582.99 417.948L1530.99 470.349C1529.16 472.19 1526.76 473.114 1524.35 473.114Z"
    fill="#37246B"
  />
  <path
    d="M1628.71 473.114C1626.31 473.114 1623.91 472.193 1622.08 470.348L1570.08 417.948C1566.44 414.28 1566.46 408.355 1570.13 404.717C1573.79 401.075 1579.71 401.098 1583.35 404.766L1635.35 457.166C1638.99 460.834 1638.97 466.759 1635.3 470.397C1633.48 472.208 1631.09 473.114 1628.71 473.114Z"
    fill="#37246B"
  />
  <path
    d="M1596.7 463.759H1556.37C1552.53 463.759 1549.42 466.876 1549.42 470.721V492.729C1549.42 496.573 1552.53 499.69 1556.37 499.69H1596.7C1600.55 499.69 1603.66 496.573 1603.66 492.729V470.721C1603.66 466.876 1600.55 463.759 1596.7 463.759Z"
    fill="#FEAC18"
  />
  <path
    d="M1596.7 511.291H1556.37C1552.53 511.291 1549.42 514.408 1549.42 518.253V540.261C1549.42 544.106 1552.53 547.222 1556.37 547.222H1596.7C1600.55 547.222 1603.66 544.106 1603.66 540.261V518.253C1603.66 514.408 1600.55 511.291 1596.7 511.291Z"
    fill="#FEAC18"
  />
  <path
    d="M1596.7 559.948H1556.37C1552.53 559.948 1549.42 563.065 1549.42 566.91V588.918C1549.42 592.763 1552.53 595.88 1556.37 595.88H1596.7C1600.55 595.88 1603.66 592.763 1603.66 588.918V566.91C1603.66 563.065 1600.55 559.948 1596.7 559.948Z"
    fill="#FEAC18"
  />
  <path
    d="M1596.7 608.605H1556.37C1552.53 608.605 1549.42 611.722 1549.42 615.567V637.575C1549.42 641.42 1552.53 644.537 1556.37 644.537H1596.7C1600.55 644.537 1603.66 641.42 1603.66 637.575V615.567C1603.66 611.722 1600.55 608.605 1596.7 608.605Z"
    fill="#FEAC18"
  />
  <path
    d="M1596.7 656.14H1556.37C1552.53 656.14 1549.42 659.257 1549.42 663.101V685.109C1549.42 688.954 1552.53 692.071 1556.37 692.071H1596.7C1600.55 692.071 1603.66 688.954 1603.66 685.109V663.101C1603.66 659.257 1600.55 656.14 1596.7 656.14Z"
    fill="#FEAC18"
  />
  <path
    d="M1596.7 704.797H1556.37C1552.53 704.797 1549.42 707.914 1549.42 711.759V733.767C1549.42 737.611 1552.53 740.728 1556.37 740.728H1596.7C1600.55 740.728 1603.66 737.611 1603.66 733.767V711.759C1603.66 707.914 1600.55 704.797 1596.7 704.797Z"
    fill="#242B6B"
  />
  <path
    d="M1556.38 705.546H1596.71C1600.55 705.546 1603.67 708.664 1603.67 712.508V765.806H1549.42V712.508C1549.42 708.667 1552.54 705.546 1556.38 705.546H1556.38Z"
    fill="#FEAC18"
  />
  <path
    d="M581.291 285.06H309.509C304.867 285.06 301.104 288.825 301.104 293.469V362.33C301.104 366.975 304.867 370.74 309.509 370.74H581.291C585.933 370.74 589.696 366.975 589.696 362.33V293.469C589.696 288.825 585.933 285.06 581.291 285.06Z"
    fill="#3B246B"
  />
  <path
    d="M301.104 331.522H589.696V368.376C589.696 369.678 588.639 370.737 587.337 370.737H303.463C302.161 370.737 301.104 369.678 301.104 368.376V331.522Z"
    fill="#4C2F89"
  />
  <path
    d="M317.914 293.469C317.914 288.825 314.151 285.06 309.509 285.06C304.867 285.06 301.104 288.825 301.104 293.469V362.591C301.104 367.235 304.867 371 309.509 371C314.151 371 317.914 367.235 317.914 362.591V293.469Z"
    fill="#4C2F89"
  />
  <path
    d="M327.842 338.508H334.503C337.257 338.508 339.496 340.745 339.496 343.504V370.739H322.852V343.504C322.852 340.748 325.088 338.508 327.845 338.508H327.842Z"
    fill="#FEAC18"
  />
  <path
    d="M589.592 293.209C589.592 288.565 585.829 284.8 581.187 284.8C576.545 284.8 572.781 288.565 572.781 293.209V362.331C572.781 366.975 576.545 370.74 581.187 370.74C585.829 370.74 589.592 366.975 589.592 362.331V293.209Z"
    fill="#4C2F89"
  />
  <path
    d="M588.268 277H304.405C299.763 277 296 280.765 296 285.41C296 290.054 299.763 293.819 304.405 293.819H588.268C592.91 293.819 596.673 290.054 596.673 285.41C596.673 280.765 592.91 277 588.268 277Z"
    fill="#3B246B"
  />
  <path
    d="M391.678 301.175H385.021C382.263 301.175 380.027 303.411 380.027 306.171V318.203C380.027 320.962 382.263 323.199 385.021 323.199H391.678C394.436 323.199 396.672 320.962 396.672 318.203V306.171C396.672 303.411 394.436 301.175 391.678 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M363.223 301.175H356.566C353.808 301.175 351.572 303.411 351.572 306.171V318.203C351.572 320.962 353.808 323.199 356.566 323.199H363.223C365.981 323.199 368.217 320.962 368.217 318.203V306.171C368.217 303.411 365.981 301.175 363.223 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M334.493 301.175H327.835C325.077 301.175 322.842 303.411 322.842 306.171V318.203C322.842 320.962 325.077 323.199 327.835 323.199H334.493C337.251 323.199 339.486 320.962 339.486 318.203V306.171C339.486 303.411 337.251 301.175 334.493 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M477.321 301.175H470.663C467.906 301.175 465.67 303.411 465.67 306.171V318.203C465.67 320.962 467.906 323.199 470.663 323.199H477.321C480.079 323.199 482.314 320.962 482.314 318.203V306.171C482.314 303.411 480.079 301.175 477.321 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M448.864 301.175H442.206C439.448 301.175 437.213 303.411 437.213 306.171V318.203C437.213 320.962 439.448 323.199 442.206 323.199H448.864C451.622 323.199 453.857 320.962 453.857 318.203V306.171C453.857 303.411 451.622 301.175 448.864 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M420.137 301.175H413.48C410.722 301.175 408.486 303.411 408.486 306.171V318.203C408.486 320.962 410.722 323.199 413.48 323.199H420.137C422.895 323.199 425.131 320.962 425.131 318.203V306.171C425.131 303.411 422.895 301.175 420.137 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M562.958 301.175H556.3C553.542 301.175 551.307 303.411 551.307 306.171V357.686C551.307 360.445 553.542 362.682 556.3 362.682H562.958C565.715 362.682 567.951 360.445 567.951 357.686V306.171C567.951 303.411 565.715 301.175 562.958 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M534.501 301.175H527.843C525.085 301.175 522.85 303.411 522.85 306.171V357.686C522.85 360.445 525.085 362.682 527.843 362.682H534.501C537.258 362.682 539.494 360.445 539.494 357.686V306.171C539.494 303.411 537.258 301.175 534.501 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M505.776 301.175H499.118C496.361 301.175 494.125 303.411 494.125 306.171V357.686C494.125 360.445 496.361 362.682 499.118 362.682H505.776C508.534 362.682 510.769 360.445 510.769 357.686V306.171C510.769 303.411 508.534 301.175 505.776 301.175Z"
    fill="#FEAC18"
  />
  <path
    d="M582.826 86.8223H368.174C365.869 86.8223 364 88.6919 364 90.9981V147.819C364 150.126 365.869 151.995 368.174 151.995H582.826C585.131 151.995 587 150.126 587 147.819V90.9981C587 88.6919 585.131 86.8223 582.826 86.8223Z"
    fill="#3B246B"
  />
  <path
    d="M574.043 98.7539H376.661C374.444 98.7539 372.646 100.552 372.646 102.77V109.8C372.646 112.019 374.444 113.817 376.661 113.817H574.043C576.26 113.817 578.057 112.019 578.057 109.8V102.77C578.057 100.552 576.26 98.7539 574.043 98.7539Z"
    fill="#58399A"
  />
  <path
    d="M574.043 130.669H376.661C374.444 130.669 372.646 132.467 372.646 134.685V141.716C372.646 143.934 374.444 145.732 376.661 145.732H574.043C576.26 145.732 578.057 143.934 578.057 141.716V134.685C578.057 132.467 576.26 130.669 574.043 130.669Z"
    fill="#58399A"
  />
  <path
    d="M522.454 79.0674H469.834C467.529 79.0674 465.66 80.937 465.66 83.2432V148.565C465.66 150.871 467.529 152.741 469.834 152.741H522.454C524.759 152.741 526.627 150.871 526.627 148.565V83.2432C526.627 80.937 524.759 79.0674 522.454 79.0674Z"
    fill="#4C2F89"
  />
  <path
    d="M387.436 7.17676H255.564C254.148 7.17676 253 8.32534 253 9.74218V44.6503C253 46.0671 254.148 47.2157 255.564 47.2157H387.436C388.852 47.2157 390 46.0671 390 44.6503V9.74218C390 8.32534 388.852 7.17676 387.436 7.17676Z"
    fill="#3B246B"
  />
  <path
    d="M382.04 14.5068H260.779C259.417 14.5068 258.312 15.6115 258.312 16.9742V21.2933C258.312 22.656 259.417 23.7607 260.779 23.7607H382.04C383.402 23.7607 384.506 22.656 384.506 21.2933V16.9742C384.506 15.6115 383.402 14.5068 382.04 14.5068Z"
    fill="#58399A"
  />
  <path
    d="M382.04 34.1133H260.779C259.417 34.1133 258.312 35.218 258.312 36.5807V40.8997C258.312 42.2624 259.417 43.3671 260.779 43.3671H382.04C383.402 43.3671 384.506 42.2624 384.506 40.8997V36.5807C384.506 35.218 383.402 34.1133 382.04 34.1133Z"
    fill="#58399A"
  />
  <path
    d="M350.346 2.41211H318.019C316.603 2.41211 315.455 3.56069 315.455 4.97753V45.1081C315.455 46.5249 316.603 47.6735 318.019 47.6735H350.346C351.762 47.6735 352.91 46.5249 352.91 45.1081V4.97753C352.91 3.56069 351.762 2.41211 350.346 2.41211Z"
    fill="#4C2F89"
  />
  <path
    d="M12.718 56.6514L242.231 56.6514C244.492 56.6514 246.324 54.8179 246.324 52.5561V36.3888C246.324 34.1271 244.492 32.2936 242.231 32.2936L12.718 32.2936C10.4574 32.2936 8.62477 34.1271 8.62477 36.3888V52.5561C8.62477 54.8179 10.4574 56.6514 12.718 56.6514Z"
    fill="#3B246B"
  />
  <path
    d="M34.13 50.8818L221.761 50.8818C223.935 50.8818 225.697 49.1184 225.697 46.9431V41.8723C225.697 39.697 223.935 37.9336 221.761 37.9336L34.13 37.9336C31.9557 37.9336 30.1932 39.697 30.1932 41.8723V46.9431C30.1932 49.1184 31.9557 50.8818 34.13 50.8818Z"
    fill="#58399A"
  />
  <path
    d="M38.0375 36.1387L221.604 36.1387C223.865 36.1387 225.697 34.3052 225.697 32.0434V8.82499C225.697 6.56326 223.865 4.72976 221.604 4.72976L38.0375 4.72976C35.7769 4.72976 33.9443 6.56326 33.9443 8.82499V32.0434C33.9443 34.3052 35.7769 36.1387 38.0375 36.1387Z"
    fill="#3B246B"
  />
  <path
    d="M62.7261 23.7041L235.223 23.7041C237.398 23.7041 239.16 21.9407 239.16 19.7654V14.6946C239.16 12.5193 237.398 10.7559 235.223 10.7559L62.7261 10.7559C60.5518 10.7559 58.7893 12.5193 58.7893 14.6946V19.7654C58.7893 21.9407 60.5518 23.7041 62.7261 23.7041Z"
    fill="#58399A"
  />
  <path
    d="M70.0234 32.2266H4.09323C1.8326 32.2266 0 34.0601 0 36.3218V55.9204C0 58.1821 1.8326 60.0156 4.09323 60.0156H70.0234C72.284 60.0156 74.1166 58.1821 74.1166 55.9204V36.3218C74.1166 34.0601 72.284 32.2266 70.0234 32.2266Z"
    fill="#3B246B"
  />
  <path
    d="M63.4547 38.6611H10.3684C8.19416 38.6611 6.43164 40.4246 6.43164 42.5999V49.4945C6.43164 51.6698 8.19416 53.4332 10.3684 53.4332H63.4547C65.6289 53.4332 67.3915 51.6698 67.3915 49.4945V42.5999C67.3915 40.4246 65.6289 38.6611 63.4547 38.6611Z"
    fill="#58399A"
  />
  <path
    d="M62.1287 0.780273H10.5249C8.26424 0.780273 6.43164 2.61377 6.43164 4.8755V32.5183C6.43164 34.78 8.26424 36.6135 10.5249 36.6135H62.1287C64.3894 36.6135 66.222 34.78 66.222 32.5183V4.8755C66.222 2.61377 64.3894 0.780273 62.1287 0.780273Z"
    fill="#3B246B"
  />
  <path
    d="M54.537 7.6543H18.8469C16.6727 7.6543 14.9102 9.41772 14.9102 11.593V18.4876C14.9102 20.6629 16.6727 22.4264 18.8469 22.4264H54.537C56.7112 22.4264 58.4738 20.6629 58.4738 18.4876V11.593C58.4738 9.41772 56.7112 7.6543 54.537 7.6543Z"
    fill="#58399A"
  />
  <path
    d="M205.977 60.0146L271.907 60.0146C274.167 60.0146 276 58.1812 276 55.9194V36.3209C276 34.0591 274.167 32.2256 271.907 32.2256L205.977 32.2256C203.716 32.2256 201.883 34.0591 201.883 36.3209V55.9194C201.883 58.1812 203.716 60.0146 205.977 60.0146Z"
    fill="#3B246B"
  />
  <path
    d="M212.545 53.4336L265.632 53.4336C267.806 53.4336 269.568 51.6702 269.568 49.4949V42.6003C269.568 40.425 267.806 38.6615 265.632 38.6615L212.545 38.6615C210.371 38.6615 208.609 40.425 208.609 42.6003V49.4949C208.609 51.6702 210.371 53.4336 212.545 53.4336Z"
    fill="#58399A"
  />
  <path
    d="M213.871 36.6143L265.475 36.6143C267.736 36.6143 269.568 34.7808 269.568 32.519V4.87627C269.568 2.61454 267.736 0.781044 265.475 0.781044L213.871 0.781044C211.611 0.781044 209.778 2.61454 209.778 4.87627V32.519C209.778 34.7808 211.611 36.6143 213.871 36.6143Z"
    fill="#3B246B"
  />
  <path
    d="M221.463 22.4268L257.153 22.4268C259.327 22.4268 261.09 20.6633 261.09 18.488V11.5934C261.09 9.41813 259.327 7.6547 257.153 7.6547L221.463 7.6547C219.289 7.6547 217.526 9.41813 217.526 11.5934V18.488C217.526 20.6633 219.289 22.4268 221.463 22.4268Z"
    fill="#58399A"
  />
  <path d="M142.53 34.8594H138.145V52.4103H142.53V34.8594Z" fill="#3B246B" />
  <path d="M107.593 33.543H103.207V51.0939H107.593V33.543Z" fill="#3B246B" />
  <path d="M175.716 35.7363H171.33V53.2873H175.716V35.7363Z" fill="#3B246B" />
  <path d="M194.28 9.99512H189.895V27.5461H194.28V9.99512Z" fill="#3B246B" />
  <path d="M168.114 11.6035H163.729V29.1545H168.114V11.6035Z" fill="#3B246B" />
  <path d="M168.114 9.99512H163.729V27.5461H168.114V9.99512Z" fill="#3B246B" />
  <path d="M99.5516 8.38574H95.166V25.9367H99.5516V8.38574Z" fill="#3B246B" />
  <ellipse cx="219.68" cy="37.0361" rx="15.9062" ry="17.0361" fill="#185E45" />
  <ellipse cx="228.094" cy="48.8623" rx="15.9062" ry="17.0361" fill="#069461" />
  <ellipse cx="209.559" cy="41.8228" rx="14.7231" ry="15.9098" fill="#185E45" />
  <rect
    x="212.188"
    y="65.8994"
    width="12.094"
    height="32.1011"
    rx="6.04698"
    fill="url(#paint2_linear_909_5)"
  />
  <ellipse cx="228.094" cy="69.9814" rx="15.9062" ry="17.0361" fill="#069461" />
  <ellipse cx="203.906" cy="61.9568" rx="15.9062" ry="16.8953" fill="#D9D9D9" />
  <ellipse cx="203.906" cy="61.9568" rx="15.9062" ry="16.8953" fill="#069461" />
  <path
    d="M235.799 57.0596H431.201C433.299 57.0596 435 58.7615 435 60.8609V112.586C435 114.685 433.299 116.387 431.201 116.387H235.799C233.701 116.387 232 114.685 232 112.586V60.8609C232 58.7615 233.701 57.0596 235.799 57.0596Z"
    fill="#3B246B"
  />
  <path
    d="M243.797 67.9209H423.477C425.495 67.9209 427.131 69.5578 427.131 71.577V77.9768C427.131 79.9959 425.495 81.6328 423.477 81.6328H243.797C241.779 81.6328 240.143 79.9959 240.143 77.9768V71.577C240.143 69.5578 241.779 67.9209 243.797 67.9209Z"
    fill="#58399A"
  />
  <path
    d="M243.797 96.9736H423.477C425.495 96.9736 427.131 98.6105 427.131 100.63V107.029C427.131 109.049 425.495 110.686 423.477 110.686H243.797C241.779 110.686 240.143 109.049 240.143 107.029V100.63C240.143 98.6105 241.779 96.9736 243.797 96.9736Z"
    fill="#58399A"
  />
  <path
    d="M290.755 50H338.656C340.754 50 342.455 51.7019 342.455 53.8013V113.265C342.455 115.364 340.754 117.066 338.656 117.066H290.755C288.657 117.066 286.956 115.364 286.956 113.265V53.8013C286.956 51.7019 288.657 50 290.755 50Z"
    fill="#4C2F89"
  />
  <circle cx="372.834" cy="102.994" r="21.5059" fill="#069461" />
  <circle cx="358.971" cy="99.084" r="20.084" fill="#069461" />
  <rect
    x="362.703"
    y="129.477"
    width="16.3516"
    height="40.5234"
    rx="8.17578"
    fill="url(#paint3_linear_909_5)"
  />
  <circle cx="384.209" cy="120.768" r="21.5059" fill="#185E45" />
  <ellipse cx="351.506" cy="124.5" rx="21.5059" ry="21.3281" fill="#D9D9D9" />
  <ellipse cx="351.506" cy="124.5" rx="21.5059" ry="21.3281" fill="#069461" />
  <path
    d="M1052.09 603.469C1049.5 603.469 1047.4 601.368 1047.4 598.778V564.691C1047.4 562.102 1049.5 560 1052.09 560C1054.68 560 1056.78 562.102 1056.78 564.691V598.778C1056.78 601.368 1054.68 603.469 1052.09 603.469Z"
    fill="#2F246B"
  />
  <path
    d="M1193.87 595.285H1038.79C1034.23 595.285 1030.53 598.986 1030.53 603.551V643.051C1030.53 647.616 1034.23 651.317 1038.79 651.317H1193.87C1198.43 651.317 1202.13 647.616 1202.13 643.051V603.551C1202.13 598.986 1198.43 595.285 1193.87 595.285Z"
    fill="#45399A"
  />
  <path
    d="M1207.33 598.77H1025.69L1075.43 564.826H1157.58L1207.33 598.77Z"
    fill="#2F246B"
  />
  <path
    d="M1207.33 603.46H1025.69C1023.63 603.46 1021.82 602.119 1021.21 600.151C1020.6 598.184 1021.35 596.052 1023.05 594.895L1072.79 560.951C1073.57 560.419 1074.49 560.135 1075.43 560.135H1157.58C1158.52 560.135 1159.44 560.419 1160.22 560.951L1209.97 594.895C1211.67 596.055 1212.41 598.188 1211.81 600.151C1211.2 602.119 1209.38 603.46 1207.33 603.46H1207.33ZM1040.88 594.078H1192.13L1156.13 569.517H1076.88L1040.88 594.078H1040.88Z"
    fill="#2F246B"
  />
  <path
    d="M1104.48 612.773H1127.55C1130.76 612.773 1133.37 615.378 1133.37 618.59V651.317H1098.67V618.59C1098.67 615.382 1101.28 612.773 1104.49 612.773H1104.48Z"
    fill="#FEAC18"
  />
  <path
    d="M1178.13 612.773H1154.55C1151.48 612.773 1148.99 615.264 1148.99 618.337V636.997C1148.99 640.07 1151.48 642.561 1154.55 642.561H1178.13C1181.2 642.561 1183.69 640.07 1183.69 636.997V618.337C1183.69 615.264 1181.2 612.773 1178.13 612.773Z"
    fill="#FEAC18"
  />
  <path
    d="M1077.17 612.773H1053.59C1050.52 612.773 1048.03 615.264 1048.03 618.337V636.997C1048.03 640.07 1050.52 642.561 1053.59 642.561H1077.17C1080.24 642.561 1082.73 640.07 1082.73 636.997V618.337C1082.73 615.264 1080.24 612.773 1077.17 612.773Z"
    fill="#FEAC18"
  />
  <path
    d="M837.71 571.897C834.987 571.897 832.777 569.727 832.777 567.052V531.845C832.777 529.171 834.987 527 837.71 527C840.433 527 842.642 529.171 842.642 531.845V567.052C842.642 569.727 840.433 571.897 837.71 571.897Z"
    fill="#2F246B"
  />
  <path
    d="M1028.76 571.897C1026.04 571.897 1023.83 569.727 1023.83 567.052V531.845C1023.83 529.171 1026.04 527 1028.76 527C1031.49 527 1033.7 529.171 1033.7 531.845V567.052C1033.7 569.727 1031.49 571.897 1028.76 571.897Z"
    fill="#2F246B"
  />
  <path
    d="M1041.12 563.444H823.715C818.915 563.444 815.023 567.266 815.023 571.981V612.78C815.023 617.495 818.915 621.317 823.715 621.317H1041.12C1045.92 621.317 1049.81 617.495 1049.81 612.78V571.981C1049.81 567.266 1045.92 563.444 1041.12 563.444Z"
    fill="#45399A"
  />
  <path
    d="M1055.07 567.043H809.932L862.266 531.984H1002.74L1055.07 567.043Z"
    fill="#2F246B"
  />
  <path
    d="M1055.07 571.887H809.932C807.768 571.887 805.858 570.502 805.22 568.47C804.582 566.438 805.364 564.235 807.153 563.04L859.487 527.982C860.306 527.433 861.276 527.139 862.266 527.139H1002.73C1003.73 527.139 1004.69 527.433 1005.51 527.982L1057.85 563.04C1059.64 564.239 1060.42 566.442 1059.78 568.47C1059.14 570.502 1057.23 571.887 1055.07 571.887H1055.07ZM825.917 562.197H1039.08L1001.21 536.829H863.785L825.913 562.197H825.917Z"
    fill="#2F246B"
  />
  <path
    d="M892.822 581.507H917.093C920.467 581.507 923.21 584.197 923.21 587.515V621.317H886.709V587.515C886.709 584.201 889.448 581.507 892.825 581.507H892.822Z"
    fill="#FEAC18"
  />
  <path
    d="M947.08 581.507H971.351C974.725 581.507 977.467 584.197 977.467 587.515V621.317H940.967V587.515C940.967 584.201 943.706 581.507 947.083 581.507H947.08Z"
    fill="#FEAC18"
  />
  <path
    d="M1025.55 581.507H1000.74C997.514 581.507 994.895 584.079 994.895 587.253V606.527C994.895 609.7 997.514 612.273 1000.74 612.273H1025.55C1028.78 612.273 1031.4 609.7 1031.4 606.527V587.253C1031.4 584.079 1028.78 581.507 1025.55 581.507Z"
    fill="#FEAC18"
  />
  <path
    d="M864.088 581.507H839.287C836.057 581.507 833.438 584.079 833.438 587.253V606.527C833.438 609.7 836.057 612.273 839.287 612.273H864.088C867.319 612.273 869.938 609.7 869.938 606.527V587.253C869.938 584.079 867.319 581.507 864.088 581.507Z"
    fill="#FEAC18"
  />
  <circle
    cx="34.3681"
    cy="34.3681"
    r="34.3681"
    transform="matrix(-1 0 0 1 1250.92 531.344)"
    fill="#069461"
  />
  <circle
    cx="32.0958"
    cy="32.0958"
    r="32.0958"
    transform="matrix(-1 0 0 1 1270.8 527.367)"
    fill="#069461"
  />
  <rect
    width="26.1311"
    height="64.7598"
    rx="13.0656"
    transform="matrix(-1 0 0 1 1232.74 608.033)"
    fill="url(#paint4_linear_909_5)"
  />
  <circle
    cx="34.3681"
    cy="34.3681"
    r="34.3681"
    transform="matrix(-1 0 0 1 1232.74 559.747)"
    fill="#185E45"
  />
  <ellipse
    cx="34.3681"
    cy="34.0841"
    rx="34.3681"
    ry="34.0841"
    transform="matrix(-1 0 0 1 1285 565.996)"
    fill="#D9D9D9"
  />
  <ellipse
    cx="34.3681"
    cy="34.0841"
    rx="34.3681"
    ry="34.0841"
    transform="matrix(-1 0 0 1 1285 565.996)"
    fill="#069461"
  />
  <path
    d="M290.744 530.613H347.657C349.739 530.613 351.426 528.925 351.426 526.843V479.31C351.426 477.228 349.739 475.54 347.657 475.54H290.744C288.663 475.54 286.975 477.228 286.975 479.31V526.843C286.975 528.925 288.663 530.613 290.744 530.613Z"
    fill="#FF3883"
  />
  <path
    d="M372.754 423H251.422C247.875 423 245 425.877 245 429.425V479.45C245 482.999 247.875 485.876 251.422 485.876H372.754C376.301 485.876 379.176 482.999 379.176 479.45V429.425C379.176 425.877 376.301 423 372.754 423Z"
    fill="#58399A"
  />
  <path
    d="M372.754 473.024H251.422C247.875 473.024 245 475.901 245 479.45C245 482.998 247.875 485.875 251.422 485.875H372.754C376.301 485.875 379.176 482.998 379.176 479.45C379.176 475.901 376.301 473.024 372.754 473.024Z"
    fill="#4C2F89"
  />
  <path
    d="M619.317 431.721H341.332C337.785 431.721 334.91 434.597 334.91 438.146V525.575C334.91 529.124 337.785 532 341.332 532H619.317C622.864 532 625.74 529.124 625.74 525.575V438.146C625.74 434.597 622.864 431.721 619.317 431.721Z"
    fill="#3B246B"
  />
  <path d="M608.538 450.079H334.91V473.256H608.538V450.079Z" fill="#58399A" />
  <path d="M608.538 499.187H334.91V522.363H608.538V499.187Z" fill="#58399A" />
  <path
    d="M778.349 482.326H547.918C544.836 482.326 542.338 484.934 542.338 488.151V545.113C542.338 548.33 544.836 550.938 547.918 550.938H778.349C781.431 550.938 783.929 548.33 783.929 545.113V488.151C783.929 484.934 781.431 482.326 778.349 482.326Z"
    fill="#43246B"
  />
  <path
    d="M782.485 427.237H539.127C536.045 427.237 533.547 429.845 533.547 433.062V490.025C533.547 493.242 536.045 495.849 539.127 495.849H782.485C785.567 495.849 788.065 493.242 788.065 490.025V433.062C788.065 429.845 785.567 427.237 782.485 427.237Z"
    fill="#64399A"
  />
  <path
    d="M640.974 529.174H516.58C513.498 529.174 511 531.782 511 534.999V554.583C511 557.8 513.498 560.408 516.58 560.408H640.974C644.056 560.408 646.554 557.8 646.554 554.583V534.999C646.554 531.782 644.056 529.174 640.974 529.174Z"
    fill="#64399A"
  />
  <path
    d="M729.438 508.299H721.088C717.503 508.299 714.598 511.332 714.598 515.073V529.401C714.598 533.142 717.503 536.175 721.088 536.175H729.438C733.022 536.175 735.928 533.142 735.928 529.401V515.073C735.928 511.332 733.022 508.299 729.438 508.299Z"
    fill="#64399A"
  />
  <path
    d="M612.118 508.299H603.767C600.183 508.299 597.277 511.332 597.277 515.073V529.401C597.277 533.142 600.183 536.175 603.767 536.175H612.118C615.702 536.175 618.608 533.142 618.608 529.401V515.073C618.608 511.332 615.702 508.299 612.118 508.299Z"
    fill="#64399A"
  />
  <path
    d="M762.165 508.299H753.814C750.23 508.299 747.324 511.332 747.324 515.073V529.401C747.324 533.142 750.23 536.175 753.814 536.175H762.165C765.749 536.175 768.655 533.142 768.655 529.401V515.073C768.655 511.332 765.749 508.299 762.165 508.299Z"
    fill="#64399A"
  />
  <path
    d="M668.666 504.039H687.301C690.884 504.039 693.791 507.076 693.791 510.813V550.938H662.174V510.813C662.174 507.073 665.083 504.039 668.664 504.039H668.666Z"
    fill="#64399A"
  />
  <path
    d="M788.066 434.475H533.465C529.635 434.475 526.531 431.236 526.531 427.238C526.531 423.24 529.635 420 533.465 420H788.066C791.896 420 795 423.24 795 427.238C795 431.236 791.896 434.475 788.066 434.475Z"
    fill="#43246B"
  />
  <path
    d="M773.535 445.561H729.105C725.521 445.561 722.615 448.594 722.615 452.335V470.921C722.615 474.662 725.521 477.695 729.105 477.695H773.535C777.12 477.695 780.025 474.662 780.025 470.921V452.335C780.025 448.594 777.12 445.561 773.535 445.561Z"
    fill="#FF3883"
  />
  <path
    d="M674.52 445.477H642.015C638.431 445.477 635.525 448.51 635.525 452.251V470.837C635.525 474.578 638.431 477.611 642.015 477.611H674.52C678.104 477.611 681.01 474.578 681.01 470.837V452.251C681.01 448.51 678.104 445.477 674.52 445.477Z"
    fill="#FF3883"
  />
  <path
    d="M590.766 445.477H558.261C554.677 445.477 551.771 448.51 551.771 452.251V470.837C551.771 474.578 554.677 477.611 558.261 477.611H590.766C594.35 477.611 597.256 474.578 597.256 470.837V452.251C597.256 448.51 594.35 445.477 590.766 445.477Z"
    fill="#FF3883"
  />
  <path
    d="M788.066 489.482H533.465C529.635 489.482 526.531 486.242 526.531 482.244C526.531 478.246 529.635 475.007 533.465 475.007H788.066C791.896 475.007 795 478.246 795 482.244C795 486.242 791.896 489.482 788.066 489.482Z"
    fill="#43246B"
  />
  <path
    d="M785.568 556.647H611.396C607.566 556.647 604.463 553.407 604.463 549.409C604.463 545.411 607.566 542.172 611.396 542.172H785.568C789.398 542.172 792.502 545.411 792.502 549.409C792.502 553.407 789.398 556.647 785.568 556.647Z"
    fill="#64399A"
  />
  <path
    d="M695.713 504.247H660.49C658.194 504.247 656.33 502.301 656.33 499.904C656.33 497.507 658.194 495.562 660.49 495.562H695.713C698.009 495.562 699.873 497.507 699.873 499.904C699.873 502.301 698.009 504.247 695.713 504.247Z"
    fill="#43246B"
  />
  <path
    d="M716.866 275.727H473.756C468.619 275.727 464.455 279.893 464.455 285.032V361.23C464.455 366.369 468.619 370.536 473.756 370.536H716.866C722.002 370.536 726.167 366.369 726.167 361.23V285.032C726.167 279.893 722.002 275.727 716.866 275.727Z"
    fill="#3B246B"
  />
  <path
    d="M483.059 370.535H473.731C468.612 370.535 464.46 366.377 464.46 361.259V285.005C464.46 279.884 468.615 275.729 473.731 275.729H473.787C478.906 275.729 483.059 279.887 483.059 285.005V370.538V370.535Z"
    fill="#4C2F89"
  />
  <path
    d="M716.752 275.727C721.885 275.727 726.053 279.896 726.053 285.032V361.23C726.053 366.366 721.885 370.536 716.752 370.536H707.451V285.032C707.451 279.896 711.619 275.727 716.752 275.727Z"
    fill="#4C2F89"
  />
  <path
    d="M526.934 275.727H537.331C542.464 275.727 546.632 279.896 546.632 285.032V370.536H517.633V285.032C517.633 279.896 521.801 275.727 526.934 275.727Z"
    fill="#4C2F89"
  />
  <path
    d="M567.361 334.871H559.994C556.943 334.871 554.469 337.346 554.469 340.399V353.714C554.469 356.767 556.943 359.242 559.994 359.242H567.361C570.413 359.242 572.887 356.767 572.887 353.714V340.399C572.887 337.346 570.413 334.871 567.361 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M567.361 292.966H559.994C556.943 292.966 554.469 295.441 554.469 298.494V311.809C554.469 314.862 556.943 317.337 559.994 317.337H567.361C570.413 317.337 572.887 314.862 572.887 311.809V298.494C572.887 295.441 570.413 292.966 567.361 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M535.869 292.966H528.502C525.45 292.966 522.977 295.441 522.977 298.494V311.809C522.977 314.862 525.45 317.337 528.502 317.337H535.869C538.921 317.337 541.394 314.862 541.394 311.809V298.494C541.394 295.441 538.921 292.966 535.869 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M504.084 292.966H496.717C493.665 292.966 491.191 295.441 491.191 298.494V311.809C491.191 314.862 493.665 317.337 496.717 317.337H504.084C507.135 317.337 509.609 314.862 509.609 311.809V298.494C509.609 295.441 507.135 292.966 504.084 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M504.084 334.871H496.717C493.665 334.871 491.191 337.346 491.191 340.399V353.714C491.191 356.767 493.665 359.242 496.717 359.242H504.084C507.135 359.242 509.609 356.767 509.609 353.714V340.399C509.609 337.346 507.135 334.871 504.084 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M528.503 334.871H535.873C538.921 334.871 541.398 337.347 541.398 340.399V370.536H522.98V340.399C522.98 337.35 525.455 334.871 528.506 334.871H528.503Z"
    fill="#FEAC18"
  />
  <path
    d="M590.207 275.727H600.604C605.738 275.727 609.905 279.896 609.905 285.032V370.536H580.906V285.032C580.906 279.896 585.074 275.727 590.207 275.727Z"
    fill="#4C2F89"
  />
  <path
    d="M599.144 292.966H591.777C588.726 292.966 586.252 295.441 586.252 298.494V311.809C586.252 314.862 588.726 317.337 591.777 317.337H599.144C602.196 317.337 604.67 314.862 604.67 311.809V298.494C604.67 295.441 602.196 292.966 599.144 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M567.361 292.966H559.994C556.943 292.966 554.469 295.441 554.469 298.494V311.809C554.469 314.862 556.943 317.337 559.994 317.337H567.361C570.413 317.337 572.887 314.862 572.887 311.809V298.494C572.887 295.441 570.413 292.966 567.361 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M567.361 334.871H559.994C556.943 334.871 554.469 337.346 554.469 340.399V353.714C554.469 356.767 556.943 359.242 559.994 359.242H567.361C570.413 359.242 572.887 356.767 572.887 353.714V340.399C572.887 337.346 570.413 334.871 567.361 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M654.074 275.727H664.472C669.605 275.727 673.773 279.896 673.773 285.032V370.536H644.773V285.032C644.773 279.896 648.941 275.727 654.074 275.727Z"
    fill="#4C2F89"
  />
  <path
    d="M663.012 292.966H655.645C652.593 292.966 650.119 295.441 650.119 298.494V311.809C650.119 314.862 652.593 317.337 655.645 317.337H663.012C666.063 317.337 668.537 314.862 668.537 311.809V298.494C668.537 295.441 666.063 292.966 663.012 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M631.224 292.966H623.857C620.806 292.966 618.332 295.441 618.332 298.494V311.809C618.332 314.862 620.806 317.337 623.857 317.337H631.224C634.276 317.337 636.75 314.862 636.75 311.809V298.494C636.75 295.441 634.276 292.966 631.224 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M631.224 334.871H623.857C620.806 334.871 618.332 337.346 618.332 340.399V353.714C618.332 356.767 620.806 359.242 623.857 359.242H631.224C634.276 359.242 636.75 356.767 636.75 353.714V340.399C636.75 337.346 634.276 334.871 631.224 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M693.904 292.966H686.537C683.486 292.966 681.012 295.441 681.012 298.494V311.809C681.012 314.862 683.486 317.337 686.537 317.337H693.904C696.956 317.337 699.43 314.862 699.43 311.809V298.494C699.43 295.441 696.956 292.966 693.904 292.966Z"
    fill="#FEAC18"
  />
  <path
    d="M693.904 334.871H686.537C683.486 334.871 681.012 337.346 681.012 340.399V353.714C681.012 356.767 683.486 359.242 686.537 359.242H693.904C696.956 359.242 699.43 356.767 699.43 353.714V340.399C699.43 337.346 696.956 334.871 693.904 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M599.144 334.871H591.777C588.726 334.871 586.252 337.346 586.252 340.399V353.714C586.252 356.767 588.726 359.242 591.777 359.242H599.144C602.196 359.242 604.67 356.767 604.67 353.714V340.399C604.67 337.346 602.196 334.871 599.144 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M663.012 334.871H655.645C652.593 334.871 650.119 337.346 650.119 340.399V353.714C650.119 356.767 652.593 359.242 655.645 359.242H663.012C666.063 359.242 668.537 356.767 668.537 353.714V340.399C668.537 337.346 666.063 334.871 663.012 334.871Z"
    fill="#FEAC18"
  />
  <path
    d="M723.699 268H469.301C464.164 268 460 272.166 460 277.306C460 282.445 464.164 286.611 469.301 286.611H723.699C728.836 286.611 733 282.445 733 277.306C733 272.166 728.836 268 723.699 268Z"
    fill="#3B246B"
  />
  <defs>
    <linearGradient
      id="paint0_linear_909_5"
      x1="1036.89"
      y1="291.048"
      x2="1036.89"
      y2="336.541"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_909_5"
      x1="878.885"
      y1="343.679"
      x2="878.885"
      y2="408"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_909_5"
      x1="218.234"
      y1="65.8994"
      x2="218.234"
      y2="98.0005"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_909_5"
      x1="370.879"
      y1="129.477"
      x2="370.879"
      y2="170"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint4_linear_909_5"
      x1="13.0656"
      y1="0"
      x2="13.0656"
      y2="64.7598"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>
