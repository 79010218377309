<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 392 392"
  enable-background="new 0 0 392 392"
  xml:space="preserve"
>
  <path
    fill="#FDFEFE"
    opacity="1.000000"
    stroke="none"
    d="
M1.000000,144.000000 
	C1.000000,96.000008 1.000000,48.500015 1.000000,1.000019 
	C131.666641,1.000013 262.333282,1.000013 392.999939,1.000006 
	C392.999939,131.666611 392.999939,262.333221 392.999969,392.999878 
	C262.333405,392.999939 131.666824,392.999939 1.000118,392.999969 
	C1.000000,310.166656 1.000000,227.333328 1.000000,144.000000 
M126.367767,295.753998 
	C126.668343,295.588104 126.895149,295.351501 127.648743,295.127838 
	C128.051819,295.119720 128.454895,295.111633 129.294266,295.451019 
	C129.468658,295.971649 129.643051,296.492279 129.289429,297.638489 
	C129.222687,302.082520 129.155930,306.526550 128.604462,311.316406 
	C129.277588,311.937744 129.950699,312.559082 130.914871,313.828918 
	C131.939072,314.301758 132.963272,314.774628 134.547333,315.711578 
	C137.337601,315.152069 140.127853,314.592560 143.435654,314.442902 
	C144.267761,314.716553 145.099869,314.990204 146.137558,315.917816 
	C147.783478,315.714417 149.429382,315.510986 151.691635,315.109253 
	C152.115936,315.032043 152.540237,314.954834 153.343216,315.415253 
	C154.115570,315.095306 155.185211,314.995270 155.611313,314.419220 
	C158.091751,311.065796 158.162949,307.380829 156.834915,302.796631 
	C156.862076,301.206116 156.889236,299.615631 157.402908,297.713074 
	C156.958801,297.131989 156.514679,296.550934 155.930908,295.596375 
	C155.930908,295.596375 155.860641,295.203857 155.898453,294.424774 
	C153.985992,290.042297 148.152588,287.925964 143.166534,290.793793 
	C139.304337,293.015198 137.744049,290.306641 135.514450,288.730377 
	C134.083420,287.718658 132.910645,286.341614 131.622086,285.128357 
	C130.390335,287.048035 129.158585,288.967712 127.508354,291.004120 
	C127.508354,291.004120 127.080154,291.080902 126.263977,291.032166 
	C125.178192,291.018097 124.092415,291.004059 122.642319,290.557495 
	C122.103149,291.044403 121.563980,291.531311 120.799141,292.444244 
	C120.617058,292.719055 120.378647,292.930298 119.275436,293.182098 
	C118.871407,297.086304 118.164528,300.990479 118.164261,304.894714 
	C118.164062,307.746582 117.724327,309.925140 114.415321,311.027679 
	C113.955841,310.685120 113.496361,310.342560 113.045708,309.103821 
	C112.805511,305.392303 112.565323,301.680756 112.477180,297.320068 
	C112.649490,296.215057 112.821800,295.110016 113.491936,293.608765 
	C112.604698,292.477783 111.271576,290.235535 110.898262,290.385315 
	C106.504158,292.148499 103.658310,289.972229 100.332436,286.910309 
	C99.881668,286.908020 99.430893,286.905762 98.583511,286.464691 
	C98.044861,287.305756 97.506203,288.146851 96.351845,289.351074 
	C94.761627,289.825104 93.196106,290.431702 91.573875,290.733917 
	C90.104401,291.007660 88.573082,290.949524 86.336151,291.019440 
	C85.849564,291.037231 85.362984,291.054993 84.109367,291.077637 
	C83.609047,291.186920 83.108719,291.296173 82.074142,291.287384 
	C81.724693,291.183105 81.375244,291.078857 81.035835,290.222748 
	C80.342033,289.161774 79.648224,288.100769 79.000618,287.001221 
	C79.000618,287.001221 78.960487,287.046600 78.939392,286.444122 
	C78.526695,286.130676 78.114006,285.817261 77.701302,285.503815 
	C77.484207,286.021881 77.267113,286.539978 76.999176,287.001221 
	C76.999176,287.001221 77.056190,287.051086 76.359703,287.083405 
	C75.322365,288.812195 74.285027,290.541016 73.189835,292.659119 
	C73.189835,292.659119 73.064278,293.032135 72.221001,293.009796 
	C71.384430,293.088715 70.547859,293.167664 69.134056,292.806763 
	C66.445999,292.263672 63.757950,291.720551 60.722656,290.637756 
	C57.234535,291.398590 56.567814,292.214508 57.068810,295.856812 
	C57.102768,297.219055 57.136730,298.581299 56.622898,300.297455 
	C56.779144,302.563507 56.935390,304.829559 57.121326,307.064880 
	C57.121326,307.064880 57.115856,307.109344 56.400604,307.110199 
	C54.270084,308.580658 54.205498,309.926117 57.043106,311.667480 
	C58.330147,312.875305 59.617184,314.083130 61.112946,315.899384 
	C62.449841,315.726227 63.786732,315.553040 65.740555,315.216309 
	C66.162140,315.161530 66.583725,315.106750 67.421341,315.502014 
	C71.620575,314.160645 72.604767,310.755615 71.799629,307.151215 
	C71.396713,305.347534 69.155739,303.954437 67.673637,302.326019 
	C67.673637,302.326019 67.592583,302.292145 67.898643,301.558807 
	C69.922859,300.054108 71.947067,298.549408 73.991165,297.968170 
	C73.996170,301.751770 73.680130,305.576630 74.158195,309.299469 
	C74.360146,310.872162 76.234100,312.230164 78.019318,313.977295 
	C79.665009,314.260925 81.310699,314.544525 83.212357,315.330322 
	C83.781128,315.169281 84.349899,315.008240 85.669685,314.970520 
	C87.557007,315.235443 89.808014,317.752258 91.160278,313.599304 
	C91.216995,313.439148 91.273720,313.278992 92.032524,312.845215 
	C92.348946,310.562256 92.665367,308.279297 93.686249,305.996277 
	C94.156555,305.978851 94.626869,305.961456 95.619797,306.433411 
	C96.577408,307.970642 97.535027,309.507904 98.583473,311.784485 
	C98.774933,312.502655 98.966400,313.220795 98.514442,314.037415 
	C98.285103,314.571228 98.055756,315.105042 97.826416,315.638855 
	C98.593933,315.343933 99.361450,315.049011 100.906479,314.810547 
	C101.941147,314.890808 102.975815,314.971069 104.316055,315.530182 
	C104.853462,315.671539 105.390869,315.812866 106.714699,315.981323 
	C108.226974,315.607086 109.739250,315.232880 111.816010,314.891052 
	C112.181473,314.982849 112.546928,315.074677 112.924820,315.639191 
	C112.924820,315.639191 112.973434,316.109589 113.065094,316.678040 
	C113.719963,316.759064 114.374825,316.840088 115.622765,316.861572 
	C116.122002,316.331848 116.621231,315.802124 117.747467,315.057617 
	C118.782608,314.817017 119.817757,314.576416 121.051315,314.936768 
	C122.019775,315.068909 122.988235,315.201050 123.956703,315.333191 
	C123.958656,314.548920 123.960609,313.764648 123.980827,312.070068 
	C124.210640,307.002930 124.440453,301.935760 125.236610,296.799530 
	C125.460838,296.515930 125.685059,296.232330 126.367767,295.753998 
M320.969666,60.947456 
	C320.969666,60.947456 320.943756,60.944241 320.296387,61.048687 
	C319.964783,61.749458 319.633209,62.450226 319.162994,63.606945 
	C319.162994,63.606945 319.058655,64.071930 318.345245,64.140022 
	C318.016022,65.153534 317.686829,66.167053 317.191589,67.774704 
	C317.124969,68.180222 317.058319,68.585747 316.278625,69.111206 
	C315.840698,70.409637 315.402771,71.708061 314.893311,73.404015 
	C314.893311,73.404015 314.899933,73.807869 314.279114,74.056747 
	C313.914764,75.703522 313.550415,77.350296 313.065247,79.645096 
	C313.025116,80.082474 312.984985,80.519852 312.225433,81.130920 
	C311.830505,81.804977 311.141602,82.455444 311.085327,83.156685 
	C310.389404,91.824867 309.551147,100.493393 309.290222,109.178085 
	C309.203369,112.069176 310.657623,115.006561 311.349579,118.547119 
	C311.401672,118.960724 311.453766,119.374329 311.196136,120.562202 
	C311.790863,122.047394 312.385590,123.532585 312.997131,125.684937 
	C313.055084,126.125702 313.113007,126.566467 312.593750,127.419983 
	C313.379303,129.627930 314.164856,131.835876 314.982178,134.040329 
	C314.982178,134.040329 314.988831,134.009018 314.923218,134.715302 
	C315.026367,135.174530 315.129517,135.633759 315.197723,136.769196 
	C315.318085,137.431137 315.438446,138.093063 315.281219,139.507492 
	C316.287781,142.825012 316.984039,146.277222 318.355316,149.436417 
	C323.899994,162.210388 329.883453,174.798309 335.219147,187.656509 
	C337.474274,193.091156 339.164825,198.935043 339.981384,204.751633 
	C341.282349,214.018631 337.014954,222.203461 333.184631,230.269089 
	C330.462128,236.001938 327.259827,241.506943 323.973663,247.697937 
	C323.307434,248.786407 322.641205,249.874863 321.422943,251.168030 
	C320.929962,251.767609 320.436951,252.367172 319.274323,253.086838 
	C317.453339,255.755951 313.109406,257.142365 315.051880,263.397949 
	C317.572968,260.768066 319.322113,258.943451 321.482697,256.586548 
	C322.225006,256.221954 323.076721,255.988449 323.693634,255.473587 
	C339.895874,241.951752 354.478302,226.990875 363.020203,207.337067 
	C365.326324,202.030960 367.013672,195.650452 366.370056,190.064407 
	C365.495056,182.470016 362.437683,175.042419 359.732391,167.760193 
	C352.925171,149.436340 345.401123,131.371048 338.919525,112.936615 
	C334.344391,99.924301 333.778778,86.403816 336.727722,72.731018 
	C340.222229,56.528778 346.263611,41.358036 353.972870,26.768751 
	C354.817505,25.170300 355.255890,23.357195 355.882721,21.643654 
	C353.465515,21.581215 351.734253,21.997885 350.711975,23.082703 
	C342.775177,31.505167 334.937775,40.022873 327.199493,48.627934 
	C326.537079,49.364586 326.814880,50.946781 326.578003,52.562702 
	C325.734558,53.398827 324.891083,54.234951 323.370514,55.102146 
	C322.624634,56.406212 321.878754,57.710274 320.975403,59.656418 
	C320.972137,60.095245 320.968872,60.534069 320.969666,60.947456 
M32.998558,283.001923 
	C32.998558,283.001923 32.941029,283.072693 32.907448,282.398621 
	C31.920547,282.557739 30.933647,282.716827 29.414211,283.538483 
	C29.312836,293.348663 29.211460,303.158813 28.599215,313.293854 
	C29.407694,313.838379 30.216173,314.382904 31.761755,314.602509 
	C32.248329,312.765625 33.108833,310.938416 33.156731,309.090149 
	C33.363876,301.097778 33.333336,293.099274 33.912651,284.927521 
	C33.584454,284.304932 33.256256,283.682312 32.998558,283.001923 
M89.999695,347.000000 
	C89.999695,347.000000 90.013580,346.983215 89.964104,346.299652 
	C88.966499,345.536285 87.968895,344.772919 86.453354,343.439301 
	C83.734161,343.718445 81.014969,343.997559 77.741341,344.274536 
	C77.507133,344.540863 77.272926,344.807190 76.747192,345.576233 
	C76.186119,346.099243 75.625038,346.622223 74.668686,346.566132 
	C72.402367,344.362305 70.199532,342.086487 67.830704,339.998962 
	C67.129196,339.380768 65.960167,339.293091 64.383049,339.018799 
	C63.966934,339.037292 63.550819,339.055786 62.377537,339.025665 
	C60.916061,341.017517 59.454586,343.009369 57.377205,345.053986 
	C56.905556,345.403076 56.433910,345.752136 55.305950,346.032349 
	C54.866234,346.025604 54.426517,346.018829 53.611839,345.524750 
	C52.752110,344.994385 51.892380,344.463989 50.862080,343.193237 
	C46.769428,340.952576 43.214481,342.364716 39.066605,345.127411 
	C38.723080,351.146057 38.361507,357.163757 38.044685,363.183807 
	C37.913136,365.683380 38.166958,367.968567 41.533123,367.951630 
	C44.922092,367.934570 44.891472,365.582245 45.010616,363.125641 
	C45.223564,358.735107 45.621735,354.353546 46.638668,350.049286 
	C47.745892,350.369354 48.853115,350.689423 50.154003,351.429382 
	C50.309090,351.708435 50.531719,351.914520 50.904533,352.971466 
	C50.914078,355.629028 50.665722,358.321228 51.016663,360.932892 
	C51.286152,362.938385 52.310856,364.842407 52.999710,366.791534 
	C53.770027,364.882385 54.540344,362.973236 55.659641,360.788849 
	C55.852177,360.556976 56.004917,360.301300 56.873676,359.813354 
	C57.249252,357.812347 57.473698,355.766327 58.052147,353.825775 
	C58.455509,352.472656 59.344097,351.264191 60.020638,350.858521 
	C60.356693,355.242615 60.430450,359.673431 61.197483,363.980774 
	C61.412193,365.186493 63.669712,366.028473 65.825653,367.070129 
	C67.217896,367.052643 68.610138,367.035156 70.381569,367.509552 
	C71.981209,366.536163 73.580849,365.562775 75.779694,364.855652 
	C76.827988,365.641052 77.876289,366.426422 79.074333,367.862366 
	C81.259361,370.158356 83.364983,371.230194 85.781242,367.242767 
	C86.574371,366.858521 87.367500,366.474304 88.854141,365.972809 
	C91.888565,364.356293 93.088211,362.170319 90.989922,358.161530 
	C90.911354,357.623627 90.832779,357.085754 90.791710,356.033081 
	C90.884010,355.701904 90.976311,355.370697 91.785660,355.018494 
	C92.501755,355.041504 93.217842,355.064545 93.930191,355.951782 
	C94.954338,359.969391 95.978485,363.986969 96.283295,368.141022 
	C94.569084,370.052032 93.911263,371.804260 97.557419,373.275726 
	C98.343193,373.935699 99.128967,374.595673 100.066917,375.933380 
	C102.758736,379.616028 104.986450,378.114288 107.613052,375.075867 
	C107.975433,374.976746 108.337814,374.877625 109.465065,374.863617 
	C110.954155,372.882782 113.568985,370.987671 113.727570,368.905487 
	C114.268044,361.809082 114.200928,354.631134 113.768234,347.520660 
	C113.659370,345.731506 111.653946,343.409302 109.916618,342.544769 
	C108.069618,341.625671 105.506783,342.107391 103.254646,342.048279 
	C99.029533,341.937439 94.988914,345.436981 93.970329,350.642731 
	C93.962479,351.070557 93.954628,351.498352 93.266869,352.002991 
	C92.587578,351.968201 91.908279,351.933441 91.126091,351.180450 
	C91.073555,350.456940 91.021019,349.733398 91.436066,348.680420 
	C90.962967,348.115906 90.489868,347.551392 89.999695,347.000000 
M147.353577,365.851959 
	C147.423172,365.743469 147.577179,365.587891 147.547531,365.534760 
	C147.429108,365.322479 147.253616,365.142029 146.937134,364.378937 
	C146.827057,364.214569 146.716980,364.050201 146.850128,363.444916 
	C146.941986,363.304413 147.033844,363.163910 147.816330,363.011597 
	C150.445190,361.864075 152.446335,362.687439 154.053802,365.626190 
	C155.036423,366.083282 156.019043,366.540375 157.856064,367.016113 
	C159.005249,367.040985 160.154419,367.065857 162.008560,367.067108 
	C163.369934,366.045776 164.731308,365.024475 166.161041,364.683136 
	C167.104935,365.122864 168.048813,365.562592 169.494568,366.267090 
	C169.970764,366.576019 170.446945,366.884949 171.044373,367.815033 
	C173.292816,370.409973 175.284958,369.753174 177.781647,367.123199 
	C179.187256,366.083862 180.592850,365.044495 182.719635,363.878479 
	C182.786530,360.916229 182.853424,357.953949 182.964859,354.195221 
	C182.968475,353.131714 182.972092,352.068237 183.516479,350.569519 
	C182.720688,349.022461 181.924896,347.475403 180.700790,345.486115 
	C179.794052,345.004395 178.887329,344.522675 177.847000,343.285736 
	C175.375763,341.792969 172.920914,340.437958 170.479889,344.440430 
	C169.166122,346.239227 167.852341,348.038025 165.875885,350.188446 
	C165.609848,353.789978 165.343826,357.391510 164.299408,361.089661 
	C163.083099,361.351654 161.866806,361.613647 159.996124,362.016571 
	C159.996124,359.334869 159.782516,357.203583 160.055969,355.136749 
	C160.331497,353.054077 160.982483,350.997894 161.673752,349.001007 
	C162.192780,347.501709 163.985001,345.296082 163.572464,344.817810 
	C161.678040,342.621674 160.854645,338.979309 156.416122,339.057617 
	C156.020660,339.077148 155.625198,339.096710 154.518448,339.086029 
	C153.346329,340.391510 152.174210,341.696960 150.242920,343.002563 
	C148.483139,343.686371 146.723343,344.370148 144.251160,344.955048 
	C141.962433,344.402039 139.673691,343.849030 136.603027,343.113495 
	C134.948334,343.824707 133.024918,344.214264 131.700485,345.320801 
	C129.593292,347.081299 128.501343,349.285248 132.217163,351.537659 
	C132.503799,352.034119 132.790436,352.530548 132.430817,353.331635 
	C128.276047,356.543335 127.985504,360.872681 130.345383,364.847626 
	C131.533539,366.848969 134.608276,368.378174 137.054153,368.790466 
	C139.211212,369.154053 141.679901,367.668823 144.678207,366.986359 
	C145.450577,366.659729 146.222946,366.333099 147.353577,365.851959 
M366.993713,293.005402 
	C366.993713,293.005402 366.924469,293.094482 366.921967,292.390961 
	C365.616913,291.914825 364.311829,291.438660 362.174927,290.981415 
	C360.788666,291.001373 359.402374,291.021301 357.618164,290.500244 
	C355.448181,291.313416 353.278198,292.126587 351.004761,292.146088 
	C348.739685,289.690735 342.623871,289.162842 340.623749,291.340179 
	C340.406433,291.576752 340.871338,292.440002 340.910004,293.943115 
	C341.026398,300.756287 341.142761,307.569427 341.046783,314.851440 
	C341.412323,315.430267 341.777863,316.009094 342.143433,316.587891 
	C342.488159,315.920013 342.832916,315.252136 343.226776,314.220703 
	C343.478821,314.186218 343.730865,314.151764 344.273346,314.431946 
	C344.438660,314.135162 344.604004,313.838409 344.734833,312.668121 
	C345.007538,307.697174 345.280212,302.726227 346.462952,297.900360 
	C349.735931,297.652222 349.927856,299.654755 350.115845,302.252350 
	C350.281036,304.534576 351.340240,306.752167 352.203674,309.666046 
	C354.109894,311.584930 356.016144,313.503815 358.074432,316.020477 
	C360.179626,319.271210 361.659790,317.604614 363.690063,315.165649 
	C364.123383,315.102448 364.556671,315.039246 365.381927,315.486816 
	C366.100708,315.294922 366.974091,315.301147 367.513855,314.879944 
	C371.949493,311.418640 369.236298,307.045624 368.960236,302.130859 
	C368.926483,300.436371 368.892731,298.741852 369.430237,296.659882 
	C368.588562,295.465027 367.746887,294.270172 366.993713,293.005402 
M323.085876,366.674042 
	C324.334015,366.913086 325.582153,367.152130 326.830292,367.391174 
	C326.911133,366.593170 326.991974,365.795135 327.041412,364.311676 
	C326.949646,363.866272 326.857849,363.420898 326.933380,362.163757 
	C326.976929,360.782776 327.020477,359.401764 327.590729,357.513275 
	C327.427460,355.667084 327.264191,353.820923 326.985352,351.419952 
	C327.284454,348.514099 328.463226,346.661652 331.917023,348.264313 
	C331.961304,352.897125 331.864685,357.538361 332.139893,362.157410 
	C332.217590,363.461365 333.383698,364.700439 334.923767,366.153870 
	C335.728058,365.087952 337.049652,364.102325 337.230164,362.939575 
	C337.619720,360.430084 337.423431,357.829651 338.136047,354.922821 
	C338.247070,353.182404 338.358063,351.442017 338.634033,349.257141 
	C338.634033,349.257141 338.830994,348.825928 339.377655,348.690399 
	C339.666412,348.226288 339.955170,347.762207 340.906860,347.220703 
	C341.573730,347.237518 342.240570,347.254364 343.192261,347.794373 
	C343.414124,348.532013 343.635986,349.269684 343.090240,350.196289 
	C342.811554,354.791199 342.486359,359.384674 342.344452,363.983795 
	C342.333405,364.342255 343.604889,364.740295 344.619965,365.618835 
	C344.712067,365.797699 344.804169,365.976532 345.031494,366.798615 
	C345.985016,366.964264 346.938568,367.129883 347.892090,367.295532 
	C347.998322,366.200439 348.104523,365.105347 348.164520,363.201294 
	C348.275696,362.137207 348.386871,361.073090 349.222046,360.013367 
	C350.429352,360.015320 351.636627,360.017273 352.943573,360.546539 
	C352.981018,360.721436 353.018494,360.896362 353.087860,361.702942 
	C353.560791,362.346039 354.033691,362.989136 354.555847,364.290558 
	C355.350922,365.258698 356.145966,366.226837 357.074493,367.886292 
	C360.434570,370.982056 367.610596,368.262299 368.892517,363.234680 
	C369.375610,361.339966 371.161072,358.592957 367.013153,357.513306 
	C367.013153,357.513306 366.978088,357.051300 367.089661,356.385590 
	C367.949524,353.752441 369.254578,351.151459 369.448334,348.470184 
	C369.524994,347.409485 367.129822,346.170197 365.472382,344.829773 
	C365.229492,344.655457 365.069916,344.423767 364.931549,343.495758 
	C363.958832,342.858978 363.054779,341.869232 361.998138,341.663605 
	C360.159943,341.305939 358.135712,341.076935 356.380066,341.531067 
	C355.681488,341.711761 355.490143,343.853485 354.998840,345.031525 
	C354.998840,345.031525 355.091156,345.067169 354.180634,345.023041 
	C352.060577,345.051392 349.940491,345.079712 347.240540,345.215454 
	C347.057892,345.291565 346.875275,345.367645 346.202728,345.164337 
	C345.802094,344.788361 345.401459,344.412415 344.940765,343.433136 
	C344.277679,343.314667 343.614594,343.196167 342.266266,343.070740 
	C341.581940,343.105652 340.897583,343.140533 339.566864,343.110291 
	C339.006805,343.655029 338.446716,344.199768 337.220551,344.953613 
	C336.142609,344.644196 335.064667,344.334778 333.855011,343.276825 
	C329.849701,341.383789 326.250610,342.014923 322.912170,345.632568 
	C322.832794,346.042206 322.753448,346.451843 322.075012,347.099213 
	C322.287903,348.442200 322.500793,349.785187 322.925140,351.121643 
	C322.925140,351.121643 323.079926,350.977417 322.932251,351.603668 
	C322.875275,352.028687 322.818268,352.453705 322.062805,352.956451 
	C321.360291,353.057526 320.657806,353.158600 319.881622,352.497498 
	C319.495148,351.225830 319.265442,349.878082 318.692291,348.696960 
	C317.070099,345.353760 315.264374,342.303650 310.841980,342.016907 
	C306.018463,341.704224 301.919556,343.890503 301.059448,347.998077 
	C299.984192,353.133118 300.101501,358.929932 301.375977,364.022644 
	C302.525116,368.614624 308.492584,369.852814 312.737091,367.175781 
	C313.170654,367.080688 313.604248,366.985596 314.188049,367.269531 
	C314.336395,367.051605 314.484711,366.833679 314.981659,366.043823 
	C316.807648,364.068329 319.398651,362.310181 316.985718,358.549011 
	C316.986176,358.358276 316.986664,358.167542 317.335846,357.441254 
	C318.217682,356.527496 319.099518,355.613739 320.549377,355.354980 
	C321.294891,358.601196 322.040375,361.847443 322.930359,365.528839 
	C322.930359,365.528839 323.015076,365.979370 323.085876,366.674042 
M263.983795,295.802277 
	C264.662872,296.541901 265.341980,297.281525 266.047272,298.890808 
	C266.300018,301.600067 266.552765,304.309296 266.783112,307.663208 
	C266.788971,308.093018 266.794830,308.522797 266.368500,309.427002 
	C266.281952,310.275757 266.195435,311.124481 265.563416,312.357422 
	C266.077637,313.215149 266.591827,314.072906 267.737915,314.982361 
	C268.160248,314.996185 268.582611,315.010040 269.362640,315.490234 
	C269.880432,314.651093 270.398224,313.811981 270.936340,312.040863 
	C270.947418,307.878021 270.741974,303.696899 271.091644,299.562683 
	C271.207886,298.188385 272.749084,296.934631 274.510315,295.828888 
	C275.676849,297.012939 277.647064,298.074280 277.850403,299.405457 
	C278.344238,302.639099 277.959656,305.999481 278.033783,309.306427 
	C278.144745,314.254730 281.489349,316.967621 286.159790,315.974091 
	C289.343597,315.296844 290.136108,312.379761 288.355682,310.881348 
	C284.082062,307.284637 286.689270,302.935760 286.115143,298.995819 
	C286.002930,298.225647 287.212067,297.262970 287.811768,296.389099 
	C288.524414,297.135040 289.332306,297.812958 289.925812,298.643982 
	C290.684387,299.706146 291.270081,300.891846 292.442719,302.439667 
	C292.606537,303.297028 292.770355,304.154358 292.397552,305.520752 
	C292.902466,308.038361 292.933197,310.805603 294.080414,312.985138 
	C294.834656,314.418030 297.147888,315.584015 298.863647,315.736450 
	C299.799591,315.819641 301.042419,313.611298 301.974365,312.312622 
	C303.490723,310.199585 304.865723,307.985107 306.627838,306.446625 
	C306.754456,307.630768 306.881042,308.814911 306.477386,310.412567 
	C306.583099,314.572449 307.034210,315.161682 310.670715,314.986176 
	C311.116943,315.006531 311.563171,315.026855 312.391602,315.511017 
	C312.841614,314.881683 313.291595,314.252319 313.756012,313.276947 
	C313.756012,313.276947 313.892517,312.958679 314.654694,312.856171 
	C315.100647,311.904236 315.932800,310.948334 315.923065,310.001099 
	C315.888153,306.607178 317.708160,305.733398 320.746460,306.873718 
	C320.769989,308.265259 320.793518,309.656830 320.338745,311.513702 
	C321.375519,312.586426 322.412262,313.659149 323.976532,314.791382 
	C324.305206,314.919952 324.633881,315.048523 325.136292,315.874054 
	C327.768555,315.540009 330.400818,315.205963 333.851715,314.922455 
	C339.810211,313.946716 336.184296,309.723389 336.739685,306.126068 
	C336.670868,305.128815 336.602020,304.131561 336.870880,302.289032 
	C336.900848,300.197174 336.930786,298.105286 337.440369,295.644806 
	C335.623962,294.103485 333.807556,292.562164 331.137970,290.987579 
	C329.431946,291.020874 327.725952,291.054169 325.695801,290.597137 
	C325.138947,291.086639 324.582062,291.576172 323.690491,292.198608 
	C323.690491,292.198608 323.345551,292.302094 322.566467,292.136719 
	C321.397675,291.650330 320.243469,290.832367 319.055573,290.780090 
	C318.064575,290.736450 317.034088,291.590332 315.597168,292.437775 
	C315.306824,292.933960 315.016449,293.430145 314.088562,294.113403 
	C313.739380,296.081085 313.390198,298.048798 313.012238,300.659149 
	C312.909393,301.074554 312.806549,301.489990 311.962494,301.962769 
	C311.214386,302.033478 310.466278,302.104218 309.377533,301.610809 
	C309.244110,300.744843 309.110687,299.878845 309.522614,298.574921 
	C308.828949,296.126068 308.582764,293.403473 307.303711,291.312744 
	C306.229828,289.557434 304.003052,288.854980 302.766754,292.399689 
	C302.575989,292.686676 302.317169,292.884766 301.399017,293.034271 
	C301.278625,293.694946 301.158234,294.355652 301.015137,295.757050 
	C300.873993,296.483368 300.732849,297.209717 299.928772,298.086304 
	C297.384125,299.458252 296.233307,298.458923 296.002655,295.809479 
	C295.652985,291.793152 293.371948,290.171539 289.425140,289.892242 
	C288.056946,289.795441 286.481201,288.406891 285.550690,287.188110 
	C283.278107,284.211456 280.194366,284.551117 279.047272,287.023315 
	C277.631775,290.073975 275.413757,290.368652 272.307922,290.960602 
	C271.728027,290.592865 271.148132,290.225128 270.723389,289.240326 
	C270.798920,288.823212 270.874451,288.406067 271.712860,287.992767 
	C273.091248,286.368439 274.469604,284.744110 275.847961,283.119751 
	C274.526245,282.151947 272.646576,280.087311 271.987701,280.422485 
	C270.027588,281.419586 265.963928,280.691925 266.884399,285.835419 
	C266.839600,287.500916 266.794800,289.166412 266.720184,290.708588 
	C266.720184,290.708588 266.593597,290.717285 266.062347,290.768066 
	C265.709320,290.823090 265.356293,290.878082 264.585632,290.462524 
	C262.725311,291.307251 260.864990,292.151978 259.000000,293.000000 
	C259.000000,293.000000 259.003143,292.995209 258.919769,292.230499 
	C256.428802,288.485870 253.194839,289.782043 249.477402,290.937439 
	C249.308090,290.946167 249.138779,290.954865 248.259842,291.047516 
	C246.388962,292.596436 242.316650,292.899902 244.817871,297.817719 
	C244.729446,299.504181 244.641022,301.190674 243.887436,302.916962 
	C241.831757,301.407349 239.776077,299.897736 237.686646,298.325165 
	C237.686646,298.325165 237.615509,298.318909 237.782822,297.583099 
	C235.694321,296.496429 233.605804,295.409729 231.000229,294.228668 
	C230.626755,293.883484 230.253265,293.538269 229.973724,292.285095 
	C229.981812,290.180756 229.989899,288.076416 230.317795,285.791229 
	C230.317795,285.791229 230.685181,285.788757 231.585144,285.949280 
	C232.493591,285.895050 233.402023,285.840851 234.963135,285.888702 
	C235.628296,286.921600 236.293442,287.954468 236.612930,289.192047 
	C236.788116,289.451752 236.963303,289.711456 237.631653,290.434753 
	C238.738480,290.610138 239.845291,290.785522 241.606949,290.931702 
	C241.551758,289.924957 241.496567,288.918213 241.213623,287.229462 
	C239.801910,285.510651 238.390198,283.791840 236.855347,281.293945 
	C234.032455,279.447723 231.310074,278.601318 228.537247,282.264557 
	C228.194687,282.299377 227.852142,282.334198 226.730072,282.188416 
	C220.948914,285.579193 220.157913,291.282593 225.483780,295.328644 
	C226.322708,296.219269 227.161621,297.109924 228.032013,298.581177 
	C228.682724,298.750244 229.333435,298.919312 230.668060,299.179993 
	C232.845581,301.098297 235.023087,303.016632 237.058441,305.752533 
	C237.103348,306.854523 237.148254,307.956512 236.897034,309.670410 
	C235.950867,310.175659 235.004700,310.680878 233.627121,310.572754 
	C232.260223,309.434479 230.923904,308.256653 229.517853,307.169006 
	C228.103806,306.075226 226.734787,304.684174 225.114853,304.205139 
	C224.398254,303.993225 222.148285,305.884735 222.263855,306.515442 
	C222.618256,308.449402 223.780838,310.235291 225.053329,312.601837 
	C225.973114,313.178741 226.892914,313.755646 228.132858,314.701630 
	C228.397614,314.891418 228.665695,315.076355 229.169922,315.963013 
	C231.965027,317.184174 234.781906,318.593323 237.422806,314.551514 
	C238.645737,313.382660 239.868668,312.213776 241.804016,311.042389 
	C243.569427,311.999329 245.334854,312.956238 247.008759,313.998810 
	C247.008759,313.998810 247.083389,313.898285 247.136230,314.658081 
	C252.872498,318.679993 258.286652,317.778076 259.733948,312.238678 
	C259.733948,312.238678 259.899719,311.959747 260.500397,311.947418 
	C260.687103,311.303833 260.873840,310.660248 261.059998,309.085541 
	C261.011230,304.731750 260.962463,300.377991 261.509186,295.793610 
	C262.065552,295.483643 262.621948,295.173676 263.562988,294.986816 
	C263.562988,294.986816 263.945099,295.117645 263.983795,295.802277 
M297.624298,366.863556 
	C299.047760,363.408844 298.531799,362.812225 293.173340,360.957367 
	C293.210022,356.978424 293.246704,352.999451 293.964752,348.912109 
	C295.572784,348.191864 297.180786,347.471588 299.499817,346.432861 
	C296.560150,343.360718 294.062469,340.750519 291.564758,338.140259 
	C289.454529,340.344879 287.141724,342.411377 285.445923,344.898560 
	C285.182617,345.284729 287.739838,347.593964 289.024506,349.908997 
	C289.041382,352.598450 289.058258,355.287933 288.509155,358.506042 
	C288.493469,360.774567 286.397247,363.602448 290.659760,365.065430 
	C290.945984,365.236481 291.232208,365.407532 291.858521,366.233429 
	C293.553284,366.458771 295.248016,366.684143 297.624298,366.863556 
M204.006546,365.154663 
	C203.778183,363.072266 203.549820,360.989868 203.279358,357.993500 
	C203.300110,353.704529 203.320847,349.415527 203.605469,344.860809 
	C203.642441,344.741760 203.739212,344.600189 203.704529,344.508118 
	C203.620270,344.284393 203.474915,344.083679 203.303513,343.001404 
	C203.259018,340.656586 203.214539,338.311737 203.586411,335.552124 
	C201.701431,331.019409 199.873047,334.022858 198.816666,335.888245 
	C197.912811,337.484344 197.997681,339.640350 197.656342,341.488647 
	C195.572220,341.988556 193.526154,342.107239 191.840317,342.948120 
	C184.491013,346.613770 183.152222,360.475189 189.517029,365.949066 
	C189.617859,366.035767 189.838715,365.982910 190.017868,366.047302 
	C190.017868,366.047302 189.969513,366.071472 190.233856,366.488373 
	C190.452026,366.758392 190.733047,366.917419 191.692657,367.089630 
	C192.096146,367.196014 192.499649,367.302399 193.068222,368.031799 
	C195.167465,369.547394 201.893967,368.281799 204.006546,365.154663 
M282.691803,348.019897 
	C282.396881,348.064117 282.136017,348.182037 281.572418,347.769104 
	C280.096191,346.389343 278.619995,345.009613 276.891449,343.014709 
	C274.122223,341.139984 272.251556,342.139709 270.612762,345.344696 
	C270.070801,345.843658 269.528809,346.342621 268.529510,346.359436 
	C267.385315,345.510254 266.331329,344.184601 265.071533,343.946228 
	C263.816589,343.708740 262.355194,344.562225 260.687592,345.302277 
	C260.503723,345.556549 260.331848,345.818665 259.425293,346.133209 
	C257.577972,347.773956 255.730682,349.414734 253.556610,350.440216 
	C253.317627,349.309448 253.078644,348.178650 253.389572,346.702606 
	C253.332886,343.154083 251.065018,342.164185 247.688995,341.554260 
	C247.119064,341.371124 246.549133,341.188019 245.970291,340.249268 
	C242.828598,331.585480 234.616943,329.570099 228.567398,334.118347 
	C223.524063,337.910126 223.851135,345.847290 229.050980,349.789520 
	C230.984436,351.241943 233.175293,352.460144 234.774124,354.217072 
	C236.185669,355.768219 237.081345,357.864166 237.854111,359.855652 
	C238.003281,360.240082 236.644241,361.661835 235.784378,361.902405 
	C233.010590,362.678375 232.271729,360.871429 231.442093,358.565216 
	C231.031952,357.425110 228.830994,356.129517 227.633118,356.295502 
	C226.415558,356.464264 224.834991,358.211761 224.546677,359.518036 
	C224.328278,360.507568 225.890182,361.890015 226.845642,363.580048 
	C226.879562,363.748993 226.913467,363.917938 226.958115,364.829559 
	C227.910751,366.015930 228.671387,367.457672 229.849792,368.343842 
	C234.592972,371.910797 238.750534,368.830078 243.009918,367.693939 
	C243.808563,368.281952 244.607193,368.869934 245.405838,369.457947 
	C246.641663,366.028992 249.586105,367.191864 252.022995,367.775909 
	C251.557648,369.485657 250.815872,371.181671 250.730087,372.910248 
	C250.678207,373.955963 251.378372,375.471649 252.226349,376.007385 
	C253.070190,376.540497 254.768036,376.514252 255.631973,375.984283 
	C256.386475,375.521423 256.501923,374.016785 256.961731,372.325928 
	C257.081512,371.910736 257.201294,371.495514 258.036316,370.902283 
	C259.278900,366.151947 260.521484,361.401642 261.768860,356.293274 
	C261.768860,356.293274 261.905640,355.962311 262.527252,355.933594 
	C262.606354,355.273041 262.685455,354.612457 262.839539,353.307129 
	C262.952057,352.889801 263.064575,352.472504 263.898499,351.929047 
	C265.350983,351.574371 266.803436,351.219696 268.528839,351.424164 
	C269.362579,352.610687 270.196289,353.797180 270.482635,355.397034 
	C269.465820,356.642334 268.420532,357.865662 267.438110,359.137512 
	C265.425964,361.742432 264.969513,363.972382 269.529327,365.278107 
	C270.319672,365.918152 271.109985,366.558197 272.106110,367.914032 
	C275.352997,369.909576 278.092407,369.676514 280.524109,365.743073 
	C281.017639,365.160248 281.511169,364.577454 282.712463,363.882355 
	C282.548157,362.218018 282.383850,360.553711 281.961273,358.295959 
	C280.868530,356.974152 279.775818,355.652313 278.931976,353.578979 
	C280.287231,351.718842 281.642487,349.858673 283.286591,347.887085 
	C283.191864,347.925537 283.097168,347.963989 282.691803,348.019897 
M205.705597,299.212524 
	C207.142838,297.847473 208.580078,296.482452 210.449844,295.602020 
	C211.245773,294.500031 212.328857,293.482025 212.688431,292.252441 
	C212.821915,291.795990 211.368118,290.423340 210.451996,290.185425 
	C204.520355,288.644989 200.891525,290.689575 199.341293,296.216736 
	C194.461807,288.828156 188.819809,287.581543 183.054459,292.880280 
	C181.087021,291.628021 179.119583,290.375763 176.692261,288.830780 
	C178.602203,287.142700 180.156006,285.769440 182.791351,283.440247 
	C180.148468,282.479034 178.238281,281.176300 176.373962,281.238953 
	C172.997925,281.352356 166.666580,290.557068 167.233612,293.895081 
	C167.287079,294.209808 167.385559,294.640808 167.611343,294.783783 
	C171.206726,297.060272 169.835342,300.669098 170.008148,303.820312 
	C170.162704,306.638397 169.821762,309.505524 170.212158,312.278839 
	C170.386124,313.514771 171.741058,314.655762 172.767715,315.618286 
	C172.910446,315.752106 174.249451,314.609924 174.996185,314.005463 
	C174.996185,314.005463 175.053040,314.031281 175.832062,313.827881 
	C176.210846,309.501465 176.510971,305.165985 177.009842,300.853455 
	C177.191605,299.282166 177.846649,297.765625 178.285782,296.224091 
	C178.872040,296.360168 179.458282,296.496246 180.044540,296.632324 
	C180.044540,299.073578 179.833832,301.539398 180.114899,303.947845 
	C180.319382,305.700195 181.173416,307.376770 181.989487,309.676361 
	C182.299469,310.454559 182.609451,311.232727 182.472519,312.397949 
	C183.649399,313.267548 184.826279,314.137146 186.645279,315.080902 
	C187.068283,315.159637 187.491272,315.238403 188.157135,315.993530 
	C193.615585,318.860443 196.075729,314.631409 198.617264,311.398285 
	C200.100159,312.418243 201.328629,313.263214 203.074219,314.554535 
	C203.734207,314.049469 204.394196,313.544403 205.190033,312.160492 
	C205.286499,309.814423 205.382980,307.468353 206.102539,304.845581 
	C205.948090,303.214691 205.793640,301.583832 205.705597,299.212524 
M33.106129,363.064667 
	C33.060665,354.714050 33.015198,346.363464 33.146801,337.309235 
	C32.792297,336.462006 32.437798,335.614746 31.907921,334.135468 
	C30.109529,332.480469 29.242174,332.921875 29.128399,336.209076 
	C29.082394,345.361115 28.962008,354.514160 29.089203,363.663788 
	C29.104259,364.746918 30.474720,365.811249 31.216877,366.884308 
	C31.805920,365.921112 32.394962,364.957947 33.106129,363.064667 
M120.317078,344.099640 
	C119.885071,344.082672 119.453072,344.065704 118.682610,343.560486 
	C118.150116,344.086884 117.617630,344.613281 117.015762,346.057159 
	C116.999420,352.028412 116.864082,358.004028 117.080696,363.966797 
	C117.117973,364.993103 118.651901,365.965027 119.494484,366.962067 
	C120.226822,366.064545 121.576576,365.174072 121.586960,364.268280 
	C121.637794,359.831604 121.331985,355.390900 121.279854,350.437164 
	C121.342964,350.091187 121.406067,349.745178 122.106094,349.311127 
	C122.716049,349.111572 123.326004,348.912018 124.231094,349.268280 
	C124.779068,348.778839 125.327049,348.289368 126.290543,347.257629 
	C126.517822,346.186462 126.745110,345.115295 126.951721,343.452057 
	C126.660568,343.112366 126.369415,342.772675 126.078262,342.432983 
	C125.693604,342.865265 125.308945,343.297516 124.308533,343.927002 
	C123.885345,344.007080 123.462166,344.087189 122.763100,343.654205 
	C122.163536,343.815491 121.563972,343.976807 120.317078,344.099640 
M38.755772,292.413025 
	C38.550549,292.684784 38.286121,292.873688 37.174892,293.158173 
	C36.874073,299.808807 36.422344,306.459961 36.443485,313.109558 
	C36.446484,314.051392 38.677101,315.557343 40.016026,315.731598 
	C40.977901,315.856781 42.602428,314.409393 43.152977,313.283081 
	C43.837090,311.883514 43.782150,310.097748 43.935017,308.468353 
	C44.349472,304.050537 44.705078,299.627197 45.861813,295.098938 
	C46.921333,296.628906 48.722904,298.080536 48.894253,299.704224 
	C49.322369,303.760986 49.010357,307.895844 48.466194,312.435638 
	C48.961369,313.555664 49.456543,314.675720 49.951702,315.795715 
	C50.958698,315.182922 51.965694,314.570129 53.072460,313.029938 
	C53.037514,307.023468 53.002567,301.016998 53.471588,294.639984 
	C52.650597,291.920837 51.939095,289.037445 47.257595,291.015198 
	C46.513805,291.040558 45.770020,291.065918 44.541687,290.508667 
	C42.690331,290.998199 40.838970,291.487732 38.755772,292.413025 
M249.726700,160.500000 
	C249.726852,138.344971 249.727356,116.189934 249.727036,94.034904 
	C249.726715,71.546722 249.752441,49.058441 249.670670,26.570557 
	C249.664505,24.876074 248.889786,23.184387 248.472336,21.491398 
	C246.853714,22.127493 245.018173,22.464046 243.654160,23.451746 
	C237.873276,27.637709 232.076065,31.832899 226.642868,36.446381 
	C225.157043,37.708042 224.211502,40.436108 224.207993,42.493256 
	C224.086243,113.955475 224.100388,185.417938 224.151947,256.880341 
	C224.153091,258.478912 224.810638,260.077026 225.336685,262.469910 
	C232.287872,256.431732 238.144180,250.765518 244.621613,245.931610 
	C248.613022,242.952911 249.851547,239.703949 249.816772,234.960815 
	C249.637177,210.474960 249.728745,185.987122 249.726700,160.500000 
M57.015175,136.500000 
	C57.015175,98.153122 57.015175,59.806248 57.015175,21.249712 
	C55.520966,21.424459 54.917561,21.310894 54.592346,21.560207 
	C47.880161,26.705841 41.065739,31.734444 34.613728,37.190826 
	C33.133644,38.442513 32.230083,41.192642 32.226414,43.255238 
	C32.099953,114.369400 32.107918,185.483826 32.143215,256.598175 
	C32.144035,258.250641 32.678478,259.902832 33.123905,262.480347 
	C39.847641,256.728394 45.555595,251.472809 51.668880,246.740875 
	C56.066105,243.337265 57.193859,239.360016 57.148769,233.928162 
	C56.881985,201.787277 57.016041,169.643082 57.015175,136.500000 
M171.942993,192.436890 
	C170.247116,185.564484 169.148041,178.475098 166.743225,171.860443 
	C159.596359,152.202423 151.649796,132.833984 144.573608,113.151810 
	C139.628616,99.397446 139.586441,85.125023 142.767014,70.907883 
	C146.270645,55.246773 152.169830,40.483688 159.856354,26.405489 
	C160.603714,25.036688 160.610458,23.263525 161.127945,20.922842 
	C159.492767,21.541121 158.834412,21.669586 158.317307,22.011591 
	C157.353424,22.649096 156.458954,23.395042 155.557739,24.123234 
	C145.523499,32.230984 136.931839,41.775257 130.423767,52.798233 
	C125.229256,61.596401 120.551033,70.966911 117.437195,80.659576 
	C113.371811,93.314201 114.427666,106.710052 116.394058,119.690971 
	C119.657257,141.232697 127.917023,161.208939 137.890244,180.398727 
	C146.578918,197.116867 149.327240,213.575836 138.867523,230.487717 
	C133.632248,238.952438 128.379456,247.406448 123.186966,255.897354 
	C122.348869,257.267853 121.785294,258.806213 120.586273,261.342896 
	C122.682587,260.614807 123.628014,260.567688 124.127701,260.071594 
	C135.182693,249.095718 146.806900,238.608337 157.007965,226.883987 
	C165.177948,217.493958 171.329376,206.468201 171.942993,192.436890 
z"
  />
  <path
    fill="#7CB7D4"
    opacity="1.000000"
    stroke="none"
    d="
M249.726227,161.000000 
	C249.728745,185.987122 249.637177,210.474960 249.816772,234.960815 
	C249.851547,239.703949 248.613022,242.952911 244.621613,245.931610 
	C238.144180,250.765518 232.287872,256.431732 225.336685,262.469910 
	C224.810638,260.077026 224.153091,258.478912 224.151947,256.880341 
	C224.100388,185.417938 224.086243,113.955475 224.207993,42.493256 
	C224.211502,40.436108 225.157043,37.708042 226.642868,36.446381 
	C232.076065,31.832899 237.873276,27.637709 243.654160,23.451746 
	C245.018173,22.464046 246.853714,22.127493 248.472321,21.491398 
	C248.889786,23.184387 249.664505,24.876074 249.670670,26.570557 
	C249.752441,49.058441 249.726715,71.546722 249.727036,94.034904 
	C249.727356,116.189934 249.726852,138.344971 249.726227,161.000000 
z"
  />
  <path
    fill="#7BB6D4"
    opacity="1.000000"
    stroke="none"
    d="
M57.015213,137.000000 
	C57.016041,169.643082 56.881985,201.787277 57.148769,233.928162 
	C57.193859,239.360016 56.066105,243.337265 51.668880,246.740875 
	C45.555595,251.472809 39.847641,256.728394 33.123905,262.480347 
	C32.678478,259.902832 32.144035,258.250641 32.143215,256.598175 
	C32.107918,185.483826 32.099953,114.369400 32.226414,43.255238 
	C32.230083,41.192642 33.133644,38.442513 34.613728,37.190826 
	C41.065739,31.734444 47.880161,26.705841 54.592346,21.560207 
	C54.917561,21.310894 55.520966,21.424459 57.015175,21.249712 
	C57.015175,59.806248 57.015175,98.153122 57.015213,137.000000 
z"
  />
  <path
    fill="#76B3D2"
    opacity="1.000000"
    stroke="none"
    d="
M315.558807,138.755005 
	C315.438446,138.093063 315.318085,137.431137 315.498108,136.372528 
	C315.528625,135.320267 315.258728,134.664642 314.988831,134.009018 
	C314.988831,134.009018 314.982178,134.040329 315.015930,133.665131 
	C315.046295,132.531418 315.042877,131.772888 315.251709,130.835236 
	C315.011536,130.054626 314.559174,129.453156 314.082397,128.576935 
	C313.762360,127.870537 313.466644,127.438881 313.170929,127.007233 
	C313.113007,126.566467 313.055084,126.125702 313.037292,124.965393 
	C312.553589,122.759880 312.029724,121.273903 311.505859,119.787933 
	C311.453766,119.374329 311.401672,118.960724 311.596619,117.928497 
	C311.919342,114.880638 311.994995,112.451401 312.440033,109.930908 
	C314.287964,107.086479 315.841400,104.316399 311.203369,102.518204 
	C311.190430,100.421425 311.222534,98.742538 311.610718,97.030891 
	C315.156952,95.888412 314.281830,93.983253 312.937592,91.695206 
	C312.629425,90.930855 312.330811,90.459457 312.038696,89.586945 
	C312.345062,86.442955 312.644958,83.700089 312.944885,80.957222 
	C312.984985,80.519852 313.025116,80.082474 313.321106,79.230820 
	C313.806152,78.627960 313.969849,78.393639 314.417633,78.010780 
	C315.514709,75.604652 316.262207,73.301323 317.030273,70.663452 
	C317.031128,69.883026 317.011414,69.437149 316.991699,68.991264 
	C317.058319,68.585747 317.124969,68.180222 317.581390,67.374985 
	C318.333679,66.007484 318.696167,65.039711 319.058655,64.071930 
	C319.058655,64.071930 319.162994,63.606945 319.529510,63.299561 
	C320.245270,62.309528 320.594513,61.626881 320.943756,60.944237 
	C320.943756,60.944241 320.969666,60.947456 321.296692,60.942787 
	C322.073822,60.288570 322.523865,59.639027 323.244690,58.762886 
	C323.692810,57.381214 323.870209,56.226147 324.047638,55.071075 
	C324.891083,54.234951 325.734558,53.398827 326.886749,52.099876 
	C328.660400,50.097416 330.197021,48.619133 331.576385,47.006336 
	C335.340118,42.605721 338.908569,38.029049 342.837585,33.784229 
	C344.057587,32.466171 346.184052,31.987156 347.754425,31.195168 
	C343.627258,42.037209 338.541016,51.771519 336.349243,62.118927 
	C333.878418,73.783707 334.013397,86.000450 333.008301,98.234375 
	C333.001862,98.663185 333.002167,98.834740 333.000061,99.430901 
	C335.440643,108.535118 337.459869,117.362686 340.426300,125.859550 
	C344.829803,138.472687 349.906097,150.850845 354.691956,163.330566 
	C357.461884,170.553574 360.223877,177.779633 362.763489,185.172226 
	C363.000549,185.900375 363.463593,186.460602 363.942688,187.478088 
	C363.602081,192.220367 364.159607,196.840973 362.731110,200.732544 
	C356.440186,217.870148 345.369995,231.797913 331.770660,243.914337 
	C330.987305,243.177322 330.477539,242.571381 329.977234,241.595932 
	C330.449371,240.196472 330.810974,239.105515 331.390350,238.146072 
	C337.125793,228.647644 341.886017,218.945999 342.121033,207.391235 
	C342.362061,195.541000 336.320099,185.937485 332.098206,175.641144 
	C328.349823,166.499557 324.704590,157.313522 320.796082,148.240906 
	C319.373871,144.939560 317.322906,141.909073 315.558807,138.755005 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M171.940887,192.895203 
	C171.329376,206.468201 165.177948,217.493958 157.007965,226.883987 
	C146.806900,238.608337 135.182693,249.095718 124.127701,260.071594 
	C123.628014,260.567688 122.682587,260.614807 120.586273,261.342896 
	C121.785294,258.806213 122.348869,257.267853 123.186966,255.897354 
	C128.379456,247.406448 133.632248,238.952438 138.867523,230.487717 
	C149.327240,213.575836 146.578918,197.116867 137.890244,180.398727 
	C127.917023,161.208939 119.657257,141.232697 116.394058,119.690971 
	C114.427666,106.710052 113.371811,93.314201 117.437195,80.659576 
	C120.551033,70.966911 125.229256,61.596401 130.423767,52.798233 
	C136.931839,41.775257 145.523499,32.230984 155.557739,24.123234 
	C156.458954,23.395042 157.353424,22.649096 158.317307,22.011591 
	C158.834412,21.669586 159.492767,21.541121 161.127945,20.922842 
	C160.610458,23.263525 160.603714,25.036688 159.856354,26.405489 
	C152.169830,40.483688 146.270645,55.246773 142.767014,70.907883 
	C139.586441,85.125023 139.628616,99.397446 144.573608,113.151810 
	C151.649796,132.833984 159.596359,152.202423 166.743225,171.860443 
	C169.148041,178.475098 170.247116,185.564484 171.940887,192.895203 
M119.998566,125.675339 
	C120.320442,126.453545 120.642311,127.231743 120.993774,128.817841 
	C121.652817,131.713470 121.938980,134.758896 123.032295,137.480042 
	C129.429031,153.400818 135.661835,169.402969 142.644958,185.067856 
	C148.533508,198.277313 150.738373,211.267883 144.152771,224.757721 
	C141.492569,230.206833 138.704468,235.593521 135.671646,241.609360 
	C133.767334,244.432724 131.863022,247.256073 129.958725,250.079437 
	C130.508316,250.489243 131.057922,250.899048 131.607513,251.308853 
	C134.089813,248.225967 136.572113,245.143066 139.639969,241.743958 
	C148.002518,233.876404 155.116837,225.123047 161.399719,215.397034 
	C171.124054,200.343597 170.524643,185.586365 163.136948,170.200745 
	C160.713577,165.153809 158.250336,160.105820 156.211121,154.899368 
	C153.110138,146.982117 150.359863,138.927444 147.466599,130.928970 
	C145.392029,125.193810 142.599686,119.598862 141.430984,113.683830 
	C139.740463,105.127640 138.664215,96.356239 138.390945,87.643250 
	C138.167847,80.529762 138.412506,73.044746 140.404312,66.301804 
	C143.436340,56.037258 148.181381,46.281067 152.130539,36.281967 
	C152.603027,35.085690 152.648941,33.720943 152.892578,32.434284 
	C152.472366,32.186924 152.052139,31.939568 151.631912,31.692207 
	C150.072403,32.980839 148.375549,34.137699 146.984436,35.587875 
	C144.690979,37.978695 142.526947,40.503319 140.427994,43.069412 
	C131.765427,53.659950 124.589737,64.978043 120.626122,78.337593 
	C117.221222,89.814026 118.167618,101.380318 118.030846,113.654121 
	C118.354866,114.434586 118.678886,115.215050 119.001572,116.849854 
	C119.337471,119.567245 119.673363,122.284645 119.998566,125.675339 
z"
  />
  <path
    fill="#ACD0E1"
    opacity="1.000000"
    stroke="none"
    d="
M362.989502,185.004303 
	C360.223877,177.779633 357.461884,170.553574 354.691956,163.330566 
	C349.906097,150.850845 344.829803,138.472687 340.426300,125.859550 
	C337.459869,117.362686 335.440643,108.535118 333.173401,99.341965 
	C333.415039,98.717720 333.562531,98.545601 333.533173,98.506653 
	C333.385895,98.310997 333.192322,98.150177 333.015015,97.977119 
	C334.013397,86.000450 333.878418,73.783707 336.349243,62.118927 
	C338.541016,51.771519 343.627258,42.037209 347.754425,31.195168 
	C346.184052,31.987156 344.057587,32.466171 342.837585,33.784229 
	C338.908569,38.029049 335.340118,42.605721 331.576385,47.006336 
	C330.197021,48.619133 328.660400,50.097416 326.925720,51.886623 
	C326.814880,50.946781 326.537079,49.364586 327.199493,48.627934 
	C334.937775,40.022873 342.775177,31.505167 350.711975,23.082703 
	C351.734253,21.997885 353.465515,21.581215 355.882721,21.643654 
	C355.255890,23.357195 354.817505,25.170300 353.972870,26.768751 
	C346.263611,41.358036 340.222229,56.528778 336.727722,72.731018 
	C333.778778,86.403816 334.344391,99.924301 338.919525,112.936615 
	C345.401123,131.371048 352.925171,149.436340 359.732391,167.760193 
	C362.437683,175.042419 365.495056,182.470016 366.370056,190.064407 
	C367.013672,195.650452 365.326324,202.030960 363.020203,207.337067 
	C354.478302,226.990875 339.895874,241.951752 323.693634,255.473587 
	C323.076721,255.988449 322.225006,256.221954 320.944946,256.783081 
	C320.252777,255.641998 320.098358,254.304382 319.943939,252.966766 
	C320.436951,252.367172 320.929962,251.767609 321.722290,251.372314 
	C322.380829,252.001099 322.739960,252.425598 323.099091,252.850098 
	C326.080780,249.915207 329.062500,246.980316 332.044220,244.045410 
	C345.369995,231.797913 356.440186,217.870148 362.731110,200.732544 
	C364.159607,196.840973 363.602081,192.220367 363.947998,187.177216 
	C363.621338,185.947510 363.305420,185.475906 362.989502,185.004303 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M291.930298,302.024292 
	C291.270081,300.891846 290.684387,299.706146 289.925812,298.643982 
	C289.332306,297.812958 288.524414,297.135040 287.811768,296.389099 
	C287.212067,297.262970 286.002930,298.225647 286.115143,298.995819 
	C286.689270,302.935760 284.082062,307.284637 288.355682,310.881348 
	C290.136108,312.379761 289.343597,315.296844 286.159790,315.974091 
	C281.489349,316.967621 278.144745,314.254730 278.033783,309.306427 
	C277.959656,305.999481 278.344238,302.639099 277.850403,299.405457 
	C277.647064,298.074280 275.676849,297.012939 274.179352,295.408264 
	C273.562653,293.687012 273.276917,292.386414 272.991180,291.085815 
	C275.413757,290.368652 277.631775,290.073975 279.047272,287.023315 
	C280.194366,284.551117 283.278107,284.211456 285.550690,287.188110 
	C286.481201,288.406891 288.056946,289.795441 289.425140,289.892242 
	C293.371948,290.171539 295.652985,291.793152 296.002655,295.809479 
	C296.233307,298.458923 297.384125,299.458252 300.339539,298.379089 
	C300.191467,301.123596 299.632599,303.575348 298.825500,306.280701 
	C297.723572,305.941406 296.358551,305.512177 296.107117,304.726532 
	C295.162750,301.776031 294.755035,298.651489 293.776886,295.715179 
	C293.399506,294.582306 292.035919,293.778015 291.121674,292.824005 
	C290.871918,293.052002 290.622192,293.279999 290.372437,293.507996 
	C290.891724,296.346771 291.411011,299.185516 291.930298,302.024292 
M279.953430,307.213135 
	C280.250122,308.950439 280.304871,310.786682 280.955872,312.379150 
	C281.238800,313.071228 282.747528,313.262177 283.699585,313.680695 
	C283.681091,312.740417 283.670654,311.799896 283.642731,310.859894 
	C283.502045,306.120789 283.312622,301.382599 283.229767,296.642517 
	C283.180267,293.811279 283.295319,290.977142 283.336395,288.144318 
	C282.746643,288.103912 282.156860,288.063507 281.567078,288.023071 
	C280.519531,289.965881 278.629150,291.895721 278.599091,293.854156 
	C278.535553,297.991638 279.444550,302.144043 279.953430,307.213135 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M315.420013,139.131256 
	C317.322906,141.909073 319.373871,144.939560 320.796082,148.240906 
	C324.704590,157.313522 328.349823,166.499557 332.098206,175.641144 
	C336.320099,185.937485 342.362061,195.541000 342.121033,207.391235 
	C341.886017,218.945999 337.125793,228.647644 331.390350,238.146072 
	C330.810974,239.105515 330.449371,240.196472 329.679382,241.748291 
	C327.671906,243.884674 325.971802,245.499146 324.271698,247.113647 
	C327.259827,241.506943 330.462128,236.001938 333.184631,230.269089 
	C337.014954,222.203461 341.282349,214.018631 339.981384,204.751633 
	C339.164825,198.935043 337.474274,193.091156 335.219147,187.656509 
	C329.883453,174.798309 323.899994,162.210388 318.355316,149.436417 
	C316.984039,146.277222 316.287781,142.825012 315.420013,139.131256 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M175.034668,314.058472 
	C174.249451,314.609924 172.910446,315.752106 172.767715,315.618286 
	C171.741058,314.655762 170.386124,313.514771 170.212158,312.278839 
	C169.821762,309.505524 170.162704,306.638397 170.008148,303.820312 
	C169.835342,300.669098 171.206726,297.060272 167.611343,294.783783 
	C167.385559,294.640808 167.287079,294.209808 167.233612,293.895081 
	C166.666580,290.557068 172.997925,281.352356 176.373962,281.238953 
	C178.238281,281.176300 180.148468,282.479034 182.791351,283.440247 
	C180.156006,285.769440 178.602203,287.142700 176.692261,288.830780 
	C179.119583,290.375763 181.087021,291.628021 183.474243,292.891296 
	C183.894012,292.902344 184.027405,293.035675 183.851105,293.377380 
	C183.029160,298.841797 182.383514,303.964447 181.737869,309.087128 
	C181.173416,307.376770 180.319382,305.700195 180.114899,303.947845 
	C179.833832,301.539398 180.044540,299.073578 180.044540,296.632324 
	C179.458282,296.496246 178.872040,296.360168 178.285782,296.224091 
	C177.846649,297.765625 177.191605,299.282166 177.009842,300.853455 
	C176.510971,305.165985 176.210846,309.501465 175.410706,313.582214 
	C174.958191,312.874298 174.927017,312.412048 174.943527,311.487274 
	C174.991211,302.743591 174.991211,294.462372 174.991211,286.181183 
	C174.424667,286.064423 173.858139,285.947632 173.291595,285.830872 
	C172.413605,288.610901 170.737289,291.415253 170.821243,294.165924 
	C171.004868,300.182892 172.082031,306.172577 172.841461,312.501923 
	C173.604019,313.239777 174.319351,313.649139 175.034668,314.058472 
z"
  />
  <path
    fill="#848BAA"
    opacity="1.000000"
    stroke="none"
    d="
M99.914734,375.255646 
	C99.128967,374.595673 98.343193,373.935699 97.280609,372.748260 
	C97.033287,371.200104 97.062775,370.179413 97.437698,369.168640 
	C98.598145,369.711426 99.350029,370.419495 100.241318,370.740692 
	C102.084900,371.405121 104.590736,372.846191 105.740814,372.157806 
	C107.172829,371.300690 107.511131,368.616333 108.526505,366.242218 
	C98.098946,366.563080 96.013573,364.753571 95.938004,356.462769 
	C95.918335,354.304474 95.929985,352.145874 96.088547,349.676270 
	C99.527916,343.425781 101.068245,343.045319 111.176163,345.896942 
	C111.176163,352.350647 111.432709,358.943146 111.064430,365.500519 
	C110.888809,368.627594 109.525520,371.687927 108.700195,374.778503 
	C108.337814,374.877625 107.975433,374.976746 106.909737,375.118591 
	C104.109192,375.192749 102.011963,375.224182 99.914734,375.255646 
M107.978989,352.826324 
	C106.738068,350.911621 105.698448,348.786316 104.093636,347.252380 
	C103.755379,346.929047 100.231583,348.619781 100.088921,349.647980 
	C99.580826,353.309875 99.643173,357.116882 100.084846,360.797546 
	C100.193199,361.700531 102.546326,362.874817 103.895767,362.912628 
	C104.925011,362.941467 106.565536,361.562622 106.932899,360.472595 
	C107.654037,358.332886 107.677750,355.958160 107.978989,352.826324 
z"
  />
  <path
    fill="#8E97B6"
    opacity="1.000000"
    stroke="none"
    d="
M358.016113,291.041260 
	C359.402374,291.021301 360.788666,291.001373 362.772919,291.243073 
	C364.555420,292.034668 365.739929,292.564575 366.924469,293.094482 
	C366.924469,293.094482 366.993713,293.005402 366.986267,293.363708 
	C367.605530,294.830444 368.232239,295.938904 368.859009,297.047363 
	C368.892731,298.741852 368.926483,300.436371 368.602325,302.702454 
	C364.309906,304.063019 360.375427,304.852020 355.404602,305.848816 
	C356.744415,307.946045 357.893341,311.208252 359.007111,311.196320 
	C361.685638,311.167511 364.347656,309.604095 367.447449,308.507233 
	C369.768829,311.342499 365.911896,312.765289 364.989990,314.976044 
	C364.556671,315.039246 364.123383,315.102448 362.957764,315.174255 
	C360.791107,315.262817 359.356720,315.342773 357.922363,315.422699 
	C356.016144,313.503815 354.109894,311.584930 352.146545,308.876282 
	C352.078247,301.713989 350.913788,294.940430 358.016113,291.041260 
M357.191467,296.564148 
	C357.194763,297.866150 357.198059,299.168182 357.201355,300.470184 
	C359.425781,300.455658 361.658783,300.528229 363.863251,300.310944 
	C364.124390,300.285187 364.662231,298.171021 364.330444,297.885040 
	C362.995483,296.734375 361.430878,295.782196 359.813812,295.067230 
	C359.315491,294.846924 358.380219,295.614960 357.191467,296.564148 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M142.918106,314.033051 
	C140.127853,314.592560 137.337601,315.152069 134.278961,315.090942 
	C133.991776,313.958160 133.972946,313.445984 133.970886,312.554626 
	C133.710129,310.441254 133.432602,308.707123 133.389374,306.794525 
	C133.470993,305.753632 133.318298,304.891205 133.177704,303.590027 
	C133.276367,300.837311 133.362900,298.523285 133.776672,296.158569 
	C135.344971,295.560944 136.586029,295.013977 139.255112,293.837708 
	C136.528778,292.395721 135.003845,291.589142 133.402512,290.504822 
	C133.023514,289.235596 132.720901,288.244049 132.116455,286.263580 
	C130.943619,288.624176 130.373734,289.771240 129.491333,290.932373 
	C128.761490,290.926727 128.344162,290.907043 127.926819,290.887390 
	C129.158585,288.967712 130.390335,287.048035 131.622086,285.128357 
	C132.910645,286.341614 134.083420,287.718658 135.514450,288.730377 
	C137.744049,290.306641 139.304337,293.015198 143.166534,290.793793 
	C148.152588,287.925964 153.985992,290.042297 155.534698,294.703278 
	C151.929688,292.233795 148.359528,290.768463 144.241135,292.796234 
	C140.063019,294.853455 139.842621,298.962860 140.215546,302.716888 
	C140.595383,306.540710 141.975800,310.265106 142.918106,314.033051 
z"
  />
  <path
    fill="#808AA9"
    opacity="1.000000"
    stroke="none"
    d="
M234.058517,311.186096 
	C235.004700,310.680878 235.950867,310.175659 237.364670,309.278503 
	C237.621735,307.569397 237.411163,306.252167 237.200592,304.934937 
	C235.023087,303.016632 232.845581,301.098297 230.174072,298.868896 
	C229.120239,298.372070 228.560394,298.186310 228.000534,298.000580 
	C227.161621,297.109924 226.322708,296.219269 225.191315,294.781494 
	C222.956970,289.660461 224.408508,285.833252 227.509583,282.369019 
	C227.852142,282.334198 228.194687,282.299377 229.222031,282.188660 
	C232.264038,282.099548 234.621262,282.086273 236.978485,282.073029 
	C238.390198,283.791840 239.801910,285.510651 241.206573,287.939819 
	C241.117050,289.420410 241.034576,290.190643 240.952118,290.960907 
	C239.845291,290.785522 238.738480,290.610138 237.389923,289.947937 
	C237.084976,289.303192 237.021774,289.145264 236.958588,288.987366 
	C236.293442,287.954468 235.628296,286.921600 234.379547,285.622040 
	C232.759048,285.499817 231.722107,285.644287 230.685181,285.788757 
	C230.685181,285.788757 230.317795,285.791229 229.814529,286.017822 
	C227.198608,288.748993 227.078156,291.090576 229.879776,293.193085 
	C230.253265,293.538269 230.626755,293.883484 231.414795,294.583252 
	C233.758087,296.064850 235.686798,297.191864 237.615509,298.318909 
	C237.615509,298.318909 237.686646,298.325165 237.792664,298.688416 
	C241.803467,302.293213 241.223419,306.728729 241.091599,311.044922 
	C239.868668,312.213776 238.645737,313.382660 236.775787,314.770660 
	C233.731537,315.078674 231.334335,315.167542 228.937119,315.256409 
	C228.665695,315.076355 228.397614,314.891418 228.030884,314.050293 
	C227.989197,312.460846 228.049469,311.522675 228.456146,310.702454 
	C230.554550,310.942291 232.306534,311.064178 234.058517,311.186096 
z"
  />
  <path
    fill="#9399B6"
    opacity="1.000000"
    stroke="none"
    d="
M75.063965,347.145233 
	C75.625038,346.622223 76.186119,346.099243 77.138168,345.255249 
	C77.784691,344.715088 78.040230,344.495880 78.295776,344.276672 
	C81.014969,343.997559 83.734161,343.718445 86.709137,344.074554 
	C87.981133,345.467621 88.997353,346.225433 90.013580,346.983215 
	C90.013580,346.983215 89.999695,347.000000 90.013123,347.291687 
	C90.340523,348.058868 90.654503,348.534393 90.968483,349.009888 
	C91.021019,349.733398 91.073555,350.456940 91.083214,351.925842 
	C91.049767,353.460663 91.059189,354.250092 91.068619,355.039520 
	C90.976311,355.370697 90.884010,355.701904 90.339355,356.384247 
	C87.234932,356.812775 84.580528,356.996643 81.931412,356.938843 
	C79.028351,356.875488 78.570465,358.511414 79.007111,360.805450 
	C79.666359,364.268890 82.554062,364.794891 86.433876,362.166168 
	C87.251953,363.496246 87.706291,364.793152 88.160629,366.090057 
	C87.367500,366.474304 86.574371,366.858521 85.032883,367.244141 
	C82.497879,367.234283 80.711235,367.223053 78.924591,367.211823 
	C77.876289,366.426422 76.827988,365.641052 75.458130,364.335327 
	C75.112366,358.258423 75.088165,352.701813 75.063965,347.145233 
M87.772728,351.503876 
	C87.979836,347.591675 85.681770,346.740021 82.382294,347.057617 
	C78.831169,347.399353 78.616241,349.591492 79.479652,353.201263 
	C82.137283,352.892456 84.812302,352.581635 87.772728,351.503876 
z"
  />
  <path
    fill="#F7FDFC"
    opacity="1.000000"
    stroke="none"
    d="
M252.024628,366.995117 
	C249.586105,367.191864 246.641663,366.028992 245.405838,369.457947 
	C244.607193,368.869934 243.808563,368.281952 243.132355,367.037231 
	C244.159012,365.256256 245.063232,364.131958 246.269867,362.967865 
	C246.898254,362.519501 247.224258,362.110962 247.550262,361.702454 
	C247.026520,361.466766 246.502792,361.231110 245.974655,360.576965 
	C245.622635,357.491974 247.060394,354.032104 242.550507,352.923889 
	C240.152283,351.221527 238.033386,349.686249 235.903412,347.786499 
	C234.905777,346.961121 233.919220,346.500214 232.720673,345.933197 
	C232.244293,345.633301 232.061218,345.380646 231.965302,344.758850 
	C231.642731,343.962860 231.314270,343.477112 230.939987,342.661743 
	C230.927902,341.890900 230.961655,341.449707 231.308563,340.856018 
	C234.643448,337.802734 236.622665,339.400940 238.758057,342.054382 
	C241.906799,345.967072 243.605591,345.627594 245.979202,341.004883 
	C246.549133,341.188019 247.119064,341.371124 247.855347,342.188538 
	C248.043579,343.583252 248.065430,344.343719 248.051956,345.054321 
	C248.016632,345.004425 248.111511,345.081573 247.768127,345.090088 
	C244.879883,347.351685 246.515152,348.298004 248.783722,349.384705 
	C248.970459,350.111908 249.043411,350.536224 248.843597,351.146606 
	C249.271912,352.582825 249.972992,353.833038 250.781876,355.388916 
	C250.950272,356.121429 251.010849,356.548309 250.806061,357.165833 
	C251.252167,358.598846 251.963669,359.841217 252.780762,361.427979 
	C252.987320,362.485199 253.088272,363.197998 252.895523,364.107849 
	C252.409424,365.201630 252.217041,366.098389 252.024628,366.995117 
z"
  />
  <path
    fill="#8A90AB"
    opacity="1.000000"
    stroke="none"
    d="
M177.980606,344.040924 
	C178.887329,344.522675 179.794052,345.004395 180.905487,346.061005 
	C181.732025,348.092163 182.353867,349.548462 182.975708,351.004730 
	C182.972092,352.068237 182.968475,353.131714 182.651947,354.726959 
	C181.220795,355.851044 180.102539,356.443359 178.550797,357.002686 
	C176.089264,357.002167 174.061203,357.034698 171.679535,357.070374 
	C171.204590,358.385651 171.083252,359.697723 171.017426,361.339722 
	C172.043060,362.455231 173.013184,363.240875 174.161652,364.249939 
	C175.240997,363.995178 176.141998,363.516998 177.312225,362.882141 
	C178.074936,361.848450 178.568420,360.971497 179.441345,360.080994 
	C180.546677,361.380035 181.272568,362.692566 181.998444,364.005127 
	C180.592850,365.044495 179.187256,366.083862 177.021347,367.140198 
	C174.481750,367.169434 172.702438,367.181641 170.923141,367.193878 
	C170.446945,366.884949 169.970764,366.576019 169.163879,365.865540 
	C168.294922,364.965118 167.756668,364.466217 167.162247,363.602570 
	C166.939926,362.519989 166.773788,361.802124 166.691772,360.622681 
	C166.696793,356.719666 166.617676,353.278259 166.538559,349.836823 
	C167.852341,348.038025 169.166122,346.239227 171.202026,344.260803 
	C173.942963,344.067780 175.961792,344.054352 177.980606,344.040924 
M176.295959,353.143097 
	C178.956039,353.074829 179.720398,351.190308 178.393814,349.570526 
	C177.287430,348.219635 174.927826,347.375702 173.106873,347.347015 
	C172.186768,347.332520 171.230347,349.621155 170.290344,350.868439 
	C172.010925,351.634644 173.731506,352.400848 176.295959,353.143097 
z"
  />
  <path
    fill="#8E97B6"
    opacity="1.000000"
    stroke="none"
    d="
M143.176880,314.237976 
	C141.975800,310.265106 140.595383,306.540710 140.215546,302.716888 
	C139.842621,298.962860 140.063019,294.853455 144.241135,292.796234 
	C148.359528,290.768463 151.929688,292.233795 155.515808,295.092834 
	C155.860641,295.203857 155.930908,295.596375 155.972778,296.082092 
	C156.315216,297.053558 156.615799,297.539337 156.916382,298.025116 
	C156.889236,299.615631 156.862076,301.206116 156.424728,303.286011 
	C153.439774,303.887482 150.844208,303.841278 148.298584,304.175385 
	C146.931259,304.354858 144.791794,304.925598 144.529099,305.786316 
	C144.128372,307.099274 144.609512,309.219208 145.542572,310.244476 
	C146.410294,311.197937 148.343536,311.374451 149.837997,311.485809 
	C150.618088,311.543915 151.463501,310.725311 153.298630,309.774750 
	C153.149460,312.053101 153.056992,313.465363 152.964539,314.877625 
	C152.540237,314.954834 152.115936,315.032043 150.960159,315.124176 
	C148.796448,315.180695 147.364212,315.222290 145.931976,315.263885 
	C145.099869,314.990204 144.267761,314.716553 143.176880,314.237976 
M145.547058,295.969360 
	C144.999344,297.368591 144.451630,298.767792 143.673538,300.755554 
	C146.167374,300.879852 147.824387,301.248230 149.349014,300.946899 
	C150.542221,300.711060 151.571365,299.645233 152.673401,298.948151 
	C151.782410,297.687012 151.128311,296.048431 149.921280,295.290802 
	C149.044159,294.740326 147.435898,295.354706 145.547058,295.969360 
z"
  />
  <path
    fill="#8A94B4"
    opacity="1.000000"
    stroke="none"
    d="
M299.073730,306.027100 
	C299.632599,303.575348 300.191467,301.123596 300.671021,298.303955 
	C300.732849,297.209717 300.873993,296.483368 301.272247,295.218384 
	C301.683014,294.117828 301.836670,293.555908 301.990356,292.993958 
	C302.317169,292.884766 302.575989,292.686676 303.257690,292.195618 
	C308.673035,291.962799 306.115845,297.505371 308.977234,299.012878 
	C309.110687,299.878845 309.244110,300.744843 309.574188,302.241577 
	C310.333984,303.495453 310.897156,304.118530 311.460297,304.741638 
	C311.874786,303.796234 312.289246,302.850800 312.703705,301.905396 
	C312.806549,301.489990 312.909393,301.074554 313.299255,300.110413 
	C313.966248,297.683228 314.346161,295.804779 314.726074,293.926331 
	C315.016449,293.430145 315.306824,292.933960 316.159485,292.314270 
	C317.197571,293.752808 318.326721,295.451569 318.033478,296.852631 
	C316.899109,302.272797 315.311371,307.598053 313.892517,312.958679 
	C313.892517,312.958679 313.756012,313.276947 313.482574,313.562439 
	C312.809235,314.247711 312.409332,314.647461 312.009399,315.047211 
	C311.563171,315.026855 311.116943,315.006531 310.192871,314.700043 
	C308.812561,312.942291 307.910095,311.470673 307.007629,309.999054 
	C306.881042,308.814911 306.754456,307.630768 306.442841,305.767761 
	C305.643066,303.777527 305.028320,302.466095 304.283844,300.878021 
	C303.097839,303.120392 302.304016,304.621216 301.104645,306.091370 
	C300.157288,306.049469 299.615509,306.038269 299.073730,306.027100 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M202.557098,314.108185 
	C201.328629,313.263214 200.100159,312.418243 198.617264,311.398285 
	C196.075729,314.631409 193.615585,318.860443 188.423950,315.552551 
	C195.024963,314.072174 197.512665,311.464478 197.914200,305.443207 
	C198.702957,293.615875 195.876404,291.090424 184.027405,293.035675 
	C184.027405,293.035675 183.894012,292.902344 183.822998,292.837280 
	C188.819809,287.581543 194.461807,288.828156 199.341293,296.216736 
	C200.891525,290.689575 204.520355,288.644989 210.451996,290.185425 
	C211.368118,290.423340 212.821915,291.795990 212.688431,292.252441 
	C212.328857,293.482025 211.245773,294.500031 210.215637,294.985840 
	C207.923264,289.234985 205.187286,295.791290 202.848145,293.202026 
	C202.848145,300.363312 202.848145,306.766571 202.801239,313.412476 
	C202.754318,313.655060 202.557098,314.108185 202.557098,314.108185 
z"
  />
  <path
    fill="#8186A5"
    opacity="1.000000"
    stroke="none"
    d="
M203.170059,335.966919 
	C203.214539,338.311737 203.259018,340.656586 203.247955,343.750214 
	C203.242111,344.708191 203.291855,344.917358 203.341599,345.126556 
	C203.320847,349.415527 203.300110,353.704529 203.186737,358.881897 
	C203.046722,361.837280 202.999313,363.904297 202.658844,366.124573 
	C199.211563,366.654846 196.057358,367.031799 192.903152,367.408752 
	C192.499649,367.302399 192.096146,367.196014 191.295197,366.778564 
	C190.588333,366.335510 190.278931,366.203491 189.969513,366.071472 
	C189.969513,366.071472 190.017868,366.047302 189.921661,365.641602 
	C189.395309,360.065430 188.412262,354.863129 188.707672,349.734467 
	C188.949692,345.533020 192.025131,343.531433 196.511551,344.623962 
	C197.405151,344.841614 198.397141,344.655334 200.036377,344.655334 
	C200.036377,342.666138 199.637970,340.772461 200.162750,339.183716 
	C200.580704,337.918396 202.125488,337.025269 203.170059,335.966919 
M195.467270,347.001801 
	C194.320801,347.543732 192.194992,348.063324 192.182022,348.631073 
	C192.073563,353.387085 192.226456,358.165527 192.733368,362.890778 
	C192.778488,363.311432 196.463730,364.218994 197.468567,363.505829 
	C201.588104,360.582062 200.641373,350.091248 195.467270,347.001801 
z"
  />
  <path
    fill="#8793B3"
    opacity="1.000000"
    stroke="none"
    d="
M45.026230,291.091309 
	C45.770020,291.065918 46.513805,291.040558 47.819237,291.262207 
	C49.909794,292.676300 51.438709,293.843414 52.967625,295.010529 
	C53.002567,301.016998 53.037514,307.023468 52.678223,313.513794 
	C51.187775,313.331543 50.091564,312.665466 48.995354,311.999359 
	C49.010357,307.895844 49.322369,303.760986 48.894253,299.704224 
	C48.722904,298.080536 46.921333,296.628906 45.087311,295.136566 
	C43.486057,298.858215 42.569302,302.525116 41.863773,306.232208 
	C41.383175,308.757446 41.198048,311.338959 40.880798,313.895294 
	C40.061008,313.850281 39.241215,313.805267 38.421425,313.760254 
	C38.268448,306.833405 38.115467,299.906555 37.962490,292.979706 
	C38.286121,292.873688 38.550549,292.684784 39.218811,292.256622 
	C41.463310,291.763916 43.244770,291.427612 45.026230,291.091309 
z"
  />
  <path
    fill="#E2E8F4"
    opacity="1.000000"
    stroke="none"
    d="
M74.866333,346.855682 
	C75.088165,352.701813 75.112366,358.258423 75.158524,364.202179 
	C73.580849,365.562775 71.981209,366.536163 70.173996,366.896027 
	C64.510971,357.985992 64.406540,350.091431 69.383659,345.959381 
	C67.893990,343.578705 66.449684,341.270508 65.005386,338.962311 
	C65.960167,339.293091 67.129196,339.380768 67.830704,339.998962 
	C70.199532,342.086487 72.402367,344.362305 74.866333,346.855682 
z"
  />
  <path
    fill="#797E9F"
    opacity="1.000000"
    stroke="none"
    d="
M137.384949,343.296021 
	C139.673691,343.849030 141.962433,344.402039 144.698181,345.370850 
	C145.696045,347.611053 146.572281,349.407776 146.730225,351.265533 
	C147.061996,355.167816 147.016388,359.102234 147.125687,363.023438 
	C147.033844,363.163910 146.941986,363.304413 146.451904,363.725098 
	C145.371674,364.652740 144.689667,365.300232 143.586761,365.934143 
	C141.577728,366.280029 140.001862,366.711426 138.399017,366.984711 
	C134.442520,367.659241 131.559952,365.422058 130.897446,361.702728 
	C130.125137,357.366974 132.664688,355.449554 136.258789,354.074707 
	C136.024872,355.282593 135.073425,356.286804 135.034637,357.325073 
	C134.953262,359.504181 134.627274,362.205078 135.719437,363.775146 
	C137.445251,366.256042 139.765442,364.941315 141.442245,362.926392 
	C144.470734,359.287140 142.884476,356.691803 139.964706,354.889038 
	C140.911423,353.200775 141.803757,351.609467 142.744843,349.700256 
	C142.252609,348.790771 141.711609,348.199158 140.942505,347.423157 
	C140.142548,347.167603 139.570709,347.096405 138.749161,346.985718 
	C138.334839,346.982422 138.170227,347.018677 137.667572,347.053650 
	C136.895081,347.170685 136.460663,347.289001 135.758942,347.185303 
	C136.122757,345.740845 136.753860,344.518433 137.384949,343.296021 
z"
  />
  <path
    fill="#7B83A5"
    opacity="1.000000"
    stroke="none"
    d="
M183.851105,293.377380 
	C195.876404,291.090424 198.702957,293.615875 197.914200,305.443207 
	C197.512665,311.464478 195.024963,314.072174 188.302521,315.214355 
	C187.491272,315.238403 187.068283,315.159637 186.192261,314.760132 
	C184.799301,313.629913 183.859375,312.820404 182.919434,312.010925 
	C182.609451,311.232727 182.299469,310.454559 181.863678,309.381744 
	C182.383514,303.964447 183.029160,298.841797 183.851105,293.377380 
M185.026550,303.503540 
	C185.039017,304.670013 184.622726,306.102905 185.149155,306.949982 
	C186.425095,309.003174 188.139999,310.783569 189.678024,312.673889 
	C191.087280,310.875122 193.236954,309.255920 193.727173,307.234314 
	C194.412598,304.407715 194.155716,301.235840 193.707352,298.302582 
	C193.497543,296.930023 192.109116,295.113220 190.861053,294.706604 
	C189.706894,294.330597 187.315979,295.162537 186.733490,296.183502 
	C185.670807,298.046112 185.542435,300.441803 185.026550,303.503540 
z"
  />
  <path
    fill="#878DAB"
    opacity="1.000000"
    stroke="none"
    d="
M271.030029,354.983704 
	C270.196289,353.797180 269.362579,352.610687 268.425446,350.804230 
	C268.543671,349.070068 268.765259,347.955811 268.986847,346.841583 
	C269.528809,346.342621 270.070801,345.843658 271.163361,345.104919 
	C273.523895,344.453400 275.333832,344.041626 277.143768,343.629852 
	C278.619995,345.009613 280.096191,346.389343 281.469177,348.382751 
	C279.930481,348.635986 278.495056,348.275574 276.658508,347.807922 
	C275.437988,347.736847 274.618591,347.773071 273.569275,347.934723 
	C273.236420,348.381958 273.133423,348.703735 273.016693,349.376892 
	C274.001038,350.806061 274.999176,351.883850 276.166504,353.230408 
	C277.118195,353.776276 277.900665,354.053375 278.683105,354.330505 
	C279.775818,355.652313 280.868530,356.974152 282.007202,359.014282 
	C282.036957,361.153290 282.020844,362.573944 282.004700,363.994629 
	C281.511169,364.577454 281.017639,365.160248 279.905731,366.025055 
	C276.825012,366.604126 274.362640,366.901184 271.900299,367.198242 
	C271.109985,366.558197 270.319672,365.918152 269.230164,364.801025 
	C268.931000,362.964569 268.931000,361.605164 268.931000,360.245758 
	C271.075867,361.023651 271.673767,362.500366 272.692657,362.897736 
	C274.216858,363.492279 276.037170,363.327698 277.731506,363.486023 
	C277.607452,361.909851 278.082397,359.737000 277.232544,358.883942 
	C275.552612,357.197754 273.139832,356.241669 271.030029,354.983704 
z"
  />
  <path
    fill="#848BAA"
    opacity="1.000000"
    stroke="none"
    d="
M322.761292,352.878723 
	C322.818268,352.453705 322.875275,352.028687 323.352905,351.236633 
	C324.518433,348.932373 325.263306,346.995148 326.327881,345.043396 
	C329.093964,344.694366 331.540344,344.359863 333.986725,344.025360 
	C335.064667,344.334778 336.142609,344.644196 337.875183,344.901062 
	C339.091003,344.290802 339.652130,343.733124 340.213257,343.175415 
	C340.897583,343.140533 341.581940,343.105652 342.615540,343.343628 
	C342.965881,343.796204 342.966980,343.975922 342.978119,344.544495 
	C342.961273,345.712585 342.934357,346.491882 342.907440,347.271179 
	C342.240570,347.254364 341.573730,347.237518 340.272827,347.270386 
	C339.369537,347.821991 339.100281,348.323975 338.830994,348.825928 
	C338.830994,348.825928 338.634033,349.257141 338.243347,349.669495 
	C337.724060,351.810028 337.595459,353.538208 337.466858,355.266418 
	C337.423431,357.829651 337.619720,360.430084 337.230164,362.939575 
	C337.049652,364.102325 335.728058,365.087952 334.505829,365.597015 
	C334.074310,360.962463 334.285828,356.865936 333.946350,352.815552 
	C333.791046,350.962524 332.551056,349.200378 331.802948,347.397034 
	C328.463226,346.661652 327.284454,348.514099 326.942627,352.120972 
	C326.954651,354.554932 327.009338,356.287872 327.064026,358.020782 
	C327.020477,359.401764 326.976929,360.782776 326.544189,362.611572 
	C325.181091,363.357391 324.207214,363.655396 323.177948,363.500153 
	C323.002106,359.657501 322.881683,356.268127 322.761292,352.878723 
z"
  />
  <path
    fill="#858AA3"
    opacity="1.000000"
    stroke="none"
    d="
M366.978088,357.051300 
	C366.978088,357.051300 367.013153,357.513306 367.051147,358.132874 
	C366.327759,366.037598 365.147980,367.019104 356.941010,367.195007 
	C356.145966,366.226837 355.350922,365.258698 354.673523,363.504150 
	C354.832825,362.087524 354.874512,361.457275 355.275299,360.792877 
	C356.113617,360.796356 356.592865,360.834015 357.108704,361.223785 
	C358.087280,362.419952 359.029266,363.263977 360.144531,364.317810 
	C360.955475,364.124481 361.593170,363.721436 362.243561,363.289764 
	C362.256256,363.261139 362.311554,363.231812 362.620880,363.151886 
	C362.683502,361.675842 362.436768,360.279694 362.171143,358.866882 
	C362.152191,358.850220 362.166931,358.802002 362.101654,358.493530 
	C361.335388,357.840118 360.634369,357.495117 359.627075,357.015930 
	C358.899719,356.733826 358.478668,356.585907 358.013336,356.162048 
	C357.345337,355.540161 356.721649,355.194153 355.874176,354.580383 
	C352.217560,351.427185 352.444855,348.320343 355.091156,345.067169 
	C355.091156,345.067169 354.998840,345.031525 355.438568,345.006042 
	C358.916748,344.698639 361.955170,344.416687 364.993622,344.134705 
	C365.069916,344.423767 365.229492,344.655457 365.725830,345.259583 
	C366.295227,346.753479 366.611206,347.817505 366.642975,349.025360 
	C365.090759,348.744324 363.844757,348.078156 362.548767,347.959900 
	C361.141937,347.831451 359.688568,348.212952 358.255493,348.372070 
	C358.854156,349.856537 359.098724,351.699860 360.144226,352.731476 
	C361.357880,353.928986 363.274353,354.414154 365.030762,355.456543 
	C365.776550,356.153503 366.377319,356.602386 366.978088,357.051300 
z"
  />
  <path
    fill="#EDF3FB"
    opacity="1.000000"
    stroke="none"
    d="
M147.471008,363.017517 
	C147.016388,359.102234 147.061996,355.167816 146.730225,351.265533 
	C146.572281,349.407776 145.696045,347.611053 145.054382,345.420288 
	C146.723343,344.370148 148.483139,343.686371 150.486084,343.317719 
	C151.164505,344.750122 151.599762,345.867340 152.339569,347.005554 
	C153.095093,347.386932 153.546036,347.747375 154.033905,348.255920 
	C154.146118,348.597015 154.265503,348.757141 154.545074,349.334900 
	C154.609695,352.883911 154.558273,355.982330 154.419128,359.172729 
	C154.223785,359.527649 154.287262,359.747253 154.610550,360.339478 
	C154.782074,362.173096 154.864853,363.590851 154.706131,364.996979 
	C154.464615,364.985382 153.981140,364.975037 153.981140,364.975037 
	C152.446335,362.687439 150.445190,361.864075 147.471008,363.017517 
z"
  />
  <path
    fill="#DADEF0"
    opacity="1.000000"
    stroke="none"
    d="
M245.974747,340.627075 
	C243.605591,345.627594 241.906799,345.967072 238.758057,342.054382 
	C236.622665,339.400940 234.643448,337.802734 231.276428,340.475555 
	C233.139343,334.190460 237.377762,338.791748 240.737869,338.731842 
	C240.816528,337.936584 240.947006,337.069550 240.896942,337.059021 
	C237.726227,336.390717 234.421143,335.018372 231.402863,335.485260 
	C229.667999,335.753632 227.408905,338.897583 227.169495,340.954712 
	C226.881607,343.428162 228.489365,346.122253 229.175720,348.822021 
	C229.075027,348.923218 228.873016,349.125000 228.873016,349.125000 
	C223.851135,345.847290 223.524063,337.910126 228.567398,334.118347 
	C234.616943,329.570099 242.828598,331.585480 245.974747,340.627075 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M109.082626,374.821045 
	C109.525520,371.687927 110.888809,368.627594 111.064430,365.500519 
	C111.432709,358.943146 111.176163,352.350647 111.176163,345.896942 
	C101.068245,343.045319 99.527916,343.425781 95.769035,349.676239 
	C94.861107,349.992157 94.433983,349.997009 94.006859,350.001862 
	C94.988914,345.436981 99.029533,341.937439 103.254646,342.048279 
	C105.506783,342.107391 108.069618,341.625671 109.916618,342.544769 
	C111.653946,343.409302 113.659370,345.731506 113.768234,347.520660 
	C114.200928,354.631134 114.268044,361.809082 113.727570,368.905487 
	C113.568985,370.987671 110.954155,372.882782 109.082626,374.821045 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M314.273621,312.907410 
	C315.311371,307.598053 316.899109,302.272797 318.033478,296.852631 
	C318.326721,295.451569 317.197571,293.752808 316.371429,292.118774 
	C317.034088,291.590332 318.064575,290.736450 319.055573,290.780090 
	C320.243469,290.832367 321.397675,291.650330 322.826416,292.524170 
	C322.694244,294.316742 322.302124,295.721832 321.909973,297.126923 
	C323.296265,296.844604 324.692413,296.602844 326.066315,296.269470 
	C327.733582,295.864899 329.382080,295.383026 331.344177,295.090912 
	C332.040833,296.747253 332.431976,298.247223 332.443665,299.849426 
	C329.278656,300.841217 326.493103,301.730835 323.413513,302.771942 
	C322.294647,303.964050 321.469757,305.004639 320.644867,306.045227 
	C317.708160,305.733398 315.888153,306.607178 315.923065,310.001099 
	C315.932800,310.948334 315.100647,311.904236 314.273621,312.907410 
z"
  />
  <path
    fill="#8A94B4"
    opacity="1.000000"
    stroke="none"
    d="
M120.852905,314.335815 
	C119.817757,314.576416 118.782608,314.817017 117.018829,315.073914 
	C115.164261,315.115601 114.038322,315.141052 112.912384,315.166504 
	C112.546928,315.074677 112.181473,314.982849 111.432404,314.528320 
	C110.532982,312.819489 110.017174,311.473450 109.945473,310.094971 
	C111.272018,310.041718 112.154449,310.020844 113.036880,310.000000 
	C113.496361,310.342560 113.955841,310.685120 114.923950,311.347015 
	C118.752586,311.739807 120.101692,309.880188 120.113823,306.803772 
	C120.131866,302.228607 120.096542,297.653259 120.083923,293.077972 
	C120.378647,292.930298 120.617058,292.719055 121.224838,292.264801 
	C122.068924,292.113190 122.487320,292.141022 123.082886,292.420807 
	C123.718269,293.521790 124.176476,294.370850 124.614944,295.378540 
	C124.595207,295.537170 124.738632,295.822876 124.681168,296.081665 
	C124.639221,296.516541 124.654739,296.692566 124.670265,296.868622 
	C124.440453,301.935760 124.210640,307.002930 123.660561,312.659851 
	C122.511162,313.611725 121.682030,313.973755 120.852905,314.335815 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M312.101074,367.375580 
	C308.492584,369.852814 302.525116,368.614624 301.375977,364.022644 
	C300.101501,358.929932 299.984192,353.133118 301.059448,347.998077 
	C301.919556,343.890503 306.018463,341.704224 310.841980,342.016907 
	C315.264374,342.303650 317.070099,345.353760 318.692291,348.696960 
	C319.265442,349.878082 319.495148,351.225830 319.929321,353.117004 
	C319.981842,354.057648 319.983276,354.378815 319.981323,354.699982 
	C319.099518,355.613739 318.217682,356.527496 316.877930,357.690948 
	C316.232178,357.915009 316.044373,357.889374 315.875610,357.539490 
	C315.870117,356.780396 315.845612,356.345551 316.053955,355.591583 
	C317.961670,351.869263 317.524811,348.192413 314.607483,346.399475 
	C312.277130,344.967255 308.219360,344.437164 305.868591,345.529694 
	C303.862091,346.462250 302.545624,350.166595 302.106110,352.839233 
	C301.618408,355.804626 302.471619,358.990509 302.794189,362.446808 
	C304.513519,367.210968 308.410950,367.083008 312.101074,367.375580 
z"
  />
  <path
    fill="#7E89AF"
    opacity="1.000000"
    stroke="none"
    d="
M29.110088,312.968994 
	C29.211460,303.158813 29.312836,293.348663 30.052332,283.266937 
	C31.440645,283.021179 32.190838,283.046936 32.941029,283.072693 
	C32.941029,283.072693 32.998558,283.001923 32.983288,283.382294 
	C33.108433,284.209442 33.248848,284.656219 33.389263,285.102997 
	C33.333336,293.099274 33.363876,301.097778 33.156731,309.090149 
	C33.108833,310.938416 32.248329,312.765625 31.290438,314.501556 
	C30.249445,313.923401 29.679768,313.446198 29.110088,312.968994 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M98.492645,311.045135 
	C97.535027,309.507904 96.577408,307.970642 95.340248,305.775696 
	C95.289856,302.166870 95.519012,299.215759 95.748177,296.264618 
	C94.826042,296.231049 93.903915,296.197479 92.981789,296.163879 
	C92.981789,299.441376 92.981789,302.718842 92.981789,305.996338 
	C92.665367,308.279297 92.348946,310.562256 91.593880,312.510132 
	C91.134064,306.877167 91.015419,301.577148 91.129128,296.282166 
	C91.194870,293.220581 89.813446,292.483124 87.097031,292.817627 
	C87.059952,291.988037 87.064491,291.512848 87.069031,291.037689 
	C88.573082,290.949524 90.104401,291.007660 91.573875,290.733917 
	C93.196106,290.431702 94.761627,289.825104 96.910912,289.174316 
	C97.637268,288.998535 97.804565,288.999542 97.944870,289.343384 
	C97.004578,291.150146 96.091263,292.614105 95.084740,294.227448 
	C96.436775,295.052979 97.538971,295.725983 98.646011,296.838745 
	C98.598114,301.867371 98.545380,306.456268 98.492645,311.045135 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M142.696075,350.018158 
	C141.803757,351.609467 140.911423,353.200775 139.964706,354.889038 
	C142.884476,356.691803 144.470734,359.287140 141.442245,362.926392 
	C139.765442,364.941315 137.445251,366.256042 135.719437,363.775146 
	C134.627274,362.205078 134.953262,359.504181 135.034637,357.325073 
	C135.073425,356.286804 136.024872,355.282593 136.056915,353.831848 
	C134.726303,353.276123 133.901688,353.151550 133.077072,353.026978 
	C132.790436,352.530548 132.503799,352.034119 132.457275,351.267883 
	C133.807007,349.801178 134.916611,348.604218 136.026215,347.407288 
	C136.460663,347.289001 136.895081,347.170685 137.759430,347.211700 
	C138.459198,347.255768 138.729034,347.140503 138.998871,347.025269 
	C139.570709,347.096405 140.142548,347.167603 140.915756,347.759460 
	C141.643448,348.859497 142.169769,349.438812 142.696075,350.018158 
z"
  />
  <path
    fill="#8890B0"
    opacity="1.000000"
    stroke="none"
    d="
M64.694214,338.990540 
	C66.449684,341.270508 67.893990,343.578705 69.383659,345.959381 
	C64.406540,350.091431 64.510971,357.985992 69.984398,366.650085 
	C68.610138,367.035156 67.217896,367.052643 65.230293,366.703094 
	C64.024811,364.520630 63.048206,362.737427 62.885059,360.882690 
	C62.594498,357.579498 63.061531,354.200745 62.662594,350.920349 
	C62.500202,349.584991 60.904041,348.424011 59.766357,346.963104 
	C59.459240,346.182251 59.345982,345.622009 59.569588,345.001526 
	C60.982533,342.985626 62.058613,341.029938 63.134701,339.074280 
	C63.550819,339.055786 63.966934,339.037292 64.694214,338.990540 
z"
  />
  <path
    fill="#7F8BAC"
    opacity="1.000000"
    stroke="none"
    d="
M82.956390,314.828125 
	C81.310699,314.544525 79.665009,314.260925 77.589912,313.443787 
	C76.825966,308.558044 76.651764,304.184509 76.077286,299.864227 
	C75.850197,298.156372 74.732300,296.566925 73.828766,294.732300 
	C73.446442,294.038086 73.255356,293.535095 73.064278,293.032135 
	C73.064278,293.032135 73.189835,292.659119 73.549438,292.371460 
	C74.958084,290.406219 76.007141,288.728638 77.056198,287.051086 
	C77.056190,287.051086 76.999176,287.001221 77.342560,287.057373 
	C78.110794,287.091217 78.535637,287.068909 78.960487,287.046600 
	C78.960487,287.046600 79.000618,287.001221 78.984459,287.368591 
	C79.654129,288.815521 80.339958,289.895081 81.025787,290.974609 
	C81.375244,291.078857 81.724693,291.183105 82.408966,291.688721 
	C82.792389,293.032501 82.840996,293.974976 82.683060,294.967163 
	C82.476517,295.016846 82.081329,295.172821 81.684059,295.147583 
	C78.325874,298.059601 78.224129,307.618011 81.371307,310.935791 
	C82.136269,311.357147 82.649971,311.630157 83.093674,312.266388 
	C83.001251,313.362427 82.978821,314.095306 82.956390,314.828125 
z"
  />
  <path
    fill="#878DAB"
    opacity="1.000000"
    stroke="none"
    d="
M55.310654,361.064056 
	C54.540344,362.973236 53.770027,364.882385 52.999702,366.791534 
	C52.310856,364.842407 51.286152,362.938385 51.016663,360.932892 
	C50.665722,358.321228 50.914078,355.629028 50.954636,352.165771 
	C50.739540,350.493103 50.474339,349.626190 50.117062,348.478638 
	C49.360844,347.885986 48.696701,347.573975 47.777954,347.217896 
	C47.231915,347.345001 46.940475,347.516113 46.454819,347.691284 
	C46.260601,347.695312 45.894196,347.824524 45.614716,347.834290 
	C44.974323,347.936737 44.613415,348.029388 43.951576,348.071045 
	C43.434025,347.054016 43.217403,346.087982 43.403877,345.086090 
	C46.215527,344.678009 48.624088,344.305817 51.032650,343.933624 
	C51.892380,344.463989 52.752110,344.994385 53.801258,346.070923 
	C54.341877,347.073944 54.693077,347.530823 55.046623,348.365051 
	C55.113796,349.493805 55.178627,350.245178 55.146385,351.443359 
	C55.136425,354.948151 55.223537,358.006104 55.310654,361.064056 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M109.501373,310.127380 
	C110.017174,311.473450 110.532982,312.819489 111.150162,314.512085 
	C109.739250,315.232880 108.226974,315.607086 106.483551,315.638733 
	C106.818115,313.501282 108.349731,311.309540 104.339172,311.246277 
	C103.733170,311.097626 103.392593,310.988831 103.032120,310.424774 
	C103.062256,305.094574 103.112297,300.219574 103.464157,295.264191 
	C104.173729,295.099091 104.581482,295.014374 105.146774,295.195648 
	C106.416794,296.006927 107.529266,296.552216 108.717133,297.324280 
	C108.792519,297.551056 108.956482,297.998901 108.902237,298.453125 
	C109.065788,302.647369 109.283585,306.387390 109.501373,310.127380 
z"
  />
  <path
    fill="#959CBC"
    opacity="1.000000"
    stroke="none"
    d="
M123.935959,348.712494 
	C123.326004,348.912018 122.716049,349.111572 121.681335,348.948181 
	C120.903954,347.833893 120.551331,347.082611 120.198708,346.331299 
	C119.913902,346.401031 119.629097,346.470764 119.344292,346.540497 
	C119.344292,352.299500 119.344292,358.058502 119.344292,364.895996 
	C120.041733,359.516724 120.596977,355.234161 121.152229,350.951599 
	C121.331985,355.390900 121.637794,359.831604 121.586960,364.268280 
	C121.576576,365.174072 120.226822,366.064545 119.494476,366.962067 
	C118.651901,365.965027 117.117973,364.993103 117.080696,363.966797 
	C116.864082,358.004028 116.999420,352.028412 117.337540,345.548584 
	C118.113235,344.709564 118.567154,344.379150 119.021065,344.048737 
	C119.453072,344.065704 119.885071,344.082672 120.792313,344.329590 
	C121.858032,344.428772 122.448509,344.298035 123.038986,344.167297 
	C123.462166,344.087189 123.885345,344.007080 124.949951,343.928711 
	C126.051712,343.968353 126.512054,344.006256 126.972389,344.044128 
	C126.745110,345.115295 126.517822,346.186462 125.802414,347.553314 
	C124.854851,348.136810 124.395401,348.424652 123.935959,348.712494 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M153.153870,315.146423 
	C153.056992,313.465363 153.149460,312.053101 153.298630,309.774750 
	C151.463501,310.725311 150.618088,311.543915 149.837997,311.485809 
	C148.343536,311.374451 146.410294,311.197937 145.542572,310.244476 
	C144.609512,309.219208 144.128372,307.099274 144.529099,305.786316 
	C144.791794,304.925598 146.931259,304.354858 148.298584,304.175385 
	C150.844208,303.841278 153.439774,303.887482 156.366547,303.678284 
	C158.162949,307.380829 158.091751,311.065796 155.611313,314.419220 
	C155.185211,314.995270 154.115570,315.095306 153.153870,315.146423 
z"
  />
  <path
    fill="#E2E7F2"
    opacity="1.000000"
    stroke="none"
    d="
M166.607635,361.084290 
	C166.773788,361.802124 166.939926,362.519989 166.880325,363.614319 
	C166.467255,363.994934 166.279953,363.999054 166.092682,364.003174 
	C164.731308,365.024475 163.369934,366.045776 161.605804,366.698914 
	C155.817978,360.717316 156.124680,348.429840 161.057327,345.926880 
	C159.708878,343.613007 158.356857,341.292969 157.004822,338.972961 
	C160.854645,338.979309 161.678040,342.621674 163.572464,344.817810 
	C163.985001,345.296082 162.192780,347.501709 161.673752,349.001007 
	C160.982483,350.997894 160.331497,353.054077 160.055969,355.136749 
	C159.782516,357.203583 159.996124,359.334869 159.996124,362.016571 
	C161.866806,361.613647 163.083099,361.351654 164.944092,361.045624 
	C165.928391,361.029144 166.268005,361.056702 166.607635,361.084290 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M83.163673,311.903198 
	C82.649971,311.630157 82.136269,311.357147 81.337440,310.491241 
	C81.395317,304.989868 81.738319,300.081329 82.081329,295.172821 
	C82.081329,295.172821 82.476517,295.016846 83.054031,294.955139 
	C84.046494,293.619873 84.461441,292.346313 84.876396,291.072754 
	C85.362984,291.054993 85.849564,291.037231 86.702591,291.028564 
	C87.064491,291.512848 87.059952,291.988037 86.988312,293.117096 
	C86.950310,295.845734 86.979401,297.920502 86.749573,300.207703 
	C86.669823,301.617249 86.848991,302.814301 87.044434,304.454498 
	C87.048889,307.264618 87.037048,309.631561 86.650040,311.998962 
	C85.525558,311.975891 84.776245,311.952362 83.811455,311.911255 
	C83.595970,311.893646 83.163673,311.903198 83.163673,311.903198 
z"
  />
  <path
    fill="#797E9F"
    opacity="1.000000"
    stroke="none"
    d="
M156.710480,339.015289 
	C158.356857,341.292969 159.708878,343.613007 161.057327,345.926880 
	C156.124680,348.429840 155.817978,360.717316 161.253326,366.710693 
	C160.154419,367.065857 159.005249,367.040985 157.314087,366.734741 
	C156.163956,365.971771 155.555801,365.490173 154.947632,365.008575 
	C154.864853,363.590851 154.782074,362.173096 154.682571,360.127563 
	C154.665848,359.499725 154.506851,359.080780 154.506851,359.080780 
	C154.558273,355.982330 154.609695,352.883911 154.611786,349.157898 
	C154.486557,348.239258 154.298065,348.098389 153.996979,348.107819 
	C153.546036,347.747375 153.095093,347.386932 152.348740,346.645142 
	C153.112122,343.881256 154.170929,341.498749 155.229736,339.116241 
	C155.625198,339.096710 156.020660,339.077148 156.710480,339.015289 
z"
  />
  <path
    fill="#7B83A5"
    opacity="1.000000"
    stroke="none"
    d="
M341.259155,314.382599 
	C341.142761,307.569427 341.026398,300.756287 341.373657,293.456116 
	C344.571045,293.338928 347.304810,293.708710 349.745239,294.222839 
	C348.152252,295.496582 346.852570,296.625946 345.552887,297.755280 
	C345.280212,302.726227 345.007538,307.697174 344.526794,313.209564 
	C344.318787,313.751007 343.982910,314.117310 343.982910,314.117310 
	C343.730865,314.151764 343.478821,314.186218 342.730652,314.267395 
	C341.909424,314.336914 341.584290,314.359741 341.259155,314.382599 
z"
  />
  <path
    fill="#8A94B4"
    opacity="1.000000"
    stroke="none"
    d="
M244.552597,302.877136 
	C244.641022,301.190674 244.729446,299.504181 245.175201,297.299072 
	C246.693924,295.812897 247.855331,294.845367 249.053101,294.302368 
	C249.133392,295.856171 249.177307,296.985474 249.146347,298.422241 
	C249.044998,299.150543 249.018539,299.571320 248.764587,300.184509 
	C248.689163,301.252808 248.841248,302.128723 248.980011,303.426147 
	C249.039017,305.529755 249.111343,307.211792 249.167145,309.253998 
	C250.712158,311.742950 252.420395,313.238800 255.291199,310.918213 
	C255.955276,310.753265 256.329163,310.639893 256.861542,310.850891 
	C257.351959,311.814362 257.683899,312.453491 257.826630,313.421143 
	C254.119415,313.799164 250.601395,313.848724 247.083389,313.898285 
	C247.083389,313.898285 247.008759,313.998810 246.986923,313.582520 
	C246.160919,309.736542 245.356766,306.306824 244.552597,302.877136 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M203.378235,335.759521 
	C202.125488,337.025269 200.580704,337.918396 200.162750,339.183716 
	C199.637970,340.772461 200.036377,342.666138 200.036377,344.655334 
	C198.397141,344.655334 197.405151,344.841614 196.511551,344.623962 
	C192.025131,343.531433 188.949692,345.533020 188.707672,349.734467 
	C188.412262,354.863129 189.395309,360.065430 189.914505,365.615540 
	C189.838715,365.982910 189.617859,366.035767 189.517029,365.949066 
	C183.152222,360.475189 184.491013,346.613770 191.840317,342.948120 
	C193.526154,342.107239 195.572220,341.988556 197.656342,341.488647 
	C197.997681,339.640350 197.912811,337.484344 198.816666,335.888245 
	C199.873047,334.022858 201.701431,331.019409 203.378235,335.759521 
z"
  />
  <path
    fill="#C9CFE7"
    opacity="1.000000"
    stroke="none"
    d="
M93.988594,350.322296 
	C94.433983,349.997009 94.861107,349.992157 95.607742,349.987366 
	C95.929985,352.145874 95.918335,354.304474 95.938004,356.462769 
	C96.013573,364.753571 98.098946,366.563080 108.526505,366.242218 
	C107.511131,368.616333 107.172829,371.300690 105.740814,372.157806 
	C104.590736,372.846191 102.084900,371.405121 100.241318,370.740692 
	C99.350029,370.419495 98.598145,369.711426 97.407196,368.880310 
	C97.021713,368.389587 97.012177,368.197083 97.002632,368.004578 
	C95.978485,363.986969 94.954338,359.969391 93.957245,355.124084 
	C93.971786,353.506348 93.959282,352.716248 93.946777,351.926178 
	C93.954628,351.498352 93.962479,351.070557 93.988594,350.322296 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M37.568691,293.068939 
	C38.115467,299.906555 38.268448,306.833405 38.421425,313.760254 
	C39.241215,313.805267 40.061008,313.850281 40.880798,313.895294 
	C41.198048,311.338959 41.383175,308.757446 41.863773,306.232208 
	C42.569302,302.525116 43.486057,298.858215 44.698418,295.190125 
	C44.705078,299.627197 44.349472,304.050537 43.935017,308.468353 
	C43.782150,310.097748 43.837090,311.883514 43.152977,313.283081 
	C42.602428,314.409393 40.977901,315.856781 40.016026,315.731598 
	C38.677101,315.557343 36.446484,314.051392 36.443485,313.109558 
	C36.422344,306.459961 36.874073,299.808807 37.568691,293.068939 
z"
  />
  <path
    fill="#797E9F"
    opacity="1.000000"
    stroke="none"
    d="
M226.947388,364.086884 
	C226.913467,363.917938 226.879562,363.748993 226.829346,362.983704 
	C226.813049,360.994781 226.813049,359.602173 226.813049,358.209595 
	C227.339951,358.163971 227.866852,358.118347 228.393753,358.072723 
	C228.854233,358.883514 229.533875,359.642883 229.738403,360.513794 
	C230.793762,365.008026 233.795593,365.121887 237.226471,363.782288 
	C242.139023,361.864166 239.534958,358.130676 239.419983,354.825500 
	C240.681808,354.477570 241.662155,354.261597 242.838593,354.393738 
	C242.885315,357.570740 243.588547,360.831604 242.417557,363.142944 
	C239.560181,368.782898 234.157990,368.883179 226.947388,364.086884 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M365.185974,315.231445 
	C365.911896,312.765289 369.768829,311.342499 367.447449,308.507233 
	C364.347656,309.604095 361.685638,311.167511 359.007111,311.196320 
	C357.893341,311.208252 356.744415,307.946045 355.404602,305.848816 
	C360.375427,304.852020 364.309906,304.063019 368.592041,303.126160 
	C369.236298,307.045624 371.949493,311.418640 367.513855,314.879944 
	C366.974091,315.301147 366.100708,315.294922 365.185974,315.231445 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M354.635895,345.045105 
	C352.444855,348.320343 352.217560,351.427185 355.838806,354.948547 
	C354.966125,357.062714 353.905029,358.540955 352.843933,360.019226 
	C351.636627,360.017273 350.429352,360.015320 348.713745,359.572296 
	C348.109650,355.758698 348.013855,352.386139 348.169983,348.839203 
	C348.221375,347.479218 348.020905,346.293640 347.820435,345.108063 
	C349.940491,345.079712 352.060577,345.051392 354.635895,345.045105 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M44.252506,348.122070 
	C44.613415,348.029388 44.974323,347.936737 45.648323,348.192261 
	C45.955101,349.016357 45.948784,349.492310 45.942467,349.968231 
	C45.621735,354.353546 45.223564,358.735107 45.010616,363.125641 
	C44.891472,365.582245 44.922092,367.934570 41.533123,367.951630 
	C38.166958,367.968567 37.913136,365.683380 38.044685,363.183807 
	C38.361507,357.163757 38.723080,351.146057 39.533596,345.064819 
	C40.000587,345.002197 40.184071,345.183258 40.115822,345.631226 
	C39.467987,363.720612 39.472115,363.765961 42.515011,366.838196 
	C42.753521,364.255768 42.938663,362.240906 43.125881,360.226257 
	C43.500824,356.191467 43.876900,352.156799 44.252506,348.122070 
z"
  />
  <path
    fill="#DADEF0"
    opacity="1.000000"
    stroke="none"
    d="
M239.138519,354.957489 
	C239.534958,358.130676 242.139023,361.864166 237.226471,363.782288 
	C233.795593,365.121887 230.793762,365.008026 229.738403,360.513794 
	C229.533875,359.642883 228.854233,358.883514 228.393753,358.072723 
	C227.866852,358.118347 227.339951,358.163971 226.813049,358.209595 
	C226.813049,359.602173 226.813049,360.994781 226.736450,362.742493 
	C225.890182,361.890015 224.328278,360.507568 224.546677,359.518036 
	C224.834991,358.211761 226.415558,356.464264 227.633118,356.295502 
	C228.830994,356.129517 231.031952,357.425110 231.442093,358.565216 
	C232.271729,360.871429 233.010590,362.678375 235.784378,361.902405 
	C236.644241,361.661835 238.003281,360.240082 237.854111,359.855652 
	C237.081345,357.864166 236.185669,355.768219 234.774124,354.217072 
	C233.175293,352.460144 230.984436,351.241943 228.962006,349.457275 
	C228.873016,349.125000 229.075027,348.923218 229.490997,348.939880 
	C232.984146,350.956848 236.061325,352.957153 239.138519,354.957489 
z"
  />
  <path
    fill="#848BAA"
    opacity="1.000000"
    stroke="none"
    d="
M57.170689,299.943542 
	C57.136730,298.581299 57.102768,297.219055 57.371643,295.310944 
	C58.638348,294.066711 59.602211,293.368317 60.867012,292.864990 
	C61.441494,293.698395 61.715038,294.336761 61.820362,295.288910 
	C59.983803,301.072449 64.595108,300.965729 67.592583,302.292145 
	C67.592583,302.292145 67.673637,302.326019 67.823410,302.684143 
	C69.765450,306.681183 71.783188,310.335785 67.108841,313.014252 
	C66.875816,312.152771 66.775665,311.810059 66.718018,311.103455 
	C65.754250,308.775513 65.097404,306.487915 63.653744,304.928955 
	C61.827614,302.957031 59.361027,301.578186 57.170689,299.943542 
z"
  />
  <path
    fill="#959CB2"
    opacity="1.000000"
    stroke="none"
    d="
M87.008507,299.995239 
	C86.979401,297.920502 86.950310,295.845734 87.029930,293.471497 
	C89.813446,292.483124 91.194870,293.220581 91.129128,296.282166 
	C91.015419,301.577148 91.134064,306.877167 91.242844,312.646912 
	C91.273720,313.278992 91.216995,313.439148 90.760040,313.829895 
	C89.248268,313.373169 88.136742,312.685852 87.025215,311.998535 
	C87.037048,309.631561 87.048889,307.264618 87.275467,304.227234 
	C87.329651,302.369598 87.169083,301.182434 87.008507,299.995239 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M312.070648,110.022171 
	C311.994995,112.451401 311.919342,114.880638 311.625488,117.616325 
	C310.657623,115.006561 309.203369,112.069176 309.290222,109.178085 
	C309.551147,100.493393 310.389404,91.824867 311.085327,83.156685 
	C311.141602,82.455444 311.830505,81.804977 312.585144,81.044067 
	C312.644958,83.700089 312.345062,86.442955 311.782013,89.767357 
	C311.390594,90.945213 311.262390,91.541534 311.118652,92.547577 
	C311.153656,94.326088 311.204163,95.694862 311.254639,97.063644 
	C311.222534,98.742538 311.190430,100.421425 311.135529,102.923386 
	C311.432068,105.838356 311.751343,107.930267 312.070648,110.022171 
z"
  />
  <path
    fill="#7379A1"
    opacity="1.000000"
    stroke="none"
    d="
M298.825500,306.280701 
	C299.615509,306.038269 300.157288,306.049469 301.021118,306.480042 
	C300.536560,309.379944 299.729919,311.860443 298.923279,314.340912 
	C298.258789,314.360413 297.594330,314.379883 296.929840,314.399384 
	C295.597961,311.270172 294.266052,308.140930 292.934174,305.011719 
	C292.770355,304.154358 292.606537,303.297028 292.186523,302.231995 
	C291.411011,299.185516 290.891724,296.346771 290.372437,293.507996 
	C290.622192,293.279999 290.871918,293.052002 291.121674,292.824005 
	C292.035919,293.778015 293.399506,294.582306 293.776886,295.715179 
	C294.755035,298.651489 295.162750,301.776031 296.107117,304.726532 
	C296.358551,305.512177 297.723572,305.941406 298.825500,306.280701 
z"
  />
  <path
    fill="#8A94B4"
    opacity="1.000000"
    stroke="none"
    d="
M32.083298,334.767517 
	C32.437798,335.614746 32.792297,336.462006 32.658447,337.744751 
	C31.794901,338.664642 31.099018,339.144867 31.092600,339.634125 
	C30.995916,347.002991 30.930738,354.374023 31.051445,361.741364 
	C31.063921,362.502838 32.310215,363.244110 32.984009,363.994751 
	C32.394962,364.957947 31.805920,365.921112 31.216881,366.884308 
	C30.474720,365.811249 29.104259,364.746918 29.089203,363.663788 
	C28.962008,354.514160 29.082394,345.361115 29.539845,335.701843 
	C30.661959,335.052246 31.372627,334.909882 32.083298,334.767517 
z"
  />
  <path
    fill="#808AA9"
    opacity="1.000000"
    stroke="none"
    d="
M258.015839,313.092651 
	C257.683899,312.453491 257.351959,311.814362 256.890198,310.485168 
	C256.833130,308.531921 256.905853,307.268646 257.226990,305.743958 
	C257.315338,303.652039 257.155304,301.821564 256.997375,299.673157 
	C256.931030,298.937408 256.862610,298.519684 256.835144,297.758972 
	C255.936890,296.575470 254.997650,295.734894 253.916687,294.610016 
	C254.333267,289.228058 257.532349,294.507355 259.003143,292.995209 
	C259.003143,292.995209 259.000000,293.000000 259.031616,293.313293 
	C259.680054,294.425781 260.296875,295.225006 260.913696,296.024200 
	C260.962463,300.377991 261.011230,304.731750 260.792908,309.695740 
	C260.317108,310.857239 260.108398,311.408478 259.899719,311.959747 
	C259.899719,311.959747 259.733948,312.238678 259.448639,312.514404 
	C258.780823,312.890961 258.398346,312.991821 258.015839,313.092651 
z"
  />
  <path
    fill="#DEE5F5"
    opacity="1.000000"
    stroke="none"
    d="
M270.756348,355.190369 
	C273.139832,356.241669 275.552612,357.197754 277.232544,358.883942 
	C278.082397,359.737000 277.607452,361.909851 277.731506,363.486023 
	C276.037170,363.327698 274.216858,363.492279 272.692657,362.897736 
	C271.673767,362.500366 271.075867,361.023651 268.931000,360.245758 
	C268.931000,361.605164 268.931000,362.964569 268.955994,364.667297 
	C264.969513,363.972382 265.425964,361.742432 267.438110,359.137512 
	C268.420532,357.865662 269.465820,356.642334 270.756348,355.190369 
z"
  />
  <path
    fill="#848BAA"
    opacity="1.000000"
    stroke="none"
    d="
M251.071442,356.975189 
	C251.010849,356.548309 250.950272,356.121429 250.844452,355.059296 
	C250.238281,353.269562 249.677338,352.115051 249.116394,350.960571 
	C249.043411,350.536224 248.970459,350.111908 248.850769,349.047424 
	C248.573196,347.298706 248.342361,346.190155 248.111511,345.081573 
	C248.111511,345.081573 248.016632,345.004425 248.397552,345.027405 
	C250.132202,345.716217 251.485931,346.382050 252.839661,347.047882 
	C253.078644,348.178650 253.317627,349.309448 253.732208,351.122375 
	C254.900604,354.082306 255.893402,356.360016 256.922760,359.099854 
	C257.036835,360.485321 257.114380,361.408722 257.174530,362.504486 
	C257.157135,362.676849 257.068481,363.011810 256.727234,363.015961 
	C255.592651,363.328156 254.799301,363.636200 253.802292,363.923004 
	C253.598648,363.901764 253.189240,363.910797 253.189240,363.910797 
	C253.088272,363.197998 252.987320,362.485199 252.850937,361.098450 
	C252.234146,359.274750 251.652786,358.124969 251.071442,356.975189 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M288.992737,349.013916 
	C287.739838,347.593964 285.182617,345.284729 285.445923,344.898560 
	C287.141724,342.411377 289.454529,340.344879 291.564758,338.140259 
	C294.062469,340.750519 296.560150,343.360718 299.499817,346.432861 
	C297.180786,347.471588 295.572784,348.191864 293.428162,348.781494 
	C293.833984,347.749023 294.776398,346.847137 295.731598,345.933044 
	C294.132843,344.737244 292.665527,343.639771 289.866486,341.546265 
	C289.669769,344.211273 289.537109,346.008240 289.289917,348.103455 
	C289.114471,348.605713 289.053619,348.809814 288.992737,349.013916 
z"
  />
  <path
    fill="#DADEF0"
    opacity="1.000000"
    stroke="none"
    d="
M226.952759,364.458221 
	C234.157990,368.883179 239.560181,368.782898 242.417557,363.142944 
	C243.588547,360.831604 242.885315,357.570740 242.903259,354.158203 
	C242.771866,353.574615 242.829803,353.090973 242.829803,353.090973 
	C247.060394,354.032104 245.622635,357.491974 245.970505,360.912354 
	C245.969666,362.113342 245.968567,362.560486 245.967468,363.007660 
	C245.063232,364.131958 244.159012,365.256256 243.119553,366.657990 
	C238.750534,368.830078 234.592972,371.910797 229.849792,368.343842 
	C228.671387,367.457672 227.910751,366.015930 226.952759,364.458221 
z"
  />
  <path
    fill="#A1ABC6"
    opacity="1.000000"
    stroke="none"
    d="
M272.649536,291.023193 
	C273.276917,292.386414 273.562653,293.687012 273.743591,295.306702 
	C272.749084,296.934631 271.207886,298.188385 271.091644,299.562683 
	C270.741974,303.696899 270.947418,307.878021 270.669708,312.636414 
	C269.937012,313.829254 269.470978,314.426544 269.004944,315.023865 
	C268.582611,315.010040 268.160248,314.996185 267.393158,314.634521 
	C266.735229,313.515503 266.422089,312.744385 266.108917,311.973236 
	C266.195435,311.124481 266.281952,310.275757 266.852844,309.392822 
	C267.720612,309.969238 268.104034,310.579834 269.072968,312.122772 
	C269.072968,306.592896 269.064117,302.163330 269.076355,297.733826 
	C269.083496,295.160370 269.124451,292.586945 269.388367,289.996094 
	C269.938721,289.924927 270.252625,289.884521 270.568237,289.857391 
	C271.148132,290.225128 271.728027,290.592865 272.649536,291.023193 
z"
  />
  <path
    fill="#DEE5F5"
    opacity="1.000000"
    stroke="none"
    d="
M56.896793,300.120483 
	C59.361027,301.578186 61.827614,302.957031 63.653744,304.928955 
	C65.097404,306.487915 65.754250,308.775513 66.358444,311.223511 
	C64.326057,311.309418 62.547630,311.193146 61.106209,310.435669 
	C59.599171,309.643585 58.416241,308.234802 57.091637,307.095612 
	C56.935390,304.829559 56.779144,302.563507 56.896793,300.120483 
z"
  />
  <path
    fill="#8186A5"
    opacity="1.000000"
    stroke="none"
    d="
M347.918091,349.013611 
	C348.013855,352.386139 348.109650,355.758698 348.351746,359.570129 
	C348.386871,361.073090 348.275696,362.137207 347.880463,363.754150 
	C346.696381,364.923126 345.796356,365.539276 344.896301,366.155396 
	C344.804169,365.976532 344.712067,365.797699 344.626465,364.919312 
	C344.374603,359.482300 344.116211,354.744812 343.857849,350.007324 
	C343.635986,349.269684 343.414124,348.532013 343.049866,347.532776 
	C342.934357,346.491882 342.961273,345.712585 343.315857,344.501282 
	C344.095947,344.058350 344.548401,344.047394 345.000824,344.036438 
	C345.401459,344.412415 345.802094,344.788361 346.494904,345.623108 
	C347.164062,347.059082 347.541077,348.036346 347.918091,349.013611 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M292.665863,305.266235 
	C294.266052,308.140930 295.597961,311.270172 296.929840,314.399384 
	C297.594330,314.379883 298.258789,314.360413 298.923279,314.340912 
	C299.729919,311.860443 300.536560,309.379944 301.426697,306.510773 
	C302.304016,304.621216 303.097839,303.120392 304.283844,300.878021 
	C305.028320,302.466095 305.643066,303.777527 306.279114,305.451172 
	C304.865723,307.985107 303.490723,310.199585 301.974365,312.312622 
	C301.042419,313.611298 299.799591,315.819641 298.863647,315.736450 
	C297.147888,315.584015 294.834656,314.418030 294.080414,312.985138 
	C292.933197,310.805603 292.902466,308.038361 292.665863,305.266235 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M289.404480,347.805237 
	C289.537109,346.008240 289.669769,344.211273 289.866486,341.546265 
	C292.665527,343.639771 294.132843,344.737244 295.731598,345.933044 
	C294.776398,346.847137 293.833984,347.749023 293.087463,348.835693 
	C293.246704,352.999451 293.210022,356.978424 293.146057,361.721436 
	C293.214111,362.907928 293.309448,363.330414 293.131958,364.096954 
	C292.412231,364.820221 291.965332,365.199402 291.518433,365.578583 
	C291.232208,365.407532 290.945984,365.236481 290.428284,364.529327 
	C290.205780,361.998657 290.214752,360.004059 290.468628,357.693054 
	C290.277191,354.186157 289.840820,350.995697 289.404480,347.805237 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M133.449432,296.209290 
	C133.362900,298.523285 133.276367,300.837311 132.861526,303.712250 
	C132.740509,305.173126 132.947784,306.073029 133.155060,306.972961 
	C133.432602,308.707123 133.710129,310.441254 133.650879,312.571442 
	C132.522552,312.682037 131.731018,312.396576 130.865799,311.856812 
	C130.718124,311.442200 130.644119,311.281921 130.690170,310.665161 
	C130.479294,305.810089 130.148376,301.411499 129.817459,297.012909 
	C129.643051,296.492279 129.468658,295.971649 129.130447,294.915283 
	C129.245697,293.225769 129.524765,292.072052 129.803833,290.918304 
	C130.373734,289.771240 130.943619,288.624176 132.116455,286.263580 
	C132.720901,288.244049 133.023514,289.235596 133.361359,290.863159 
	C133.414215,293.069214 133.431824,294.639252 133.449432,296.209290 
z"
  />
  <path
    fill="#808AA9"
    opacity="1.000000"
    stroke="none"
    d="
M312.419067,367.275696 
	C308.410950,367.083008 304.513519,367.210968 303.148926,362.399963 
	C304.003082,361.595947 304.555725,361.205200 305.387970,360.988678 
	C308.409973,364.627411 311.029388,366.049011 313.797607,361.072174 
	C314.322205,360.129059 315.871796,359.756042 316.948425,359.119995 
	C319.398651,362.310181 316.807648,364.068329 314.648071,366.373840 
	C314.314453,366.703888 314.037811,366.890503 314.037811,366.890503 
	C313.604248,366.985596 313.170654,367.080688 312.419067,367.275696 
z"
  />
  <path
    fill="#7379A1"
    opacity="1.000000"
    stroke="none"
    d="
M332.823120,299.747223 
	C332.431976,298.247223 332.040833,296.747253 331.390198,294.727844 
	C331.559631,293.643463 331.988586,293.078430 332.712097,292.635468 
	C333.814819,293.711121 334.622986,294.664734 335.316772,296.053711 
	C335.645996,298.704132 336.089600,300.919220 336.533203,303.134308 
	C336.602020,304.131561 336.670868,305.128815 336.362549,306.611145 
	C335.301300,309.107117 334.617218,311.117950 333.933105,313.128815 
	C333.472107,312.945526 333.011139,312.762238 332.550140,312.578979 
	C332.477905,311.384857 332.405701,310.190765 332.256104,308.570374 
	C332.148193,306.694489 332.117615,305.244873 332.076569,303.447693 
	C332.318451,301.982452 332.570801,300.864838 332.823120,299.747223 
z"
  />
  <path
    fill="#878DAB"
    opacity="1.000000"
    stroke="none"
    d="
M257.191895,362.332123 
	C257.114380,361.408722 257.036835,360.485321 257.106445,358.790283 
	C258.226349,354.041992 259.199127,350.065338 260.171936,346.088684 
	C260.331848,345.818665 260.503723,345.556549 261.226471,345.141510 
	C266.690582,346.449951 261.829529,349.872070 263.177063,352.055206 
	C263.064575,352.472504 262.952057,352.889801 262.572174,353.805542 
	C262.171753,354.856720 262.038696,355.409515 261.905640,355.962311 
	C261.905640,355.962311 261.768860,356.293274 261.454529,356.615723 
	C259.824127,358.736176 258.507996,360.534149 257.191895,362.332123 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M346.007935,297.827820 
	C346.852570,296.625946 348.152252,295.496582 349.897980,294.378967 
	C350.598755,293.907104 350.853485,293.423462 351.108215,292.939758 
	C353.278198,292.126587 355.448181,291.313416 357.817139,290.770752 
	C350.913788,294.940430 352.078247,301.713989 352.046997,308.542419 
	C351.340240,306.752167 350.281036,304.534576 350.115845,302.252350 
	C349.927856,299.654755 349.735931,297.652222 346.007935,297.827820 
z"
  />
  <path
    fill="#7379A1"
    opacity="1.000000"
    stroke="none"
    d="
M202.848145,313.169861 
	C202.848145,306.766571 202.848145,300.363312 202.848145,293.202026 
	C205.187286,295.791290 207.923264,289.234985 209.999359,294.743530 
	C208.580078,296.482452 207.142838,297.847473 205.473923,299.898346 
	C205.321320,302.096863 205.400391,303.609589 205.479462,305.122284 
	C205.382980,307.468353 205.286499,309.814423 204.753845,312.598450 
	C203.827820,313.080872 203.337982,313.125366 202.848145,313.169861 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M67.745613,301.925476 
	C64.595108,300.965729 59.983803,301.072449 62.214104,295.288574 
	C64.856834,295.895050 66.937599,296.815613 69.711288,298.042725 
	C69.711288,296.241730 69.711288,294.744171 69.711288,293.246613 
	C70.547859,293.167664 71.384430,293.088715 72.642639,293.020966 
	C73.255356,293.535095 73.446442,294.038086 73.821396,295.086243 
	C73.993935,296.102539 73.982605,296.573608 73.971283,297.044708 
	C71.947067,298.549408 69.922859,300.054108 67.745613,301.925476 
z"
  />
  <path
    fill="#E7EEF9"
    opacity="1.000000"
    stroke="none"
    d="
M86.070145,362.132996 
	C82.554062,364.794891 79.666359,364.268890 79.007111,360.805450 
	C78.570465,358.511414 79.028351,356.875488 81.931412,356.938843 
	C84.580528,356.996643 87.234932,356.812775 90.320602,356.641632 
	C90.832779,357.085754 90.911354,357.623627 90.619171,358.568237 
	C88.855667,360.027649 87.462906,361.080322 86.070145,362.132996 
z"
  />
  <path
    fill="#797E9F"
    opacity="1.000000"
    stroke="none"
    d="
M103.052032,310.880005 
	C103.392593,310.988831 103.733170,311.097626 104.400162,311.564514 
	C104.487892,312.965515 104.249199,314.008392 104.010498,315.051331 
	C102.975815,314.971069 101.941147,314.890808 100.428413,314.573273 
	C99.749405,314.077484 99.485237,313.945129 99.157860,313.938965 
	C98.966400,313.220795 98.774933,312.502655 98.538055,311.414795 
	C98.545380,306.456268 98.598114,301.867371 98.766220,296.492310 
	C99.254936,295.285034 99.628288,294.863983 100.001648,294.442932 
	C100.390945,295.019867 100.780228,295.596832 101.160477,296.622681 
	C101.141541,299.865479 100.894623,302.686035 101.204544,305.443970 
	C101.413307,307.301727 102.408600,309.071106 103.052032,310.880005 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M132.753937,353.179321 
	C133.901688,353.151550 134.726303,353.276123 135.752808,353.643555 
	C132.664688,355.449554 130.125137,357.366974 130.897446,361.702728 
	C131.559952,365.422058 134.442520,367.659241 138.399017,366.984711 
	C140.001862,366.711426 141.577728,366.280029 143.586243,366.197327 
	C144.008057,366.649414 144.009521,366.824707 144.010986,367.000000 
	C141.679901,367.668823 139.211212,369.154053 137.054153,368.790466 
	C134.608276,368.378174 131.533539,366.848969 130.345383,364.847626 
	C127.985504,360.872681 128.276047,356.543335 132.753937,353.179321 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M33.045071,363.529724 
	C32.310215,363.244110 31.063921,362.502838 31.051445,361.741364 
	C30.930738,354.374023 30.995916,347.002991 31.092600,339.634125 
	C31.099018,339.144867 31.794901,338.664642 32.569916,338.096558 
	C33.015198,346.363464 33.060665,354.714050 33.045071,363.529724 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M233.842819,310.879425 
	C232.306534,311.064178 230.554550,310.942291 228.434448,310.384583 
	C227.577438,309.422485 227.088577,308.896210 226.599731,308.369934 
	C225.942886,309.606201 225.286041,310.842468 224.629181,312.078735 
	C223.780838,310.235291 222.618256,308.449402 222.263855,306.515442 
	C222.148285,305.884735 224.398254,303.993225 225.114853,304.205139 
	C226.734787,304.684174 228.103806,306.075226 229.517853,307.169006 
	C230.923904,308.256653 232.260223,309.434479 233.842819,310.879425 
z"
  />
  <path
    fill="#E2E8F4"
    opacity="1.000000"
    stroke="none"
    d="
M59.960220,347.183685 
	C60.904041,348.424011 62.500202,349.584991 62.662594,350.920349 
	C63.061531,354.200745 62.594498,357.579498 62.885059,360.882690 
	C63.048206,362.737427 64.024811,364.520630 64.812546,366.686707 
	C63.669712,366.028473 61.412193,365.186493 61.197483,363.980774 
	C60.430450,359.673431 60.356693,355.242615 60.016953,350.073059 
	C59.995583,348.586273 59.977905,347.884979 59.960220,347.183685 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M244.220016,302.897034 
	C245.356766,306.306824 246.160919,309.736542 247.032684,313.539673 
	C245.334854,312.956238 243.569427,311.999329 241.447815,311.043640 
	C241.223419,306.728729 241.803467,302.293213 237.809525,298.719910 
	C239.776077,299.897736 241.831757,301.407349 244.220016,302.897034 
z"
  />
  <path
    fill="#EDF3FB"
    opacity="1.000000"
    stroke="none"
    d="
M316.967072,358.834503 
	C315.871796,359.756042 314.322205,360.129059 313.797607,361.072174 
	C311.029388,366.049011 308.409973,364.627411 305.369995,360.659302 
	C305.369263,359.396301 305.666107,358.636932 306.416687,357.913696 
	C309.865814,357.921112 312.861176,357.892426 315.856567,357.863770 
	C316.044373,357.889374 316.232178,357.915009 316.703552,357.958710 
	C316.986664,358.167542 316.986176,358.358276 316.967072,358.834503 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M108.641739,297.097534 
	C107.529266,296.552216 106.416794,296.006927 105.127403,294.833588 
	C103.634483,291.805115 102.318481,289.404633 101.002472,287.004181 
	C103.658310,289.972229 106.504158,292.148499 110.898262,290.385315 
	C111.271576,290.235535 112.604698,292.477783 112.905518,293.651215 
	C108.004951,291.491791 109.492165,295.557404 108.641739,297.097534 
z"
  />
  <path
    fill="#8D97BD"
    opacity="1.000000"
    stroke="none"
    d="
M100.667450,286.957245 
	C102.318481,289.404633 103.634483,291.805115 104.969864,294.567627 
	C104.581482,295.014374 104.173729,295.099091 103.181717,295.233856 
	C102.121468,295.580536 101.645493,295.877167 101.169510,296.173767 
	C100.780228,295.596832 100.390945,295.019867 100.001648,294.442932 
	C99.628288,294.863983 99.254936,295.285034 98.761383,296.052551 
	C97.538971,295.725983 96.436775,295.052979 95.084740,294.227448 
	C96.091263,292.614105 97.004578,291.150146 98.084198,289.086426 
	C98.493713,287.958923 98.736923,287.431183 98.980125,286.903473 
	C99.430893,286.905762 99.881668,286.908020 100.667450,286.957245 
z"
  />
  <path
    fill="#797E9F"
    opacity="1.000000"
    stroke="none"
    d="
M320.695679,306.459473 
	C321.469757,305.004639 322.294647,303.964050 323.754974,303.050903 
	C324.769684,303.956879 325.148926,304.735413 325.360901,305.569763 
	C325.084442,306.066223 324.975281,306.506805 324.595825,307.015747 
	C324.485291,307.747559 324.645050,308.411102 324.932983,309.377594 
	C326.034363,310.569916 327.007538,311.459290 327.969299,312.672302 
	C327.963104,313.643280 327.968292,314.290588 327.596039,314.965576 
	C326.466583,315.054504 325.714569,315.115814 324.962555,315.177063 
	C324.633881,315.048523 324.305206,314.919952 323.597595,314.447449 
	C322.418152,313.085144 321.617584,312.066742 320.817047,311.048370 
	C320.793518,309.656830 320.769989,308.265259 320.695679,306.459473 
z"
  />
  <path
    fill="#959CBC"
    opacity="1.000000"
    stroke="none"
    d="
M332.417542,292.513428 
	C331.988586,293.078430 331.559631,293.643463 331.084686,294.571533 
	C329.382080,295.383026 327.733582,295.864899 326.066315,296.269470 
	C324.692413,296.602844 323.296265,296.844604 321.909973,297.126923 
	C322.302124,295.721832 322.694244,294.316742 323.215942,292.606873 
	C323.345551,292.302094 323.690491,292.198608 324.153778,292.123474 
	C325.084686,291.728058 325.552307,291.407745 326.019928,291.087463 
	C327.725952,291.054169 329.431946,291.020874 331.599915,291.263123 
	C332.180420,291.863556 332.298981,292.188507 332.417542,292.513428 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M357.007751,367.540649 
	C365.147980,367.019104 366.327759,366.037598 367.144653,358.344849 
	C371.161072,358.592957 369.375610,361.339966 368.892517,363.234680 
	C367.610596,368.262299 360.434570,370.982056 357.007751,367.540649 
z"
  />
  <path
    fill="#8890B0"
    opacity="1.000000"
    stroke="none"
    d="
M109.945480,310.094971 
	C109.283585,306.387390 109.065788,302.647369 109.320892,298.452026 
	C110.637558,297.987549 111.481346,297.978394 112.325127,297.969238 
	C112.565323,301.680756 112.805511,305.392303 113.041298,309.551880 
	C112.154449,310.020844 111.272018,310.041718 109.945480,310.094971 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M324.122681,247.405792 
	C325.971802,245.499146 327.671906,243.884674 329.669891,242.117828 
	C330.477539,242.571381 330.987305,243.177322 331.770660,243.914337 
	C329.062500,246.980316 326.080780,249.915207 323.099091,252.850098 
	C322.739960,252.425598 322.380829,252.001099 321.998322,251.269958 
	C322.641205,249.874863 323.307434,248.786407 324.122681,247.405792 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M343.474060,350.101807 
	C344.116211,354.744812 344.374603,359.482300 344.456604,364.669861 
	C343.604889,364.740295 342.333405,364.342255 342.344452,363.983795 
	C342.486359,359.384674 342.811554,354.791199 343.474060,350.101807 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M59.766361,346.963104 
	C59.977905,347.884979 59.995583,348.586273 60.014603,349.639343 
	C59.344097,351.264191 58.455509,352.472656 58.052147,353.825775 
	C57.473698,355.766327 57.249252,357.812347 56.459572,359.479553 
	C55.778133,356.429321 55.510796,353.712952 55.243454,350.996552 
	C55.178627,350.245178 55.113796,349.493805 55.280167,348.210968 
	C55.661663,347.153412 55.811958,346.627289 55.962257,346.101196 
	C56.433910,345.752136 56.905556,345.403076 57.995155,345.041626 
	C58.819641,345.040100 59.026180,345.050903 59.232723,345.061737 
	C59.345982,345.622009 59.459240,346.182251 59.766361,346.963104 
z"
  />
  <path
    fill="#8E97B6"
    opacity="1.000000"
    stroke="none"
    d="
M57.106483,307.080261 
	C58.416241,308.234802 59.599171,309.643585 61.106209,310.435669 
	C62.547630,311.193146 64.326057,311.309418 66.315933,311.587463 
	C66.775665,311.810059 66.875816,312.152771 67.068275,313.262817 
	C67.108818,314.370758 67.057060,314.711365 67.005310,315.051941 
	C66.583725,315.106750 66.162140,315.161530 65.018448,315.189026 
	C63.165634,315.204834 62.034931,315.247894 60.904228,315.290955 
	C59.617184,314.083130 58.330147,312.875305 56.925800,310.995239 
	C56.910950,309.251770 57.013405,308.180573 57.115856,307.109344 
	C57.115856,307.109344 57.121326,307.064880 57.106483,307.080261 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M119.679680,293.130035 
	C120.096542,297.653259 120.131866,302.228607 120.113823,306.803772 
	C120.101692,309.880188 118.752586,311.739807 115.204666,311.419617 
	C117.724327,309.925140 118.164062,307.746582 118.164261,304.894714 
	C118.164528,300.990479 118.871407,297.086304 119.679680,293.130035 
z"
  />
  <path
    fill="#97A1C5"
    opacity="1.000000"
    stroke="none"
    d="
M327.973480,314.937927 
	C327.968292,314.290588 327.963104,313.643280 328.086670,312.334961 
	C329.588074,310.781555 330.960785,309.889130 332.333466,308.996674 
	C332.405701,310.190765 332.477905,311.384857 332.550140,312.578979 
	C333.011139,312.762238 333.472107,312.945526 333.933105,313.128815 
	C334.617218,311.117950 335.301300,309.107117 336.283936,306.979919 
	C336.184296,309.723389 339.810211,313.946716 333.222687,314.652161 
	C331.053589,314.567200 329.513550,314.752563 327.973480,314.937927 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M257.174500,362.504486 
	C258.507996,360.534149 259.824127,358.736176 261.452148,356.794739 
	C260.521484,361.401642 259.278900,366.151947 257.595428,370.546875 
	C257.125854,367.798279 257.097168,365.405029 257.068481,363.011810 
	C257.068481,363.011810 257.157135,362.676849 257.174500,362.504486 
z"
  />
  <path
    fill="#9399B6"
    opacity="1.000000"
    stroke="none"
    d="
M69.422668,293.026672 
	C69.711288,294.744171 69.711288,296.241730 69.711288,298.042725 
	C66.937599,296.815613 64.856834,295.895050 62.382324,294.974823 
	C61.715038,294.336761 61.441494,293.698395 60.990185,292.630829 
	C60.898247,291.860260 60.984070,291.518860 61.069893,291.177460 
	C63.757950,291.720551 66.445999,292.263672 69.422668,293.026672 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M135.758942,347.185272 
	C134.916611,348.604218 133.807007,349.801178 132.329956,351.010315 
	C128.501343,349.285248 129.593292,347.081299 131.700485,345.320801 
	C133.024918,344.214264 134.948334,343.824707 136.993988,343.204773 
	C136.753860,344.518433 136.122757,345.740845 135.758942,347.185272 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M266.943848,284.998383 
	C265.963928,280.691925 270.027588,281.419586 271.987701,280.422485 
	C272.646576,280.087311 274.526245,282.151947 275.847961,283.119751 
	C274.469604,284.744110 273.091248,286.368439 271.337677,287.635254 
	C271.902374,286.077454 272.842224,284.877197 273.782104,283.676941 
	C273.556152,283.274475 273.330231,282.872009 273.104279,282.469513 
	C271.050812,283.312469 268.997314,284.155426 266.943848,284.998383 
z"
  />
  <path
    fill="#8D97BD"
    opacity="1.000000"
    stroke="none"
    d="
M256.727234,363.015961 
	C257.097168,365.405029 257.125854,367.798279 257.237823,370.635925 
	C257.201294,371.495514 257.081512,371.910736 256.630737,372.815887 
	C255.420135,373.812927 254.540543,374.319977 253.028152,375.191864 
	C253.399918,370.915375 253.702927,367.429810 254.005936,363.944244 
	C254.799301,363.636200 255.592651,363.328156 256.727234,363.015961 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M73.981216,297.506439 
	C73.982605,296.573608 73.993935,296.102539 74.012634,295.277527 
	C74.732300,296.566925 75.850197,298.156372 76.077286,299.864227 
	C76.651764,304.184509 76.825966,308.558044 77.254288,313.297974 
	C76.234100,312.230164 74.360146,310.872162 74.158195,309.299469 
	C73.680130,305.576630 73.996170,301.751770 73.981216,297.506439 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M331.859985,347.830688 
	C332.551056,349.200378 333.791046,350.962524 333.946350,352.815552 
	C334.285828,356.865936 334.074310,360.962463 334.069214,365.504700 
	C333.383698,364.700439 332.217590,363.461365 332.139893,362.157410 
	C331.864685,357.538361 331.961304,352.897125 331.859985,347.830688 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M263.537781,351.992126 
	C261.829529,349.872070 266.690582,346.449951 261.374817,344.959045 
	C262.355194,344.562225 263.816589,343.708740 265.071533,343.946228 
	C266.331329,344.184601 267.385315,345.510254 268.758179,346.600525 
	C268.765259,347.955811 268.543671,349.070068 268.289001,350.524658 
	C266.803436,351.219696 265.350983,351.574371 263.537781,351.992126 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M319.609131,253.026794 
	C320.098358,254.304382 320.252777,255.641998 320.739227,257.049255 
	C319.322113,258.943451 317.572968,260.768066 315.051880,263.397949 
	C313.109406,257.142365 317.453339,255.755951 319.609131,253.026794 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M259.798584,346.110962 
	C259.199127,350.065338 258.226349,354.041992 257.069885,358.328186 
	C255.893402,356.360016 254.900604,354.082306 253.895599,351.430023 
	C255.730682,349.414734 257.577972,347.773956 259.798584,346.110962 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M253.802292,363.923004 
	C253.702927,367.429810 253.399918,370.915375 253.028152,375.191864 
	C254.540543,374.319977 255.420135,373.812927 256.599701,373.139130 
	C256.501923,374.016785 256.386475,375.521423 255.631973,375.984283 
	C254.768036,376.514252 253.070190,376.540497 252.226349,376.007385 
	C251.378372,375.471649 250.678207,373.955963 250.730087,372.910248 
	C250.815872,371.181671 251.557648,369.485657 252.023819,367.385498 
	C252.217041,366.098389 252.409424,365.201630 252.895523,364.107849 
	C253.189240,363.910797 253.598648,363.901764 253.802292,363.923004 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M269.150177,290.013519 
	C269.124451,292.586945 269.083496,295.160370 269.076355,297.733826 
	C269.064117,302.163330 269.072968,306.592896 269.072968,312.122772 
	C268.104034,310.579834 267.720612,309.969238 267.068970,309.155640 
	C266.794830,308.522797 266.788971,308.093018 267.093628,307.075378 
	C267.243500,303.656067 267.082825,300.824615 266.919861,297.647278 
	C266.763672,296.635712 266.609802,295.970001 266.546570,294.852112 
	C266.622711,293.172394 266.608154,291.944824 266.593597,290.717285 
	C266.593597,290.717285 266.720184,290.708588 267.052460,290.725861 
	C267.973206,290.499908 268.561707,290.256714 269.150177,290.013519 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M67.213326,315.276978 
	C67.057060,314.711365 67.108818,314.370758 67.201141,313.781616 
	C71.783188,310.335785 69.765450,306.681183 67.859634,302.708984 
	C69.155739,303.954437 71.396713,305.347534 71.799629,307.151215 
	C72.604767,310.755615 71.620575,314.160645 67.213326,315.276978 
z"
  />
  <path
    fill="#858AA3"
    opacity="1.000000"
    stroke="none"
    d="
M269.388367,289.996094 
	C268.561707,290.256714 267.973206,290.499908 267.067383,290.787537 
	C266.794800,289.166412 266.839600,287.500916 266.914124,285.416901 
	C268.997314,284.155426 271.050812,283.312469 273.104279,282.469513 
	C273.330231,282.872009 273.556152,283.274475 273.782104,283.676941 
	C272.842224,284.877197 271.902374,286.077454 270.956238,287.633331 
	C270.874451,288.406067 270.798920,288.823212 270.645813,289.548859 
	C270.252625,289.884521 269.938721,289.924927 269.388367,289.996094 
z"
  />
  <path
    fill="#C7CCE2"
    opacity="1.000000"
    stroke="none"
    d="
M351.056519,292.542938 
	C350.853485,293.423462 350.598755,293.907104 350.191284,294.234619 
	C347.304810,293.708710 344.571045,293.338928 341.428558,292.990845 
	C340.871338,292.440002 340.406433,291.576752 340.623749,291.340179 
	C342.623871,289.162842 348.739685,289.690735 351.056519,292.542938 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M367.033875,356.718445 
	C366.377319,356.602386 365.776550,356.153503 365.074158,355.063904 
	C365.624084,352.575989 366.275635,350.728790 366.927185,348.881561 
	C366.611206,347.817505 366.295227,346.753479 365.919678,345.350830 
	C367.129822,346.170197 369.524994,347.409485 369.448334,348.470184 
	C369.254578,351.151459 367.949524,353.752441 367.033875,356.718445 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M86.433884,362.166199 
	C87.462906,361.080322 88.855667,360.027649 90.637695,358.973602 
	C93.088211,362.170319 91.888565,364.356293 88.507385,366.031433 
	C87.706291,364.793152 87.251953,363.496246 86.433884,362.166199 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M364.962585,343.815247 
	C361.955170,344.416687 358.916748,344.698639 355.473083,345.041504 
	C355.490143,343.853485 355.681488,341.711761 356.380066,341.531067 
	C358.135712,341.076935 360.159943,341.305939 361.998138,341.663605 
	C363.054779,341.869232 363.958832,342.858978 364.962585,343.815247 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M258.961456,292.612854 
	C257.532349,294.507355 254.333267,289.228058 253.899048,294.879883 
	C254.021927,295.614075 254.020706,295.794128 253.679474,295.981995 
	C252.885834,295.983398 252.432205,295.977051 251.976669,295.612732 
	C251.311554,293.807709 250.648346,292.360626 249.985138,290.913574 
	C253.194839,289.782043 256.428802,288.485870 258.961456,292.612854 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M129.553436,297.325684 
	C130.148376,301.411499 130.479294,305.810089 130.444153,310.640961 
	C129.750824,311.016144 129.421204,310.981934 129.089188,310.970581 
	C129.155930,306.526550 129.222687,302.082520 129.553436,297.325684 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M227.119827,282.278717 
	C224.408508,285.833252 222.956970,289.660461 224.924347,294.629364 
	C220.157913,291.282593 220.948914,285.579193 227.119827,282.278717 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M257.826630,313.421143 
	C258.398346,312.991821 258.780823,312.890961 259.439148,312.676331 
	C258.286652,317.778076 252.872498,318.679993 247.109802,314.278198 
	C250.601395,313.848724 254.119415,313.799164 257.826630,313.421143 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M182.359039,363.941803 
	C181.272568,362.692566 180.546677,361.380035 179.414795,359.697998 
	C179.000626,358.564240 178.992447,357.799957 178.984283,357.035675 
	C180.102539,356.443359 181.220795,355.851044 182.629684,355.125183 
	C182.853424,357.953949 182.786530,360.916229 182.359039,363.941803 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M320.265350,355.027466 
	C319.983276,354.378815 319.981842,354.057648 319.966156,353.498077 
	C320.657806,353.158600 321.360291,353.057526 322.412048,352.917603 
	C322.881683,356.268127 323.002106,359.657501 323.067413,363.786194 
	C322.936829,364.714874 322.861359,364.904266 322.785858,365.093658 
	C322.040375,361.847443 321.294891,358.601196 320.265350,355.027466 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M93.334015,305.996307 
	C92.981789,302.718842 92.981789,299.441376 92.981789,296.163879 
	C93.903915,296.197479 94.826042,296.231049 95.748177,296.264618 
	C95.519012,299.215759 95.289856,302.166870 95.078934,305.531006 
	C94.626869,305.961456 94.156555,305.978851 93.334015,305.996307 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M121.216042,350.694397 
	C120.596977,355.234161 120.041733,359.516724 119.344292,364.895996 
	C119.344292,358.058502 119.344292,352.299500 119.344292,346.540497 
	C119.629097,346.470764 119.913902,346.401031 120.198708,346.331299 
	C120.551331,347.082611 120.903954,347.833893 121.362877,348.992188 
	C121.406067,349.745178 121.342964,350.091187 121.216042,350.694397 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M333.920868,343.651093 
	C331.540344,344.359863 329.093964,344.694366 325.957703,344.995300 
	C324.523010,344.980621 323.778198,344.999542 323.033356,345.018433 
	C326.250610,342.014923 329.849701,341.383789 333.920868,343.651093 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M50.947365,343.563416 
	C48.624088,344.305817 46.215527,344.678009 43.053970,345.013306 
	C41.595337,345.045380 40.889706,345.114319 40.184071,345.183289 
	C40.184071,345.183258 40.000587,345.002197 39.906811,344.910950 
	C43.214481,342.364716 46.769428,340.952576 50.947365,343.563416 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M277.059601,347.915192 
	C278.495056,348.275574 279.930481,348.635986 281.637573,348.684998 
	C282.136017,348.182037 282.396881,348.064117 282.845886,348.009949 
	C283.000000,348.000000 282.997772,347.998535 282.997772,347.998535 
	C281.642487,349.858673 280.287231,351.718842 278.807556,353.954742 
	C277.900665,354.053375 277.118195,353.776276 276.328400,352.894714 
	C276.567230,350.831909 276.813416,349.373535 277.059601,347.915192 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M309.249939,298.793884 
	C306.115845,297.505371 308.673035,291.962799 303.361450,291.970245 
	C304.003052,288.854980 306.229828,289.557434 307.303711,291.312744 
	C308.582764,293.403473 308.828949,296.126068 309.249939,298.793884 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M166.691772,360.622681 
	C166.268005,361.056702 165.928391,361.029144 165.333282,360.997314 
	C165.343826,357.391510 165.609848,353.789978 166.207214,350.012634 
	C166.617676,353.278259 166.696793,356.719666 166.691772,360.622681 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M192.985687,367.720276 
	C196.057358,367.031799 199.211563,366.654846 202.915451,366.130920 
	C203.636093,365.982361 203.807053,365.980713 203.978027,365.979095 
	C201.893967,368.281799 195.167465,369.547394 192.985687,367.720276 
z"
  />
  <path
    fill="#9399B6"
    opacity="1.000000"
    stroke="none"
    d="
M289.289886,348.103455 
	C289.840820,350.995697 290.277191,354.186157 290.180908,357.680908 
	C289.457245,357.982544 289.266205,357.979950 289.075134,357.977386 
	C289.058258,355.287933 289.041382,352.598450 289.008606,349.461456 
	C289.053619,348.809814 289.114471,348.605713 289.289886,348.103455 
z"
  />
  <path
    fill="#E2E8F4"
    opacity="1.000000"
    stroke="none"
    d="
M59.569588,345.001526 
	C59.026180,345.050903 58.819641,345.040100 58.303108,345.015259 
	C59.454586,343.009369 60.916061,341.017517 62.756119,339.049988 
	C62.058613,341.029938 60.982533,342.985626 59.569588,345.001526 
z"
  />
  <path
    fill="#E2E7F2"
    opacity="1.000000"
    stroke="none"
    d="
M154.874084,339.101135 
	C154.170929,341.498749 153.112122,343.881256 152.044159,346.624176 
	C151.599762,345.867340 151.164505,344.750122 150.865662,343.317657 
	C152.174210,341.696960 153.346329,340.391510 154.874084,339.101135 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M249.016724,293.877808 
	C247.855331,294.845367 246.693924,295.812897 245.220062,296.876709 
	C242.316650,292.899902 246.388962,292.596436 248.620361,291.371155 
	C248.992813,292.422455 249.004776,293.150146 249.016724,293.877808 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M99.990829,375.594513 
	C102.011963,375.224182 104.109192,375.192749 106.651726,375.232666 
	C104.986450,378.114288 102.758736,379.616028 99.990829,375.594513 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M352.893738,360.282898 
	C353.905029,358.540955 354.966125,357.062714 356.062622,355.216309 
	C356.721649,355.194153 357.345337,355.540161 357.980743,356.549164 
	C357.685669,358.431976 357.378876,359.651825 357.072083,360.871643 
	C356.592865,360.834015 356.113617,360.796356 354.964294,360.822266 
	C353.881470,360.947662 353.468689,361.009491 353.055939,361.071289 
	C353.018494,360.896362 352.981018,360.721436 352.893738,360.282898 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M86.650040,311.998962 
	C88.136742,312.685852 89.248268,313.373169 90.711212,314.080017 
	C89.808014,317.752258 87.557007,315.235443 85.173988,314.629486 
	C84.461174,313.501923 84.244057,312.715393 84.026939,311.928864 
	C84.776245,311.952362 85.525558,311.975891 86.650040,311.998962 
z"
  />
  <path
    fill="#A1ABC6"
    opacity="1.000000"
    stroke="none"
    d="
M266.922150,297.993164 
	C267.082825,300.824615 267.243500,303.656067 267.104828,306.753052 
	C266.552765,304.309296 266.300018,301.600067 266.259430,298.450439 
	C266.471588,298.010071 266.922150,297.993164 266.922150,297.993164 
z"
  />
  <path
    fill="#8A94B4"
    opacity="1.000000"
    stroke="none"
    d="
M112.401154,297.644653 
	C111.481346,297.978394 110.637558,297.987549 109.375130,297.997803 
	C108.956482,297.998901 108.792519,297.551056 108.717133,297.324280 
	C109.492165,295.557404 108.004951,291.491791 112.656601,293.849335 
	C112.821800,295.110016 112.649490,296.215057 112.401154,297.644653 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M253.114624,346.875244 
	C251.485931,346.382050 250.132202,345.716217 248.432877,345.077271 
	C248.065430,344.343719 248.043579,343.583252 248.023682,342.444885 
	C251.065018,342.164185 253.332886,343.154083 253.114624,346.875244 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M261.211426,295.908905 
	C260.296875,295.225006 259.680054,294.425781 259.033936,293.311646 
	C260.864990,292.151978 262.725311,291.307251 264.668701,290.996094 
	C264.227264,292.640991 263.702789,293.752350 263.178314,294.863739 
	C262.621948,295.173676 262.065552,295.483643 261.211426,295.908905 
z"
  />
  <path
    fill="#97A1C5"
    opacity="1.000000"
    stroke="none"
    d="
M224.841263,312.340271 
	C225.286041,310.842468 225.942886,309.606201 226.599731,308.369934 
	C227.088577,308.896210 227.577438,309.422485 228.088013,310.266663 
	C228.049469,311.522675 227.989197,312.460846 227.870819,313.865784 
	C226.892914,313.755646 225.973114,313.178741 224.841263,312.340271 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M249.053101,294.302368 
	C249.004776,293.150146 248.992813,292.422455 248.975159,291.329163 
	C249.138779,290.954865 249.308090,290.946167 249.731277,290.925507 
	C250.648346,292.360626 251.311554,293.807709 251.832031,295.882935 
	C250.866608,297.045654 250.043915,297.580200 249.221222,298.114746 
	C249.177307,296.985474 249.133392,295.856171 249.053101,294.302368 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M236.916916,281.683472 
	C234.621262,282.086273 232.264038,282.099548 229.464722,282.097076 
	C231.310074,278.601318 234.032455,279.447723 236.916916,281.683472 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M332.712097,292.635468 
	C332.298981,292.188507 332.180420,291.863556 332.026489,291.279724 
	C333.807556,292.562164 335.623962,294.103485 336.936646,295.800598 
	C336.098969,295.843689 335.765045,295.731018 335.431122,295.618347 
	C334.622986,294.664734 333.814819,293.711121 332.712097,292.635468 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M229.053528,315.609680 
	C231.334335,315.167542 233.731537,315.078674 236.577011,315.021301 
	C234.781906,318.593323 231.965027,317.184174 229.053528,315.609680 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M293.404785,363.752869 
	C293.309448,363.330414 293.214111,362.907928 293.206787,362.171326 
	C298.531799,362.812225 299.047760,363.408844 297.253601,366.549438 
	C295.723541,365.407806 294.564148,364.580353 293.404785,363.752869 
z"
  />
  <path
    fill="#8E97B6"
    opacity="1.000000"
    stroke="none"
    d="
M322.972778,345.325500 
	C323.778198,344.999542 324.523010,344.980621 325.638031,345.009827 
	C325.263306,346.995148 324.518433,348.932373 323.426758,350.923523 
	C323.079926,350.977417 322.925140,351.121643 322.929138,350.704987 
	C322.846771,349.146057 322.760406,348.003754 322.674072,346.861481 
	C322.753448,346.451843 322.832794,346.042206 322.972778,345.325500 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M44.783958,290.799988 
	C43.244770,291.427612 41.463310,291.763916 39.334728,292.038757 
	C40.838970,291.487732 42.690331,290.998199 44.783958,290.799988 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M53.219608,294.825256 
	C51.438709,293.843414 49.909794,292.676300 48.191231,291.251648 
	C51.939095,289.037445 52.650597,291.920837 53.219608,294.825256 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M272.003204,367.556152 
	C274.362640,366.901184 276.825012,366.604126 279.678955,366.203705 
	C278.092407,369.676514 275.352997,369.909576 272.003204,367.556152 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M327.596039,314.965576 
	C329.513550,314.752563 331.053589,314.567200 332.813354,314.626892 
	C330.400818,315.205963 327.768555,315.540009 325.049438,315.525574 
	C325.714569,315.115814 326.466583,315.054504 327.596039,314.965576 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M78.999466,367.537109 
	C80.711235,367.223053 82.497879,367.234283 84.720795,367.356689 
	C83.364983,371.230194 81.259361,370.158356 78.999466,367.537109 
z"
  />
  <path
    fill="#DEE5F5"
    opacity="1.000000"
    stroke="none"
    d="
M60.896275,290.907593 
	C60.984070,291.518860 60.898247,291.860260 60.689251,292.435791 
	C59.602211,293.368317 58.638348,294.066711 57.400333,294.902008 
	C56.567814,292.214508 57.234535,291.398590 60.896275,290.907593 
z"
  />
  <path
    fill="#97A1C5"
    opacity="1.000000"
    stroke="none"
    d="
M335.316772,296.053711 
	C335.765045,295.731018 336.098969,295.843689 336.696808,295.984924 
	C336.930786,298.105286 336.900848,300.197174 336.702026,302.711670 
	C336.089600,300.919220 335.645996,298.704132 335.316772,296.053711 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M177.913803,343.663330 
	C175.961792,344.054352 173.942963,344.067780 171.492920,344.086853 
	C172.920914,340.437958 175.375763,341.792969 177.913803,343.663330 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M288.792145,358.241699 
	C289.266205,357.979950 289.457245,357.982544 289.936035,357.997314 
	C290.214752,360.004059 290.205780,361.998657 290.204529,364.417175 
	C286.397247,363.602448 288.493469,360.774567 288.792145,358.241699 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M306.742493,310.205811 
	C307.910095,311.470673 308.812561,312.942291 309.857971,314.714600 
	C307.034210,315.161682 306.583099,314.572449 306.742493,310.205811 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M315.039459,131.014374 
	C315.042877,131.772888 315.046295,132.531418 315.000031,133.666885 
	C314.164856,131.835876 313.379303,129.627930 312.882324,127.213608 
	C313.466644,127.438881 313.762360,127.870537 314.065765,128.897812 
	C314.395477,130.000412 314.717468,130.507401 315.039459,131.014374 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M170.983765,367.504456 
	C172.702438,367.181641 174.481750,367.169434 176.699661,367.245972 
	C175.284958,369.753174 173.292816,370.409973 170.983765,367.504456 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M48.730774,312.217499 
	C50.091564,312.665466 51.187775,313.331543 52.628334,313.977478 
	C51.965694,314.570129 50.958698,315.182922 49.951714,315.795715 
	C49.456543,314.675720 48.961369,313.555664 48.730774,312.217499 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M237.699158,297.950989 
	C235.686798,297.191864 233.758087,296.064850 231.673340,294.630432 
	C233.605804,295.409729 235.694321,296.496429 237.699158,297.950989 
z"
  />
  <path
    fill="#A1ABC6"
    opacity="1.000000"
    stroke="none"
    d="
M263.370667,294.925293 
	C263.702789,293.752350 264.227264,292.640991 264.877502,291.231384 
	C265.356293,290.878082 265.709320,290.823090 266.327972,290.742676 
	C266.608154,291.944824 266.622711,293.172394 266.238312,294.799835 
	C265.207947,295.172363 264.576508,295.144989 263.945099,295.117645 
	C263.945099,295.117645 263.562988,294.986816 263.370667,294.925293 
z"
  />
  <path
    fill="#9399B6"
    opacity="1.000000"
    stroke="none"
    d="
M293.131958,364.096954 
	C294.564148,364.580353 295.723541,365.407806 296.912842,366.572388 
	C295.248016,366.684143 293.553284,366.458771 291.688477,365.906006 
	C291.965332,365.199402 292.412231,364.820221 293.131958,364.096954 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M277.017609,343.322266 
	C275.333832,344.041626 273.523895,344.453400 271.319458,344.872498 
	C272.251556,342.139709 274.122223,341.139984 277.017609,343.322266 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M46.290565,350.008759 
	C45.948784,349.492310 45.955101,349.016357 45.927807,348.182495 
	C45.894196,347.824524 46.260601,347.695312 46.713802,347.739502 
	C47.455524,347.609802 47.744045,347.435883 48.032562,347.261932 
	C48.696701,347.573975 49.360844,347.885986 50.041328,348.850769 
	C50.025227,350.005524 49.992783,350.507538 49.960339,351.009521 
	C48.853115,350.689423 47.745892,350.369354 46.290565,350.008759 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M76.707947,287.067261 
	C76.007141,288.728638 74.958084,290.406219 73.578369,292.176788 
	C74.285027,290.541016 75.322365,288.812195 76.707947,287.067261 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M229.926758,292.739075 
	C227.078156,291.090576 227.198608,288.748993 229.654633,286.108246 
	C229.989899,288.076416 229.981812,290.180756 229.926758,292.739075 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M357.998413,315.721588 
	C359.356720,315.342773 360.791107,315.262817 362.647583,315.284210 
	C361.659790,317.604614 360.179626,319.271210 357.998413,315.721588 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M314.407349,294.019867 
	C314.346161,295.804779 313.966248,297.683228 313.313660,299.789062 
	C313.390198,298.048798 313.739380,296.081085 314.407349,294.019867 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M183.246094,350.787109 
	C182.353867,349.548462 181.732025,348.092163 181.119659,346.282104 
	C181.924896,347.475403 182.720688,349.022461 183.246094,350.787109 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M129.491333,290.932373 
	C129.524765,292.072052 129.245697,293.225769 128.912292,294.741516 
	C128.454895,295.111633 128.051819,295.119720 127.145065,295.047852 
	C126.641380,294.967865 126.231209,294.921539 126.214752,294.590088 
	C126.492249,293.199402 126.786209,292.140167 127.080162,291.080902 
	C127.080154,291.080902 127.508354,291.004120 127.717590,290.945740 
	C128.344162,290.907043 128.761490,290.926727 129.491333,290.932373 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M344.963898,366.476990 
	C345.796356,365.539276 346.696381,364.923126 347.903564,364.158630 
	C348.104523,365.105347 347.998322,366.200439 347.892090,367.295532 
	C346.938568,367.129883 345.985016,366.964264 344.963898,366.476990 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M327.072784,364.997131 
	C326.991974,365.795135 326.911133,366.593170 326.830292,367.391174 
	C325.582153,367.152130 324.334015,366.913086 323.406738,366.333618 
	C324.842651,365.661194 325.957733,365.329163 327.072784,364.997131 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M104.163284,315.290741 
	C104.249199,314.008392 104.487892,312.965515 104.665604,311.604340 
	C108.349731,311.309540 106.818115,313.501282 106.090347,315.625183 
	C105.390869,315.812866 104.853462,315.671539 104.163284,315.290741 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M317.009735,70.997986 
	C316.262207,73.301323 315.514709,75.604652 314.426147,77.640198 
	C314.356750,76.184235 314.628326,74.996048 314.899933,73.807869 
	C314.899933,73.807869 314.893311,73.404015 315.179626,73.053497 
	C315.980560,72.134651 316.495148,71.566315 317.009735,70.997986 
z"
  />
  <path
    fill="#ACD0E1"
    opacity="1.000000"
    stroke="none"
    d="
M323.709076,55.086613 
	C323.870209,56.226147 323.692810,57.381214 322.937439,58.767502 
	C321.950592,59.003929 321.541718,59.009132 321.132874,59.014336 
	C321.878754,57.710274 322.624634,56.406212 323.709076,55.086613 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M126.672073,291.056519 
	C126.786209,292.140167 126.492249,293.199402 125.812546,294.655212 
	C125.162758,295.107819 124.898720,295.163849 124.634689,295.219910 
	C124.176476,294.370850 123.718269,293.521790 123.117630,292.126312 
	C122.985672,291.383270 122.996155,291.186615 123.006630,290.989990 
	C124.092415,291.004059 125.178192,291.018097 126.672073,291.056519 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M96.642960,368.072815 
	C97.012177,368.197083 97.021713,368.389587 97.061760,368.870422 
	C97.062775,370.179413 97.033287,371.200104 97.008072,372.606140 
	C93.911263,371.804260 94.569084,370.052032 96.642960,368.072815 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M146.034760,315.590851 
	C147.364212,315.222290 148.796448,315.180695 150.651993,315.223328 
	C149.429382,315.510986 147.783478,315.714417 146.034760,315.590851 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M205.791000,304.983948 
	C205.400391,303.609589 205.321320,302.096863 205.440720,300.268555 
	C205.793640,301.583832 205.948090,303.214691 205.791000,304.983948 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M182.695984,312.204437 
	C183.859375,312.820404 184.799301,313.629913 185.871216,314.723083 
	C184.826279,314.137146 183.649399,313.267548 182.695984,312.204437 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M337.801453,355.094604 
	C337.595459,353.538208 337.724060,351.810028 338.160889,349.891724 
	C338.358063,351.442017 338.247070,353.182404 337.801453,355.094604 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M55.146385,351.443359 
	C55.510796,353.712952 55.778133,356.429321 56.081665,359.583801 
	C56.004917,360.301300 55.852177,360.556976 55.485146,360.926453 
	C55.223537,358.006104 55.136425,354.948151 55.146385,351.443359 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M282.358582,363.938477 
	C282.020844,362.573944 282.036957,361.153290 282.136322,359.310974 
	C282.383850,360.553711 282.548157,362.218018 282.358582,363.938477 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M311.351013,120.175064 
	C312.029724,121.273903 312.553589,122.759880 313.028870,124.631813 
	C312.385590,123.532585 311.790863,122.047394 311.351013,120.175064 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M366.923218,292.742737 
	C365.739929,292.564575 364.555420,292.034668 363.188843,291.233643 
	C364.311829,291.438660 365.616913,291.914825 366.923218,292.742737 
z"
  />
  <path
    fill="#8E97B6"
    opacity="1.000000"
    stroke="none"
    d="
M327.057098,364.654419 
	C325.957733,365.329163 324.842651,365.661194 323.371338,365.986298 
	C323.015076,365.979370 322.930359,365.528839 322.858093,365.311249 
	C322.861359,364.904266 322.936829,364.714874 323.122803,364.239441 
	C324.207214,363.655396 325.181091,363.357391 326.460510,363.017456 
	C326.857849,363.420898 326.949646,363.866272 327.057098,364.654419 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M314.589539,73.932312 
	C314.628326,74.996048 314.356750,76.184235 314.076599,77.742996 
	C313.969849,78.393639 313.806152,78.627960 313.381531,78.906815 
	C313.550415,77.350296 313.914764,75.703522 314.589539,73.932312 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M93.606827,351.964600 
	C93.959282,352.716248 93.971786,353.506348 93.959114,354.692017 
	C93.217842,355.064545 92.501755,355.041504 91.427139,355.028992 
	C91.059189,354.250092 91.049767,353.460663 91.134659,352.284943 
	C91.908279,351.933441 92.587578,351.968201 93.606827,351.964600 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M369.144592,296.853638 
	C368.232239,295.938904 367.605530,294.830444 366.941986,293.398682 
	C367.746887,294.270172 368.588562,295.465027 369.144592,296.853638 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M120.952103,314.636292 
	C121.682030,313.973755 122.511162,313.611725 123.651428,313.115021 
	C123.960609,313.764648 123.958656,314.548920 123.956703,315.333191 
	C122.988235,315.201050 122.019775,315.068909 120.952103,314.636292 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M320.577881,311.281036 
	C321.617584,312.066742 322.418152,313.085144 323.333862,314.417694 
	C322.412262,313.659149 321.375519,312.586426 320.577881,311.281036 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M263.964447,295.459961 
	C264.576508,295.144989 265.207947,295.172363 266.147644,295.252014 
	C266.609802,295.970001 266.763672,296.635712 266.919861,297.647278 
	C266.922150,297.993164 266.471588,298.010071 266.246338,298.015625 
	C265.341980,297.281525 264.662872,296.541901 263.964447,295.459961 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M61.008591,315.595154 
	C62.034931,315.247894 63.165634,315.204834 64.709976,315.270813 
	C63.786732,315.553040 62.449841,315.726227 61.008591,315.595154 
z"
  />
  <path
    fill="#97A1C5"
    opacity="1.000000"
    stroke="none"
    d="
M237.129517,305.343750 
	C237.411163,306.252167 237.621735,307.569397 237.512726,308.972565 
	C237.148254,307.956512 237.103348,306.854523 237.129517,305.343750 
z"
  />
  <path
    fill="#9DA5BC"
    opacity="1.000000"
    stroke="none"
    d="
M144.344604,366.993164 
	C144.009521,366.824707 144.008057,366.649414 144.007141,366.210907 
	C144.689667,365.300232 145.371674,364.652740 146.330292,363.945557 
	C146.716980,364.050201 146.827057,364.214569 146.955353,364.925110 
	C146.980804,365.649689 146.988068,365.828094 146.995316,366.006500 
	C146.222946,366.333099 145.450577,366.659729 144.344604,366.993164 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M56.758232,307.109772 
	C57.013405,308.180573 56.910950,309.251770 56.725868,310.721558 
	C54.205498,309.926117 54.270084,308.580658 56.758232,307.109772 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M322.374512,346.980347 
	C322.760406,348.003754 322.846771,349.146057 322.823395,350.708252 
	C322.500793,349.785187 322.287903,348.442200 322.374512,346.980347 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M84.492882,291.075195 
	C84.461441,292.346313 84.046494,293.619873 83.260567,294.905426 
	C82.840996,293.974976 82.792389,293.032501 82.676086,291.747742 
	C83.108719,291.296173 83.609047,291.186920 84.492882,291.075195 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M312.333099,301.934082 
	C312.289246,302.850800 311.874786,303.796234 311.460297,304.741638 
	C310.897156,304.118530 310.333984,303.495453 309.744507,302.523651 
	C310.466278,302.104218 311.214386,302.033478 312.333099,301.934082 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M81.030807,290.598694 
	C80.339958,289.895081 79.654129,288.815521 78.961365,287.387878 
	C79.648224,288.100769 80.342033,289.161774 81.030807,290.598694 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M89.988838,346.641418 
	C88.997353,346.225433 87.981133,345.467621 86.968094,344.359680 
	C87.968895,344.772919 88.966499,345.536285 89.988838,346.641418 
z"
  />
  <path
    fill="#E2E7F2"
    opacity="1.000000"
    stroke="none"
    d="
M154.706116,364.996979 
	C155.555801,365.490173 156.163956,365.971771 156.886887,366.725403 
	C156.019043,366.540375 155.036423,366.083282 154.017471,365.300598 
	C153.981140,364.975037 154.464615,364.985382 154.706116,364.996979 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M28.854652,313.131409 
	C29.679768,313.446198 30.249445,313.923401 30.921886,314.664001 
	C30.216173,314.382904 29.407694,313.838379 28.854652,313.131409 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M203.992279,365.566895 
	C203.807053,365.980713 203.636093,365.982361 203.208527,365.977631 
	C202.999313,363.904297 203.046722,361.837280 203.207794,359.338867 
	C203.549820,360.989868 203.778183,363.072266 203.992279,365.566895 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M269.183777,315.257050 
	C269.470978,314.426544 269.937012,313.829254 270.659546,313.102417 
	C270.398224,313.811981 269.880432,314.651093 269.183777,315.257050 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M130.939468,312.111145 
	C131.731018,312.396576 132.522552,312.682037 133.634125,312.950653 
	C133.972946,313.445984 133.991776,313.958160 133.999023,314.858887 
	C132.963272,314.774628 131.939072,314.301758 130.869537,313.242432 
	C130.862625,312.474335 130.901047,312.292755 130.939468,312.111145 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M241.279526,290.946289 
	C241.034576,290.190643 241.117050,289.420410 241.320435,288.280823 
	C241.496567,288.918213 241.551758,289.924957 241.279526,290.946289 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M348.169983,348.839203 
	C347.541077,348.036346 347.164062,347.059082 346.739868,345.762756 
	C346.875275,345.367645 347.057892,345.291565 347.530487,345.161743 
	C348.020905,346.293640 348.221375,347.479218 348.169983,348.839203 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M83.811455,311.911255 
	C84.244057,312.715393 84.461174,313.501923 84.798485,314.567810 
	C84.349899,315.008240 83.781128,315.169281 83.084373,315.079224 
	C82.978821,314.095306 83.001251,313.362427 83.093674,312.266388 
	C83.163673,311.903198 83.595970,311.893646 83.811455,311.911255 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M265.836182,312.165344 
	C266.422089,312.744385 266.735229,313.515503 267.077209,314.608643 
	C266.591827,314.072906 266.077637,313.215149 265.836182,312.165344 
z"
  />
  <path
    fill="#D7DCEC"
    opacity="1.000000"
    stroke="none"
    d="
M31.995609,334.451477 
	C31.372627,334.909882 30.661959,335.052246 29.597294,335.256714 
	C29.242174,332.921875 30.109529,332.480469 31.995609,334.451477 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M32.924240,282.735657 
	C32.190838,283.046936 31.440645,283.021179 30.318600,282.935669 
	C30.933647,282.716827 31.920547,282.557739 32.924240,282.735657 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M112.918610,315.402832 
	C114.038322,315.141052 115.164261,315.115601 116.705330,315.181274 
	C116.621231,315.802124 116.122002,316.331848 115.142197,316.662170 
	C114.098900,316.345062 113.536163,316.227325 112.973434,316.109589 
	C112.973434,316.109589 112.924820,315.639191 112.918610,315.402832 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M317.030273,70.663452 
	C316.495148,71.566315 315.980560,72.134651 315.215393,72.854736 
	C315.402771,71.708061 315.840698,70.409637 316.635162,69.051239 
	C317.011414,69.437149 317.031128,69.883026 317.030273,70.663452 
z"
  />
  <path
    fill="#AED4E4"
    opacity="1.000000"
    stroke="none"
    d="
M318.701965,64.105972 
	C318.696167,65.039711 318.333679,66.007484 317.664429,67.077911 
	C317.686829,66.167053 318.016022,65.153534 318.701965,64.105972 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M341.152954,314.617004 
	C341.584290,314.359741 341.909424,314.336914 342.706085,314.449158 
	C342.832916,315.252136 342.488159,315.920013 342.143433,316.587891 
	C341.777863,316.009094 341.412323,315.430267 341.152954,314.617004 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M98.836151,313.988190 
	C99.485237,313.945129 99.749405,314.077484 100.039658,314.545044 
	C99.361450,315.049011 98.593933,315.343933 97.826416,315.638855 
	C98.055756,315.105042 98.285103,314.571228 98.836151,313.988190 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M130.865799,311.856812 
	C130.901047,312.292755 130.862625,312.474335 130.724030,312.918182 
	C129.950699,312.559082 129.277588,311.937744 128.846832,311.143494 
	C129.421204,310.981934 129.750824,311.016144 130.324097,311.097443 
	C130.644119,311.281921 130.718124,311.442200 130.865799,311.856812 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M327.327393,357.767029 
	C327.009338,356.287872 326.954651,354.554932 327.000427,352.398376 
	C327.264191,353.820923 327.427460,355.667084 327.327393,357.767029 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M98.781815,286.684082 
	C98.736923,287.431183 98.493713,287.958923 98.111183,288.743591 
	C97.804565,288.999542 97.637268,288.998535 97.218765,288.992737 
	C97.506203,288.146851 98.044861,287.305756 98.781815,286.684082 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M166.126862,364.343140 
	C166.279953,363.999054 166.467255,363.994934 166.936493,363.979065 
	C167.756668,364.466217 168.294922,364.965118 168.912933,365.733154 
	C168.048813,365.562592 167.104935,365.122864 166.126862,364.343140 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M78.949936,286.745361 
	C78.535637,287.068909 78.110794,287.091217 77.367981,287.085754 
	C77.267113,286.539978 77.484207,286.021881 77.701309,285.503815 
	C78.114006,285.817261 78.526695,286.130676 78.949936,286.745361 
z"
  />
  <path
    fill="#959CB2"
    opacity="1.000000"
    stroke="none"
    d="
M353.071899,361.387115 
	C353.468689,361.009491 353.881470,360.947662 354.605225,360.856445 
	C354.874512,361.457275 354.832825,362.087524 354.648865,363.174988 
	C354.033691,362.989136 353.560791,362.346039 353.071899,361.387115 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M113.019264,316.393799 
	C113.536163,316.227325 114.098900,316.345062 114.845665,316.691956 
	C114.374825,316.840088 113.719963,316.759064 113.019264,316.393799 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M312.200500,315.279114 
	C312.409332,314.647461 312.809235,314.247711 313.475372,313.735474 
	C313.291595,314.252319 312.841614,314.881683 312.200500,315.279114 
z"
  />
  <path
    fill="#ACD0E1"
    opacity="1.000000"
    stroke="none"
    d="
M320.620056,60.996460 
	C320.594513,61.626881 320.245270,62.309528 319.598816,63.071583 
	C319.633209,62.450226 319.964783,61.749458 320.620056,60.996460 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M321.054138,59.335381 
	C321.541718,59.009132 321.950592,59.003929 322.666687,58.994102 
	C322.523865,59.639027 322.073822,60.288570 321.294678,60.955505 
	C320.968872,60.534069 320.972137,60.095245 321.054138,59.335381 
z"
  />
  <path
    fill="#A1ABC6"
    opacity="1.000000"
    stroke="none"
    d="
M202.801239,313.412445 
	C203.337982,313.125366 203.827820,313.080872 204.685913,313.037872 
	C204.394196,313.544403 203.734207,314.049469 202.815659,314.331360 
	C202.557098,314.108185 202.754318,313.655060 202.801239,313.412445 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M260.200073,311.953583 
	C260.108398,311.408478 260.317108,310.857239 260.793213,310.161316 
	C260.873840,310.660248 260.687103,311.303833 260.200073,311.953583 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M301.694702,293.014099 
	C301.836670,293.555908 301.683014,294.117828 301.283569,294.848022 
	C301.158234,294.355652 301.278625,293.694946 301.694702,293.014099 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M55.634102,346.066772 
	C55.811958,346.627289 55.661663,347.153412 55.277824,347.833618 
	C54.693077,347.530823 54.341877,347.073944 53.988739,346.314575 
	C54.426517,346.018829 54.866234,346.025604 55.634102,346.066772 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M157.159637,297.869080 
	C156.615799,297.539337 156.315216,297.053558 156.042603,296.268799 
	C156.514679,296.550934 156.958801,297.131989 157.159637,297.869080 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M91.202271,348.845154 
	C90.654503,348.534393 90.340523,348.058868 90.021660,347.285126 
	C90.489868,347.551392 90.962967,348.115906 91.202271,348.845154 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M228.016266,298.290894 
	C228.560394,298.186310 229.120239,298.372070 229.832123,298.823090 
	C229.333435,298.919312 228.682724,298.750244 228.016266,298.290894 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M325.857849,290.842285 
	C325.552307,291.407745 325.084686,291.728058 324.321106,292.057007 
	C324.582062,291.576172 325.138947,291.086639 325.857849,290.842285 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M124.083527,348.990387 
	C124.395401,348.424652 124.854851,348.136810 125.594658,347.824463 
	C125.327049,348.289368 124.779068,348.778839 124.083527,348.990387 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M339.890076,343.142883 
	C339.652130,343.733124 339.091003,344.290802 338.208252,344.796509 
	C338.446716,344.199768 339.006805,343.655029 339.890076,343.142883 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M122.824478,290.773743 
	C122.996155,291.186615 122.985672,291.383270 122.940453,291.874390 
	C122.487320,292.141022 122.068924,292.113190 121.337669,292.051788 
	C121.563980,291.531311 122.103149,291.044403 122.824478,290.773743 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M122.901047,343.910767 
	C122.448509,344.298035 121.858032,344.428772 121.115982,344.348816 
	C121.563972,343.976807 122.163536,343.815491 122.901047,343.910767 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M262.216431,355.947937 
	C262.038696,355.409515 262.171753,354.856720 262.534668,354.127930 
	C262.685455,354.612457 262.606354,355.273041 262.216431,355.947937 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M33.650955,285.015259 
	C33.248848,284.656219 33.108433,284.209442 32.948040,283.411194 
	C33.256256,283.682312 33.584454,284.304932 33.650955,285.015259 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M118.851837,343.804626 
	C118.567154,344.379150 118.113235,344.709564 117.372231,345.089813 
	C117.617630,344.613281 118.150116,344.086884 118.851837,343.804626 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M126.962051,343.748108 
	C126.512054,344.006256 126.051712,343.968353 125.257828,343.830139 
	C125.308945,343.297516 125.693604,342.865265 126.078262,342.432983 
	C126.369415,342.772675 126.660568,343.112366 126.962051,343.748108 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M344.970795,343.734802 
	C344.548401,344.047394 344.095947,344.058350 343.305786,344.112488 
	C342.966980,343.975922 342.965881,343.796204 342.958160,343.347107 
	C343.614594,343.196167 344.277679,343.314667 344.970795,343.734802 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M314.956024,134.362152 
	C315.258728,134.664642 315.528625,135.320267 315.515594,136.034424 
	C315.129517,135.633759 315.026367,135.174530 314.956024,134.362152 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M231.135162,285.869019 
	C231.722107,285.644287 232.759048,285.499817 234.053223,285.570984 
	C233.402023,285.840851 232.493591,285.895050 231.135162,285.869019 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M50.057171,351.219452 
	C49.992783,350.507538 50.025227,350.005524 50.133404,349.131409 
	C50.474339,349.626190 50.739540,350.493103 50.913319,351.703857 
	C50.531719,351.914520 50.309090,351.708435 50.057171,351.219452 
z"
  />
  <path
    fill="#BDC5DC"
    opacity="1.000000"
    stroke="none"
    d="
M339.104309,348.758179 
	C339.100281,348.323975 339.369537,347.821991 339.941345,347.309082 
	C339.955170,347.762207 339.666412,348.226288 339.104309,348.758179 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M124.614944,295.378540 
	C124.898720,295.163849 125.162758,295.107819 125.828995,294.986664 
	C126.231209,294.921539 126.641380,294.967865 126.844788,295.006042 
	C126.895149,295.351501 126.668343,295.588104 125.844818,295.834473 
	C125.127464,295.884277 124.933052,295.853577 124.738632,295.822876 
	C124.738632,295.822876 124.595207,295.537170 124.614944,295.378540 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M78.018555,344.275604 
	C78.040230,344.495880 77.784691,344.715088 77.283936,345.003906 
	C77.272926,344.807190 77.507133,344.540863 78.018555,344.275604 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M314.112915,367.080017 
	C314.037811,366.890503 314.314453,366.703888 314.473755,366.659790 
	C314.484711,366.833679 314.336395,367.051605 314.112915,367.080017 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M124.681168,296.081665 
	C124.933052,295.853577 125.127464,295.884277 125.615578,295.931854 
	C125.685059,296.232330 125.460838,296.515930 124.953438,296.834076 
	C124.654739,296.692566 124.639221,296.516541 124.681168,296.081665 
z"
  />
  <path
    fill="#C3C7DF"
    opacity="1.000000"
    stroke="none"
    d="
M203.473541,344.993683 
	C203.291855,344.917358 203.242111,344.708191 203.273285,344.186340 
	C203.474915,344.083679 203.620270,344.284393 203.704529,344.508118 
	C203.739212,344.600189 203.642441,344.741760 203.473541,344.993683 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M190.101685,366.279907 
	C190.278931,366.203491 190.588333,366.335510 190.987320,366.716461 
	C190.733047,366.917419 190.452026,366.758392 190.101685,366.279907 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M344.128113,314.274628 
	C343.982910,314.117310 344.318787,313.751007 344.544067,313.646301 
	C344.604004,313.838409 344.438660,314.135162 344.128113,314.274628 
z"
  />
  <path
    fill="#DEE3F2"
    opacity="1.000000"
    stroke="none"
    d="
M236.785767,289.089722 
	C237.021774,289.145264 237.084976,289.303192 237.143341,289.716156 
	C236.963303,289.711456 236.788116,289.451752 236.785767,289.089722 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M147.174438,365.929230 
	C146.988068,365.828094 146.980804,365.649689 147.036392,365.210571 
	C147.253616,365.142029 147.429108,365.322479 147.547531,365.534760 
	C147.577179,365.587891 147.423172,365.743469 147.174438,365.929230 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M283.001221,348.001221 
	C283.097168,347.963989 283.191864,347.925537 283.142181,347.942810 
	C282.997772,347.998535 283.000000,348.000000 283.001221,348.001221 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M312.440033,109.930908 
	C311.751343,107.930267 311.432068,105.838356 311.180603,103.341263 
	C315.841400,104.316399 314.287964,107.086479 312.440033,109.930908 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M311.610718,97.030891 
	C311.204163,95.694862 311.153656,94.326088 311.417908,92.501534 
	C312.137482,92.026558 312.542297,92.007362 312.947113,91.988159 
	C314.281830,93.983253 315.156952,95.888412 311.610718,97.030891 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M315.251709,130.835236 
	C314.717468,130.507401 314.395477,130.000412 314.090149,129.172546 
	C314.559174,129.453156 315.011536,130.054626 315.251709,130.835236 
z"
  />
  <path
    fill="#A4CCDE"
    opacity="1.000000"
    stroke="none"
    d="
M312.937592,91.695206 
	C312.542297,92.007362 312.137482,92.026558 311.433411,92.091812 
	C311.262390,91.541534 311.390594,90.945213 311.775513,90.168480 
	C312.330811,90.459457 312.629425,90.930855 312.937592,91.695206 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M362.763519,185.172211 
	C363.305420,185.475906 363.621338,185.947510 363.931946,186.719971 
	C363.463593,186.460602 363.000549,185.900375 362.763519,185.172211 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M333.008301,98.234375 
	C333.192322,98.150177 333.385895,98.310997 333.533173,98.506653 
	C333.562531,98.545601 333.415039,98.717720 333.175781,98.917374 
	C333.002167,98.834740 333.001862,98.663185 333.008301,98.234375 
z"
  />
  <path
    fill="#76B3D2"
    opacity="1.000000"
    stroke="none"
    d="
M118.069664,112.975212 
	C118.167618,101.380318 117.221222,89.814026 120.626122,78.337593 
	C124.589737,64.978043 131.765427,53.659950 140.427994,43.069412 
	C142.526947,40.503319 144.690979,37.978695 146.984436,35.587875 
	C148.375549,34.137699 150.072403,32.980839 151.631912,31.692207 
	C152.052139,31.939568 152.472366,32.186924 152.892578,32.434284 
	C152.648941,33.720943 152.603027,35.085690 152.130539,36.281967 
	C148.181381,46.281067 143.436340,56.037258 140.404312,66.301804 
	C138.412506,73.044746 138.167847,80.529762 138.390945,87.643250 
	C138.664215,96.356239 139.740463,105.127640 141.430984,113.683830 
	C142.599686,119.598862 145.392029,125.193810 147.466599,130.928970 
	C150.359863,138.927444 153.110138,146.982117 156.211121,154.899368 
	C158.250336,160.105820 160.713577,165.153809 163.136948,170.200745 
	C170.524643,185.586365 171.124054,200.343597 161.399719,215.397034 
	C155.116837,225.123047 148.002518,233.876404 139.045914,241.763031 
	C137.626083,241.524277 136.800323,241.266449 135.974564,241.008606 
	C138.704468,235.593521 141.492569,230.206833 144.152771,224.757721 
	C150.738373,211.267883 148.533508,198.277313 142.644958,185.067856 
	C135.661835,169.402969 129.429031,153.400818 123.032295,137.480042 
	C121.938980,134.758896 121.652817,131.713470 121.239960,128.218170 
	C120.993851,126.746353 120.501556,125.874199 120.009262,125.002037 
	C119.673363,122.284645 119.337471,119.567245 119.255295,116.226578 
	C119.029236,114.727264 118.549454,113.851234 118.069664,112.975212 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M135.823105,241.308990 
	C136.800323,241.266449 137.626083,241.524277 138.753143,241.921143 
	C136.572113,245.143066 134.089813,248.225967 131.607513,251.308853 
	C131.057922,250.899048 130.508316,250.489243 129.958725,250.079437 
	C131.863022,247.256073 133.767334,244.432724 135.823105,241.308990 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M120.003914,125.338684 
	C120.501556,125.874199 120.993851,126.746353 121.225166,127.814232 
	C120.642311,127.231743 120.320442,126.453545 120.003914,125.338684 
z"
  />
  <path
    fill="#87B9D2"
    opacity="1.000000"
    stroke="none"
    d="
M118.050255,113.314667 
	C118.549454,113.851234 119.029236,114.727264 119.255959,115.799408 
	C118.678886,115.215050 118.354866,114.434586 118.050255,113.314667 
z"
  />
  <path
    fill="#677397"
    opacity="1.000000"
    stroke="none"
    d="
M279.954041,306.751740 
	C279.444550,302.144043 278.535553,297.991638 278.599091,293.854156 
	C278.629150,291.895721 280.519531,289.965881 281.567078,288.023071 
	C282.156860,288.063507 282.746643,288.103912 283.336395,288.144318 
	C283.295319,290.977142 283.180267,293.811279 283.229767,296.642517 
	C283.312622,301.382599 283.502045,306.120789 283.642731,310.859894 
	C283.670654,311.799896 283.681091,312.740417 283.699585,313.680695 
	C282.747528,313.262177 281.238800,313.071228 280.955872,312.379150 
	C280.304871,310.786682 280.250122,308.950439 279.954041,306.751740 
z"
  />
  <path
    fill="#5F6D8C"
    opacity="1.000000"
    stroke="none"
    d="
M172.794235,312.173401 
	C172.082031,306.172577 171.004868,300.182892 170.821243,294.165924 
	C170.737289,291.415253 172.413605,288.610901 173.291595,285.830872 
	C173.858139,285.947632 174.424667,286.064423 174.991211,286.181183 
	C174.991211,294.462372 174.991211,302.743591 174.593201,311.511780 
	C173.728210,312.056976 173.261230,312.115204 172.794235,312.173401 
z"
  />
  <path
    fill="#8A90AB"
    opacity="1.000000"
    stroke="none"
    d="
M172.841461,312.501953 
	C173.261230,312.115204 173.728210,312.056976 174.545532,311.974274 
	C174.927017,312.412048 174.958191,312.874298 175.021194,313.683929 
	C175.053040,314.031281 174.996185,314.005463 175.015427,314.031982 
	C174.319351,313.649139 173.604019,313.239777 172.841461,312.501953 
z"
  />
  <path
    fill="#E5EAF5"
    opacity="1.000000"
    stroke="none"
    d="
M107.984520,353.253479 
	C107.677750,355.958160 107.654037,358.332886 106.932899,360.472595 
	C106.565536,361.562622 104.925011,362.941467 103.895767,362.912628 
	C102.546326,362.874817 100.193199,361.700531 100.084846,360.797546 
	C99.643173,357.116882 99.580826,353.309875 100.088921,349.647980 
	C100.231583,348.619781 103.755379,346.929047 104.093636,347.252380 
	C105.698448,348.786316 106.738068,350.911621 107.984520,353.253479 
z"
  />
  <path
    fill="#E0E5F2"
    opacity="1.000000"
    stroke="none"
    d="
M357.418274,296.247314 
	C358.380219,295.614960 359.315491,294.846924 359.813812,295.067230 
	C361.430878,295.782196 362.995483,296.734375 364.330444,297.885040 
	C364.662231,298.171021 364.124390,300.285187 363.863251,300.310944 
	C361.658783,300.528229 359.425781,300.455658 357.201355,300.470184 
	C357.198059,299.168182 357.194763,297.866150 357.418274,296.247314 
z"
  />
  <path
    fill="#B8BBD1"
    opacity="1.000000"
    stroke="none"
    d="
M133.776672,296.158569 
	C133.431824,294.639252 133.414215,293.069214 133.437744,291.140869 
	C135.003845,291.589142 136.528778,292.395721 139.255112,293.837708 
	C136.586029,295.013977 135.344971,295.560944 133.776672,296.158569 
z"
  />
  <path
    fill="#B8BBD1"
    opacity="1.000000"
    stroke="none"
    d="
M133.389374,306.794525 
	C132.947784,306.073029 132.740509,305.173126 132.849426,304.150970 
	C133.318298,304.891205 133.470993,305.753632 133.389374,306.794525 
z"
  />
  <path
    fill="#DEE5F5"
    opacity="1.000000"
    stroke="none"
    d="
M87.630028,351.887329 
	C84.812302,352.581635 82.137283,352.892456 79.479652,353.201263 
	C78.616241,349.591492 78.831169,347.399353 82.382294,347.057617 
	C85.681770,346.740021 87.979836,347.591675 87.630028,351.887329 
z"
  />
  <path
    fill="#8387AB"
    opacity="1.000000"
    stroke="none"
    d="
M242.550507,352.923889 
	C242.829803,353.090973 242.771866,353.574615 242.707184,353.810120 
	C241.662155,354.261597 240.681808,354.477570 239.419983,354.825500 
	C236.061325,352.957153 232.984146,350.956848 229.591675,348.838684 
	C228.489365,346.122253 226.881607,343.428162 227.169495,340.954712 
	C227.408905,338.897583 229.667999,335.753632 231.402863,335.485260 
	C234.421143,335.018372 237.726227,336.390717 240.896942,337.059021 
	C240.947006,337.069550 240.816528,337.936584 240.737869,338.731842 
	C237.377762,338.791748 233.139343,334.190460 230.963242,340.628052 
	C230.961655,341.449707 230.927902,341.890900 230.906372,342.975372 
	C231.265518,344.102112 231.612473,344.585602 231.959427,345.069061 
	C232.061218,345.380646 232.244293,345.633301 232.852676,346.226471 
	C234.102615,347.134216 235.008545,347.642578 235.914490,348.150970 
	C238.033386,349.686249 240.152283,351.221527 242.550507,352.923889 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M248.843613,351.146606 
	C249.677338,352.115051 250.238281,353.269562 250.736649,354.753662 
	C249.972992,353.833038 249.271912,352.582825 248.843613,351.146606 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M250.806046,357.165833 
	C251.652786,358.124969 252.234146,359.274750 252.745331,360.754059 
	C251.963669,359.841217 251.252167,358.598846 250.806046,357.165833 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M247.768127,345.090088 
	C248.342361,346.190155 248.573196,347.298706 248.737000,348.744568 
	C246.515152,348.298004 244.879883,347.351685 247.768127,345.090088 
z"
  />
  <path
    fill="#DADEF0"
    opacity="1.000000"
    stroke="none"
    d="
M235.903397,347.786499 
	C235.008545,347.642578 234.102615,347.134216 233.064682,346.332581 
	C233.919220,346.500214 234.905777,346.961121 235.903397,347.786499 
z"
  />
  <path
    fill="#FDFEFE"
    opacity="1.000000"
    stroke="none"
    d="
M246.269867,362.967865 
	C245.968567,362.560486 245.969666,362.113342 245.974899,361.330811 
	C246.502792,361.231110 247.026520,361.466766 247.550262,361.702454 
	C247.224258,362.110962 246.898254,362.519501 246.269867,362.967865 
z"
  />
  <path
    fill="#DADEF0"
    opacity="1.000000"
    stroke="none"
    d="
M231.965302,344.758850 
	C231.612473,344.585602 231.265518,344.102112 230.952194,343.304993 
	C231.314270,343.477112 231.642731,343.962860 231.965302,344.758850 
z"
  />
  <path
    fill="#D6DBEE"
    opacity="1.000000"
    stroke="none"
    d="
M175.874023,353.155090 
	C173.731506,352.400848 172.010925,351.634644 170.290344,350.868439 
	C171.230347,349.621155 172.186768,347.332520 173.106873,347.347015 
	C174.927826,347.375702 177.287430,348.219635 178.393814,349.570526 
	C179.720398,351.190308 178.956039,353.074829 175.874023,353.155090 
z"
  />
  <path
    fill="#EDF3FB"
    opacity="1.000000"
    stroke="none"
    d="
M178.550812,357.002686 
	C178.992447,357.799957 179.000626,358.564240 179.035355,359.711548 
	C178.568420,360.971497 178.074936,361.848450 176.967194,362.864380 
	C175.563080,363.344421 174.773193,363.685486 173.983307,364.026520 
	C173.013184,363.240875 172.043060,362.455231 171.287994,361.111389 
	C171.679733,359.391174 171.856430,358.229218 172.033142,357.067230 
	C174.061203,357.034698 176.089264,357.002167 178.550812,357.002686 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M171.679535,357.070374 
	C171.856430,358.229218 171.679733,359.391174 171.232483,360.781494 
	C171.083252,359.697723 171.204590,358.385651 171.679535,357.070374 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M174.161652,364.249939 
	C174.773193,363.685486 175.563080,363.344421 176.697968,363.021118 
	C176.141998,363.516998 175.240997,363.995178 174.161652,364.249939 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M145.850342,295.709534 
	C147.435898,295.354706 149.044159,294.740326 149.921280,295.290802 
	C151.128311,296.048431 151.782410,297.687012 152.673401,298.948151 
	C151.571365,299.645233 150.542221,300.711060 149.349014,300.946899 
	C147.824387,301.248230 146.167374,300.879852 143.673538,300.755554 
	C144.451630,298.767792 144.999344,297.368591 145.850342,295.709534 
z"
  />
  <path
    fill="#E2E8F4"
    opacity="1.000000"
    stroke="none"
    d="
M195.869125,347.028595 
	C200.641373,350.091248 201.588104,360.582062 197.468567,363.505829 
	C196.463730,364.218994 192.778488,363.311432 192.733368,362.890778 
	C192.226456,358.165527 192.073563,353.387085 192.182022,348.631073 
	C192.194992,348.063324 194.320801,347.543732 195.869125,347.028595 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M142.744843,349.700256 
	C142.169769,349.438812 141.643448,348.859497 141.143860,347.943848 
	C141.711609,348.199158 142.252609,348.790771 142.744843,349.700256 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M138.749161,346.985718 
	C138.729034,347.140503 138.459198,347.255768 138.097504,347.212952 
	C138.170227,347.018677 138.334839,346.982422 138.749161,346.985718 
z"
  />
  <path
    fill="#E7EEF9"
    opacity="1.000000"
    stroke="none"
    d="
M185.025421,303.059326 
	C185.542435,300.441803 185.670807,298.046112 186.733490,296.183502 
	C187.315979,295.162537 189.706894,294.330597 190.861053,294.706604 
	C192.109116,295.113220 193.497543,296.930023 193.707352,298.302582 
	C194.155716,301.235840 194.412598,304.407715 193.727173,307.234314 
	C193.236954,309.255920 191.087280,310.875122 189.678024,312.673889 
	C188.139999,310.783569 186.425095,309.003174 185.149155,306.949982 
	C184.622726,306.102905 185.039017,304.670013 185.025421,303.059326 
z"
  />
  <path
    fill="#FDFEFE"
    opacity="1.000000"
    stroke="none"
    d="
M276.658508,347.807922 
	C276.813416,349.373535 276.567230,350.831909 276.159180,352.625977 
	C274.999176,351.883850 274.001038,350.806061 273.237000,349.242340 
	C273.580444,348.440704 273.689789,348.125000 273.799164,347.809265 
	C274.618591,347.773071 275.437988,347.736847 276.658508,347.807922 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M273.569275,347.934692 
	C273.689789,348.125000 273.580444,348.440704 273.250763,348.890991 
	C273.133423,348.703735 273.236420,348.381958 273.569275,347.934692 
z"
  />
  <path
    fill="#DCE2F1"
    opacity="1.000000"
    stroke="none"
    d="
M366.642975,349.025360 
	C366.275635,350.728790 365.624084,352.575989 364.929138,354.815857 
	C363.274353,354.414154 361.357880,353.928986 360.144226,352.731476 
	C359.098724,351.699860 358.854156,349.856537 358.255493,348.372070 
	C359.688568,348.212952 361.141937,347.831451 362.548767,347.959900 
	C363.844757,348.078156 365.090759,348.744324 366.642975,349.025360 
z"
  />
  <path
    fill="#F6F9FD"
    opacity="1.000000"
    stroke="none"
    d="
M357.108704,361.223755 
	C357.378876,359.651825 357.685669,358.431976 358.025024,356.825073 
	C358.478668,356.585907 358.899719,356.733826 359.694397,357.330322 
	C360.767639,358.119934 361.467285,358.460968 362.166931,358.802002 
	C362.166931,358.802002 362.152191,358.850220 362.110016,359.299652 
	C362.149078,360.910004 362.230316,362.070892 362.311584,363.231812 
	C362.311554,363.231812 362.256256,363.261139 361.914185,363.206177 
	C361.038483,363.470184 360.504883,363.789124 359.971252,364.108032 
	C359.029266,363.263977 358.087280,362.419952 357.108704,361.223755 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M362.620911,363.151886 
	C362.230316,362.070892 362.149078,360.910004 362.128967,359.316345 
	C362.436768,360.279694 362.683502,361.675842 362.620911,363.151886 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M362.101654,358.493530 
	C361.467285,358.460968 360.767639,358.119934 360.000671,357.464508 
	C360.634369,357.495117 361.335388,357.840118 362.101654,358.493530 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M360.144531,364.317810 
	C360.504883,363.789124 361.038483,363.470184 361.901489,363.234802 
	C361.593170,363.721436 360.955475,364.124481 360.144531,364.317810 
z"
  />
  <path
    fill="#E2E7F2"
    opacity="1.000000"
    stroke="none"
    d="
M154.419128,359.172729 
	C154.506851,359.080780 154.665848,359.499725 154.593826,359.711639 
	C154.287262,359.747253 154.223785,359.527649 154.419128,359.172729 
z"
  />
  <path
    fill="#E2E7F2"
    opacity="1.000000"
    stroke="none"
    d="
M154.033905,348.255920 
	C154.298065,348.098389 154.486557,348.239258 154.495758,348.707367 
	C154.265503,348.757141 154.146118,348.597015 154.033905,348.255920 
z"
  />
  <path
    fill="#959CBC"
    opacity="1.000000"
    stroke="none"
    d="
M325.528168,305.513916 
	C325.148926,304.735413 324.769684,303.956879 324.048981,302.899414 
	C326.493103,301.730835 329.278656,300.841217 332.443665,299.849426 
	C332.570801,300.864838 332.318451,301.982452 331.684662,303.523804 
	C329.378204,304.469635 327.453186,304.991791 325.528168,305.513916 
z"
  />
  <path
    fill="#7E89AF"
    opacity="1.000000"
    stroke="none"
    d="
M305.962952,357.877594 
	C305.666107,358.636932 305.369263,359.396301 305.090393,360.485046 
	C304.555725,361.205200 304.003082,361.595947 303.095734,362.033569 
	C302.471619,358.990509 301.618408,355.804626 302.106110,352.839233 
	C302.545624,350.166595 303.862091,346.462250 305.868591,345.529694 
	C308.219360,344.437164 312.277130,344.967255 314.607483,346.399475 
	C317.524811,348.192413 317.961670,351.869263 315.717865,355.600769 
	C314.723999,354.563599 314.299072,353.198090 313.873718,351.468933 
	C312.760651,347.564056 309.865540,347.378418 307.196594,348.284546 
	C306.375122,348.563477 306.396271,351.324158 306.018097,353.360168 
	C305.990723,355.140472 305.976868,356.509033 305.962952,357.877594 
z"
  />
  <path
    fill="#9DA5BC"
    opacity="1.000000"
    stroke="none"
    d="
M313.874146,351.832581 
	C314.299072,353.198090 314.723999,354.563599 315.484985,355.919922 
	C315.845612,356.345551 315.870117,356.780396 315.875610,357.539490 
	C312.861176,357.892426 309.865814,357.921112 306.416687,357.913696 
	C305.976868,356.509033 305.990723,355.140472 306.426270,353.383057 
	C309.190002,352.606995 311.532074,352.219788 313.874146,351.832581 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M81.684059,295.147583 
	C81.738319,300.081329 81.395317,304.989868 81.086182,310.342926 
	C78.224129,307.618011 78.325874,298.059601 81.684059,295.147583 
z"
  />
  <path
    fill="#8A90AB"
    opacity="1.000000"
    stroke="none"
    d="
M40.115822,345.631226 
	C40.889706,345.114319 41.595337,345.045380 42.650879,345.049194 
	C43.217403,346.087982 43.434025,347.054016 43.951576,348.071045 
	C43.876900,352.156799 43.500824,356.191467 43.125881,360.226257 
	C42.938663,362.240906 42.753521,364.255768 42.515011,366.838196 
	C39.472115,363.765961 39.467987,363.720612 40.115822,345.631226 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M47.777962,347.217896 
	C47.744045,347.435883 47.455524,347.609802 46.908020,347.735474 
	C46.940475,347.516113 47.231915,347.345001 47.777962,347.217896 
z"
  />
  <path
    fill="#D0D6E8"
    opacity="1.000000"
    stroke="none"
    d="
M101.160477,296.622681 
	C101.645493,295.877167 102.121468,295.580536 102.879898,295.314270 
	C103.112297,300.219574 103.062256,305.094574 103.032120,310.424774 
	C102.408600,309.071106 101.413307,307.301727 101.204544,305.443970 
	C100.894623,302.686035 101.141541,299.865479 101.160477,296.622681 
z"
  />
  <path
    fill="#D3D9EC"
    opacity="1.000000"
    stroke="none"
    d="
M86.749588,300.207703 
	C87.169083,301.182434 87.329651,302.369598 87.259186,303.784058 
	C86.848991,302.814301 86.669823,301.617249 86.749588,300.207703 
z"
  />
  <path
    fill="#F7FDFC"
    opacity="1.000000"
    stroke="none"
    d="
M249.146347,298.422241 
	C250.043915,297.580200 250.866608,297.045654 251.833923,296.240875 
	C252.432205,295.977051 252.885834,295.983398 253.826294,296.250916 
	C255.140152,297.042023 255.967148,297.571960 256.794159,298.101929 
	C256.862610,298.519684 256.931030,298.937408 256.728516,299.922119 
	C256.631195,302.327850 256.804871,304.166626 256.978577,306.005402 
	C256.905853,307.268646 256.833130,308.531921 256.731750,310.160858 
	C256.329163,310.639893 255.955276,310.753265 255.054962,310.665649 
	C252.746918,309.941071 250.965302,309.417450 249.183685,308.893860 
	C249.111343,307.211792 249.039017,305.529755 249.226425,303.241943 
	C249.321472,301.754822 249.156769,300.873474 248.992065,299.992126 
	C249.018539,299.571320 249.044998,299.150543 249.146347,298.422241 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M249.167145,309.253998 
	C250.965302,309.417450 252.746918,309.941071 254.764771,310.717255 
	C252.420395,313.238800 250.712158,311.742950 249.167145,309.253998 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M248.764587,300.184509 
	C249.156769,300.873474 249.321472,301.754822 249.239746,302.820374 
	C248.841248,302.128723 248.689163,301.252808 248.764587,300.184509 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M257.226959,305.743958 
	C256.804871,304.166626 256.631195,302.327850 256.726379,300.240112 
	C257.155304,301.821564 257.315338,303.652039 257.226959,305.743958 
z"
  />
  <path
    fill="#CCD3E6"
    opacity="1.000000"
    stroke="none"
    d="
M256.835144,297.758972 
	C255.967148,297.571960 255.140152,297.042023 254.166306,296.243103 
	C254.020706,295.794128 254.021927,295.614075 254.040787,295.164185 
	C254.997650,295.734894 255.936890,296.575470 256.835144,297.758972 
z"
  />
  <path
    fill="#EDF3FB"
    opacity="1.000000"
    stroke="none"
    d="
M325.360901,305.569763 
	C327.453186,304.991791 329.378204,304.469635 331.695129,303.871399 
	C332.117615,305.244873 332.148193,306.694489 332.256104,308.570374 
	C330.960785,309.889130 329.588074,310.781555 328.098053,312.011322 
	C327.007538,311.459290 326.034363,310.569916 325.018951,309.028046 
	C324.939850,307.899475 324.902985,307.423462 324.866089,306.947418 
	C324.975281,306.506805 325.084442,306.066223 325.360901,305.569763 
z"
  />
  <path
    fill="#97A1C5"
    opacity="1.000000"
    stroke="none"
    d="
M324.595825,307.015747 
	C324.902985,307.423462 324.939850,307.899475 324.890747,308.725067 
	C324.645050,308.411102 324.485291,307.747559 324.595825,307.015747 
z"
  />
  <path
    fill="#EDF3FB"
    opacity="1.000000"
    stroke="none"
    d="
M313.873718,351.468933 
	C311.532074,352.219788 309.190002,352.606995 306.439758,352.971313 
	C306.396271,351.324158 306.375122,348.563477 307.196594,348.284546 
	C309.865540,347.378418 312.760651,347.564056 313.873718,351.468933 
z"
  />
</svg>
