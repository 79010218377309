<svg viewBox="0 0 390 208" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M388.013 73.9839C387.981 73.9839 387.962 74.0178 387.978 74.0448C387.993 74.0719 387.974 74.1058 387.942 74.1058H283.164C283.132 74.1058 283.113 74.0718 283.128 74.0447C283.144 74.0176 283.124 73.9837 283.093 73.9837H271.655C266.54 73.9837 263.343 68.4464 265.9 64.0166L281.679 36.6873C284.236 32.2575 290.63 32.2575 293.188 36.6873C295.745 41.1171 302.139 41.1171 304.697 36.6873L321.041 8.37864C327.491 -2.79288 343.615 -2.79288 350.065 8.37864L357.962 22.0571C360.902 27.1493 368.252 27.1493 371.192 22.0571C374.132 16.9649 381.482 16.9649 384.422 22.0571L407.787 62.5264C410.727 67.6187 407.052 73.9839 401.172 73.9839H388.013Z"
    fill="#817B9D"
  />
  <path
    d="M26.2003 29.8206C33.9075 16.5352 53.0925 16.5352 60.7997 29.8205L116.575 125.964C124.31 139.297 114.69 156 99.2753 156H-12.2754C-27.6899 156 -37.31 139.297 -29.575 125.964L26.2003 29.8206Z"
    fill="#C27D00"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M158.443 158.15C173.839 158.15 183.461 141.483 175.763 128.15L138.193 63.0771C130.495 49.7438 111.25 49.7438 103.552 63.0772L75.9718 110.848C73.468 115.185 67.2085 115.185 64.7047 110.848C62.2009 106.511 55.9413 106.511 53.4375 110.848L31.8558 148.229C29.352 152.565 32.4818 157.986 37.4894 157.986H48.662C48.704 157.986 48.7303 158.032 48.7093 158.068C48.6883 158.104 48.7145 158.15 48.7565 158.15H158.443Z"
    fill="#C9A76B"
  />
</svg>
