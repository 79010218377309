<svg viewBox="0 0 592 634" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M179.463 104.262H-42.9411C-48.2559 104.262 -52.5645 108.568 -52.5645 113.88V578.918C-52.5645 584.231 -48.2559 588.537 -42.9411 588.537H179.463C184.778 588.537 189.086 584.231 189.086 578.918V113.88C189.086 108.568 184.778 104.262 179.463 104.262Z"
    fill="#023566"
  />
  <path
    d="M163.28 360.324H119.523C117.4 360.324 115.678 362.046 115.678 364.17V426.49C115.678 428.614 117.4 430.336 119.523 430.336H163.28C165.404 430.336 167.126 428.614 167.126 426.49V364.17C167.126 362.046 165.404 360.324 163.28 360.324Z"
    fill="#B3B3B3"
  />
  <mask
    id="mask0_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="115"
    y="360"
    width="53"
    height="71"
  >
    <path
      d="M163.282 360.326H119.525C117.401 360.326 115.68 362.048 115.68 364.172V426.492C115.68 428.616 117.401 430.338 119.525 430.338H163.282C165.406 430.338 167.128 428.616 167.128 426.492V364.172C167.128 362.048 165.406 360.326 163.282 360.326Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_587_2)">
    <path
      opacity="0.2"
      d="M105.072 420.746L188.791 401.11L197.787 439.465C198.272 441.532 196.988 443.603 194.921 444.087L118.691 461.967C116.624 462.452 114.553 461.168 114.068 459.101L105.072 420.746Z"
      fill="white"
    />
  </g>
  <path
    d="M87.464 399.779H43.7071C41.5831 399.779 39.8613 401.501 39.8613 403.625V465.945C39.8613 468.069 41.5831 469.791 43.7071 469.791H87.464C89.5879 469.791 91.3097 468.069 91.3097 465.945V403.625C91.3097 401.501 89.5879 399.779 87.464 399.779Z"
    fill="#FEAC18"
  />
  <mask
    id="mask1_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="39"
    y="399"
    width="53"
    height="71"
  >
    <path
      d="M87.464 399.775H43.7071C41.5831 399.775 39.8613 401.497 39.8613 403.621V465.941C39.8613 468.065 41.5831 469.787 43.7071 469.787H87.464C89.5879 469.787 91.3097 468.065 91.3097 465.941V403.621C91.3097 401.497 89.5879 399.775 87.464 399.775Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask1_587_2)">
    <path
      opacity="0.2"
      d="M29.2539 460.189L112.973 440.553L121.969 478.908C122.454 480.975 121.17 483.046 119.103 483.531L42.8723 501.41C40.8055 501.895 38.7347 500.611 38.25 498.544L29.2539 460.189Z"
      fill="white"
    />
  </g>
  <path
    d="M12.671 400.096H-31.0859C-33.2098 400.096 -34.9316 401.818 -34.9316 403.941V466.262C-34.9316 468.386 -33.2098 470.108 -31.0859 470.108H12.671C14.795 470.108 16.5168 468.386 16.5168 466.262V403.941C16.5168 401.818 14.795 400.096 12.671 400.096Z"
    fill="#FF3A52"
  />
  <mask
    id="mask2_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-35"
    y="400"
    width="52"
    height="71"
  >
    <path
      d="M12.671 400.096H-31.0859C-33.2098 400.096 -34.9316 401.818 -34.9316 403.941V466.262C-34.9316 468.386 -33.2098 470.108 -31.0859 470.108H12.671C14.795 470.108 16.5168 468.386 16.5168 466.262V403.941C16.5168 401.818 14.795 400.096 12.671 400.096Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask2_587_2)">
    <path
      opacity="0.2"
      d="M-45.5391 460.512L38.1799 440.876L47.176 479.231C47.6607 481.297 46.3768 483.368 44.31 483.853L-31.9207 501.733C-33.9874 502.217 -36.0582 500.933 -36.543 498.867L-45.5391 460.512Z"
      fill="white"
    />
  </g>
  <path
    d="M164.318 223.615H120.561C118.437 223.615 116.715 225.337 116.715 227.461V289.781C116.715 291.905 118.437 293.627 120.561 293.627H164.318C166.441 293.627 168.163 291.905 168.163 289.781V227.461C168.163 225.337 166.441 223.615 164.318 223.615Z"
    fill="#858585"
  />
  <mask
    id="mask3_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="116"
    y="223"
    width="53"
    height="71"
  >
    <path
      d="M164.319 223.613H120.563C118.439 223.613 116.717 225.335 116.717 227.459V289.779C116.717 291.903 118.439 293.625 120.563 293.625H164.319C166.443 293.625 168.165 291.903 168.165 289.779V227.459C168.165 225.335 166.443 223.613 164.319 223.613Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask3_587_2)">
    <path
      opacity="0.2"
      d="M106.115 284.033L189.834 264.397L198.83 302.752C199.315 304.819 198.031 306.89 195.964 307.374L119.734 325.254C117.667 325.739 115.596 324.455 115.111 322.388L106.115 284.033Z"
      fill="white"
    />
  </g>
  <path
    d="M88.505 263.07H44.7481C42.6241 263.07 40.9023 264.792 40.9023 266.916V329.236C40.9023 331.36 42.6241 333.082 44.7481 333.082H88.505C90.629 333.082 92.3508 331.36 92.3508 329.236V266.916C92.3508 264.792 90.629 263.07 88.505 263.07Z"
    fill="#FF3A52"
  />
  <mask
    id="mask4_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="40"
    y="263"
    width="53"
    height="71"
  >
    <path
      d="M88.5089 263.07H44.752C42.628 263.07 40.9062 264.792 40.9062 266.916V329.236C40.9062 331.36 42.628 333.082 44.752 333.082H88.5089C90.6329 333.082 92.3547 331.36 92.3547 329.236V266.916C92.3547 264.792 90.6329 263.07 88.5089 263.07Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask4_587_2)">
    <path
      opacity="0.2"
      d="M30.3027 323.48L114.022 303.844L123.018 342.199C123.503 344.266 122.219 346.337 120.152 346.822L43.9211 364.701C41.8544 365.186 39.7836 363.902 39.2988 361.835L30.3027 323.48Z"
      fill="#FF3A52"
    />
  </g>
  <path
    d="M13.7081 263.385H-30.0488C-32.1727 263.385 -33.8945 265.107 -33.8945 267.231V329.551C-33.8945 331.675 -32.1727 333.397 -30.0488 333.397H13.7081C15.8321 333.397 17.5539 331.675 17.5539 329.551V267.231C17.5539 265.107 15.8321 263.385 13.7081 263.385Z"
    fill="#FEAC18"
  />
  <mask
    id="mask5_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-34"
    y="263"
    width="52"
    height="71"
  >
    <path
      d="M13.7081 263.387H-30.0488C-32.1727 263.387 -33.8945 265.109 -33.8945 267.232V329.553C-33.8945 331.677 -32.1727 333.399 -30.0488 333.399H13.7081C15.8321 333.399 17.5539 331.677 17.5539 329.553V267.232C17.5539 265.109 15.8321 263.387 13.7081 263.387Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask5_587_2)">
    <path
      opacity="0.2"
      d="M-44.5 323.801L39.2189 304.165L48.215 342.52C48.6998 344.586 47.4158 346.657 45.3491 347.142L-30.8816 365.022C-32.9484 365.506 -35.0192 364.222 -35.5039 362.156L-44.5 323.801Z"
      fill="#FEAC18"
    />
  </g>
  <path
    d="M165.101 88.6211H121.344C119.22 88.6211 117.498 90.3429 117.498 92.4668V154.787C117.498 156.911 119.22 158.633 121.344 158.633H165.101C167.225 158.633 168.946 156.911 168.946 154.787V92.4668C168.946 90.3429 167.225 88.6211 165.101 88.6211Z"
    fill="#B3B3B3"
  />
  <mask
    id="mask6_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="117"
    y="88"
    width="52"
    height="71"
  >
    <path
      d="M165.103 88.623H121.346C119.222 88.623 117.5 90.3448 117.5 92.4688V154.789C117.5 156.913 119.222 158.635 121.346 158.635H165.103C167.227 158.635 168.948 156.913 168.948 154.789V92.4688C168.948 90.3448 167.227 88.623 165.103 88.623Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask6_587_2)">
    <path
      opacity="0.2"
      d="M106.904 149.041L190.623 129.405L199.619 167.76C200.104 169.827 198.82 171.897 196.753 172.382L120.523 190.262C118.456 190.747 116.385 189.463 115.9 187.396L106.904 149.041Z"
      fill="white"
    />
  </g>
  <path
    d="M89.2843 128.078H45.5274C43.4034 128.078 41.6816 129.8 41.6816 131.924V194.244C41.6816 196.368 43.4034 198.09 45.5274 198.09H89.2843C91.4082 198.09 93.13 196.368 93.13 194.244V131.924C93.13 129.8 91.4082 128.078 89.2843 128.078Z"
    fill="#FEAC18"
  />
  <mask
    id="mask7_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="41"
    y="128"
    width="53"
    height="71"
  >
    <path
      d="M89.2843 128.08H45.5274C43.4034 128.08 41.6816 129.802 41.6816 131.926V194.246C41.6816 196.37 43.4034 198.092 45.5274 198.092H89.2843C91.4082 198.092 93.13 196.37 93.13 194.246V131.926C93.13 129.802 91.4082 128.08 89.2843 128.08Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask7_587_2)">
    <path
      opacity="0.2"
      d="M31.082 188.494L114.801 168.858L123.797 207.213C124.282 209.28 122.998 211.351 120.931 211.835L44.7004 229.715C42.6337 230.2 40.5629 228.916 40.0781 226.849L31.082 188.494Z"
      fill="white"
    />
  </g>
  <path
    d="M-27.3144 531.803H84.7391C86.862 531.803 88.5849 533.526 88.5849 535.648V570.429H-31.1602V535.648C-31.1602 533.526 -29.4373 531.803 -27.3144 531.803Z"
    fill="#FEAC18"
  />
  <mask
    id="mask8_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-32"
    y="531"
    width="121"
    height="40"
  >
    <path
      d="M-27.3144 531.801H84.7391C86.862 531.801 88.5849 533.524 88.5849 535.647V570.428H-31.1602V535.647C-31.1602 533.524 -29.4373 531.801 -27.3144 531.801Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask8_587_2)">
    <path
      opacity="0.2"
      d="M-45.3652 554.996L89.918 523.266L99.873 565.709C100.358 567.776 99.0738 569.847 97.0071 570.332L-30.7879 600.306C-32.8546 600.79 -34.9254 599.506 -35.4102 597.44L-45.3652 554.996Z"
      fill="white"
    />
  </g>
  <path
    d="M164.07 549.561L164.239 489.238C164.255 483.273 159.411 478.43 153.429 478.43H125.355C119.385 478.43 114.545 483.254 114.545 489.207V549.561"
    fill="#B3B3B3"
  />
  <path
    d="M108.211 549.561H169.598V554.921C169.598 558.415 166.664 561.253 163.053 561.253H114.756C111.145 561.253 108.211 558.415 108.211 554.921V549.561Z"
    fill="#222222"
  />
  <path
    d="M14.4913 128.393H-29.2656C-31.3895 128.393 -33.1113 130.114 -33.1113 132.238V194.559C-33.1113 196.683 -31.3895 198.404 -29.2656 198.404H14.4913C16.6153 198.404 18.3371 196.683 18.3371 194.559V132.238C18.3371 130.114 16.6153 128.393 14.4913 128.393Z"
    fill="#FF3A52"
  />
  <mask
    id="mask9_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-34"
    y="128"
    width="53"
    height="71"
  >
    <path
      d="M14.4913 128.395H-29.2656C-31.3895 128.395 -33.1113 130.116 -33.1113 132.24V194.561C-33.1113 196.685 -31.3895 198.406 -29.2656 198.406H14.4913C16.6153 198.406 18.3371 196.685 18.3371 194.561V132.24C18.3371 130.116 16.6153 128.395 14.4913 128.395Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask9_587_2)">
    <path
      opacity="0.2"
      d="M-43.709 188.809L40.01 169.172L49.0061 207.527C49.4908 209.594 48.2069 211.665 46.1401 212.15L-30.0906 230.03C-32.1574 230.514 -34.2282 229.23 -34.7129 227.164L-43.709 188.809Z"
      fill="white"
    />
  </g>
  <path
    d="M-38.9238 465.762H20.0272V472.91C20.0272 475.15 18.2814 476.967 16.1304 476.967H-35.027C-37.1781 476.967 -38.9238 475.15 -38.9238 472.91V465.762Z"
    fill="#10293A"
  />
  <path
    d="M-34.0508 476.48H14.6691V483.318C14.6691 485.46 12.9309 487.199 10.7892 487.199H-30.1709C-32.3126 487.199 -34.0508 485.46 -34.0508 483.318V476.48Z"
    fill="#10293A"
  />
  <path
    d="M37.5684 466.736H95.0578V473.263C95.0578 475.308 93.3553 476.968 91.2576 476.968H41.3686C39.2708 476.968 37.5684 475.308 37.5684 473.263V466.736Z"
    fill="#10293A"
  />
  <path
    d="M42.4395 476.48H90.6721V483.318C90.6721 485.46 88.9513 487.199 86.831 487.199H46.2806C44.1603 487.199 42.4395 485.46 42.4395 483.318V476.48Z"
    fill="#10293A"
  />
  <path
    d="M112.109 289.883H171.06V296.41C171.06 298.454 169.315 300.114 167.164 300.114H116.006C113.855 300.114 112.109 298.454 112.109 296.41V289.883Z"
    fill="#222222"
  />
  <path
    d="M117.955 299.627H165.701V306.154C165.701 308.199 163.997 309.858 161.898 309.858H121.757C119.659 309.858 117.955 308.199 117.955 306.154V299.627Z"
    fill="#222222"
  />
  <path
    d="M39.0273 328.859H97.004V335.386C97.004 337.431 95.2871 339.091 93.1716 339.091H42.8597C40.7443 339.091 39.0273 337.431 39.0273 335.386V328.859Z"
    fill="#10293A"
  />
  <path
    d="M42.9258 339.09H91.6457V345.617C91.6457 347.662 89.9075 349.321 87.7658 349.321H46.8057C44.664 349.321 42.9258 347.662 42.9258 345.617V339.09Z"
    fill="#10293A"
  />
  <path
    d="M-37.9492 329.346H21.0018V336.183C21.0018 338.326 19.2561 340.064 17.105 340.064H-34.0524C-36.2034 340.064 -37.9492 338.326 -37.9492 336.183V329.346Z"
    fill="#10293A"
  />
  <path
    d="M-32.5898 339.09H15.1556V346.238C15.1556 348.478 13.4522 350.295 11.3533 350.295H-28.7875C-30.8864 350.295 -32.5898 348.478 -32.5898 346.238V339.09Z"
    fill="#10293A"
  />
  <path
    d="M39.0273 194.393H97.004V200.919C97.004 202.964 95.2871 204.624 93.1716 204.624H42.8597C40.7443 204.624 39.0273 202.964 39.0273 200.919V194.393Z"
    fill="#10293A"
  />
  <path
    d="M42.9258 204.137H91.6457V211.285C91.6457 213.525 89.9075 215.342 87.7658 215.342H46.8057C44.664 215.342 42.9258 213.525 42.9258 211.285V204.137Z"
    fill="#10293A"
  />
  <path
    d="M162.778 426.998C162.778 423.056 160.917 419.855 158.626 419.855C158.445 419.855 158.267 419.882 158.094 419.921C157.339 418.151 156.124 416.999 154.75 416.999C154.425 416.999 154.112 417.069 153.81 417.189C153.059 415.897 152.021 415.094 150.873 415.094C149.666 415.094 148.579 415.99 147.82 417.406C147.39 417.146 146.929 416.995 146.446 416.995C145.072 416.995 143.857 418.148 143.102 419.917C142.928 419.878 142.751 419.851 142.57 419.851C140.275 419.851 138.418 423.048 138.418 426.995C138.418 430.502 139.89 433.412 141.826 434.018C143.547 435.221 146.952 436.043 150.873 436.043C155.893 436.043 160.06 434.701 160.94 432.927C162.049 431.647 162.778 429.47 162.778 426.998Z"
    fill="#4D4D4D"
  />
  <path
    d="M112.109 427.273H171.06V433.8C171.06 435.845 169.315 437.505 167.164 437.505H116.006C113.855 437.505 112.109 435.845 112.109 433.8V427.273Z"
    fill="#222222"
  />
  <path
    d="M117.955 437.018H165.701V443.544C165.701 445.589 163.997 447.249 161.898 447.249H121.757C119.659 447.249 117.955 445.589 117.955 443.544V437.018Z"
    fill="#222222"
  />
  <path
    d="M-36.4863 194.393H21.4903V200.919C21.4903 202.964 19.7734 204.624 17.6579 204.624H-32.6539C-34.7694 204.624 -36.4863 202.964 -36.4863 200.919V194.393Z"
    fill="#10293A"
  />
  <path
    d="M-31.6152 204.137H16.6174V211.285C16.6174 213.525 14.8966 215.342 12.7763 215.342H-27.7741C-29.8944 215.342 -31.6152 213.525 -31.6152 211.285V204.137Z"
    fill="#10293A"
  />
  <path d="M126.446 145.768H124.639V158.032H126.446V145.768Z" fill="#4D4D4D" />
  <path
    d="M125.541 147.729C128.749 147.729 131.349 145.129 131.349 141.922C131.349 138.715 128.749 136.115 125.541 136.115C122.334 136.115 119.734 138.715 119.734 141.922C119.734 145.129 122.334 147.729 125.541 147.729Z"
    fill="#666666"
  />
  <path
    d="M125.546 145.765C127.71 145.765 129.465 144.011 129.465 141.847C129.465 139.682 127.71 137.928 125.546 137.928C123.381 137.928 121.627 139.682 121.627 141.847C121.627 144.011 123.381 145.765 125.546 145.765Z"
    fill="#808080"
  />
  <path d="M143.835 148.352H142.027V160.616H143.835V148.352Z" fill="#4D4D4D" />
  <path
    d="M142.93 150.313C146.137 150.313 148.737 147.713 148.737 144.506C148.737 141.299 146.137 138.699 142.93 138.699C139.723 138.699 137.123 141.299 137.123 144.506C137.123 147.713 139.723 150.313 142.93 150.313Z"
    fill="#666666"
  />
  <path
    d="M142.931 148.351C145.095 148.351 146.849 146.597 146.849 144.432C146.849 142.268 145.095 140.514 142.931 140.514C140.766 140.514 139.012 142.268 139.012 144.432C139.012 146.597 140.766 148.351 142.931 148.351Z"
    fill="#808080"
  />
  <path d="M161.696 145.939H159.889V158.204H161.696V145.939Z" fill="#4D4D4D" />
  <path
    d="M160.791 147.905C163.999 147.905 166.599 145.305 166.599 142.098C166.599 138.891 163.999 136.291 160.791 136.291C157.584 136.291 154.984 138.891 154.984 142.098C154.984 145.305 157.584 147.905 160.791 147.905Z"
    fill="#666666"
  />
  <path
    d="M160.796 145.941C162.96 145.941 164.715 144.187 164.715 142.022C164.715 139.858 162.96 138.104 160.796 138.104C158.631 138.104 156.877 139.858 156.877 142.022C156.877 144.187 158.631 145.941 160.796 145.941Z"
    fill="#808080"
  />
  <path
    d="M114.545 154.928H172.522V161.455C172.522 163.499 170.805 165.159 168.689 165.159H118.377C116.262 165.159 114.545 163.499 114.545 161.455V154.928Z"
    fill="#222222"
  />
  <path
    d="M118.93 165.16H167.65V172.309C167.65 174.548 165.911 176.366 163.77 176.366H122.81C120.668 176.366 118.93 174.548 118.93 172.309V165.16Z"
    fill="#222222"
  />
  <path
    d="M97.0059 588.535H-38.9226V582.008C-38.9226 579.963 -37.1991 578.304 -35.0755 578.304H93.1588C95.2824 578.304 97.0059 579.963 97.0059 582.008V588.535Z"
    fill="#10143A"
  />
  <path
    d="M18.9324 71.1309H103.946C106.299 71.1309 108.21 74.0876 108.21 77.7272V104.955C108.21 106.452 107.422 107.671 106.454 107.671H16.4239C15.4556 107.671 14.668 106.452 14.668 104.955V77.7272C14.668 74.0876 16.5794 71.1309 18.9324 71.1309Z"
    fill="#023566"
  />
  <path
    d="M564.14 45.7969H111.709C100.898 45.7969 92.1328 51.0261 92.1328 57.4767V622.166C92.1328 628.616 100.898 633.846 111.709 633.846H564.14C574.952 633.846 583.716 628.616 583.716 622.166V57.4767C583.716 51.0261 574.952 45.7969 564.14 45.7969Z"
    fill="#362C7B"
  />
  <path
    d="M394.918 62.793H306.114C301.803 62.793 298.309 66.2874 298.309 70.5979V197.077C298.309 201.388 301.803 204.882 306.114 204.882H394.918C399.229 204.882 402.723 201.388 402.723 197.077V70.5979C402.723 66.2874 399.229 62.793 394.918 62.793Z"
    fill="#FF3883"
  />
  <mask
    id="mask10_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="298"
    y="62"
    width="105"
    height="143"
  >
    <path
      d="M394.918 62.793H306.114C301.803 62.793 298.309 66.2874 298.309 70.5979V197.077C298.309 201.388 301.803 204.882 306.114 204.882H394.918C399.229 204.882 402.723 201.388 402.723 197.077V70.5979C402.723 66.2874 399.229 62.793 394.918 62.793Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask10_587_2)">
    <path
      opacity="0.2"
      d="M276.805 185.412L446.713 145.561L464.97 223.402C465.954 227.597 463.348 231.799 459.154 232.783L304.443 269.07C300.249 270.054 296.046 267.448 295.062 263.254L276.805 185.412Z"
      fill="white"
    />
  </g>
  <path
    d="M155.063 488.742H394.217C398.748 488.742 402.425 492.789 402.425 497.775V579.472H146.855V497.775C146.855 492.789 150.533 488.742 155.063 488.742Z"
    fill="#FEAC18"
  />
  <mask
    id="mask11_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="146"
    y="488"
    width="257"
    height="92"
  >
    <path
      d="M155.063 488.744H394.217C398.748 488.744 402.425 492.791 402.425 497.777V579.474H146.855V497.777C146.855 492.791 150.533 488.744 155.063 488.744Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask11_587_2)">
    <path
      opacity="0.2"
      d="M116.539 543.223L405.271 468.691L426.518 568.387C427.553 573.241 424.812 578.105 420.401 579.244L147.651 649.65C143.24 650.788 138.821 647.773 137.786 642.918L116.539 543.223Z"
      fill="white"
    />
  </g>
  <path
    d="M528.916 588.536L529.15 503.836C529.171 495.461 522.476 488.66 514.207 488.66H475.398C467.145 488.66 460.455 495.434 460.455 503.793V588.536"
    fill="#FEBD46"
  />
  <path
    d="M451.686 588.535H537.433V596.129C537.433 601.079 533.335 605.1 528.29 605.1H460.828C455.783 605.1 451.686 601.079 451.686 596.129V588.535Z"
    fill="#1D1743"
  />
  <path
    d="M210.024 547.519C208.955 546.91 207.715 546.71 206.366 546.827C204.694 539.648 201.018 534.648 196.74 534.648C195.119 534.648 193.583 535.391 192.207 536.677C190.264 532.044 187.327 529.098 184.033 529.098C180.74 529.098 177.803 532.052 175.859 536.677C174.484 535.391 172.948 534.648 171.326 534.648C167.116 534.648 163.492 539.49 161.786 546.493C160.36 546.326 159.044 546.518 157.915 547.16C152.848 550.04 153.484 560.441 159.333 570.407C164.205 578.712 171.157 584.062 176.275 583.954V584.638H193.218V584.204C198.124 583.353 204.21 578.245 208.607 570.757C214.455 560.791 215.092 550.391 210.024 547.511V547.519Z"
    fill="#185E45"
  />
  <path
    d="M297.835 546.058C296.801 545.449 295.603 545.249 294.298 545.366C292.681 538.187 289.127 533.187 284.99 533.187C283.423 533.187 281.937 533.93 280.607 535.216C278.728 530.583 275.888 527.637 272.703 527.637C269.519 527.637 266.679 530.592 264.799 535.216C263.47 533.93 261.984 533.187 260.416 533.187C256.346 533.187 252.841 538.029 251.191 545.032C249.812 544.865 248.54 545.057 247.448 545.7C242.548 548.579 243.164 558.98 248.819 568.946C253.53 577.251 260.252 582.601 265.202 582.493V583.177H281.584V582.743C286.328 581.892 292.213 576.784 296.464 569.296C302.12 559.33 302.735 548.93 297.835 546.05V546.058Z"
    fill="#185E45"
  />
  <path
    d="M385.53 547.519C384.496 546.91 383.298 546.71 381.993 546.827C380.376 539.648 376.822 534.648 372.685 534.648C371.118 534.648 369.632 535.391 368.303 536.677C366.423 532.044 363.583 529.098 360.399 529.098C357.214 529.098 354.374 532.052 352.495 536.677C351.165 535.391 349.679 534.648 348.112 534.648C344.041 534.648 340.536 539.49 338.886 546.493C337.507 546.326 336.235 546.518 335.144 547.161C330.244 550.04 330.859 560.441 336.514 570.407C341.226 578.712 347.948 584.062 352.897 583.954V584.638H369.279V584.204C374.023 583.353 379.908 578.245 384.16 570.757C389.815 560.791 390.43 550.391 385.53 547.511V547.519Z"
    fill="#185E45"
  />
  <path
    d="M244.332 65.2383H155.528C151.217 65.2383 147.723 68.7327 147.723 73.0432V199.523C147.723 203.833 151.217 207.328 155.528 207.328H244.332C248.643 207.328 252.137 203.833 252.137 199.523V73.0432C252.137 68.7327 248.643 65.2383 244.332 65.2383Z"
    fill="#FEAC18"
  />
  <mask
    id="mask12_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="147"
    y="65"
    width="106"
    height="143"
  >
    <path
      d="M244.328 65.2402H155.524C151.213 65.2402 147.719 68.7346 147.719 73.0452V199.525C147.719 203.835 151.213 207.33 155.524 207.33H244.328C248.639 207.33 252.133 203.835 252.133 199.525V73.0452C252.133 68.7346 248.639 65.2402 244.328 65.2402Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask12_587_2)">
    <path
      opacity="0.2"
      d="M126.213 187.854L296.121 148.002L314.378 225.843C315.362 230.038 312.756 234.241 308.562 235.225L153.851 271.512C149.657 272.495 145.454 269.89 144.47 265.695L126.213 187.854Z"
      fill="white"
    />
  </g>
  <path
    d="M186.295 178.469C186.014 178.431 185.726 178.469 185.445 178.568C185.473 177.983 185.494 177.39 185.494 176.782C185.494 169.64 183.363 163.842 180.741 163.842C179.89 163.842 179.095 164.458 178.406 165.522C177.928 159.739 176.036 155.416 173.772 155.416C171.677 155.416 169.905 159.109 169.272 164.222C168.709 163.576 168.084 163.219 167.437 163.219C164.814 163.219 162.683 169.009 162.683 176.159C162.683 176.873 162.712 177.572 162.754 178.256C162.247 177.968 161.748 177.854 161.263 177.937C158.682 178.401 157.649 184.479 158.964 191.508C160.272 198.536 163.429 203.862 166.009 203.391C166.305 203.338 166.579 203.201 166.832 203.011V204.09H181.409V203.27C181.816 203.748 182.28 204.052 182.794 204.121C185.395 204.463 188.285 198.999 189.256 191.918C190.226 184.836 188.897 178.819 186.295 178.477V178.469Z"
    fill="#185E45"
  />
  <path
    d="M139.879 199.264H258.268V212.628C258.268 216.815 254.762 220.213 250.442 220.213H147.705C143.385 220.213 139.879 216.815 139.879 212.628V199.264Z"
    fill="#1D1743"
  />
  <path
    d="M151.084 219.24H249.498V233.537C249.498 238.016 245.987 241.651 241.661 241.651H158.921C154.595 241.651 151.084 238.016 151.084 233.537V219.24Z"
    fill="#1D1743"
  />
  <path
    d="M301.141 218.752H400.529V232.738C400.529 237.12 396.983 240.676 392.614 240.676H309.056C304.687 240.676 301.141 237.12 301.141 232.738V218.752Z"
    fill="#1D1743"
  />
  <path
    d="M394.92 282.348H306.116C301.805 282.348 298.311 285.842 298.311 290.153V416.632C298.311 420.943 301.805 424.437 306.116 424.437H394.92C399.231 424.437 402.725 420.943 402.725 416.632V290.153C402.725 285.842 399.231 282.348 394.92 282.348Z"
    fill="#FEAC18"
  />
  <mask
    id="mask13_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="298"
    y="282"
    width="105"
    height="143"
  >
    <path
      d="M394.922 282.35H306.117C301.807 282.35 298.312 285.844 298.312 290.155V416.634C298.312 420.944 301.807 424.439 306.117 424.439H394.922C399.233 424.439 402.727 420.944 402.727 416.634V290.155C402.727 285.844 399.233 282.35 394.922 282.35Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask13_587_2)">
    <path
      opacity="0.2"
      d="M276.809 404.969L446.716 365.117L464.974 442.959C465.958 447.153 463.352 451.356 459.158 452.34L304.447 488.627C300.253 489.611 296.05 487.005 295.066 482.81L276.809 404.969Z"
      fill="white"
    />
  </g>
  <path
    d="M244.332 284.791H155.528C151.217 284.791 147.723 288.285 147.723 292.596V419.075C147.723 423.386 151.217 426.88 155.528 426.88H244.332C248.643 426.88 252.137 423.386 252.137 419.075V292.596C252.137 288.285 248.643 284.791 244.332 284.791Z"
    fill="#FF3883"
  />
  <mask
    id="mask14_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="147"
    y="284"
    width="106"
    height="143"
  >
    <path
      d="M244.328 284.797H155.524C151.213 284.797 147.719 288.291 147.719 292.602V419.081C147.719 423.392 151.213 426.886 155.524 426.886H244.328C248.639 426.886 252.133 423.392 252.133 419.081V292.602C252.133 288.291 248.639 284.797 244.328 284.797Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask14_587_2)">
    <path
      opacity="0.2"
      d="M126.213 407.408L296.121 367.557L314.378 445.398C315.362 449.593 312.756 453.795 308.562 454.779L153.851 491.066C149.657 492.05 145.454 489.444 144.47 485.25L126.213 407.408Z"
      fill="white"
    />
  </g>
  <path d="M316.471 398.322H312.803V423.212H316.471V398.322Z" fill="#185E45" />
  <path
    d="M314.637 402.303C321.146 402.303 326.423 397.027 326.423 390.518C326.423 384.009 321.146 378.732 314.637 378.732C308.128 378.732 302.852 384.009 302.852 390.518C302.852 397.027 308.128 402.303 314.637 402.303Z"
    fill="#FF3883"
  />
  <path
    d="M314.645 398.315C319.037 398.315 322.598 394.754 322.598 390.361C322.598 385.969 319.037 382.408 314.645 382.408C310.252 382.408 306.691 385.969 306.691 390.361C306.691 394.754 310.252 398.315 314.645 398.315Z"
    fill="#FF73B2"
  />
  <path d="M351.758 403.574H348.09V428.464H351.758V403.574Z" fill="#185E45" />
  <path
    d="M349.924 407.555C356.433 407.555 361.71 402.279 361.71 395.77C361.71 389.261 356.433 383.984 349.924 383.984C343.415 383.984 338.139 389.261 338.139 395.77C338.139 402.279 343.415 407.555 349.924 407.555Z"
    fill="#FF3883"
  />
  <path
    d="M349.924 403.576C354.316 403.576 357.877 400.016 357.877 395.623C357.877 391.231 354.316 387.67 349.924 387.67C345.531 387.67 341.971 391.231 341.971 395.623C341.971 400.016 345.531 403.576 349.924 403.576Z"
    fill="#FF73B2"
  />
  <path d="M380.045 187.674H376.377V212.564H380.045V187.674Z" fill="#185E45" />
  <path
    d="M378.211 191.655C384.72 191.655 389.997 186.378 389.997 179.869C389.997 173.361 384.72 168.084 378.211 168.084C371.702 168.084 366.426 173.361 366.426 179.869C366.426 186.378 371.702 191.655 378.211 191.655Z"
    fill="#9241E3"
  />
  <path
    d="M378.211 187.676C382.603 187.676 386.164 184.115 386.164 179.723C386.164 175.33 382.603 171.77 378.211 171.77C373.819 171.77 370.258 175.33 370.258 179.723C370.258 184.115 373.819 187.676 378.211 187.676Z"
    fill="#614ECE"
  />
  <path d="M361.532 176.957H357.863V201.847H361.532V176.957Z" fill="#185E45" />
  <path
    d="M359.698 180.938C366.207 180.938 371.483 175.662 371.483 169.153C371.483 162.644 366.207 157.367 359.698 157.367C353.189 157.367 347.912 162.644 347.912 169.153C347.912 175.662 353.189 180.938 359.698 180.938Z"
    fill="#9241E3"
  />
  <path
    d="M359.697 176.959C364.09 176.959 367.651 173.398 367.651 169.006C367.651 164.614 364.09 161.053 359.697 161.053C355.305 161.053 351.744 164.614 351.744 169.006C351.744 173.398 355.305 176.959 359.697 176.959Z"
    fill="#614ECE"
  />
  <path d="M388.012 398.676H384.344V423.566H388.012V398.676Z" fill="#185E45" />
  <path
    d="M386.178 402.665C392.687 402.665 397.964 397.388 397.964 390.879C397.964 384.37 392.687 379.094 386.178 379.094C379.669 379.094 374.393 384.37 374.393 390.879C374.393 397.388 379.669 402.665 386.178 402.665Z"
    fill="#FF3883"
  />
  <path
    d="M386.186 398.676C390.578 398.676 394.139 395.115 394.139 390.723C394.139 386.33 390.578 382.77 386.186 382.77C381.793 382.77 378.232 386.33 378.232 390.723C378.232 395.115 381.793 398.676 386.186 398.676Z"
    fill="#FF73B2"
  />
  <path
    d="M292.371 417.529H409.299V430.583C409.299 434.673 405.836 437.992 401.57 437.992H300.1C295.834 437.992 292.371 434.673 292.371 430.583V417.529Z"
    fill="#1D1743"
  />
  <path
    d="M301.141 437.992H400.529V452.289C400.529 456.768 396.983 460.403 392.614 460.403H309.056C304.687 460.403 301.141 456.768 301.141 452.289V437.992Z"
    fill="#1D1743"
  />
  <path
    d="M545.215 62.793H456.41C452.1 62.793 448.605 66.2874 448.605 70.5979V197.077C448.605 201.388 452.1 204.882 456.41 204.882H545.215C549.526 204.882 553.02 201.388 553.02 197.077V70.5979C553.02 66.2874 549.526 62.793 545.215 62.793Z"
    fill="#FEAC18"
  />
  <mask
    id="mask15_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="448"
    y="62"
    width="106"
    height="143"
  >
    <path
      d="M545.215 62.793H456.41C452.1 62.793 448.605 66.2874 448.605 70.5979V197.077C448.605 201.388 452.1 204.882 456.41 204.882H545.215C549.526 204.882 553.02 201.388 553.02 197.077V70.5979C553.02 66.2874 549.526 62.793 545.215 62.793Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask15_587_2)">
    <path
      opacity="0.2"
      d="M427.102 185.412L597.009 145.561L615.267 223.402C616.251 227.597 613.645 231.799 609.451 232.783L454.74 269.07C450.546 270.054 446.343 267.448 445.359 263.254L427.102 185.412Z"
      fill="white"
    />
  </g>
  <path
    d="M451.686 218.752H550.587V232.738C550.587 237.12 547.058 240.676 542.711 240.676H459.562C455.214 240.676 451.686 237.12 451.686 232.738V218.752Z"
    fill="#1D1743"
  />
  <path
    d="M545.215 282.348H456.41C452.1 282.348 448.605 285.842 448.605 290.153V416.632C448.605 420.943 452.1 424.437 456.41 424.437H545.215C549.526 424.437 553.02 420.943 553.02 416.632V290.153C553.02 285.842 549.526 282.348 545.215 282.348Z"
    fill="#FF3883"
  />
  <mask
    id="mask16_587_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="448"
    y="282"
    width="106"
    height="143"
  >
    <path
      d="M545.215 282.35H456.41C452.1 282.35 448.605 285.844 448.605 290.155V416.634C448.605 420.944 452.1 424.439 456.41 424.439H545.215C549.526 424.439 553.02 420.944 553.02 416.634V290.155C553.02 285.844 549.526 282.35 545.215 282.35Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask16_587_2)">
    <path
      opacity="0.2"
      d="M427.102 404.969L597.009 365.117L615.267 442.959C616.251 447.153 613.645 451.356 609.451 452.34L454.74 488.627C450.546 489.611 446.343 487.005 445.359 482.81L427.102 404.969Z"
      fill="white"
    />
  </g>
  <path
    d="M417.582 605.102H228.549V595.777C228.549 592.856 230.946 590.486 233.899 590.486H412.232C415.185 590.486 417.582 592.856 417.582 595.777V605.102Z"
    fill="#1D1743"
  />
  <path
    d="M410.273 591.945H234.882V582.31C234.882 579.292 237.307 576.842 240.295 576.842H404.86C407.848 576.842 410.273 579.292 410.273 582.31V591.945Z"
    fill="#1D1743"
  />
  <path
    d="M316.244 591.945H140.853V582.31C140.853 579.292 143.278 576.842 146.266 576.842H310.831C313.819 576.842 316.244 579.292 316.244 582.31V591.945Z"
    fill="#1D1743"
  />
  <path
    d="M306.988 605.588H131.597V595.953C131.597 592.934 134.022 590.485 137.01 590.485H301.575C304.563 590.485 306.988 592.934 306.988 595.953V605.588Z"
    fill="#1D1743"
  />
  <path
    d="M130.907 1.46094H408.528C416.212 1.46094 422.454 5.60034 422.454 10.6958V48.8142C422.454 50.9111 419.882 52.6168 416.72 52.6168H122.715C119.553 52.6168 116.98 50.9111 116.98 48.8142V10.6958C116.98 5.60034 123.223 1.46094 130.907 1.46094Z"
    fill="#362C7B"
  />
  <path
    d="M-62.3086 103.645C-62.3086 95.9125 -56.0406 89.6445 -48.3086 89.6445H91.6462V118.389H-62.3086V103.645Z"
    fill="#10293A"
  />
  <path
    d="M84.3379 38.3594C84.3379 30.6274 90.6059 24.3594 98.3379 24.3594H577.999C585.731 24.3594 591.999 30.6274 591.999 38.3594V52.6169H84.3379V38.3594Z"
    fill="#1D1743"
  />
  <g clip-path="url(#clip0_587_2)">
    <path
      d="M31.1909 568.168L29.8902 570.469L28.5894 568.168C24.2995 560.586 24.8053 551.233 29.8877 544.15C34.9702 551.233 35.476 560.586 31.1885 568.168H31.1909Z"
      fill="#185E45"
    />
    <path
      d="M29.1653 567.381L29.8879 569.921L27.325 569.205C18.8848 566.849 12.5691 559.881 11.1094 551.311C19.7567 552.757 26.7878 559.016 29.1653 567.381Z"
      fill="#185E45"
    />
    <path
      d="M32.4468 569.259L29.8887 569.996L30.5896 567.449C32.8972 559.065 39.8777 552.749 48.5106 551.23C47.1232 559.812 40.8653 566.831 32.4443 569.259H32.4468Z"
      fill="#185E45"
    />
  </g>
  <g clip-path="url(#clip1_587_2)">
    <path
      d="M71.3588 568.168L70.0581 570.469L68.7574 568.168C64.4674 560.586 64.9733 551.233 70.0557 544.15C75.1381 551.233 75.644 560.586 71.3564 568.168H71.3588Z"
      fill="#185E45"
    />
    <path
      d="M69.3332 567.381L70.0559 569.921L67.493 569.205C59.0527 566.849 52.737 559.881 51.2773 551.311C59.9247 552.757 66.9558 559.016 69.3332 567.381Z"
      fill="#185E45"
    />
    <path
      d="M72.6147 569.259L70.0566 569.996L70.7576 567.449C73.0652 559.065 80.0457 552.749 88.6786 551.23C87.2912 559.812 81.0333 566.831 72.6123 569.259H72.6147Z"
      fill="#185E45"
    />
  </g>
  <g clip-path="url(#clip2_587_2)">
    <path
      d="M-9.8052 569.259L-12.3633 569.996L-11.6623 567.449C-9.35477 559.065 -2.37424 552.749 6.25867 551.23C4.87124 559.812 -1.38666 566.831 -9.80761 569.259H-9.8052Z"
      fill="#185E45"
    />
  </g>
  <path
    d="M91.6465 578.793H-34.0508V571.644C-34.0508 569.405 -32.3128 567.587 -30.1714 567.587H87.7671C89.9085 567.587 91.6465 569.405 91.6465 571.644V578.793Z"
    fill="#10143A"
  />
  <path
    d="M442.43 197.803H559.845V211.167C559.845 215.354 556.367 218.752 552.083 218.752H450.191C445.907 218.752 442.43 215.354 442.43 211.167V197.803Z"
    fill="#1D1743"
  />
  <circle
    cx="11.6476"
    cy="11.6476"
    r="11.6476"
    transform="matrix(-1 0 0 1 232.67 368.322)"
    fill="#185E45"
  />
  <circle
    cx="11.6476"
    cy="11.6476"
    r="11.6476"
    transform="matrix(-1 0 0 1 226.51 376.408)"
    fill="#185E45"
  />
  <ellipse
    cx="10.7813"
    cy="10.8775"
    rx="10.7813"
    ry="10.8775"
    transform="matrix(-1 0 0 1 239.217 372.365)"
    fill="#185E45"
  />
  <rect
    width="5.76666"
    height="21.9476"
    rx="2.88333"
    transform="matrix(-1 0 0 1 226.51 399.703)"
    fill="#185E45"
  />
  <circle
    cx="11.6476"
    cy="11.6476"
    r="11.6476"
    transform="matrix(-1 0 0 1 226.51 390.848)"
    fill="#185E45"
  />
  <ellipse
    cx="11.6476"
    cy="11.5514"
    rx="11.6476"
    ry="11.5514"
    transform="matrix(-1 0 0 1 244.223 385.457)"
    fill="#185E45"
  />
  <ellipse
    cx="11.6476"
    cy="11.5514"
    rx="11.6476"
    ry="11.5514"
    transform="matrix(-1 0 0 1 244.223 385.457)"
    fill="#185E45"
  />
  <path
    d="M139.879 418.992H258.268V432.046C258.268 436.136 254.762 439.455 250.442 439.455H147.705C143.385 439.455 139.879 436.136 139.879 432.046V418.992Z"
    fill="#1D1743"
  />
  <path
    d="M151.084 438.965H249.498V452.951C249.498 457.333 245.987 460.889 241.661 460.889H158.921C154.595 460.889 151.084 457.333 151.084 452.951V438.965Z"
    fill="#1D1743"
  />
  <path
    d="M292.371 197.803H409.299V211.167C409.299 215.354 405.836 218.752 401.57 218.752H300.1C295.834 218.752 292.371 215.354 292.371 211.167V197.803Z"
    fill="#1D1743"
  />
  <path
    d="M526.43 426.226C531.138 426.226 534.955 422.906 534.955 418.809C534.955 414.713 531.138 411.393 526.43 411.393C521.721 411.393 517.904 414.713 517.904 418.809C517.904 422.906 521.721 426.226 526.43 426.226Z"
    fill="#185E45"
  />
  <path
    d="M514.249 429.323C520.924 429.323 526.335 424.616 526.335 418.809C526.335 413.002 520.924 408.295 514.249 408.295C507.575 408.295 502.164 413.002 502.164 418.809C502.164 424.616 507.575 429.323 514.249 429.323Z"
    fill="#185E45"
  />
  <path
    d="M497.2 427.529C503.15 427.529 507.973 423.333 507.973 418.156C507.973 412.98 503.15 408.783 497.2 408.783C491.249 408.783 486.426 412.98 486.426 418.156C486.426 423.333 491.249 427.529 497.2 427.529Z"
    fill="#185E45"
  />
  <path
    d="M509.471 439.43C509.175 439.43 508.873 439.368 508.592 439.239C503.608 436.933 502.285 434.768 501.176 432.38C500.657 431.263 500.406 430.205 500.185 429.273C499.838 427.817 499.565 426.668 498.45 425.45C497.567 424.487 496.362 423.718 494.869 423.162C493.917 422.808 493.475 421.849 493.882 421.023C494.288 420.195 495.39 419.812 496.34 420.164C498.41 420.933 500.106 422.029 501.378 423.418C503.05 425.243 503.477 427.033 503.852 428.611C504.06 429.486 504.256 430.315 504.648 431.159C505.427 432.838 506.162 434.424 510.348 436.36C511.262 436.783 511.607 437.771 511.122 438.566C510.784 439.117 510.136 439.43 509.465 439.43H509.471Z"
    fill="#185E45"
  />
  <path
    d="M508.532 455.079C507.616 455.079 506.816 454.493 506.681 453.683C505.922 449.13 506.053 444.556 507.072 440.092C507.207 439.5 507.336 438.877 507.473 438.217C508.509 433.201 509.93 426.332 516.75 424.355C517.595 424.11 518.706 423.44 519.883 422.733C521.105 421.998 522.487 421.165 524.026 420.521C525.907 419.733 527.558 418.482 528.935 416.804C529.536 416.072 530.705 415.901 531.549 416.424C532.392 416.948 532.587 417.965 531.985 418.698C530.237 420.827 528.107 422.43 525.652 423.458C524.38 423.991 523.179 424.715 522.017 425.414C520.614 426.259 519.289 427.056 517.938 427.449C513.227 428.815 512.131 434.117 511.165 438.796C511.024 439.471 510.893 440.108 510.752 440.727C509.815 444.829 509.696 449.031 510.394 453.217C510.542 454.109 509.834 454.935 508.809 455.064C508.717 455.075 508.628 455.08 508.538 455.08L508.532 455.079Z"
    fill="#185E45"
  />
  <path
    d="M514.719 427.204C513.953 427.204 513.233 426.791 512.958 426.129C512.171 424.242 511.157 422.424 509.943 420.731C507.923 417.909 505.356 415.416 502.313 413.323C501.502 412.764 501.363 411.741 502.006 411.033C502.649 410.327 503.827 410.207 504.639 410.766C508.019 413.092 510.874 415.865 513.123 419.008C514.474 420.895 515.603 422.917 516.479 425.019C516.831 425.865 516.329 426.801 515.356 427.107C515.146 427.174 514.929 427.205 514.717 427.205L514.719 427.204Z"
    fill="#185E45"
  />
  <path
    d="M534.768 424.678C540.977 424.678 546.01 420.299 546.01 414.898C546.01 409.496 540.977 405.117 534.768 405.117C528.559 405.117 523.525 409.496 523.525 414.898C523.525 420.299 528.559 424.678 534.768 424.678Z"
    fill="#185E45"
  />
  <path
    d="M486.52 418.89C491.228 418.89 495.045 415.57 495.045 411.474C495.045 407.377 491.228 404.057 486.52 404.057C481.811 404.057 477.994 407.377 477.994 411.474C477.994 415.57 481.811 418.89 486.52 418.89Z"
    fill="#185E45"
  />
  <path
    d="M522.869 419.867C527.578 419.867 531.395 416.546 531.395 412.45C531.395 408.354 527.578 405.033 522.869 405.033C518.161 405.033 514.344 408.354 514.344 412.45C514.344 416.546 518.161 419.867 522.869 419.867Z"
    fill="#185E45"
  />
  <path
    d="M523.15 398.842H497.106C492.552 398.842 488.861 402.053 488.861 406.014C488.861 409.975 492.552 413.187 497.106 413.187H523.15C527.703 413.187 531.395 409.975 531.395 406.014C531.395 402.053 527.703 398.842 523.15 398.842Z"
    fill="#185E45"
  />
  <path
    d="M506.288 449.535C506.2 450.691 505.855 452.437 504.414 453.773C501.532 456.445 496.159 455.723 496.17 456.055C496.177 456.3 499.102 457.051 502.072 456.137C503.884 455.578 504.493 454.774 505.82 454.996C507.155 455.219 507.395 456.178 508.817 456.3C510.007 456.403 510.206 455.764 511.441 455.648C513.451 455.461 514.109 457.047 516.874 457.767C519.207 458.373 521.36 457.927 521.371 457.767C521.383 457.601 519.093 457.757 516.312 456.789C515.093 456.364 512.108 455.325 510.691 452.714C510.056 451.542 509.947 450.445 509.942 449.78L506.288 449.535Z"
    fill="#185E45"
  />
  <path
    d="M442.43 417.529H559.845V430.583C559.845 434.673 556.367 437.992 552.083 437.992H450.191C445.907 437.992 442.43 434.673 442.43 430.583V417.529Z"
    fill="#1D1743"
  />
  <path
    d="M451.686 437.992H550.587V452.289C550.587 456.768 547.058 460.403 542.711 460.403H459.562C455.214 460.403 451.686 456.768 451.686 452.289V437.992Z"
    fill="#1D1743"
  />
  <defs>
    <clipPath id="clip0_587_2">
      <rect
        width="37.4005"
        height="26.3188"
        fill="white"
        transform="translate(11.1094 544.15)"
      />
    </clipPath>
    <clipPath id="clip1_587_2">
      <rect
        width="37.4005"
        height="26.3188"
        fill="white"
        transform="translate(51.2773 544.15)"
      />
    </clipPath>
    <clipPath id="clip2_587_2">
      <rect
        width="37.4005"
        height="26.3188"
        fill="white"
        transform="translate(-31.1426 544.15)"
      />
    </clipPath>
  </defs>
</svg>
