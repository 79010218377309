<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Property 1=Variant2">
<g id="Ellipse 117">
<path d="M33.2284 29.6606C27.7854 35.5346 18.6051 35.8785 12.7237 30.4286C6.84224 24.9788 6.48679 15.7989 11.9298 9.92491C17.3727 4.0509 26.553 3.70705 32.4345 9.15692C38.3159 14.6068 38.6714 23.7866 33.2284 29.6606Z" fill="#FBD90A"/>
<path d="M33.2284 29.6606C27.7854 35.5346 18.6051 35.8785 12.7237 30.4286C6.84224 24.9788 6.48679 15.7989 11.9298 9.92491C17.3727 4.0509 26.553 3.70705 32.4345 9.15692C38.3159 14.6068 38.6714 23.7866 33.2284 29.6606Z" fill="#FBD90A"/>
</g>
<path id="Vector 23" d="M26.9995 38.9985C32.5 41 31.1838 33.8972 30.9994 31.8702C30.8602 30.8384 26.2421 26.0361 25.1624 26.1343C23.8128 26.2571 23.2487 27.5346 23.5071 31.871C23.6201 33.7672 25.726 38.5352 26.9995 38.9985Z" fill="#F8951D"/>
<path id="Vector 22" d="M36.2205 33.3566C34.2299 31.5121 36.0127 27.9911 33.7236 25.87C31.6646 23.9621 29.1479 25.8793 28.1675 25.3403C27.1871 24.8014 25.6391 24.4755 24.7181 25.4695C23.7971 26.4636 24.085 27.8388 27.2254 30.7488C32.5999 35.7289 35.2933 39.1484 36.767 37.558C37.2276 37.061 37.8129 34.8322 36.2205 33.3566Z" fill="#F99C1C"/>
<ellipse id="Ellipse 118" cx="24.7972" cy="17.599" rx="3.25645" ry="3.25234" transform="rotate(42.8188 24.7972 17.599)" fill="black"/>
<ellipse id="Ellipse 119" cx="26.15" cy="18.1497" rx="0.814113" ry="0.813084" transform="rotate(42.8188 26.15 18.1497)" fill="white"/>
</g>
</svg>
