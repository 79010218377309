<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 680 178"
  enable-background="new 0 0 680 178"
  xml:space="preserve"
>
  <path
    fill="#FDFDFD"
    opacity="1.000000"
    stroke="none"
    d="
M491.000000,179.000000 
	C327.333344,179.000000 164.166702,179.000000 1.000044,179.000000 
	C1.000029,119.666695 1.000029,60.333385 1.000015,1.000060 
	C227.666611,1.000040 454.333221,1.000040 680.999878,1.000020 
	C680.999878,60.333271 680.999878,119.666542 680.999939,178.999908 
	C617.833313,179.000000 554.666687,179.000000 491.000000,179.000000 
M656.011658,88.602745 
	C626.194458,67.629608 596.394287,46.632095 566.502075,25.766428 
	C565.217163,24.869522 563.083008,25.189161 561.344421,24.942093 
	C561.607178,27.372576 560.997803,30.511028 562.291443,32.104660 
	C566.681458,37.512611 571.775330,42.345352 576.543640,47.450790 
	C587.088257,58.741047 597.598511,70.063492 608.596863,81.882248 
	C600.084473,84.719994 592.201660,87.302116 584.351685,89.980377 
	C578.872437,91.849785 578.107849,95.364548 582.292847,99.404625 
	C602.776550,119.178810 623.348267,138.862610 643.697510,158.773972 
	C646.192444,161.215195 648.730530,161.372681 650.896667,159.993668 
	C651.802673,159.416855 651.535217,155.802734 650.780029,153.996521 
	C643.722961,137.117004 636.443909,120.330292 629.070801,103.147835 
	C630.892944,102.351143 632.516724,101.566437 634.194092,100.921082 
	C640.562927,98.470802 647.022705,96.234764 653.273010,93.515221 
	C654.697327,92.895508 655.419556,90.662346 656.011658,88.602745 
M69.844139,64.680817 
	C63.484310,55.200420 54.224602,52.196888 43.380936,53.087345 
	C32.627430,53.970394 24.821360,59.395939 20.781725,69.415779 
	C15.914002,81.489601 15.852329,93.811424 20.578518,105.981499 
	C23.642439,113.871193 29.138433,119.596054 37.499523,121.634972 
	C41.209648,122.539711 42.345757,124.087395 42.146561,127.711433 
	C41.875027,132.651627 43.708363,136.894516 48.957859,137.923157 
	C53.251827,138.764557 57.759743,138.514069 62.233055,138.750854 
	C62.233055,135.202438 62.233055,132.480865 62.233055,129.572983 
	C58.745586,129.370682 55.668465,129.192200 52.733540,129.021957 
	C51.700092,124.869804 52.241703,122.447540 56.693832,121.263702 
	C63.653881,119.412971 68.733383,114.679466 71.558754,108.114624 
	C77.758850,93.708466 77.669975,79.348244 69.844139,64.680817 
M171.285126,101.922264 
	C174.027817,106.493324 176.775223,111.061569 179.512115,115.636101 
	C183.615128,122.493996 186.774994,123.616318 195.272873,120.894234 
	C190.134857,112.812866 185.351791,104.628235 179.878693,96.934471 
	C176.697281,92.462234 176.596970,89.477272 180.817154,85.656128 
	C185.229172,81.661285 188.888275,76.834900 193.187103,72.024437 
	C185.518433,70.286583 180.070770,71.420723 175.776016,77.566498 
	C171.960129,83.027023 167.192184,87.822243 161.951309,93.956535 
	C161.951309,81.272491 161.947845,70.005630 161.952393,58.738773 
	C161.956177,49.357628 161.960342,49.357628 151.886765,50.571583 
	C151.886765,74.234955 151.886765,97.929092 151.886765,121.578743 
	C162.056198,122.619949 162.424072,122.600616 161.911499,112.846367 
	C161.587860,106.687523 163.608170,102.280159 168.750961,98.710335 
	C169.448105,99.528610 170.165100,100.370201 171.285126,101.922264 
M140.387039,81.011917 
	C143.292404,78.941254 142.545090,77.189400 140.149216,75.176941 
	C133.492630,69.585602 120.239105,68.400940 112.767868,72.930649 
	C107.379997,76.197250 105.191422,81.042313 105.675713,87.273399 
	C106.126801,93.077156 109.113541,97.052895 114.517334,98.751968 
	C118.759293,100.085732 123.258713,100.588776 127.628372,101.532104 
	C131.132309,102.288536 134.164841,103.793335 133.838776,108.018234 
	C133.513214,112.236473 130.581345,113.846527 126.664200,114.202255 
	C120.209000,114.788475 114.957542,112.414230 110.481613,107.277306 
	C108.147163,109.507835 106.077621,111.485245 104.053398,113.419357 
	C110.892403,122.128563 122.310577,125.261673 132.815872,121.677444 
	C140.377670,119.097481 144.481186,113.455170 144.229797,105.983444 
	C144.000275,99.162102 140.202942,94.955185 132.058441,92.900238 
	C128.684296,92.048912 125.174652,91.749512 121.764984,91.019844 
	C118.383057,90.296135 115.736969,88.527397 116.033066,84.639359 
	C116.346817,80.519539 119.433441,78.688339 122.996201,79.086052 
	C126.817352,79.512589 130.856903,80.501587 134.147049,82.386719 
	C136.887405,83.956856 138.248978,83.640068 140.387039,81.011917 
M230.872803,70.338921 
	C228.518661,70.846764 226.164536,71.354607 223.463882,71.937202 
	C223.585083,74.696228 223.696579,77.234261 223.810135,79.819496 
	C226.525101,79.990005 228.633728,80.122429 231.085480,80.276405 
	C231.171768,82.175240 231.303040,83.790421 231.308884,85.406044 
	C231.339600,93.899216 231.253510,102.393745 231.377213,110.885330 
	C231.466400,117.008644 234.051682,120.780197 239.191055,121.474838 
	C243.204742,122.017334 247.350372,121.583473 251.403992,121.583473 
	C251.403992,118.508163 251.403992,115.953964 251.403992,113.249557 
	C248.211761,113.084564 245.439697,112.941284 242.427094,112.785576 
	C242.427094,101.801933 242.427094,91.115265 242.427094,80.263603 
	C245.879288,80.081940 248.952576,79.920219 252.069595,79.756195 
	C252.069595,76.731140 252.069595,74.172462 252.069595,71.478386 
	C248.609619,71.295036 245.528091,71.131744 241.824463,70.935478 
	C241.824463,66.318390 241.824463,62.097393 241.824463,57.730576 
	C238.415268,57.730576 235.667999,57.730576 232.801331,57.730576 
	C232.345306,61.898266 231.918228,65.801399 230.872803,70.338921 
M286.373077,138.759735 
	C290.062836,138.746902 288.948975,135.924149 288.952881,134.035629 
	C289.009186,106.706139 288.992920,79.376495 288.973419,52.046898 
	C288.972656,50.945049 288.768036,49.843353 288.647705,48.633942 
	C285.889130,48.633942 283.475189,48.633942 280.899078,48.633942 
	C280.754730,49.539822 280.566742,50.170559 280.566345,50.801414 
	C280.549011,78.797554 280.618805,106.794075 280.467804,134.789429 
	C280.445618,138.901749 282.590302,139.011627 286.373077,138.759735 
M377.912201,112.636543 
	C379.825684,113.391182 381.699585,114.305016 383.678345,114.797195 
	C384.225983,114.933411 385.487274,113.936340 385.695496,113.241699 
	C385.931335,112.454872 385.492828,110.685486 385.104340,110.610954 
	C380.496094,109.726974 382.165253,106.156952 382.029968,103.514626 
	C381.843079,99.864540 382.025726,96.197121 381.971313,92.538307 
	C381.871979,85.861977 379.894623,82.307564 374.891541,80.691490 
	C369.055573,78.806381 363.419189,78.932121 358.613983,83.530434 
	C356.024872,86.008072 356.759857,87.638832 359.569733,88.991829 
	C365.096252,84.390213 369.509155,83.315132 373.251007,85.681190 
	C376.838287,87.949509 376.468475,91.335449 376.081421,94.802353 
	C371.471375,95.137093 367.287842,95.084396 363.248535,95.821693 
	C357.836212,96.809624 355.192291,100.079735 355.095032,105.017380 
	C355.013489,109.156067 356.470795,112.484627 360.420471,114.378021 
	C365.125397,116.633469 370.697205,115.934723 374.499298,112.797638 
	C375.238983,112.187332 376.513489,112.225235 377.912201,112.636543 
M94.934647,91.500267 
	C94.934647,84.931770 94.934647,78.363274 94.934647,71.633980 
	C92.885117,71.511024 91.571083,71.535934 90.291908,71.336845 
	C85.647614,70.614037 83.900993,72.218849 84.114159,77.199219 
	C84.518471,86.645294 84.227646,96.121101 84.226265,105.584564 
	C84.225502,110.847977 84.226128,116.111397 84.226128,121.612213 
	C88.051224,121.612213 91.246849,121.612213 94.934441,121.612213 
	C94.934441,111.679222 94.934441,102.089699 94.934647,91.500267 
M204.281799,121.001976 
	C206.998672,121.309242 209.724625,121.869133 212.426926,121.771889 
	C213.251755,121.742203 214.689377,119.912048 214.699249,118.895317 
	C214.843079,104.107285 214.837906,89.316887 214.689178,74.528862 
	C214.678650,73.481102 213.383163,71.684258 212.488556,71.550476 
	C209.819641,71.151375 207.052795,71.407188 204.085281,71.407188 
	C204.085281,88.212410 204.085281,104.153656 204.281799,121.001976 
M487.075531,114.671165 
	C493.384033,116.407425 499.299622,116.082184 504.235016,111.188835 
	C506.780548,108.664948 506.710297,106.665291 502.919769,105.642120 
	C497.593201,110.742340 493.161041,112.111671 488.695068,109.788780 
	C484.577789,107.647247 482.841248,104.185402 483.696655,98.988052 
	C490.602783,98.988052 497.390289,99.097359 504.167969,98.888374 
	C505.403900,98.850273 507.444519,97.713737 507.674438,96.743309 
	C508.980011,91.232445 504.328674,83.167473 498.839325,80.771088 
	C492.755615,78.115219 485.308411,79.937042 481.235199,85.077568 
	C474.355774,93.759636 476.928680,108.411682 487.075531,114.671165 
M457.632843,93.921265 
	C460.793427,93.922752 463.957275,93.834625 467.113800,93.948769 
	C470.922272,94.086494 471.836029,92.472946 470.253143,88.648300 
	C464.046448,88.648300 457.782959,88.648300 451.325989,88.648300 
	C451.325989,83.427132 451.325989,78.710358 451.325989,73.531075 
	C457.773438,73.531075 463.904602,73.642036 470.023712,73.419914 
	C471.022736,73.383636 472.359558,72.032608 472.776306,70.985268 
	C472.989532,70.449371 471.528534,68.469749 470.795410,68.453651 
	C462.376678,68.268707 453.952423,68.335823 445.512207,68.335823 
	C445.512207,84.256210 445.512207,99.436821 445.512207,114.460823 
	C450.102631,115.942009 451.309387,114.103485 451.053680,110.163834 
	C450.827942,106.685188 451.296478,103.149757 450.933136,99.694656 
	C450.452606,95.125366 452.027435,93.134590 457.632843,93.921265 
M338.491333,68.330383 
	C333.739838,68.330383 328.988342,68.330383 324.189392,68.330383 
	C324.189392,84.236526 324.189392,99.306984 324.189392,114.372192 
	C328.254120,115.794167 330.060059,114.795349 329.798096,110.472290 
	C329.567139,106.660591 329.975281,102.807693 329.701080,99.001816 
	C329.430145,95.241661 330.400208,93.453506 334.586121,93.852386 
	C338.377777,94.213684 342.232635,93.857292 346.056213,93.952942 
	C350.070312,94.053352 350.484100,92.183601 349.007233,88.584686 
	C342.701630,88.584686 336.462860,88.584686 330.118317,88.584686 
	C330.118317,83.371841 330.118317,78.656532 330.118317,73.547905 
	C336.593475,73.547905 342.727722,73.657639 348.849915,73.435165 
	C349.820709,73.399887 350.737122,71.867905 351.678711,71.027908 
	C350.773285,70.130493 349.939697,68.565964 348.949066,68.459221 
	C345.824615,68.122566 342.641235,68.332512 338.491333,68.330383 
M536.389038,82.786095 
	C532.145813,79.288582 527.222168,78.983681 522.213501,80.206757 
	C517.571716,81.340233 514.715820,84.357231 514.527405,89.397301 
	C514.337769,94.469582 516.879883,97.568718 521.641724,98.864227 
	C524.514526,99.645813 527.698730,99.683968 530.284912,100.974373 
	C531.968018,101.814171 533.744568,104.291283 533.771118,106.060493 
	C533.793091,107.527641 531.272461,110.319908 529.958618,110.273018 
	C526.360657,110.144615 522.345703,109.585724 519.359314,107.774017 
	C516.247620,105.886261 515.091248,106.513496 513.679810,109.062454 
	C518.496033,115.182343 525.946167,117.328690 532.828979,114.664558 
	C537.439880,112.879807 539.501953,109.346130 539.407104,104.547432 
	C539.310852,99.677170 536.462769,96.950310 531.959656,95.815369 
	C529.385010,95.166473 526.532104,95.203857 524.216003,94.082428 
	C522.461060,93.232697 520.029541,90.782204 520.277161,89.505096 
	C520.638245,87.642448 523.047607,84.960884 524.738342,84.842842 
	C527.635010,84.640633 530.633850,86.229591 533.626526,86.903061 
	C534.725220,87.150314 535.928345,86.933769 537.083801,86.928780 
	C537.019592,85.694969 536.955383,84.461151 536.389038,82.786095 
M549.978394,98.513115 
	C549.981628,101.667770 549.904297,104.825035 550.006226,107.976501 
	C550.174927,113.194305 551.777466,114.960960 556.935059,114.853676 
	C558.857300,114.813698 562.224609,113.788269 562.399536,112.774895 
	C563.050903,109.001114 559.622253,110.268410 557.586243,109.861542 
	C557.114380,109.767250 556.667786,109.546165 556.054565,109.328377 
	C556.054565,101.473015 556.054565,93.632423 556.054565,85.686790 
	C558.524353,85.435249 560.619141,85.221909 562.682190,85.011795 
	C564.737122,77.648735 557.932068,81.713791 556.197510,79.461731 
	C553.876831,77.201752 558.791138,70.382149 551.051453,71.084076 
	C549.927063,75.044937 552.689941,81.019188 545.316162,80.741722 
	C545.066528,80.732330 544.415161,82.499062 544.567871,83.323776 
	C544.707214,84.077003 545.566650,84.938484 546.321716,85.276596 
	C547.318787,85.723053 548.523560,85.705589 549.978394,85.942909 
	C549.978394,89.922272 549.978394,93.723785 549.978394,98.513115 
M394.786102,65.628983 
	C394.247894,66.169533 393.242828,66.707375 393.239624,67.251045 
	C393.156769,81.530624 393.032166,95.813309 393.276703,110.089249 
	C393.342926,113.956253 399.263153,116.687889 402.540710,114.720551 
	C403.205597,114.321465 403.733673,113.096840 403.690186,112.286873 
	C403.648468,111.510185 402.760406,110.114517 402.281677,110.130219 
	C398.244568,110.262596 398.849609,107.480499 398.848907,105.027901 
	C398.845551,93.237320 398.817413,81.446663 398.854340,69.656212 
	C398.861633,67.322876 398.899658,65.162392 394.786102,65.628983 
M420.640778,114.642570 
	C423.734589,109.764137 418.688812,110.526306 416.533722,109.192177 
	C416.533722,101.960709 416.533722,94.818108 416.533722,87.675514 
	C416.533722,80.432411 416.533722,73.189316 416.533722,65.621628 
	C414.284912,65.730385 412.859711,65.799309 410.954071,65.891472 
	C410.954071,79.488129 410.943573,92.756294 410.958008,106.024437 
	C410.966644,113.934349 411.975800,114.915749 420.640778,114.642570 
M210.976349,51.777016 
	C207.448395,51.501137 204.049973,51.844440 203.204651,56.032124 
	C202.517532,59.435921 202.938980,62.849834 207.035172,64.049194 
	C210.258896,64.993111 213.394470,64.763496 215.136887,61.303402 
	C216.932877,57.736889 215.529953,54.123093 210.976349,51.777016 
M92.516258,64.002823 
	C95.960129,61.458839 97.637383,56.752930 95.050903,54.461220 
	C93.115967,52.746792 89.374405,51.859951 86.791786,52.376621 
	C83.075005,53.120190 82.791634,56.907478 83.545197,60.117767 
	C84.584305,64.544525 88.104988,64.722733 92.516258,64.002823 
z"
  />
  <path
    fill="#15130F"
    opacity="1.000000"
    stroke="none"
    d="
M656.238281,88.887268 
	C655.419556,90.662346 654.697327,92.895508 653.273010,93.515221 
	C647.022705,96.234764 640.562927,98.470802 634.194092,100.921082 
	C632.516724,101.566437 630.892944,102.351143 629.070801,103.147835 
	C636.443909,120.330292 643.722961,137.117004 650.780029,153.996521 
	C651.535217,155.802734 651.802673,159.416855 650.896667,159.993668 
	C648.730530,161.372681 646.192444,161.215195 643.697510,158.773972 
	C623.348267,138.862610 602.776550,119.178810 582.292847,99.404625 
	C578.107849,95.364548 578.872437,91.849785 584.351685,89.980377 
	C592.201660,87.302116 600.084473,84.719994 608.596863,81.882248 
	C597.598511,70.063492 587.088257,58.741047 576.543640,47.450790 
	C571.775330,42.345352 566.681458,37.512611 562.291443,32.104660 
	C560.997803,30.511028 561.607178,27.372576 561.344482,24.942089 
	C563.083008,25.189161 565.217163,24.869522 566.502075,25.766428 
	C596.394287,46.632095 626.194458,67.629608 656.238281,88.887268 
M631.140686,77.835678 
	C631.140686,77.835678 631.230286,77.775024 631.021667,77.270554 
	C630.560303,76.994171 630.098938,76.717781 629.509949,76.408188 
	C629.509949,76.408188 629.426025,76.306465 629.096741,75.855354 
	C628.453674,75.497551 627.810608,75.139748 627.096008,74.897499 
	C627.096008,74.897499 627.218689,74.838989 626.998413,74.337990 
	C626.667419,74.215675 626.336365,74.093361 625.735535,73.538864 
	C625.290283,73.312675 624.845093,73.086494 624.108826,72.342171 
	C623.751099,72.198929 623.393372,72.055687 623.015442,71.991913 
	C623.015442,71.991913 623.078247,71.939224 622.795837,71.376106 
	C621.896912,70.867668 620.997925,70.359238 620.039368,69.966850 
	C620.039368,69.966850 620.141052,69.885071 619.823364,69.332382 
	C618.913330,68.807594 618.003296,68.282806 616.760925,67.238464 
	C615.890686,66.699387 615.020447,66.160309 613.944214,65.186661 
	C613.532471,64.954575 613.120728,64.722488 612.561401,64.396385 
	C612.561401,64.396385 612.412720,64.304138 612.045715,63.841866 
	C611.023987,63.239376 610.002319,62.636883 608.662903,61.536446 
	C608.144226,61.274036 607.625610,61.011631 606.824158,60.212322 
	C605.956848,59.721043 605.089539,59.229763 603.988892,58.919472 
	C603.988892,58.919472 604.238464,58.762058 604.023132,58.267200 
	C603.564819,57.996616 603.106506,57.726032 602.511475,57.396381 
	C602.511475,57.396381 602.395874,57.302475 602.035156,56.840157 
	C601.354370,56.569420 600.673523,56.298679 599.386169,55.746246 
	C599.022095,55.500706 598.657959,55.255165 598.029236,54.440899 
	C595.268616,52.733776 592.507935,51.026649 589.747253,49.319523 
	C589.427063,49.753960 589.106873,50.188400 588.786682,50.622841 
	C591.829590,53.470093 594.872559,56.317345 598.119568,59.685760 
	C598.682739,60.156452 599.245972,60.627144 599.898071,61.777603 
	C601.258545,63.196602 602.619080,64.615601 604.000000,65.998840 
	C604.000000,65.998840 603.964905,66.020599 604.179138,66.609871 
	C605.083740,67.452332 605.988342,68.294792 606.950439,69.049858 
	C606.950439,69.049858 606.850037,69.079208 606.965393,69.625587 
	C607.269165,69.818039 607.572876,70.010490 607.918701,70.088913 
	C607.918701,70.088913 607.815979,70.153847 608.032043,70.788422 
	C610.372314,73.160851 612.712585,75.533279 615.016479,77.982735 
	C615.016479,77.982735 615.094116,77.947617 615.292114,78.539665 
	C616.155090,79.435928 617.018066,80.332199 617.812988,81.828819 
	C617.876404,82.225838 617.939819,82.622849 617.699646,83.377769 
	C617.699646,83.377769 617.665344,83.845833 617.051208,84.156830 
	C607.456360,87.533806 597.861450,90.910782 587.476990,94.565659 
	C591.621399,98.404533 595.223755,101.741318 598.980286,105.624336 
	C599.295837,105.812561 599.611328,106.000786 600.194397,106.722107 
	C601.089783,107.496094 601.985107,108.270073 603.045288,109.569405 
	C603.350159,109.748352 603.655090,109.927299 604.257690,110.634315 
	C605.166870,111.423523 606.076111,112.212730 607.158936,113.567665 
	C607.784729,114.021294 608.410461,114.474915 609.006409,114.993835 
	C609.006409,114.993835 609.071716,114.963837 609.243225,115.518257 
	C609.840149,115.982841 610.437134,116.447433 611.273010,117.368820 
	C611.551697,117.563286 611.830444,117.757751 612.277527,118.475235 
	C612.716736,118.784004 613.156006,119.092773 613.997253,119.065651 
	C613.867737,119.182953 613.738220,119.300262 613.787415,119.925163 
	C614.216003,120.217842 614.644592,120.510529 615.384460,121.337410 
	C616.455750,122.293922 617.526978,123.250443 618.779236,124.738571 
	C619.087280,124.934891 619.395325,125.131210 619.805359,125.078712 
	C619.805359,125.078712 619.690674,125.332336 619.899414,125.887497 
	C620.530823,126.335861 621.162231,126.784233 621.944397,127.064545 
	C621.944397,127.064545 621.761414,127.196800 621.956177,127.716499 
	C622.266846,127.897118 622.577576,128.077744 623.112488,128.754669 
	C623.667969,129.204163 624.223450,129.653656 624.987488,130.688370 
	C625.625305,131.185913 626.263123,131.683472 627.221802,132.750946 
	C629.479248,134.833054 631.736694,136.915161 634.221375,139.629303 
	C636.205200,141.821701 638.189087,144.014084 640.172913,146.206482 
	C640.627075,145.906723 641.081238,145.606964 641.535400,145.307205 
	C640.313171,142.538651 639.091003,139.770096 637.800659,136.288391 
	C635.971436,132.329041 634.142151,128.369705 632.351135,124.158524 
	C632.351135,124.158524 632.156921,123.993706 632.176758,123.321922 
	C631.759277,122.550545 631.341797,121.779167 630.885559,120.439308 
	C630.600403,119.957306 630.315247,119.475304 630.022156,118.350922 
	C629.765198,118.021851 629.508240,117.692780 629.279663,117.138542 
	C629.279663,117.138542 629.100830,116.998856 629.069275,116.336014 
	C628.823792,115.981194 628.578308,115.626373 628.361938,115.100388 
	C628.361938,115.100388 628.262268,114.958214 628.191711,114.219322 
	C626.916077,111.568611 625.640442,108.917892 624.389404,106.131371 
	C624.389404,106.131371 624.334595,106.004723 624.322815,105.323242 
	C623.920105,104.536484 623.517456,103.749725 623.142639,102.278595 
	C623.447754,101.215347 623.752808,100.152092 624.773804,99.005959 
	C626.754089,98.115463 628.734314,97.224976 631.183105,96.461876 
	C631.524353,96.419640 631.797546,96.260559 632.652283,96.021332 
	C633.001160,95.783783 633.349976,95.546227 633.849365,95.346214 
	C633.849365,95.346214 633.982788,95.267044 634.743286,95.244873 
	C637.047302,94.253510 639.351257,93.262146 642.140381,92.294014 
	C642.465515,92.222282 642.748047,92.070961 643.696045,91.866089 
	C645.161011,91.235687 646.625977,90.605293 648.907471,89.623543 
	C646.858032,88.393272 645.516174,87.587730 644.085083,86.899033 
	C644.085083,86.899033 644.218811,86.838066 644.002930,86.336075 
	C643.670044,86.220833 643.337219,86.105583 643.000000,86.000305 
	C643.000000,86.000305 643.009399,85.993309 642.710632,85.446815 
	C641.828674,84.930435 640.946777,84.414062 640.017029,83.987610 
	C640.017029,83.987610 640.099060,83.927277 639.845276,83.397034 
	C639.330933,83.128693 638.816589,82.860359 638.144287,82.790230 
	C638.144287,82.790230 638.349609,82.641647 638.103394,82.160950 
	C637.777100,82.033348 637.450867,81.905746 637.097900,81.893349 
	C637.097900,81.893349 637.194336,81.824860 636.884705,81.260696 
	C635.962463,80.743683 635.040283,80.226669 633.905029,79.289879 
	C633.481506,79.111839 633.057983,78.933800 632.296692,78.212982 
	C631.911682,78.051147 631.526672,77.889313 631.140686,77.835678 
z"
  />
  <path
    fill="#100F0F"
    opacity="1.000000"
    stroke="none"
    d="
M70.037170,64.986061 
	C77.669975,79.348244 77.758850,93.708466 71.558754,108.114624 
	C68.733383,114.679466 63.653881,119.412971 56.693832,121.263702 
	C52.241703,122.447540 51.700092,124.869804 52.733540,129.021957 
	C55.668465,129.192200 58.745586,129.370682 62.233055,129.572983 
	C62.233055,132.480865 62.233055,135.202438 62.233055,138.750854 
	C57.759743,138.514069 53.251827,138.764557 48.957859,137.923157 
	C43.708363,136.894516 41.875027,132.651627 42.146561,127.711433 
	C42.345757,124.087395 41.209648,122.539711 37.499523,121.634972 
	C29.138433,119.596054 23.642439,113.871193 20.578518,105.981499 
	C15.852329,93.811424 15.914002,81.489601 20.781725,69.415779 
	C24.821360,59.395939 32.627430,53.970394 43.380936,53.087345 
	C54.224602,52.196888 63.484310,55.200420 70.037170,64.986061 
M62.057484,103.193558 
	C62.546539,94.954575 63.886395,86.655350 63.308422,78.491913 
	C62.592476,68.379654 55.802319,62.858170 46.496708,62.936295 
	C37.198227,63.014359 30.649609,68.702042 29.612724,78.594208 
	C28.956982,84.850174 28.974297,91.274391 29.569426,97.539574 
	C30.351307,105.770775 35.676971,111.563042 42.488159,112.891953 
	C50.897785,114.532730 57.134686,111.629707 62.057484,103.193558 
z"
  />
  <path
    fill="#0F0F0F"
    opacity="1.000000"
    stroke="none"
    d="
M171.083618,101.567024 
	C170.165100,100.370201 169.448105,99.528610 168.750961,98.710335 
	C163.608170,102.280159 161.587860,106.687523 161.911499,112.846367 
	C162.424072,122.600616 162.056198,122.619949 151.886765,121.578743 
	C151.886765,97.929092 151.886765,74.234955 151.886765,50.571583 
	C161.960342,49.357628 161.956177,49.357628 161.952393,58.738773 
	C161.947845,70.005630 161.951309,81.272491 161.951309,93.956535 
	C167.192184,87.822243 171.960129,83.027023 175.776016,77.566498 
	C180.070770,71.420723 185.518433,70.286583 193.187103,72.024437 
	C188.888275,76.834900 185.229172,81.661285 180.817154,85.656128 
	C176.596970,89.477272 176.697281,92.462234 179.878693,96.934471 
	C185.351791,104.628235 190.134857,112.812866 195.272873,120.894234 
	C186.774994,123.616318 183.615128,122.493996 179.512115,115.636101 
	C176.775223,111.061569 174.027817,106.493324 171.083618,101.567024 
z"
  />
  <path
    fill="#111111"
    opacity="1.000000"
    stroke="none"
    d="
M140.142319,81.260468 
	C138.248978,83.640068 136.887405,83.956856 134.147049,82.386719 
	C130.856903,80.501587 126.817352,79.512589 122.996201,79.086052 
	C119.433441,78.688339 116.346817,80.519539 116.033066,84.639359 
	C115.736969,88.527397 118.383057,90.296135 121.764984,91.019844 
	C125.174652,91.749512 128.684296,92.048912 132.058441,92.900238 
	C140.202942,94.955185 144.000275,99.162102 144.229797,105.983444 
	C144.481186,113.455170 140.377670,119.097481 132.815872,121.677444 
	C122.310577,125.261673 110.892403,122.128563 104.053398,113.419357 
	C106.077621,111.485245 108.147163,109.507835 110.481613,107.277306 
	C114.957542,112.414230 120.209000,114.788475 126.664200,114.202255 
	C130.581345,113.846527 133.513214,112.236473 133.838776,108.018234 
	C134.164841,103.793335 131.132309,102.288536 127.628372,101.532104 
	C123.258713,100.588776 118.759293,100.085732 114.517334,98.751968 
	C109.113541,97.052895 106.126801,93.077156 105.675713,87.273399 
	C105.191422,81.042313 107.379997,76.197250 112.767868,72.930649 
	C120.239105,68.400940 133.492630,69.585602 140.149216,75.176941 
	C142.545090,77.189400 143.292404,78.941254 140.142319,81.260468 
z"
  />
  <path
    fill="#141414"
    opacity="1.000000"
    stroke="none"
    d="
M231.181976,70.021729 
	C231.918228,65.801399 232.345306,61.898266 232.801331,57.730576 
	C235.667999,57.730576 238.415268,57.730576 241.824463,57.730576 
	C241.824463,62.097393 241.824463,66.318390 241.824463,70.935478 
	C245.528091,71.131744 248.609619,71.295036 252.069595,71.478386 
	C252.069595,74.172462 252.069595,76.731140 252.069595,79.756195 
	C248.952576,79.920219 245.879288,80.081940 242.427094,80.263603 
	C242.427094,91.115265 242.427094,101.801933 242.427094,112.785576 
	C245.439697,112.941284 248.211761,113.084564 251.403992,113.249557 
	C251.403992,115.953964 251.403992,118.508163 251.403992,121.583473 
	C247.350372,121.583473 243.204742,122.017334 239.191055,121.474838 
	C234.051682,120.780197 231.466400,117.008644 231.377213,110.885330 
	C231.253510,102.393745 231.339600,93.899216 231.308884,85.406044 
	C231.303040,83.790421 231.171768,82.175240 231.085480,80.276405 
	C228.633728,80.122429 226.525101,79.990005 223.810135,79.819496 
	C223.696579,77.234261 223.585083,74.696228 223.463882,71.937202 
	C226.164536,71.354607 228.518661,70.846764 231.181976,70.021729 
z"
  />
  <path
    fill="#0E0E0E"
    opacity="1.000000"
    stroke="none"
    d="
M285.937866,138.763184 
	C282.590302,139.011627 280.445618,138.901749 280.467804,134.789429 
	C280.618805,106.794075 280.549011,78.797554 280.566345,50.801414 
	C280.566742,50.170559 280.754730,49.539822 280.899078,48.633942 
	C283.475189,48.633942 285.889130,48.633942 288.647705,48.633942 
	C288.768036,49.843353 288.972656,50.945049 288.973419,52.046898 
	C288.992920,79.376495 289.009186,106.706139 288.952881,134.035629 
	C288.948975,135.924149 290.062836,138.746902 285.937866,138.763184 
z"
  />
  <path
    fill="#222222"
    opacity="1.000000"
    stroke="none"
    d="
M377.726746,112.300354 
	C376.513489,112.225235 375.238983,112.187332 374.499298,112.797638 
	C370.697205,115.934723 365.125397,116.633469 360.420471,114.378021 
	C356.470795,112.484627 355.013489,109.156067 355.095032,105.017380 
	C355.192291,100.079735 357.836212,96.809624 363.248535,95.821693 
	C367.287842,95.084396 371.471375,95.137093 376.081421,94.802353 
	C376.468475,91.335449 376.838287,87.949509 373.251007,85.681190 
	C369.509155,83.315132 365.096252,84.390213 359.569733,88.991829 
	C356.759857,87.638832 356.024872,86.008072 358.613983,83.530434 
	C363.419189,78.932121 369.055573,78.806381 374.891541,80.691490 
	C379.894623,82.307564 381.871979,85.861977 381.971313,92.538307 
	C382.025726,96.197121 381.843079,99.864540 382.029968,103.514626 
	C382.165253,106.156952 380.496094,109.726974 385.104340,110.610954 
	C385.492828,110.685486 385.931335,112.454872 385.695496,113.241699 
	C385.487274,113.936340 384.225983,114.933411 383.678345,114.797195 
	C381.699585,114.305016 379.825684,113.391182 377.726746,112.300354 
M375.911377,99.847702 
	C372.483490,99.770409 369.036835,99.438248 365.633087,99.688835 
	C362.350006,99.930534 360.715637,102.009056 360.844849,105.447433 
	C360.976562,108.954399 363.035156,110.422546 366.167267,110.880524 
	C373.067017,111.889397 376.481842,108.437157 375.911377,99.847702 
z"
  />
  <path
    fill="#181818"
    opacity="1.000000"
    stroke="none"
    d="
M94.934540,92.000221 
	C94.934441,102.089699 94.934441,111.679222 94.934441,121.612213 
	C91.246849,121.612213 88.051224,121.612213 84.226128,121.612213 
	C84.226128,116.111397 84.225502,110.847977 84.226265,105.584564 
	C84.227646,96.121101 84.518471,86.645294 84.114159,77.199219 
	C83.900993,72.218849 85.647614,70.614037 90.291908,71.336845 
	C91.571083,71.535934 92.885117,71.511024 94.934647,71.633980 
	C94.934647,78.363274 94.934647,84.931770 94.934540,92.000221 
z"
  />
  <path
    fill="#161616"
    opacity="1.000000"
    stroke="none"
    d="
M204.183533,120.548439 
	C204.085281,104.153656 204.085281,88.212410 204.085281,71.407188 
	C207.052795,71.407188 209.819641,71.151375 212.488556,71.550476 
	C213.383163,71.684258 214.678650,73.481102 214.689178,74.528862 
	C214.837906,89.316887 214.843079,104.107285 214.699249,118.895317 
	C214.689377,119.912048 213.251755,121.742203 212.426926,121.771889 
	C209.724625,121.869133 206.998672,121.309242 204.183533,120.548439 
z"
  />
  <path
    fill="#1E1E1E"
    opacity="1.000000"
    stroke="none"
    d="
M486.725891,114.512756 
	C476.928680,108.411682 474.355774,93.759636 481.235199,85.077568 
	C485.308411,79.937042 492.755615,78.115219 498.839325,80.771088 
	C504.328674,83.167473 508.980011,91.232445 507.674438,96.743309 
	C507.444519,97.713737 505.403900,98.850273 504.167969,98.888374 
	C497.390289,99.097359 490.602783,98.988052 483.696655,98.988052 
	C482.841248,104.185402 484.577789,107.647247 488.695068,109.788780 
	C493.161041,112.111671 497.593201,110.742340 502.919769,105.642120 
	C506.710297,106.665291 506.780548,108.664948 504.235016,111.188835 
	C499.299622,116.082184 493.384033,116.407425 486.725891,114.512756 
M497.223206,94.989975 
	C498.668182,94.901840 500.113159,94.813713 501.735413,94.714767 
	C502.114197,90.297577 500.796936,86.575882 497.016754,85.482697 
	C494.046265,84.623672 489.986115,84.905846 487.337250,86.364716 
	C485.080902,87.607399 484.146454,91.250221 481.946106,94.988327 
	C488.047668,94.988327 492.168884,94.988327 497.223206,94.989975 
z"
  />
  <path
    fill="#212121"
    opacity="1.000000"
    stroke="none"
    d="
M457.152771,93.922577 
	C452.027435,93.134590 450.452606,95.125366 450.933136,99.694656 
	C451.296478,103.149757 450.827942,106.685188 451.053680,110.163834 
	C451.309387,114.103485 450.102631,115.942009 445.512207,114.460823 
	C445.512207,99.436821 445.512207,84.256210 445.512207,68.335823 
	C453.952423,68.335823 462.376678,68.268707 470.795410,68.453651 
	C471.528534,68.469749 472.989532,70.449371 472.776306,70.985268 
	C472.359558,72.032608 471.022736,73.383636 470.023712,73.419914 
	C463.904602,73.642036 457.773438,73.531075 451.325989,73.531075 
	C451.325989,78.710358 451.325989,83.427132 451.325989,88.648300 
	C457.782959,88.648300 464.046448,88.648300 470.253143,88.648300 
	C471.836029,92.472946 470.922272,94.086494 467.113800,93.948769 
	C463.957275,93.834625 460.793427,93.922752 457.152771,93.922577 
z"
  />
  <path
    fill="#202020"
    opacity="1.000000"
    stroke="none"
    d="
M338.986023,68.330658 
	C342.641235,68.332512 345.824615,68.122566 348.949066,68.459221 
	C349.939697,68.565964 350.773285,70.130493 351.678711,71.027908 
	C350.737122,71.867905 349.820709,73.399887 348.849915,73.435165 
	C342.727722,73.657639 336.593475,73.547905 330.118317,73.547905 
	C330.118317,78.656532 330.118317,83.371841 330.118317,88.584686 
	C336.462860,88.584686 342.701630,88.584686 349.007233,88.584686 
	C350.484100,92.183601 350.070312,94.053352 346.056213,93.952942 
	C342.232635,93.857292 338.377777,94.213684 334.586121,93.852386 
	C330.400208,93.453506 329.430145,95.241661 329.701080,99.001816 
	C329.975281,102.807693 329.567139,106.660591 329.798096,110.472290 
	C330.060059,114.795349 328.254120,115.794167 324.189392,114.372192 
	C324.189392,99.306984 324.189392,84.236526 324.189392,68.330383 
	C328.988342,68.330383 333.739838,68.330383 338.986023,68.330658 
z"
  />
  <path
    fill="#202020"
    opacity="1.000000"
    stroke="none"
    d="
M536.640137,83.006714 
	C536.955383,84.461151 537.019592,85.694969 537.083801,86.928780 
	C535.928345,86.933769 534.725220,87.150314 533.626526,86.903061 
	C530.633850,86.229591 527.635010,84.640633 524.738342,84.842842 
	C523.047607,84.960884 520.638245,87.642448 520.277161,89.505096 
	C520.029541,90.782204 522.461060,93.232697 524.216003,94.082428 
	C526.532104,95.203857 529.385010,95.166473 531.959656,95.815369 
	C536.462769,96.950310 539.310852,99.677170 539.407104,104.547432 
	C539.501953,109.346130 537.439880,112.879807 532.828979,114.664558 
	C525.946167,117.328690 518.496033,115.182343 513.679810,109.062454 
	C515.091248,106.513496 516.247620,105.886261 519.359314,107.774017 
	C522.345703,109.585724 526.360657,110.144615 529.958618,110.273018 
	C531.272461,110.319908 533.793091,107.527641 533.771118,106.060493 
	C533.744568,104.291283 531.968018,101.814171 530.284912,100.974373 
	C527.698730,99.683968 524.514526,99.645813 521.641724,98.864227 
	C516.879883,97.568718 514.337769,94.469582 514.527405,89.397301 
	C514.715820,84.357231 517.571716,81.340233 522.213501,80.206757 
	C527.222168,78.983681 532.145813,79.288582 536.640137,83.006714 
z"
  />
  <path
    fill="#262626"
    opacity="1.000000"
    stroke="none"
    d="
M549.978394,98.019211 
	C549.978394,93.723785 549.978394,89.922272 549.978394,85.942909 
	C548.523560,85.705589 547.318787,85.723053 546.321716,85.276596 
	C545.566650,84.938484 544.707214,84.077003 544.567871,83.323776 
	C544.415161,82.499062 545.066528,80.732330 545.316162,80.741722 
	C552.689941,81.019188 549.927063,75.044937 551.051453,71.084076 
	C558.791138,70.382149 553.876831,77.201752 556.197510,79.461731 
	C557.932068,81.713791 564.737122,77.648735 562.682190,85.011795 
	C560.619141,85.221909 558.524353,85.435249 556.054565,85.686790 
	C556.054565,93.632423 556.054565,101.473015 556.054565,109.328377 
	C556.667786,109.546165 557.114380,109.767250 557.586243,109.861542 
	C559.622253,110.268410 563.050903,109.001114 562.399536,112.774895 
	C562.224609,113.788269 558.857300,114.813698 556.935059,114.853676 
	C551.777466,114.960960 550.174927,113.194305 550.006226,107.976501 
	C549.904297,104.825035 549.981628,101.667770 549.978394,98.019211 
z"
  />
  <path
    fill="#2A2A2A"
    opacity="1.000000"
    stroke="none"
    d="
M395.186890,65.604309 
	C398.899658,65.162392 398.861633,67.322876 398.854340,69.656212 
	C398.817413,81.446663 398.845551,93.237320 398.848907,105.027901 
	C398.849609,107.480499 398.244568,110.262596 402.281677,110.130219 
	C402.760406,110.114517 403.648468,111.510185 403.690186,112.286873 
	C403.733673,113.096840 403.205597,114.321465 402.540710,114.720551 
	C399.263153,116.687889 393.342926,113.956253 393.276703,110.089249 
	C393.032166,95.813309 393.156769,81.530624 393.239624,67.251045 
	C393.242828,66.707375 394.247894,66.169533 395.186890,65.604309 
z"
  />
  <path
    fill="#191919"
    opacity="1.000000"
    stroke="none"
    d="
M420.259216,114.749710 
	C411.975800,114.915749 410.966644,113.934349 410.958008,106.024437 
	C410.943573,92.756294 410.954071,79.488129 410.954071,65.891472 
	C412.859711,65.799309 414.284912,65.730385 416.533722,65.621628 
	C416.533722,73.189316 416.533722,80.432411 416.533722,87.675514 
	C416.533722,94.818108 416.533722,101.960709 416.533722,109.192177 
	C418.688812,110.526306 423.734589,109.764137 420.259216,114.749710 
z"
  />
  <path
    fill="#161616"
    opacity="1.000000"
    stroke="none"
    d="
M211.344742,51.829239 
	C215.529953,54.123093 216.932877,57.736889 215.136887,61.303402 
	C213.394470,64.763496 210.258896,64.993111 207.035172,64.049194 
	C202.938980,62.849834 202.517532,59.435921 203.204651,56.032124 
	C204.049973,51.844440 207.448395,51.501137 211.344742,51.829239 
z"
  />
  <path
    fill="#171717"
    opacity="1.000000"
    stroke="none"
    d="
M92.153343,64.118622 
	C88.104988,64.722733 84.584305,64.544525 83.545197,60.117767 
	C82.791634,56.907478 83.075005,53.120190 86.791786,52.376621 
	C89.374405,51.859951 93.115967,52.746792 95.050903,54.461220 
	C97.637383,56.752930 95.960129,61.458839 92.153343,64.118622 
z"
  />
  <path
    fill="#F3DB7C"
    opacity="1.000000"
    stroke="none"
    d="
M612.109131,117.952232 
	C611.830444,117.757751 611.551697,117.563286 611.118408,116.828995 
	C610.333069,115.847382 609.702393,115.405602 609.071716,114.963837 
	C609.071716,114.963837 609.006409,114.993835 608.984009,114.641960 
	C608.302795,113.860695 607.644104,113.431305 606.985352,113.001923 
	C606.076111,112.212730 605.166870,111.423523 604.020752,110.113159 
	C603.482727,109.409348 603.181641,109.226707 602.880493,109.044060 
	C601.985107,108.270073 601.089783,107.496094 599.988159,106.178162 
	C599.463318,105.448845 599.144714,105.263481 598.826111,105.078110 
	C595.223755,101.741318 591.621399,98.404533 587.476990,94.565659 
	C597.861450,90.910782 607.456360,87.533806 617.560303,84.000938 
	C618.180420,83.772949 618.291504,83.700851 618.402588,83.628754 
	C618.269470,83.425789 618.136353,83.222816 618.003235,83.019852 
	C617.939819,82.622849 617.876404,82.225838 617.888184,81.149353 
	C617.007019,79.629135 616.050537,78.788376 615.094116,77.947617 
	C615.094116,77.947617 615.016479,77.982735 615.025513,77.581024 
	C613.419312,74.024773 612.655640,69.995338 607.815979,70.153847 
	C607.815979,70.153847 607.918701,70.088913 607.838318,69.873116 
	C607.455322,69.464607 607.152710,69.271896 606.850037,69.079201 
	C606.850037,69.079208 606.950439,69.049858 606.955322,68.712006 
	C605.961731,67.589630 604.963318,66.805115 603.964905,66.020599 
	C603.964905,66.020599 604.000000,65.998840 603.996582,65.643845 
	C602.598450,63.891850 601.203796,62.494843 599.809143,61.097836 
	C599.245972,60.627144 598.682739,60.156452 598.044495,58.904205 
	C598.077576,57.084976 598.185730,56.047306 598.293884,55.009628 
	C598.657959,55.255165 599.022095,55.500706 599.758301,56.233192 
	C600.885559,56.914253 601.640686,57.108368 602.395874,57.302479 
	C602.395874,57.302475 602.511475,57.396381 602.513428,57.757896 
	C603.089722,58.333626 603.664062,58.547844 604.238464,58.762058 
	C604.238464,58.762058 603.988892,58.919472 604.066956,59.178936 
	C605.132324,59.875347 606.119629,60.312294 607.106934,60.749229 
	C607.625610,61.011631 608.144226,61.274036 608.865234,62.149410 
	C610.182678,63.276295 611.297668,63.790218 612.412720,64.304138 
	C612.412720,64.304138 612.561401,64.396385 612.468994,64.753548 
	C612.475708,65.545517 612.574890,65.980316 612.674072,66.415131 
	C613.166138,66.150505 613.658142,65.885872 614.150208,65.621231 
	C615.020447,66.160309 615.890686,66.699387 616.944336,67.870911 
	C618.132202,68.963921 619.136658,69.424492 620.141052,69.885071 
	C620.141052,69.885071 620.039368,69.966850 620.089111,70.270508 
	C621.118652,71.029175 622.098450,71.484192 623.078247,71.939217 
	C623.078247,71.939224 623.015442,71.991913 623.162415,72.219513 
	C623.672852,72.584839 624.036377,72.722565 624.399841,72.860298 
	C624.845093,73.086494 625.290283,73.312675 625.993774,73.993958 
	C626.574280,74.579033 626.896484,74.709015 627.218689,74.838989 
	C627.218689,74.838989 627.096008,74.897499 627.134277,75.212265 
	C627.923767,75.786842 628.674866,76.046654 629.426025,76.306465 
	C629.426025,76.306465 629.509949,76.408188 629.504517,76.760216 
	C630.076172,77.333176 630.653259,77.554100 631.230286,77.775024 
	C631.230286,77.775024 631.140686,77.835678 631.269043,78.094360 
	C631.809753,78.487289 632.222107,78.621521 632.634460,78.755753 
	C633.057983,78.933800 633.481506,79.111839 634.020386,79.880028 
	C635.155334,80.921730 636.174805,81.373291 637.194336,81.824860 
	C637.194336,81.824860 637.097900,81.893349 637.205078,82.107239 
	C637.658020,82.427971 638.003784,82.534813 638.349609,82.641647 
	C638.349609,82.641647 638.144287,82.790230 638.266357,83.015518 
	C638.958618,83.469635 639.528809,83.698456 640.099060,83.927277 
	C640.099060,83.927277 640.017029,83.987610 640.067505,84.296814 
	C641.081726,85.068451 642.045593,85.530876 643.009399,85.993301 
	C643.009399,85.993309 643.000000,86.000305 643.124451,86.233917 
	C643.572205,86.591042 643.895508,86.714554 644.218811,86.838066 
	C644.218811,86.838066 644.085083,86.899033 644.075562,87.215294 
	C643.706726,88.967720 643.347351,90.403885 642.987976,91.840050 
	C642.748047,92.070961 642.465515,92.222282 641.469727,92.207718 
	C638.526917,93.169952 636.254883,94.218498 633.982788,95.267044 
	C633.982788,95.267044 633.849365,95.346214 633.442871,95.291397 
	C632.691772,95.485939 632.347168,95.735291 632.002625,95.984650 
	C631.797546,96.260559 631.524353,96.419640 630.498413,96.299843 
	C627.398071,96.152184 624.453125,95.133942 624.057861,99.088837 
	C623.752808,100.152092 623.447754,101.215347 622.909180,102.793518 
	C623.228699,104.207199 623.781677,105.105965 624.334595,106.004723 
	C624.334595,106.004723 624.389404,106.131371 624.270996,106.639404 
	C625.522461,109.751030 626.892334,112.354622 628.262268,114.958221 
	C628.262268,114.958214 628.361938,115.100388 628.317261,115.531357 
	C628.548645,116.307823 628.824768,116.653336 629.100830,116.998856 
	C629.100830,116.998856 629.279663,117.138542 629.257935,117.577347 
	C629.500916,118.341858 629.765503,118.667580 630.030151,118.993301 
	C630.315247,119.475304 630.600403,119.957306 630.683105,120.909943 
	C631.039368,122.251625 631.598145,123.122665 632.156921,123.993706 
	C632.156921,123.993706 632.351135,124.158524 632.242371,124.713440 
	C633.757751,129.178543 635.381897,133.088715 636.961426,137.349518 
	C635.942627,138.132492 634.968445,138.564865 633.994202,138.997253 
	C631.736694,136.915161 629.479248,134.833054 627.052124,132.113647 
	C626.181213,131.018616 625.480103,130.560883 624.778931,130.103149 
	C624.223450,129.653656 623.667969,129.204163 622.930054,128.226685 
	C622.418823,127.531410 622.090149,127.364113 621.761414,127.196808 
	C621.761414,127.196800 621.944397,127.064545 621.870605,126.794167 
	C621.094788,126.126633 620.392700,125.729485 619.690674,125.332336 
	C619.690674,125.332336 619.805359,125.078712 619.713135,124.903275 
	C619.279968,124.554207 618.939148,124.380577 618.598267,124.206955 
	C617.526978,123.250443 616.455750,122.293922 615.185486,120.776215 
	C614.527222,119.949211 614.067932,119.683388 613.608643,119.417564 
	C613.738220,119.300262 613.867737,119.182953 613.802002,118.929192 
	C613.107483,118.512566 612.608337,118.232399 612.109131,117.952232 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M598.161560,54.725266 
	C598.185730,56.047306 598.077576,57.084976 597.942505,58.643623 
	C594.872559,56.317345 591.829590,53.470093 588.786682,50.622841 
	C589.106873,50.188400 589.427063,49.753960 589.747253,49.319523 
	C592.507935,51.026649 595.268616,52.733776 598.161560,54.725266 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M634.107788,139.313263 
	C634.968445,138.564865 635.942627,138.132492 637.177124,137.350098 
	C637.437378,137.000076 637.868774,137.001541 637.868774,137.001541 
	C639.091003,139.770096 640.313171,142.538651 641.535400,145.307205 
	C641.081238,145.606964 640.627075,145.906723 640.172913,146.206482 
	C638.189087,144.014084 636.205200,141.821701 634.107788,139.313263 
z"
  />
  <path
    fill="#CBBC7C"
    opacity="1.000000"
    stroke="none"
    d="
M637.834717,136.644958 
	C637.868774,137.001541 637.437378,137.000076 637.221680,136.999481 
	C635.381897,133.088715 633.757751,129.178543 632.223267,124.839355 
	C634.142151,128.369705 635.971436,132.329041 637.834717,136.644958 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M607.924011,70.471130 
	C612.655640,69.995338 613.419312,74.024773 615.043701,77.542511 
	C612.712585,75.533279 610.372314,73.160851 607.924011,70.471130 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M643.342041,91.853073 
	C643.347351,90.403885 643.706726,88.967720 644.120178,87.156876 
	C645.516174,87.587730 646.858032,88.393272 648.907471,89.623543 
	C646.625977,90.605293 645.161011,91.235687 643.342041,91.853073 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M628.226990,114.588776 
	C626.892334,112.354622 625.522461,109.751030 624.258667,106.707306 
	C625.640442,108.917892 626.916077,111.568611 628.226990,114.588776 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M624.415833,99.047394 
	C624.453125,95.133942 627.398071,96.152184 630.264160,96.236145 
	C628.734314,97.224976 626.754089,98.115463 624.415833,99.047394 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M599.853638,61.437721 
	C601.203796,62.494843 602.598450,63.891850 603.986328,65.661728 
	C602.619080,64.615601 601.258545,63.196602 599.853638,61.437721 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M634.363037,95.255959 
	C636.254883,94.218498 638.526917,93.169952 641.227173,92.196098 
	C639.351257,93.262146 637.047302,94.253510 634.363037,95.255959 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M606.965576,60.480782 
	C606.119629,60.312294 605.132324,59.875347 604.183594,59.088444 
	C605.089539,59.229763 605.956848,59.721043 606.965576,60.480782 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M615.193115,78.243637 
	C616.050537,78.788376 617.007019,79.629135 617.922241,80.849182 
	C617.018066,80.332199 616.155090,79.435928 615.193115,78.243637 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M604.072021,66.315231 
	C604.963318,66.805115 605.961731,67.589630 606.926514,68.755699 
	C605.988342,68.294792 605.083740,67.452332 604.072021,66.315231 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M612.229248,64.072998 
	C611.297668,63.790218 610.182678,63.276295 609.024109,62.398384 
	C610.002319,62.636883 611.023987,63.239376 612.229248,64.072998 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M619.982178,69.608719 
	C619.136658,69.424492 618.132202,68.963921 617.110535,68.130692 
	C618.003296,68.282806 618.913330,68.807594 619.982178,69.608719 
z"
  />
  <path
    fill="#CBBC7C"
    opacity="1.000000"
    stroke="none"
    d="
M632.166870,123.657814 
	C631.598145,123.122665 631.039368,122.251625 630.702393,121.194183 
	C631.341797,121.779167 631.759277,122.550545 632.166870,123.657814 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M622.937012,71.657654 
	C622.098450,71.484192 621.118652,71.029175 620.118896,70.212479 
	C620.997925,70.359238 621.896912,70.867668 622.937012,71.657654 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M637.039551,81.542770 
	C636.174805,81.373291 635.155334,80.921730 634.126953,80.089920 
	C635.040283,80.226669 635.962463,80.743683 637.039551,81.542770 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M642.859985,85.720062 
	C642.045593,85.530876 641.081726,85.068451 640.091370,84.251854 
	C640.946777,84.414062 641.828674,84.930435 642.859985,85.720062 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M619.795044,125.609917 
	C620.392700,125.729485 621.094788,126.126633 621.795227,126.878197 
	C621.162231,126.784233 620.530823,126.335861 619.795044,125.609917 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M624.328735,105.663986 
	C623.781677,105.105965 623.228699,104.207199 622.895264,103.135704 
	C623.517456,103.749725 623.920105,104.536484 624.328735,105.663986 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M602.215515,57.071320 
	C601.640686,57.108368 600.885559,56.914253 600.061523,56.374039 
	C600.673523,56.298679 601.354370,56.569420 602.215515,57.071320 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M629.261353,76.080910 
	C628.674866,76.046654 627.923767,75.786842 627.170044,75.154480 
	C627.810608,75.139748 628.453674,75.497551 629.261353,76.080910 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M614.047241,65.403946 
	C613.658142,65.885872 613.166138,66.150505 612.674072,66.415131 
	C612.574890,65.980316 612.475708,65.545517 612.542725,64.800552 
	C613.120728,64.722488 613.532471,64.954575 614.047241,65.403946 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M624.883179,130.395752 
	C625.480103,130.560883 626.181213,131.018616 626.891663,131.828690 
	C626.263123,131.683472 625.625305,131.185913 624.883179,130.395752 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M609.157471,115.241043 
	C609.702393,115.405602 610.333069,115.847382 610.998901,116.600586 
	C610.437134,116.447433 609.840149,115.982841 609.157471,115.241043 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M607.072144,113.284790 
	C607.644104,113.431305 608.302795,113.860695 608.998901,114.609314 
	C608.410461,114.474915 607.784729,114.021294 607.072144,113.284790 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M639.972168,83.662155 
	C639.528809,83.698456 638.958618,83.469635 638.345337,82.916420 
	C638.816589,82.860359 639.330933,83.128693 639.972168,83.662155 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M613.697998,119.671364 
	C614.067932,119.683388 614.527222,119.949211 615.029846,120.509125 
	C614.644592,120.510529 614.216003,120.217842 613.697998,119.671364 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M612.193359,118.213737 
	C612.608337,118.232399 613.107483,118.512566 613.600952,119.097137 
	C613.156006,119.092773 612.716736,118.784004 612.193359,118.213737 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M631.125977,77.522789 
	C630.653259,77.554100 630.076172,77.333176 629.568359,76.776817 
	C630.098938,76.717781 630.560303,76.994171 631.125977,77.522789 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M604.130798,58.514629 
	C603.664062,58.547844 603.089722,58.333626 602.581787,57.787430 
	C603.106506,57.726032 603.564819,57.996616 604.130798,58.514629 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M598.903198,105.351227 
	C599.144714,105.263481 599.463318,105.448845 599.854370,105.911606 
	C599.611328,106.000786 599.295837,105.812561 598.903198,105.351227 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M602.962891,109.306732 
	C603.181641,109.226707 603.482727,109.409348 603.871948,109.849121 
	C603.655090,109.927299 603.350159,109.748352 602.962891,109.306732 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M632.465576,78.484367 
	C632.222107,78.621521 631.809753,78.487289 631.269531,78.040268 
	C631.526672,77.889313 631.911682,78.051147 632.465576,78.484367 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M629.085083,116.667435 
	C628.824768,116.653336 628.548645,116.307823 628.302673,115.616943 
	C628.578308,115.626373 628.823792,115.981194 629.085083,116.667435 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M638.226501,82.401299 
	C638.003784,82.534813 637.658020,82.427971 637.218384,82.049637 
	C637.450867,81.905746 637.777100,82.033348 638.226501,82.401299 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M630.026123,118.672104 
	C629.765503,118.667580 629.500916,118.341858 629.243774,117.689926 
	C629.508240,117.692780 629.765198,118.021851 630.026123,118.672104 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M617.851440,83.198807 
	C618.136353,83.222816 618.269470,83.425789 618.402588,83.628754 
	C618.291504,83.700851 618.180420,83.772949 617.867310,83.845444 
	C617.665344,83.845833 617.699646,83.377769 617.851440,83.198807 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M632.327454,96.002991 
	C632.347168,95.735291 632.691772,95.485939 633.367554,95.272629 
	C633.349976,95.546227 633.001160,95.783783 632.327454,96.002991 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M618.688721,124.472763 
	C618.939148,124.380577 619.279968,124.554207 619.662109,125.027687 
	C619.395325,125.131210 619.087280,124.934891 618.688721,124.472763 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M627.108521,74.588493 
	C626.896484,74.709015 626.574280,74.579033 626.128723,74.210052 
	C626.336365,74.093361 626.667419,74.215675 627.108521,74.588493 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M624.254333,72.601227 
	C624.036377,72.722565 623.672852,72.584839 623.172485,72.179779 
	C623.393372,72.055687 623.751099,72.198929 624.254333,72.601227 
z"
  />
  <path
    fill="#C9BE7E"
    opacity="1.000000"
    stroke="none"
    d="
M621.858765,127.456657 
	C622.090149,127.364113 622.418823,127.531410 622.817871,127.978539 
	C622.577576,128.077744 622.266846,127.897118 621.858765,127.456657 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M644.110840,86.587067 
	C643.895508,86.714554 643.572205,86.591042 643.126587,86.228935 
	C643.337219,86.105583 643.670044,86.220833 644.110840,86.587067 
z"
  />
  <path
    fill="#DCCB81"
    opacity="1.000000"
    stroke="none"
    d="
M606.907715,69.352386 
	C607.152710,69.271896 607.455322,69.464607 607.817261,69.930130 
	C607.572876,70.010490 607.269165,69.818039 606.907715,69.352386 
z"
  />
  <path
    fill="#F8F8F8"
    opacity="1.000000"
    stroke="none"
    d="
M61.913322,103.555511 
	C57.134686,111.629707 50.897785,114.532730 42.488159,112.891953 
	C35.676971,111.563042 30.351307,105.770775 29.569426,97.539574 
	C28.974297,91.274391 28.956982,84.850174 29.612724,78.594208 
	C30.649609,68.702042 37.198227,63.014359 46.496708,62.936295 
	C55.802319,62.858170 62.592476,68.379654 63.308422,78.491913 
	C63.886395,86.655350 62.546539,94.954575 61.913322,103.555511 
z"
  />
  <path
    fill="#EBEBEB"
    opacity="1.000000"
    stroke="none"
    d="
M376.112671,100.250694 
	C376.481842,108.437157 373.067017,111.889397 366.167267,110.880524 
	C363.035156,110.422546 360.976562,108.954399 360.844849,105.447433 
	C360.715637,102.009056 362.350006,99.930534 365.633087,99.688835 
	C369.036835,99.438248 372.483490,99.770409 376.112671,100.250694 
z"
  />
  <path
    fill="#E7E7E7"
    opacity="1.000000"
    stroke="none"
    d="
M496.756653,94.989151 
	C492.168884,94.988327 488.047668,94.988327 481.946106,94.988327 
	C484.146454,91.250221 485.080902,87.607399 487.337250,86.364716 
	C489.986115,84.905846 494.046265,84.623672 497.016754,85.482697 
	C500.796936,86.575882 502.114197,90.297577 501.735413,94.714767 
	C500.113159,94.813713 498.668182,94.901840 496.756653,94.989151 
z"
  />
</svg>
