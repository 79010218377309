<svg viewBox="0 0 792 319" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M789.385 39.9883H553.713C549.687 39.9883 546.424 43.2531 546.424 47.2805V106.993C546.424 111.02 549.687 114.285 553.713 114.285H789.385C793.411 114.285 796.674 111.02 796.674 106.993V47.2805C796.674 43.2531 793.411 39.9883 789.385 39.9883Z"
    fill="#3B246B"
  />
  <path
    d="M546.424 80.2793H796.674V112.236C796.674 113.366 795.757 114.283 794.628 114.283H548.47C547.341 114.283 546.424 113.366 546.424 112.236V80.2793Z"
    fill="#4C2F89"
  />
  <path
    d="M561.001 47.2805C561.001 43.2531 557.738 39.9883 553.713 39.9883C549.687 39.9883 546.424 43.2531 546.424 47.2805V107.219C546.424 111.246 549.687 114.511 553.713 114.511C557.738 114.511 561.001 111.246 561.001 107.219V47.2805Z"
    fill="#4C2F89"
  />
  <path
    d="M569.611 86.3359H575.386C577.775 86.3359 579.716 88.276 579.716 90.668V114.284H565.283V90.668C565.283 88.2784 567.222 86.3359 569.613 86.3359H569.611Z"
    fill="#FEAC18"
  />
  <path
    d="M796.583 47.0549C796.583 43.0275 793.32 39.7627 789.295 39.7627C785.269 39.7627 782.006 43.0275 782.006 47.0549V106.993C782.006 111.02 785.269 114.285 789.295 114.285C793.32 114.285 796.583 111.02 796.583 106.993V47.0549Z"
    fill="#4C2F89"
  />
  <path
    d="M795.437 33H549.289C545.263 33 542 36.2648 542 40.2922C542 44.3196 545.263 47.5845 549.289 47.5845H795.437C799.463 47.5845 802.726 44.3196 802.726 40.2922C802.726 36.2648 799.463 33 795.437 33Z"
    fill="#3B246B"
  />
  <path
    d="M624.968 53.9629H619.195C616.804 53.9629 614.865 55.9024 614.865 58.2949V68.729C614.865 71.1216 616.804 73.0611 619.195 73.0611H624.968C627.36 73.0611 629.298 71.1216 629.298 68.729V58.2949C629.298 55.9024 627.36 53.9629 624.968 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M600.293 53.9629H594.519C592.128 53.9629 590.189 55.9024 590.189 58.2949V68.729C590.189 71.1216 592.128 73.0611 594.519 73.0611H600.293C602.684 73.0611 604.622 71.1216 604.622 68.729V58.2949C604.622 55.9024 602.684 53.9629 600.293 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M575.38 53.9629H569.607C567.216 53.9629 565.277 55.9024 565.277 58.2949V68.729C565.277 71.1216 567.216 73.0611 569.607 73.0611H575.38C577.772 73.0611 579.71 71.1216 579.71 68.729V58.2949C579.71 55.9024 577.772 53.9629 575.38 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M699.228 53.9629H693.455C691.064 53.9629 689.125 55.9024 689.125 58.2949V68.729C689.125 71.1216 691.064 73.0611 693.455 73.0611H699.228C701.619 73.0611 703.558 71.1216 703.558 68.729V58.2949C703.558 55.9024 701.619 53.9629 699.228 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M674.552 53.9629H668.779C666.388 53.9629 664.449 55.9024 664.449 58.2949V68.729C664.449 71.1216 666.388 73.0611 668.779 73.0611H674.552C676.944 73.0611 678.882 71.1216 678.882 68.729V58.2949C678.882 55.9024 676.944 53.9629 674.552 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M649.644 53.9629H643.871C641.48 53.9629 639.541 55.9024 639.541 58.2949V68.729C639.541 71.1216 641.48 73.0611 643.871 73.0611H649.644C652.035 73.0611 653.974 71.1216 653.974 68.729V58.2949C653.974 55.9024 652.035 53.9629 649.644 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M773.488 53.9629H767.715C765.323 53.9629 763.385 55.9024 763.385 58.2949V102.966C763.385 105.359 765.323 107.298 767.715 107.298H773.488C775.879 107.298 777.818 105.359 777.818 102.966V58.2949C777.818 55.9024 775.879 53.9629 773.488 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M748.812 53.9629H743.039C740.648 53.9629 738.709 55.9024 738.709 58.2949V102.966C738.709 105.359 740.648 107.298 743.039 107.298H748.812C751.203 107.298 753.142 105.359 753.142 102.966V58.2949C753.142 55.9024 751.203 53.9629 748.812 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M723.904 53.9629H718.131C715.739 53.9629 713.801 55.9024 713.801 58.2949V102.966C713.801 105.359 715.739 107.298 718.131 107.298H723.904C726.295 107.298 728.234 105.359 728.234 102.966V58.2949C728.234 55.9024 726.295 53.9629 723.904 53.9629Z"
    fill="#FEAC18"
  />
  <path
    d="M143.017 12.3018H7.65906C4.79901 12.3018 2.48047 14.6214 2.48047 17.4829V59.9081C2.48047 62.7696 4.79901 65.0892 7.65906 65.0892H143.017C145.877 65.0892 148.195 62.7696 148.195 59.9081V17.4829C148.195 14.6214 145.877 12.3018 143.017 12.3018Z"
    fill="#43246B"
  />
  <path
    d="M12.8379 65.0892H7.64442C4.79462 65.0892 2.48238 62.7741 2.48238 59.9246L2.48238 17.4679C2.48238 14.6167 4.79628 12.3034 7.64442 12.3034H7.67585C10.5256 12.3034 12.8379 14.6184 12.8379 17.4679L12.8379 65.0908V65.0892Z"
    fill="#562F88"
  />
  <path
    d="M142.954 12.3018C145.812 12.3018 148.133 14.6234 148.133 17.4829V59.9081C148.133 62.7676 145.812 65.0892 142.954 65.0892H137.775V17.4829C137.775 14.6234 140.096 12.3018 142.954 12.3018Z"
    fill="#562F88"
  />
  <path
    d="M37.2665 12.3018H43.0554C45.9134 12.3018 48.234 14.6234 48.234 17.4829V65.0892H32.0879V17.4829C32.0879 14.6234 34.4084 12.3018 37.2665 12.3018Z"
    fill="#562F88"
  />
  <path
    d="M59.7759 45.2324H55.6741C53.975 45.2324 52.5977 46.6104 52.5977 48.3103V55.7237C52.5977 57.4236 53.975 58.8016 55.6741 58.8016H59.7759C61.4749 58.8016 62.8523 57.4236 62.8523 55.7237V48.3103C62.8523 46.6104 61.4749 45.2324 59.7759 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M59.7759 21.9004H55.6741C53.975 21.9004 52.5977 23.2784 52.5977 24.9783V32.3917C52.5977 34.0916 53.975 35.4696 55.6741 35.4696H59.7759C61.4749 35.4696 62.8523 34.0916 62.8523 32.3917V24.9783C62.8523 23.2784 61.4749 21.9004 59.7759 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M42.2427 21.9004H38.1408C36.4418 21.9004 35.0645 23.2784 35.0645 24.9783V32.3917C35.0645 34.0916 36.4418 35.4696 38.1408 35.4696H42.2427C43.9417 35.4696 45.3191 34.0916 45.3191 32.3917V24.9783C45.3191 23.2784 43.9417 21.9004 42.2427 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M24.5454 21.9004H20.4436C18.7445 21.9004 17.3672 23.2784 17.3672 24.9783V32.3917C17.3672 34.0916 18.7445 35.4696 20.4436 35.4696H24.5454C26.2445 35.4696 27.6218 34.0916 27.6218 32.3917V24.9783C27.6218 23.2784 26.2445 21.9004 24.5454 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M24.5454 45.2324H20.4436C18.7445 45.2324 17.3672 46.6104 17.3672 48.3103V55.7237C17.3672 57.4236 18.7445 58.8016 20.4436 58.8016H24.5454C26.2445 58.8016 27.6218 57.4236 27.6218 55.7237V48.3103C27.6218 46.6104 26.2445 45.2324 24.5454 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M38.1411 45.2324H42.2446C43.9416 45.2324 45.321 46.6108 45.321 48.3103V65.0898H35.0664V48.3103C35.0664 46.6125 36.4442 45.2324 38.1428 45.2324H38.1411Z"
    fill="#FEAC18"
  />
  <path
    d="M72.497 12.3018H78.2859C81.1439 12.3018 83.4645 14.6234 83.4645 17.4829V65.0892H67.3184V17.4829C67.3184 14.6234 69.6389 12.3018 72.497 12.3018Z"
    fill="#562F88"
  />
  <path
    d="M77.4712 21.9004H73.3694C71.6703 21.9004 70.293 23.2784 70.293 24.9783V32.3917C70.293 34.0916 71.6703 35.4696 73.3694 35.4696H77.4712C79.1702 35.4696 80.5476 34.0916 80.5476 32.3917V24.9783C80.5476 23.2784 79.1702 21.9004 77.4712 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M59.7759 21.9004H55.6741C53.975 21.9004 52.5977 23.2784 52.5977 24.9783V32.3917C52.5977 34.0916 53.975 35.4696 55.6741 35.4696H59.7759C61.4749 35.4696 62.8523 34.0916 62.8523 32.3917V24.9783C62.8523 23.2784 61.4749 21.9004 59.7759 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M59.7759 45.2324H55.6741C53.975 45.2324 52.5977 46.6104 52.5977 48.3103V55.7237C52.5977 57.4236 53.975 58.8016 55.6741 58.8016H59.7759C61.4749 58.8016 62.8523 57.4236 62.8523 55.7237V48.3103C62.8523 46.6104 61.4749 45.2324 59.7759 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M108.058 12.3018H113.846C116.704 12.3018 119.025 14.6234 119.025 17.4829V65.0892H102.879V17.4829C102.879 14.6234 105.199 12.3018 108.058 12.3018Z"
    fill="#562F88"
  />
  <path
    d="M113.032 21.9004H108.93C107.231 21.9004 105.854 23.2784 105.854 24.9783V32.3917C105.854 34.0916 107.231 35.4696 108.93 35.4696H113.032C114.731 35.4696 116.108 34.0916 116.108 32.3917V24.9783C116.108 23.2784 114.731 21.9004 113.032 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M95.3325 21.9004H91.2307C89.5317 21.9004 88.1543 23.2784 88.1543 24.9783V32.3917C88.1543 34.0916 89.5317 35.4696 91.2307 35.4696H95.3325C97.0316 35.4696 98.4089 34.0916 98.4089 32.3917V24.9783C98.4089 23.2784 97.0316 21.9004 95.3325 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M95.3325 45.2324H91.2307C89.5317 45.2324 88.1543 46.6104 88.1543 48.3103V55.7237C88.1543 57.4236 89.5317 58.8016 91.2307 58.8016H95.3325C97.0316 58.8016 98.4089 57.4236 98.4089 55.7237V48.3103C98.4089 46.6104 97.0316 45.2324 95.3325 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M130.233 21.9004H126.131C124.432 21.9004 123.055 23.2784 123.055 24.9783V32.3917C123.055 34.0916 124.432 35.4696 126.131 35.4696H130.233C131.932 35.4696 133.309 34.0916 133.309 32.3917V24.9783C133.309 23.2784 131.932 21.9004 130.233 21.9004Z"
    fill="#FEAC18"
  />
  <path
    d="M130.233 45.2324H126.131C124.432 45.2324 123.055 46.6104 123.055 48.3103V55.7237C123.055 57.4236 124.432 58.8016 126.131 58.8016H130.233C131.932 58.8016 133.309 57.4236 133.309 55.7237V48.3103C133.309 46.6104 131.932 45.2324 130.233 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M77.4712 45.2324H73.3694C71.6703 45.2324 70.293 46.6104 70.293 48.3103V55.7237C70.293 57.4236 71.6703 58.8016 73.3694 58.8016H77.4712C79.1702 58.8016 80.5476 57.4236 80.5476 55.7237V48.3103C80.5476 46.6104 79.1702 45.2324 77.4712 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M113.032 45.2324H108.93C107.231 45.2324 105.854 46.6104 105.854 48.3103V55.7237C105.854 57.4236 107.231 58.8016 108.93 58.8016H113.032C114.731 58.8016 116.108 57.4236 116.108 55.7237V48.3103C116.108 46.6104 114.731 45.2324 113.032 45.2324Z"
    fill="#FEAC18"
  />
  <path
    d="M146.821 8H5.1786C2.31854 8 0 10.3197 0 13.1811C0 16.0426 2.31854 18.3622 5.1786 18.3622H146.821C149.681 18.3622 152 16.0426 152 13.1811C152 10.3197 149.681 8 146.821 8Z"
    fill="#43246B"
  />
  <path
    d="M239.067 18.0586H68.4789C65.5652 18.0586 63.2031 20.4218 63.2031 23.337V66.5586C63.2031 69.4738 65.5652 71.837 68.4789 71.837H239.067C241.981 71.837 244.343 69.4738 244.343 66.5586V23.337C244.343 20.4218 241.981 18.0586 239.067 18.0586Z"
    fill="#3B246B"
  />
  <path
    d="M63.2031 47.2217H244.343V70.3531C244.343 71.1708 243.679 71.835 242.862 71.835H64.6843C63.867 71.835 63.2031 71.1708 63.2031 70.3531V47.2217Z"
    fill="#4C2F89"
  />
  <path
    d="M73.7547 23.337C73.7547 20.4218 71.3927 18.0586 68.4789 18.0586C65.5652 18.0586 63.2031 20.4218 63.2031 23.337V66.7221C63.2031 69.6373 65.5652 72.0005 68.4789 72.0005C71.3927 72.0005 73.7547 69.6373 73.7547 66.7221V23.337Z"
    fill="#4C2F89"
  />
  <path
    d="M79.986 51.6064H84.1665C85.8953 51.6064 87.3006 53.0107 87.3006 54.7421V71.8366H76.8535V54.7421C76.8535 53.0124 78.2571 51.6064 79.9876 51.6064H79.986Z"
    fill="#FEAC18"
  />
  <path
    d="M244.278 23.1739C244.278 20.2587 241.916 17.8955 239.002 17.8955C236.089 17.8955 233.727 20.2587 233.727 23.1739V66.559C233.727 69.4742 236.089 71.8374 239.002 71.8374C241.916 71.8374 244.278 69.4742 244.278 66.559V23.1739Z"
    fill="#4C2F89"
  />
  <path
    d="M243.446 13H65.2758C62.3621 13 60 15.3632 60 18.2784C60 21.1935 62.3621 23.5567 65.2758 23.5567H243.446C246.36 23.5567 248.722 21.1935 248.722 18.2784C248.722 15.3632 246.36 13 243.446 13Z"
    fill="#3B246B"
  />
  <path
    d="M120.053 28.1738H115.874C114.143 28.1738 112.74 29.5777 112.74 31.3095V38.8621C112.74 40.5939 114.143 41.9977 115.874 41.9977H120.053C121.784 41.9977 123.187 40.5939 123.187 38.8621V31.3095C123.187 29.5777 121.784 28.1738 120.053 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M102.194 28.1738H98.015C96.2841 28.1738 94.8809 29.5777 94.8809 31.3095V38.8621C94.8809 40.5939 96.2841 41.9977 98.015 41.9977H102.194C103.925 41.9977 105.328 40.5939 105.328 38.8621V31.3095C105.328 29.5777 103.925 28.1738 102.194 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M84.1606 28.1738H79.9818C78.2509 28.1738 76.8477 29.5777 76.8477 31.3095V38.8621C76.8477 40.5939 78.2509 41.9977 79.9818 41.9977H84.1606C85.8916 41.9977 87.2948 40.5939 87.2948 38.8621V31.3095C87.2948 29.5777 85.8916 28.1738 84.1606 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M173.809 28.1738H169.63C167.899 28.1738 166.496 29.5777 166.496 31.3095V38.8621C166.496 40.5939 167.899 41.9977 169.63 41.9977H173.809C175.54 41.9977 176.943 40.5939 176.943 38.8621V31.3095C176.943 29.5777 175.54 28.1738 173.809 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M155.948 28.1738H151.769C150.038 28.1738 148.635 29.5777 148.635 31.3095V38.8621C148.635 40.5939 150.038 41.9977 151.769 41.9977H155.948C157.679 41.9977 159.082 40.5939 159.082 38.8621V31.3095C159.082 29.5777 157.679 28.1738 155.948 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M137.917 28.1738H133.738C132.007 28.1738 130.604 29.5777 130.604 31.3095V38.8621C130.604 40.5939 132.007 41.9977 133.738 41.9977H137.917C139.647 41.9977 141.051 40.5939 141.051 38.8621V31.3095C141.051 29.5777 139.647 28.1738 137.917 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M227.559 28.1738H223.38C221.649 28.1738 220.246 29.5777 220.246 31.3095V63.644C220.246 65.3757 221.649 66.7796 223.38 66.7796H227.559C229.29 66.7796 230.693 65.3757 230.693 63.644V31.3095C230.693 29.5777 229.29 28.1738 227.559 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M209.698 28.1738H205.519C203.788 28.1738 202.385 29.5777 202.385 31.3095V63.644C202.385 65.3757 203.788 66.7796 205.519 66.7796H209.698C211.429 66.7796 212.832 65.3757 212.832 63.644V31.3095C212.832 29.5777 211.429 28.1738 209.698 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M191.668 28.1738H187.49C185.759 28.1738 184.355 29.5777 184.355 31.3095V63.644C184.355 65.3757 185.759 66.7796 187.49 66.7796H191.668C193.399 66.7796 194.803 65.3757 194.803 63.644V31.3095C194.803 29.5777 193.399 28.1738 191.668 28.1738Z"
    fill="#FEAC18"
  />
  <path
    d="M359.176 41.3896H189.595C186.012 41.3896 183.107 44.2958 183.107 47.8807V101.032C183.107 104.617 186.012 107.523 189.595 107.523H359.176C362.759 107.523 365.664 104.617 365.664 101.032V47.8807C365.664 44.2958 362.759 41.3896 359.176 41.3896Z"
    fill="#43246B"
  />
  <path
    d="M196.084 107.523H189.577C186.007 107.523 183.11 104.623 183.11 101.053V47.8619C183.11 44.2899 186.009 41.3916 189.577 41.3916H189.617C193.187 41.3916 196.084 44.2919 196.084 47.8619V107.525V107.523Z"
    fill="#562F88"
  />
  <path
    d="M359.097 41.3896C362.678 41.3896 365.585 44.2983 365.585 47.8807V101.032C365.585 104.615 362.678 107.523 359.097 107.523H352.609V47.8807C352.609 44.2983 355.517 41.3896 359.097 41.3896Z"
    fill="#562F88"
  />
  <path
    d="M226.689 41.3896H233.942C237.522 41.3896 240.429 44.2983 240.429 47.8807V107.523H220.201V47.8807C220.201 44.2983 223.108 41.3896 226.689 41.3896Z"
    fill="#562F88"
  />
  <path
    d="M254.89 82.6455H249.751C247.622 82.6455 245.896 84.3719 245.896 86.5016V95.7893C245.896 97.919 247.622 99.6454 249.751 99.6454H254.89C257.018 99.6454 258.744 97.919 258.744 95.7893V86.5016C258.744 84.3719 257.018 82.6455 254.89 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M254.89 53.415H249.751C247.622 53.415 245.896 55.1415 245.896 57.2711V66.5589C245.896 68.6885 247.622 70.4149 249.751 70.4149H254.89C257.018 70.4149 258.744 68.6885 258.744 66.5589V57.2711C258.744 55.1415 257.018 53.415 254.89 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M232.923 53.415H227.784C225.655 53.415 223.93 55.1415 223.93 57.2711V66.5589C223.93 68.6885 225.655 70.4149 227.784 70.4149H232.923C235.051 70.4149 236.777 68.6885 236.777 66.5589V57.2711C236.777 55.1415 235.051 53.415 232.923 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M210.751 53.415H205.612C203.483 53.415 201.758 55.1415 201.758 57.2711V66.5589C201.758 68.6885 203.483 70.4149 205.612 70.4149H210.751C212.88 70.4149 214.605 68.6885 214.605 66.5589V57.2711C214.605 55.1415 212.88 53.415 210.751 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M210.751 82.6455H205.612C203.483 82.6455 201.758 84.3719 201.758 86.5016V95.7893C201.758 97.919 203.483 99.6454 205.612 99.6454H210.751C212.88 99.6454 214.605 97.919 214.605 95.7893V86.5016C214.605 84.3719 212.88 82.6455 210.751 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M227.784 82.6455H232.925C235.051 82.6455 236.779 84.3724 236.779 86.5016V107.523H223.932V86.5016C223.932 84.3745 225.658 82.6455 227.786 82.6455H227.784Z"
    fill="#FEAC18"
  />
  <path
    d="M270.826 41.3896H278.078C281.659 41.3896 284.566 44.2983 284.566 47.8807V107.523H264.338V47.8807C264.338 44.2983 267.245 41.3896 270.826 41.3896Z"
    fill="#562F88"
  />
  <path
    d="M277.06 53.415H271.921C269.792 53.415 268.066 55.1415 268.066 57.2711V66.5589C268.066 68.6885 269.792 70.4149 271.921 70.4149H277.06C279.188 70.4149 280.914 68.6885 280.914 66.5589V57.2711C280.914 55.1415 279.188 53.415 277.06 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M254.89 53.415H249.751C247.622 53.415 245.896 55.1415 245.896 57.2711V66.5589C245.896 68.6885 247.622 70.4149 249.751 70.4149H254.89C257.018 70.4149 258.744 68.6885 258.744 66.5589V57.2711C258.744 55.1415 257.018 53.415 254.89 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M254.89 82.6455H249.751C247.622 82.6455 245.896 84.3719 245.896 86.5016V95.7893C245.896 97.919 247.622 99.6454 249.751 99.6454H254.89C257.018 99.6454 258.744 97.919 258.744 95.7893V86.5016C258.744 84.3719 257.018 82.6455 254.89 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M315.377 41.3896H322.629C326.21 41.3896 329.117 44.2983 329.117 47.8807V107.523H308.889V47.8807C308.889 44.2983 311.796 41.3896 315.377 41.3896Z"
    fill="#562F88"
  />
  <path
    d="M321.61 53.415H316.471C314.343 53.415 312.617 55.1415 312.617 57.2711V66.5589C312.617 68.6885 314.343 70.4149 316.471 70.4149H321.61C323.739 70.4149 325.465 68.6885 325.465 66.5589V57.2711C325.465 55.1415 323.739 53.415 321.61 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M299.436 53.415H294.298C292.169 53.415 290.443 55.1415 290.443 57.2711V66.5589C290.443 68.6885 292.169 70.4149 294.298 70.4149H299.436C301.565 70.4149 303.291 68.6885 303.291 66.5589V57.2711C303.291 55.1415 301.565 53.415 299.436 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M299.436 82.6455H294.298C292.169 82.6455 290.443 84.3719 290.443 86.5016V95.7893C290.443 97.919 292.169 99.6454 294.298 99.6454H299.436C301.565 99.6454 303.291 97.919 303.291 95.7893V86.5016C303.291 84.3719 301.565 82.6455 299.436 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M343.159 53.415H338.02C335.892 53.415 334.166 55.1415 334.166 57.2711V66.5589C334.166 68.6885 335.892 70.4149 338.02 70.4149H343.159C345.288 70.4149 347.013 68.6885 347.013 66.5589V57.2711C347.013 55.1415 345.288 53.415 343.159 53.415Z"
    fill="#FEAC18"
  />
  <path
    d="M343.159 82.6455H338.02C335.892 82.6455 334.166 84.3719 334.166 86.5016V95.7893C334.166 97.919 335.892 99.6454 338.02 99.6454H343.159C345.288 99.6454 347.013 97.919 347.013 95.7893V86.5016C347.013 84.3719 345.288 82.6455 343.159 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M277.06 82.6455H271.921C269.792 82.6455 268.066 84.3719 268.066 86.5016V95.7893C268.066 97.919 269.792 99.6454 271.921 99.6454H277.06C279.188 99.6454 280.914 97.919 280.914 95.7893V86.5016C280.914 84.3719 279.188 82.6455 277.06 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M321.61 82.6455H316.471C314.343 82.6455 312.617 84.3719 312.617 86.5016V95.7893C312.617 97.919 314.343 99.6454 316.471 99.6454H321.61C323.739 99.6454 325.465 97.919 325.465 95.7893V86.5016C325.465 84.3719 323.739 82.6455 321.61 82.6455Z"
    fill="#FEAC18"
  />
  <path
    d="M363.942 36H186.488C182.905 36 180 38.9061 180 42.4911C180 46.076 182.905 48.9821 186.488 48.9821H363.942C367.526 48.9821 370.43 46.076 370.43 42.4911C370.43 38.9061 367.526 36 363.942 36Z"
    fill="#43246B"
  />
  <path
    d="M417.595 3.04688H347.297C344.199 3.04688 341.688 5.55952 341.688 8.65904V108.387C341.688 111.487 344.199 114 347.297 114H417.595C420.693 114 423.204 111.487 423.204 108.387V8.65904C423.204 5.55952 420.693 3.04688 417.595 3.04688Z"
    fill="#3B246B"
  />
  <path
    d="M421.54 87.8301H343.352C342.433 87.8301 341.688 88.5756 341.688 89.4953V112.335C341.688 113.254 342.433 114 343.352 114H421.54C422.459 114 423.204 113.254 423.204 112.335V89.4953C423.204 88.5756 422.459 87.8301 421.54 87.8301Z"
    fill="#4C2F89"
  />
  <path
    d="M352.906 8.65904C352.906 5.55952 350.395 3.04688 347.297 3.04688C344.199 3.04688 341.688 5.55952 341.688 8.65904V108.387C341.688 111.487 344.199 114 347.297 114C350.395 114 352.906 111.487 352.906 108.387V8.65904Z"
    fill="#4C2F89"
  />
  <path
    d="M385.638 3.04688H379.367C376.269 3.04688 373.758 5.55952 373.758 8.65904V108.387C373.758 111.487 376.269 114 379.367 114H385.638C388.736 114 391.247 111.487 391.247 108.387V8.65904C391.247 5.55952 388.736 3.04688 385.638 3.04688Z"
    fill="#4C2F89"
  />
  <path
    d="M403.748 92.4893H399.305C397.465 92.4893 395.973 93.9819 395.973 95.8232V103.853C395.973 105.695 397.465 107.187 399.305 107.187H403.748C405.588 107.187 407.08 105.695 407.08 103.853V95.8232C407.08 93.9819 405.588 92.4893 403.748 92.4893Z"
    fill="#FEAC18"
  />
  <path
    d="M403.748 67.2168H399.305C397.465 67.2168 395.973 68.7095 395.973 70.5507V78.5809C395.973 80.4222 397.465 81.9149 399.305 81.9149H403.748C405.588 81.9149 407.08 80.4222 407.08 78.5809V70.5507C407.08 68.7095 405.588 67.2168 403.748 67.2168Z"
    fill="#FEAC18"
  />
  <path
    d="M403.748 49.1123H399.305C397.465 49.1123 395.973 50.605 395.973 52.4463V60.4764C395.973 62.3177 397.465 63.8104 399.305 63.8104H403.748C405.588 63.8104 407.08 62.3177 407.08 60.4764V52.4463C407.08 50.605 405.588 49.1123 403.748 49.1123Z"
    fill="#FEAC18"
  />
  <path
    d="M403.748 31.0098H399.305C397.465 31.0098 395.973 32.5024 395.973 34.3437V42.3739C395.973 44.2152 397.465 45.7079 399.305 45.7079H403.748C405.588 45.7079 407.08 44.2152 407.08 42.3739V34.3437C407.08 32.5024 405.588 31.0098 403.748 31.0098Z"
    fill="#FEAC18"
  />
  <path
    d="M403.748 13.8018H399.305C397.465 13.8018 395.973 15.2944 395.973 17.1357V25.1659C395.973 27.0072 397.465 28.4998 399.305 28.4998H403.748C405.588 28.4998 407.08 27.0072 407.08 25.1659V17.1357C407.08 15.2944 405.588 13.8018 403.748 13.8018Z"
    fill="#FEAC18"
  />
  <path
    d="M384.756 67.2168H380.313C378.472 67.2168 376.98 68.7095 376.98 70.5507V78.5809C376.98 80.4222 378.472 81.9149 380.313 81.9149H384.756C386.596 81.9149 388.088 80.4222 388.088 78.5809V70.5507C388.088 68.7095 386.596 67.2168 384.756 67.2168Z"
    fill="#FEAC18"
  />
  <path
    d="M384.756 49.1123H380.313C378.472 49.1123 376.98 50.605 376.98 52.4463V60.4764C376.98 62.3177 378.472 63.8104 380.313 63.8104H384.756C386.596 63.8104 388.088 62.3177 388.088 60.4764V52.4463C388.088 50.605 386.596 49.1123 384.756 49.1123Z"
    fill="#FEAC18"
  />
  <path
    d="M384.756 31.0098H380.313C378.472 31.0098 376.98 32.5024 376.98 34.3437V42.3739C376.98 44.2152 378.472 45.7079 380.313 45.7079H384.756C386.596 45.7079 388.088 44.2152 388.088 42.3739V34.3437C388.088 32.5024 386.596 31.0098 384.756 31.0098Z"
    fill="#FEAC18"
  />
  <path
    d="M384.756 13.8018H380.313C378.472 13.8018 376.98 15.2944 376.98 17.1357V25.1659C376.98 27.0072 378.472 28.4998 380.313 28.4998H384.756C386.596 28.4998 388.088 27.0072 388.088 25.1659V17.1357C388.088 15.2944 386.596 13.8018 384.756 13.8018Z"
    fill="#FEAC18"
  />
  <path
    d="M365.586 67.2168H361.143C359.302 67.2168 357.811 68.7095 357.811 70.5507V78.5809C357.811 80.4222 359.302 81.9149 361.143 81.9149H365.586C367.426 81.9149 368.918 80.4222 368.918 78.5809V70.5507C368.918 68.7095 367.426 67.2168 365.586 67.2168Z"
    fill="#FEAC18"
  />
  <path
    d="M365.586 49.1123H361.143C359.302 49.1123 357.811 50.605 357.811 52.4463V60.4764C357.811 62.3177 359.302 63.8104 361.143 63.8104H365.586C367.426 63.8104 368.918 62.3177 368.918 60.4764V52.4463C368.918 50.605 367.426 49.1123 365.586 49.1123Z"
    fill="#FEAC18"
  />
  <path
    d="M365.586 31.0098H361.143C359.302 31.0098 357.811 32.5024 357.811 34.3437V42.3739C357.811 44.2152 359.302 45.7079 361.143 45.7079H365.586C367.426 45.7079 368.918 44.2152 368.918 42.3739V34.3437C368.918 32.5024 367.426 31.0098 365.586 31.0098Z"
    fill="#FEAC18"
  />
  <path
    d="M365.586 13.8018H361.143C359.302 13.8018 357.811 15.2944 357.811 17.1357V25.1659C357.811 27.0072 359.302 28.4998 361.143 28.4998H365.586C367.426 28.4998 368.918 27.0072 368.918 25.1659V17.1357C368.918 15.2944 367.426 13.8018 365.586 13.8018Z"
    fill="#FEAC18"
  />
  <path
    d="M365.586 92.4893H361.143C359.302 92.4893 357.811 93.9819 357.811 95.8232V103.853C357.811 105.695 359.302 107.187 361.143 107.187H365.586C367.426 107.187 368.918 105.695 368.918 103.853V95.8232C368.918 93.9819 367.426 92.4893 365.586 92.4893Z"
    fill="#FEAC18"
  />
  <path
    d="M380.315 92.4893H384.76C386.598 92.4893 388.092 93.9824 388.092 95.8232V113.999H376.984V95.8232C376.984 93.9842 378.477 92.4893 380.317 92.4893H380.315Z"
    fill="#FEAC18"
  />
  <path
    d="M422.955 8.65904C422.955 5.55952 420.444 3.04688 417.346 3.04688C414.248 3.04688 411.736 5.55952 411.736 8.65904V108.387C411.736 111.487 414.248 114 417.346 114C420.444 114 422.955 111.487 422.955 108.387V8.65904Z"
    fill="#4C2F89"
  />
  <path
    d="M420.282 0H344.609C341.511 0 339 2.51265 339 5.61216C339 8.71167 341.511 11.2243 344.609 11.2243H420.282C423.38 11.2243 425.891 8.71167 425.891 5.61216C425.891 2.51265 423.38 0 420.282 0Z"
    fill="#3B246B"
  />
  <path
    d="M820.158 18.542H715.368C710.75 18.542 707.006 22.2875 707.006 26.9078V175.569C707.006 180.189 710.75 183.934 715.368 183.934H820.158C824.776 183.934 828.519 180.189 828.519 175.569V26.9078C828.519 22.2875 824.776 18.542 820.158 18.542Z"
    fill="#3B246B"
  />
  <path
    d="M826.038 144.925H709.487C708.117 144.925 707.006 146.036 707.006 147.407V181.453C707.006 182.824 708.117 183.935 709.487 183.935H826.038C827.408 183.935 828.519 182.824 828.519 181.453V147.407C828.519 146.036 827.408 144.925 826.038 144.925Z"
    fill="#4C2F89"
  />
  <path
    d="M723.729 26.9078C723.729 22.2875 719.986 18.542 715.368 18.542C710.75 18.542 707.006 22.2875 707.006 26.9078V175.569C707.006 180.189 710.75 183.934 715.368 183.934C719.986 183.934 723.729 180.189 723.729 175.569V26.9078Z"
    fill="#4C2F89"
  />
  <path
    d="M772.521 18.542H763.174C758.556 18.542 754.812 22.2875 754.812 26.9078V175.569C754.812 180.189 758.556 183.934 763.174 183.934H772.521C777.139 183.934 780.883 180.189 780.883 175.569V26.9078C780.883 22.2875 777.139 18.542 772.521 18.542Z"
    fill="#4C2F89"
  />
  <path
    d="M799.518 151.87H792.895C790.152 151.87 787.928 154.095 787.928 156.84V168.81C787.928 171.555 790.152 173.78 792.895 173.78H799.518C802.262 173.78 804.486 171.555 804.486 168.81V156.84C804.486 154.095 802.262 151.87 799.518 151.87Z"
    fill="#FEAC18"
  />
  <path
    d="M799.518 114.197H792.895C790.152 114.197 787.928 116.422 787.928 119.167V131.137C787.928 133.882 790.152 136.107 792.895 136.107H799.518C802.262 136.107 804.486 133.882 804.486 131.137V119.167C804.486 116.422 802.262 114.197 799.518 114.197Z"
    fill="#FEAC18"
  />
  <path
    d="M799.518 87.21H792.895C790.152 87.21 787.928 89.435 787.928 92.1797V104.15C787.928 106.895 790.152 109.12 792.895 109.12H799.518C802.262 109.12 804.486 106.895 804.486 104.15V92.1797C804.486 89.435 802.262 87.21 799.518 87.21Z"
    fill="#FEAC18"
  />
  <path
    d="M799.518 60.2246H792.895C790.152 60.2246 787.928 62.4497 787.928 65.1944V77.1646C787.928 79.9094 790.152 82.1344 792.895 82.1344H799.518C802.262 82.1344 804.486 79.9094 804.486 77.1646V65.1944C804.486 62.4497 802.262 60.2246 799.518 60.2246Z"
    fill="#FEAC18"
  />
  <path
    d="M799.518 34.5742H792.895C790.152 34.5742 787.928 36.7993 787.928 39.544V51.5142C787.928 54.259 790.152 56.484 792.895 56.484H799.518C802.262 56.484 804.486 54.259 804.486 51.5142V39.544C804.486 36.7993 802.262 34.5742 799.518 34.5742Z"
    fill="#FEAC18"
  />
  <path
    d="M771.208 114.197H764.585C761.841 114.197 759.617 116.422 759.617 119.167V131.137C759.617 133.882 761.841 136.107 764.585 136.107H771.208C773.951 136.107 776.175 133.882 776.175 131.137V119.167C776.175 116.422 773.951 114.197 771.208 114.197Z"
    fill="#FEAC18"
  />
  <path
    d="M771.208 87.21H764.585C761.841 87.21 759.617 89.435 759.617 92.1797V104.15C759.617 106.895 761.841 109.12 764.585 109.12H771.208C773.951 109.12 776.175 106.895 776.175 104.15V92.1797C776.175 89.435 773.951 87.21 771.208 87.21Z"
    fill="#FEAC18"
  />
  <path
    d="M771.208 60.2246H764.585C761.841 60.2246 759.617 62.4497 759.617 65.1944V77.1646C759.617 79.9094 761.841 82.1344 764.585 82.1344H771.208C773.951 82.1344 776.175 79.9094 776.175 77.1646V65.1944C776.175 62.4497 773.951 60.2246 771.208 60.2246Z"
    fill="#FEAC18"
  />
  <path
    d="M771.208 34.5742H764.585C761.841 34.5742 759.617 36.7993 759.617 39.544V51.5142C759.617 54.259 761.841 56.484 764.585 56.484H771.208C773.951 56.484 776.175 54.259 776.175 51.5142V39.544C776.175 36.7993 773.951 34.5742 771.208 34.5742Z"
    fill="#FEAC18"
  />
  <path
    d="M742.632 114.197H736.008C733.265 114.197 731.041 116.422 731.041 119.167V131.137C731.041 133.882 733.265 136.107 736.008 136.107H742.632C745.375 136.107 747.599 133.882 747.599 131.137V119.167C747.599 116.422 745.375 114.197 742.632 114.197Z"
    fill="#FEAC18"
  />
  <path
    d="M742.632 87.21H736.008C733.265 87.21 731.041 89.435 731.041 92.1797V104.15C731.041 106.895 733.265 109.12 736.008 109.12H742.632C745.375 109.12 747.599 106.895 747.599 104.15V92.1797C747.599 89.435 745.375 87.21 742.632 87.21Z"
    fill="#FEAC18"
  />
  <path
    d="M742.632 60.2246H736.008C733.265 60.2246 731.041 62.4497 731.041 65.1944V77.1646C731.041 79.9094 733.265 82.1344 736.008 82.1344H742.632C745.375 82.1344 747.599 79.9094 747.599 77.1646V65.1944C747.599 62.4497 745.375 60.2246 742.632 60.2246Z"
    fill="#FEAC18"
  />
  <path
    d="M742.632 34.5742H736.008C733.265 34.5742 731.041 36.7993 731.041 39.544V51.5142C731.041 54.259 733.265 56.484 736.008 56.484H742.632C745.375 56.484 747.599 54.259 747.599 51.5142V39.544C747.599 36.7993 745.375 34.5742 742.632 34.5742Z"
    fill="#FEAC18"
  />
  <path
    d="M742.632 151.87H736.008C733.265 151.87 731.041 154.095 731.041 156.84V168.81C731.041 171.555 733.265 173.78 736.008 173.78H742.632C745.375 173.78 747.599 171.555 747.599 168.81V156.84C747.599 154.095 745.375 151.87 742.632 151.87Z"
    fill="#FEAC18"
  />
  <path
    d="M764.586 151.87H771.212C773.952 151.87 776.179 154.096 776.179 156.84V183.933H759.621V156.84C759.621 154.098 761.846 151.87 764.588 151.87H764.586Z"
    fill="#FEAC18"
  />
  <path
    d="M824.164 14H711.362C706.744 14 703 17.7455 703 22.3658C703 26.9861 706.744 30.7316 711.362 30.7316H824.164C828.782 30.7316 832.525 26.9861 832.525 22.3658C832.525 17.7455 828.782 14 824.164 14Z"
    fill="#3B246B"
  />
  <path
    d="M751.158 90.542H646.368C641.75 90.542 638.006 94.2875 638.006 98.9078V247.569C638.006 252.189 641.75 255.934 646.368 255.934H751.158C755.776 255.934 759.519 252.189 759.519 247.569V98.9078C759.519 94.2875 755.776 90.542 751.158 90.542Z"
    fill="#43246B"
  />
  <path
    d="M757.038 216.925H640.487C639.117 216.925 638.006 218.036 638.006 219.407V253.453C638.006 254.824 639.117 255.935 640.487 255.935H757.038C758.408 255.935 759.519 254.824 759.519 253.453V219.407C759.519 218.036 758.408 216.925 757.038 216.925Z"
    fill="#562F88"
  />
  <path
    d="M654.729 98.9078C654.729 94.2875 650.986 90.542 646.368 90.542C641.75 90.542 638.006 94.2875 638.006 98.9078V247.569C638.006 252.189 641.75 255.934 646.368 255.934C650.986 255.934 654.729 252.189 654.729 247.569V98.9078Z"
    fill="#562F88"
  />
  <path
    d="M703.521 90.542H694.174C689.556 90.542 685.812 94.2875 685.812 98.9078V247.569C685.812 252.189 689.556 255.934 694.174 255.934H703.521C708.139 255.934 711.883 252.189 711.883 247.569V98.9078C711.883 94.2875 708.139 90.542 703.521 90.542Z"
    fill="#562F88"
  />
  <path
    d="M730.518 223.87H723.895C721.152 223.87 718.928 226.095 718.928 228.84V240.81C718.928 243.555 721.152 245.78 723.895 245.78H730.518C733.262 245.78 735.486 243.555 735.486 240.81V228.84C735.486 226.095 733.262 223.87 730.518 223.87Z"
    fill="#FEAC18"
  />
  <path
    d="M730.518 186.197H723.895C721.152 186.197 718.928 188.422 718.928 191.167V203.137C718.928 205.882 721.152 208.107 723.895 208.107H730.518C733.262 208.107 735.486 205.882 735.486 203.137V191.167C735.486 188.422 733.262 186.197 730.518 186.197Z"
    fill="#FEAC18"
  />
  <path
    d="M730.518 159.21H723.895C721.152 159.21 718.928 161.435 718.928 164.18V176.15C718.928 178.895 721.152 181.12 723.895 181.12H730.518C733.262 181.12 735.486 178.895 735.486 176.15V164.18C735.486 161.435 733.262 159.21 730.518 159.21Z"
    fill="#FEAC18"
  />
  <path
    d="M730.518 132.225H723.895C721.152 132.225 718.928 134.45 718.928 137.194V149.165C718.928 151.909 721.152 154.134 723.895 154.134H730.518C733.262 154.134 735.486 151.909 735.486 149.165V137.194C735.486 134.45 733.262 132.225 730.518 132.225Z"
    fill="#FEAC18"
  />
  <path
    d="M730.518 106.574H723.895C721.152 106.574 718.928 108.799 718.928 111.544V123.514C718.928 126.259 721.152 128.484 723.895 128.484H730.518C733.262 128.484 735.486 126.259 735.486 123.514V111.544C735.486 108.799 733.262 106.574 730.518 106.574Z"
    fill="#FEAC18"
  />
  <path
    d="M702.208 186.197H695.585C692.841 186.197 690.617 188.422 690.617 191.167V203.137C690.617 205.882 692.841 208.107 695.585 208.107H702.208C704.951 208.107 707.175 205.882 707.175 203.137V191.167C707.175 188.422 704.951 186.197 702.208 186.197Z"
    fill="#FEAC18"
  />
  <path
    d="M702.208 159.21H695.585C692.841 159.21 690.617 161.435 690.617 164.18V176.15C690.617 178.895 692.841 181.12 695.585 181.12H702.208C704.951 181.12 707.175 178.895 707.175 176.15V164.18C707.175 161.435 704.951 159.21 702.208 159.21Z"
    fill="#FEAC18"
  />
  <path
    d="M702.208 132.225H695.585C692.841 132.225 690.617 134.45 690.617 137.194V149.165C690.617 151.909 692.841 154.134 695.585 154.134H702.208C704.951 154.134 707.175 151.909 707.175 149.165V137.194C707.175 134.45 704.951 132.225 702.208 132.225Z"
    fill="#FEAC18"
  />
  <path
    d="M702.208 106.574H695.585C692.841 106.574 690.617 108.799 690.617 111.544V123.514C690.617 126.259 692.841 128.484 695.585 128.484H702.208C704.951 128.484 707.175 126.259 707.175 123.514V111.544C707.175 108.799 704.951 106.574 702.208 106.574Z"
    fill="#FEAC18"
  />
  <path
    d="M673.632 186.197H667.008C664.265 186.197 662.041 188.422 662.041 191.167V203.137C662.041 205.882 664.265 208.107 667.008 208.107H673.632C676.375 208.107 678.599 205.882 678.599 203.137V191.167C678.599 188.422 676.375 186.197 673.632 186.197Z"
    fill="#FEAC18"
  />
  <path
    d="M673.632 159.21H667.008C664.265 159.21 662.041 161.435 662.041 164.18V176.15C662.041 178.895 664.265 181.12 667.008 181.12H673.632C676.375 181.12 678.599 178.895 678.599 176.15V164.18C678.599 161.435 676.375 159.21 673.632 159.21Z"
    fill="#FEAC18"
  />
  <path
    d="M673.632 132.225H667.008C664.265 132.225 662.041 134.45 662.041 137.194V149.165C662.041 151.909 664.265 154.134 667.008 154.134H673.632C676.375 154.134 678.599 151.909 678.599 149.165V137.194C678.599 134.45 676.375 132.225 673.632 132.225Z"
    fill="#FEAC18"
  />
  <path
    d="M673.632 106.574H667.008C664.265 106.574 662.041 108.799 662.041 111.544V123.514C662.041 126.259 664.265 128.484 667.008 128.484H673.632C676.375 128.484 678.599 126.259 678.599 123.514V111.544C678.599 108.799 676.375 106.574 673.632 106.574Z"
    fill="#FEAC18"
  />
  <path
    d="M673.632 223.87H667.008C664.265 223.87 662.041 226.095 662.041 228.84V240.81C662.041 243.555 664.265 245.78 667.008 245.78H673.632C676.375 245.78 678.599 243.555 678.599 240.81V228.84C678.599 226.095 676.375 223.87 673.632 223.87Z"
    fill="#FEAC18"
  />
  <path
    d="M695.586 223.87H702.212C704.952 223.87 707.179 226.096 707.179 228.84V255.933H690.621V228.84C690.621 226.098 692.846 223.87 695.588 223.87H695.586Z"
    fill="#FEAC18"
  />
  <path
    d="M759.149 98.9078C759.149 94.2875 755.406 90.542 750.788 90.542C746.169 90.542 742.426 94.2875 742.426 98.9078V247.569C742.426 252.189 746.169 255.934 750.788 255.934C755.406 255.934 759.149 252.189 759.149 247.569V98.9078Z"
    fill="#562F88"
  />
  <path
    d="M755.164 86H642.362C637.744 86 634 89.7455 634 94.3658C634 98.9861 637.744 102.732 642.362 102.732H755.164C759.782 102.732 763.525 98.9861 763.525 94.3658C763.525 89.7455 759.782 86 755.164 86Z"
    fill="#43246B"
  />
  <rect
    x="770.42"
    y="218.89"
    width="17.2103"
    height="42.6517"
    rx="8.60516"
    fill="url(#paint0_linear_909_3)"
  />
  <circle cx="794.926" cy="209.349" r="22.6353" fill="#069461" />
  <circle cx="772.479" cy="193.635" r="22.6353" fill="#069461" />
  <circle cx="758.635" cy="213.464" r="22.6353" fill="#D9D9D9" />
  <circle cx="758.635" cy="213.464" r="22.6353" fill="#069461" />
  <path
    d="M786.926 209.946H568.368C563.75 209.946 560.006 213.692 560.006 218.312V286.815C560.006 291.435 563.75 295.181 568.368 295.181H786.926C791.544 295.181 795.288 291.435 795.288 286.815V218.312C795.288 213.692 791.544 209.946 786.926 209.946Z"
    fill="#3B246B"
  />
  <path
    d="M576.73 295.181H568.345C563.743 295.181 560.01 291.443 560.01 286.842V218.288C560.01 213.684 563.746 209.949 568.345 209.949H568.395C572.997 209.949 576.73 213.687 576.73 218.288V295.184V295.181Z"
    fill="#4C2F89"
  />
  <path
    d="M786.825 209.946C791.439 209.946 795.186 213.695 795.186 218.312V286.815C795.186 291.432 791.439 295.181 786.825 295.181H778.463V218.312C778.463 213.695 782.21 209.946 786.825 209.946Z"
    fill="#4C2F89"
  />
  <path
    d="M616.174 209.946H625.521C630.136 209.946 633.883 213.695 633.883 218.312V295.181H607.812V218.312C607.812 213.695 611.559 209.946 616.174 209.946Z"
    fill="#4C2F89"
  />
  <path
    d="M652.518 263.118H645.895C643.152 263.118 640.928 265.343 640.928 268.088V280.058C640.928 282.803 643.152 285.028 645.895 285.028H652.518C655.262 285.028 657.486 282.803 657.486 280.058V268.088C657.486 265.343 655.262 263.118 652.518 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M652.518 225.444H645.895C643.152 225.444 640.928 227.669 640.928 230.414V242.384C640.928 245.129 643.152 247.354 645.895 247.354H652.518C655.262 247.354 657.486 245.129 657.486 242.384V230.414C657.486 227.669 655.262 225.444 652.518 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M624.208 225.444H617.585C614.841 225.444 612.617 227.669 612.617 230.414V242.384C612.617 245.129 614.841 247.354 617.585 247.354H624.208C626.951 247.354 629.175 245.129 629.175 242.384V230.414C629.175 227.669 626.951 225.444 624.208 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M595.632 225.444H589.008C586.265 225.444 584.041 227.669 584.041 230.414V242.384C584.041 245.129 586.265 247.354 589.008 247.354H595.632C598.375 247.354 600.599 245.129 600.599 242.384V230.414C600.599 227.669 598.375 225.444 595.632 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M595.632 263.118H589.008C586.265 263.118 584.041 265.343 584.041 268.088V280.058C584.041 282.803 586.265 285.028 589.008 285.028H595.632C598.375 285.028 600.599 282.803 600.599 280.058V268.088C600.599 265.343 598.375 263.118 595.632 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M617.586 263.118H624.212C626.952 263.118 629.179 265.344 629.179 268.088V295.181H612.621V268.088C612.621 265.347 614.846 263.118 617.588 263.118H617.586Z"
    fill="#FEAC18"
  />
  <path
    d="M673.057 209.946H682.404C687.019 209.946 690.766 213.695 690.766 218.312V295.181H664.695V218.312C664.695 213.695 668.442 209.946 673.057 209.946Z"
    fill="#4C2F89"
  />
  <path
    d="M681.092 225.444H674.469C671.726 225.444 669.502 227.669 669.502 230.414V242.384C669.502 245.129 671.726 247.354 674.469 247.354H681.092C683.836 247.354 686.06 245.129 686.06 242.384V230.414C686.06 227.669 683.836 225.444 681.092 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M652.518 225.444H645.895C643.152 225.444 640.928 227.669 640.928 230.414V242.384C640.928 245.129 643.152 247.354 645.895 247.354H652.518C655.262 247.354 657.486 245.129 657.486 242.384V230.414C657.486 227.669 655.262 225.444 652.518 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M652.518 263.118H645.895C643.152 263.118 640.928 265.343 640.928 268.088V280.058C640.928 282.803 643.152 285.028 645.895 285.028H652.518C655.262 285.028 657.486 282.803 657.486 280.058V268.088C657.486 265.343 655.262 263.118 652.518 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M730.475 209.946H739.822C744.437 209.946 748.184 213.695 748.184 218.312V295.181H722.113V218.312C722.113 213.695 725.86 209.946 730.475 209.946Z"
    fill="#4C2F89"
  />
  <path
    d="M738.51 225.444H731.887C729.144 225.444 726.92 227.669 726.92 230.414V242.384C726.92 245.129 729.144 247.354 731.887 247.354H738.51C741.254 247.354 743.478 245.129 743.478 242.384V230.414C743.478 227.669 741.254 225.444 738.51 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M709.932 225.444H703.309C700.566 225.444 698.342 227.669 698.342 230.414V242.384C698.342 245.129 700.566 247.354 703.309 247.354H709.932C712.676 247.354 714.9 245.129 714.9 242.384V230.414C714.9 227.669 712.676 225.444 709.932 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M709.932 263.118H703.309C700.566 263.118 698.342 265.343 698.342 268.088V280.058C698.342 282.803 700.566 285.028 703.309 285.028H709.932C712.676 285.028 714.9 282.803 714.9 280.058V268.088C714.9 265.343 712.676 263.118 709.932 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M766.284 225.444H759.661C756.917 225.444 754.693 227.669 754.693 230.414V242.384C754.693 245.129 756.917 247.354 759.661 247.354H766.284C769.027 247.354 771.251 245.129 771.251 242.384V230.414C771.251 227.669 769.027 225.444 766.284 225.444Z"
    fill="#FEAC18"
  />
  <path
    d="M766.284 263.118H759.661C756.917 263.118 754.693 265.343 754.693 268.088V280.058C754.693 282.803 756.917 285.028 759.661 285.028H766.284C769.027 285.028 771.251 282.803 771.251 280.058V268.088C771.251 265.343 769.027 263.118 766.284 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M681.092 263.118H674.469C671.726 263.118 669.502 265.343 669.502 268.088V280.058C669.502 282.803 671.726 285.028 674.469 285.028H681.092C683.836 285.028 686.06 282.803 686.06 280.058V268.088C686.06 265.343 683.836 263.118 681.092 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M738.51 263.118H731.887C729.144 263.118 726.92 265.343 726.92 268.088V280.058C726.92 282.803 729.144 285.028 731.887 285.028H738.51C741.254 285.028 743.478 282.803 743.478 280.058V268.088C743.478 265.343 741.254 263.118 738.51 263.118Z"
    fill="#FEAC18"
  />
  <path
    d="M793.069 203H564.362C559.744 203 556 206.745 556 211.366C556 215.986 559.744 219.732 564.362 219.732H793.069C797.687 219.732 801.43 215.986 801.43 211.366C801.43 206.745 797.687 203 793.069 203Z"
    fill="#3B246B"
  />
  <circle
    cx="24.1433"
    cy="24.1433"
    r="24.1433"
    transform="matrix(-1 0 0 1 759.057 208)"
    fill="#185E45"
  />
  <circle
    cx="24.1433"
    cy="24.1433"
    r="24.1433"
    transform="matrix(-1 0 0 1 746.287 224.761)"
    fill="#069461"
  />
  <ellipse
    cx="22.3475"
    cy="22.5471"
    rx="22.3475"
    ry="22.5471"
    transform="matrix(-1 0 0 1 772.625 216.38)"
    fill="#185E45"
  />
  <rect
    width="18.3569"
    height="45.4932"
    rx="9.17845"
    transform="matrix(-1 0 0 1 746.287 273.048)"
    fill="url(#paint1_linear_909_3)"
  />
  <circle
    cx="24.1433"
    cy="24.1433"
    r="24.1433"
    transform="matrix(-1 0 0 1 746.287 254.69)"
    fill="#069461"
  />
  <ellipse
    cx="24.1433"
    cy="23.9438"
    rx="24.1433"
    ry="23.9438"
    transform="matrix(-1 0 0 1 783 243.517)"
    fill="#D9D9D9"
  />
  <ellipse
    cx="24.1433"
    cy="23.9438"
    rx="24.1433"
    ry="23.9438"
    transform="matrix(-1 0 0 1 783 243.517)"
    fill="#069461"
  />
  <defs>
    <linearGradient
      id="paint0_linear_909_3"
      x1="779.025"
      y1="218.89"
      x2="779.025"
      y2="261.541"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_909_3"
      x1="9.17845"
      y1="0"
      x2="9.17845"
      y2="45.4932"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>
