<svg viewBox="0 0 399 469" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M277.211 230.255C287.635 242.078 243.041 277.36 235.973 326.482C231.551 357.171 240.44 402.592 302.412 465.093C275.917 462.962 260.945 454.073 252.01 445.619C244.873 438.871 240.933 431.78 233.682 431.872C222.204 432.021 220.566 449.914 206.19 453.637C193.12 457.028 188.274 443.831 170.679 444.473C157.277 444.966 153.875 452.835 135.168 460.511C126.566 464.039 113.301 468.106 95.0758 468.529C161.057 452.561 202.707 395.547 197.026 341.374C191.275 286.502 139.338 257.21 134.023 254.312C150.702 255.538 170.519 260.166 184.425 274.932C195.903 287.12 198.022 300.558 203.899 300.134C213.406 299.458 208.675 264.255 231.391 242.857C246.271 228.846 270.682 222.855 277.211 230.255Z"
    fill="url(#paint0_linear_544_9263)"
  />
  <path
    d="M164.948 276.078C137.745 276.078 115.692 254.024 115.692 226.819C115.692 199.614 137.745 177.561 164.948 177.561C192.152 177.561 214.205 199.614 214.205 226.819C214.205 254.024 192.152 276.078 164.948 276.078Z"
    fill="#10293A"
  />
  <path
    d="M271.48 298.99C244.276 298.99 222.223 276.936 222.223 249.731C222.223 222.526 244.276 200.473 271.48 200.473C298.683 200.473 320.736 222.526 320.736 249.731C320.736 276.936 298.683 298.99 271.48 298.99Z"
    fill="#10293A"
  />
  <path
    d="M334.486 289.824C307.282 289.824 285.229 267.77 285.229 240.565C285.229 213.36 307.282 191.307 334.486 191.307C361.689 191.307 383.742 213.36 383.742 240.565C383.742 267.77 361.689 289.824 334.486 289.824Z"
    fill="#10293A"
  />
  <path
    d="M103.097 295.55C75.8931 295.55 53.8401 273.497 53.8401 246.292C53.8401 219.087 75.8931 197.033 103.097 197.033C130.301 197.033 152.354 219.087 152.354 246.292C152.354 273.497 130.301 295.55 103.097 295.55Z"
    fill="#10293A"
  />
  <path
    d="M49.257 253.166C22.0533 253.166 0.000297546 231.112 0.000297546 203.907C0.000297546 176.702 22.0533 154.648 49.257 154.648C76.4607 154.648 98.5137 176.702 98.5137 203.907C98.5137 231.112 76.4607 253.166 49.257 253.166Z"
    fill="#10293A"
  />
  <path
    d="M178.7 249.728C151.497 249.728 129.444 227.674 129.444 200.47C129.444 173.265 151.497 151.211 178.7 151.211C205.904 151.211 227.957 173.265 227.957 200.47C227.957 227.674 205.904 249.728 178.7 249.728Z"
    fill="#185E45"
  />
  <path
    d="M227.954 98.5171C200.751 98.5171 178.698 76.4633 178.698 49.2586C178.698 22.0538 200.751 0 227.954 0C255.158 0 277.211 22.0538 277.211 49.2586C277.211 76.4633 255.158 98.5171 227.954 98.5171Z"
    fill="#3EEC64"
  />
  <path
    d="M263.464 115.701C236.26 115.701 214.207 93.6469 214.207 66.4422C214.207 39.2374 236.26 17.1836 263.464 17.1836C290.668 17.1836 312.721 39.2374 312.721 66.4422C312.721 93.6469 290.668 115.701 263.464 115.701Z"
    fill="#3EEC64"
  />
  <path
    d="M169.534 101.955C142.331 101.955 120.278 79.9008 120.278 52.6961C120.278 25.4913 142.331 3.4375 169.534 3.4375C196.738 3.4375 218.791 25.4913 218.791 52.6961C218.791 79.9008 196.738 101.955 169.534 101.955Z"
    fill="#3EEC64"
  />
  <path
    d="M178.7 132.884C151.497 132.884 129.444 110.831 129.444 83.6258C129.444 56.421 151.497 34.3672 178.7 34.3672C205.904 34.3672 227.957 56.421 227.957 83.6258C227.957 110.831 205.904 132.884 178.7 132.884Z"
    fill="#069461"
  />
  <path
    d="M234.829 160.375C207.626 160.375 185.573 138.321 185.573 111.116C185.573 83.9112 207.626 61.8574 234.829 61.8574C262.033 61.8574 284.086 83.9112 284.086 111.116C284.086 138.321 262.033 160.375 234.829 160.375Z"
    fill="#069461"
  />
  <path
    d="M123.716 122.574C96.5123 122.574 74.4593 100.52 74.4593 73.3152C74.4593 46.1105 96.5123 24.0566 123.716 24.0566C150.92 24.0566 172.973 46.1105 172.973 73.3152C172.973 100.52 150.92 122.574 123.716 122.574Z"
    fill="#069461"
  />
  <path
    d="M80.1847 138.611C52.981 138.611 30.928 116.557 30.928 89.3523C30.928 62.1476 52.981 40.0938 80.1847 40.0938C107.388 40.0938 129.441 62.1476 129.441 89.3523C129.441 116.557 107.388 138.611 80.1847 138.611Z"
    fill="#069461"
  />
  <path
    d="M49.257 184.431C22.0533 184.431 0.000297546 162.377 0.000297546 135.173C0.000297546 107.968 22.0533 85.9141 49.257 85.9141C76.4607 85.9141 98.5137 107.968 98.5137 135.173C98.5137 162.377 76.4607 184.431 49.257 184.431Z"
    fill="#069461"
  />
  <path
    d="M103.097 151.212C75.8931 151.212 53.8401 129.159 53.8401 101.954C53.8401 74.7491 75.8931 52.6953 103.097 52.6953C130.301 52.6953 152.354 74.7491 152.354 101.954C152.354 129.159 130.301 151.212 103.097 151.212Z"
    fill="#185E45"
  />
  <path
    d="M66.4367 200.47C39.233 200.47 17.18 178.416 17.18 151.212C17.18 124.007 39.233 101.953 66.4367 101.953C93.6404 101.953 115.693 124.007 115.693 151.212C115.693 178.416 93.6404 200.47 66.4367 200.47Z"
    fill="#185E45"
  />
  <path
    d="M312.718 138.611C285.514 138.611 263.461 116.557 263.461 89.3523C263.461 62.1476 285.514 40.0938 312.718 40.0938C339.922 40.0938 361.975 62.1476 361.975 89.3523C361.975 116.557 339.922 138.611 312.718 138.611Z"
    fill="#069461"
  />
  <path
    d="M326.47 182.142C299.266 182.142 277.213 160.088 277.213 132.884C277.213 105.679 299.266 83.625 326.47 83.625C353.674 83.625 375.727 105.679 375.727 132.884C375.727 160.088 353.674 182.142 326.47 182.142Z"
    fill="#185E45"
  />
  <path
    d="M352.816 177.558C325.612 177.558 303.559 155.504 303.559 128.3C303.559 101.095 325.612 79.041 352.816 79.041C380.019 79.041 402.072 101.095 402.072 128.3C402.072 155.504 380.019 177.558 352.816 177.558Z"
    fill="#185E45"
  />
  <path
    d="M383.743 240.566C356.54 240.566 334.487 218.512 334.487 191.307C334.487 164.103 356.54 142.049 383.743 142.049C410.947 142.049 433 164.103 433 191.307C433 218.512 410.947 240.566 383.743 240.566Z"
    fill="#185E45"
  />
  <path
    d="M316.159 286.386C288.956 286.386 266.903 264.332 266.903 237.128C266.903 209.923 288.956 187.869 316.159 187.869C343.363 187.869 365.416 209.923 365.416 237.128C365.416 264.332 343.363 286.386 316.159 286.386Z"
    fill="#185E45"
  />
  <path
    d="M234.829 177.558C207.626 177.558 185.573 155.504 185.573 128.3C185.573 101.095 207.626 79.041 234.829 79.041C262.033 79.041 284.086 101.095 284.086 128.3C284.086 155.504 262.033 177.558 234.829 177.558Z"
    fill="#069461"
  />
  <path
    d="M218.788 203.908C191.585 203.908 169.532 181.854 169.532 154.649C169.532 127.444 191.585 105.391 218.788 105.391C245.992 105.391 268.045 127.444 268.045 154.649C268.045 181.854 245.992 203.908 218.788 203.908Z"
    fill="#185E45"
  />
  <path
    d="M309.288 237.128C282.085 237.128 260.032 215.075 260.032 187.87C260.032 160.665 282.085 138.611 309.288 138.611C336.492 138.611 358.545 160.665 358.545 187.87C358.545 215.075 336.492 237.128 309.288 237.128Z"
    fill="#185E45"
  />
  <path
    d="M169.534 154.648C142.331 154.648 120.278 132.594 120.278 105.389C120.278 78.1847 142.331 56.1309 169.534 56.1309C196.738 56.1309 218.791 78.1847 218.791 105.389C218.791 132.594 196.738 154.648 169.534 154.648Z"
    fill="#069461"
  />
  <path
    d="M152.355 182.142C125.151 182.142 103.098 160.088 103.098 132.884C103.098 105.679 125.151 83.625 152.355 83.625C179.558 83.625 201.611 105.679 201.611 132.884C201.611 160.088 179.558 182.142 152.355 182.142Z"
    fill="#185E45"
  />
  <path
    d="M248.575 240.566C221.372 240.566 199.319 218.512 199.319 191.307C199.319 164.103 221.372 142.049 248.575 142.049C275.779 142.049 297.832 164.103 297.832 191.307C297.832 218.512 275.779 240.566 248.575 240.566Z"
    fill="#069461"
  />
  <path
    d="M239.407 270.349C212.204 270.349 190.151 248.295 190.151 221.091C190.151 193.886 212.204 171.832 239.407 171.832C266.611 171.832 288.664 193.886 288.664 221.091C288.664 248.295 266.611 270.349 239.407 270.349Z"
    fill="#185E45"
  />
  <path
    d="M120.277 226.818C93.0728 226.818 71.0198 205.79 71.0198 179.85C71.0198 153.911 93.0728 132.883 120.277 132.883C147.48 132.883 169.533 153.911 169.533 179.85C169.533 205.79 147.48 226.818 120.277 226.818Z"
    fill="#069461"
  />
  <path
    d="M120.277 253.166C93.0728 253.166 71.0198 231.112 71.0198 203.907C71.0198 176.702 93.0728 154.648 120.277 154.648C147.48 154.648 169.533 176.702 169.533 203.907C169.533 231.112 147.48 253.166 120.277 253.166Z"
    fill="#185E45"
  />
  <defs>
    <linearGradient
      id="paint0_linear_544_9263"
      x1="198.744"
      y1="227.064"
      x2="198.744"
      y2="468.529"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>
