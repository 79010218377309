<svg viewBox="0 0 501 739" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M557.295 49.2598H240.577C233.009 49.2598 226.873 55.3924 226.873 62.9575V725.202C226.873 732.767 233.009 738.9 240.577 738.9H557.295C564.864 738.9 570.999 732.767 570.999 725.202V62.9575C570.999 55.3924 564.864 49.2598 557.295 49.2598Z"
    fill="#580266"
  />
  <path
    d="M340.828 49.2598H24.1106C16.5419 49.2598 10.4062 55.3924 10.4062 62.9575V725.202C10.4062 732.767 16.5419 738.9 24.1106 738.9H340.828C348.397 738.9 354.533 732.767 354.533 725.202V62.9575C354.533 55.3924 348.397 49.2598 340.828 49.2598Z"
    fill="#580266"
  />
  <path
    d="M426.983 470.1H364.67C361.645 470.1 359.193 472.552 359.193 475.576V564.324C359.193 567.349 361.645 569.801 364.67 569.801H426.983C430.007 569.801 432.459 567.349 432.459 564.324V475.576C432.459 472.552 430.007 470.1 426.983 470.1Z"
    fill="#FEAC18"
  />
  <mask
    id="mask0_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="359"
    y="470"
    width="74"
    height="100"
  >
    <path
      d="M426.985 470.098H364.672C361.647 470.098 359.195 472.55 359.195 475.574V564.323C359.195 567.347 361.647 569.799 364.672 569.799H426.985C430.009 569.799 432.461 567.347 432.461 564.323V475.574C432.461 472.55 430.009 470.098 426.985 470.098Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_587_4)">
    <path
      opacity="0.2"
      d="M344.092 556.129L463.313 528.166L476.124 582.786C476.814 585.729 474.986 588.678 472.043 589.368L363.485 614.83C360.542 615.521 357.593 613.692 356.903 610.749L344.092 556.129Z"
      fill="white"
    />
  </g>
  <path
    d="M319.776 470.549H257.463C254.438 470.549 251.986 473.001 251.986 476.025V564.774C251.986 567.798 254.438 570.25 257.463 570.25H319.776C322.8 570.25 325.252 567.798 325.252 564.774V476.025C325.252 473.001 322.8 470.549 319.776 470.549Z"
    fill="#FF3A52"
  />
  <mask
    id="mask1_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="251"
    y="470"
    width="75"
    height="101"
  >
    <path
      d="M319.776 470.551H257.463C254.438 470.551 251.986 473.003 251.986 476.027V564.776C251.986 567.8 254.438 570.252 257.463 570.252H319.776C322.8 570.252 325.252 567.8 325.252 564.776V476.027C325.252 473.003 322.8 470.551 319.776 470.551Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask1_587_4)">
    <path
      opacity="0.2"
      d="M236.883 556.586L356.104 528.623L368.915 583.243C369.605 586.186 367.777 589.135 364.834 589.825L256.276 615.287C253.333 615.978 250.384 614.149 249.694 611.206L236.883 556.586Z"
      fill="white"
    />
  </g>
  <path
    d="M428.461 275.416H366.148C363.124 275.416 360.672 277.868 360.672 280.893V369.641C360.672 372.666 363.124 375.117 366.148 375.117H428.461C431.486 375.117 433.938 372.666 433.938 369.641V280.893C433.938 277.868 431.486 275.416 428.461 275.416Z"
    fill="#FF3A52"
  />
  <mask
    id="mask2_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="360"
    y="275"
    width="74"
    height="101"
  >
    <path
      d="M428.463 275.414H366.15C363.126 275.414 360.674 277.866 360.674 280.891V369.639C360.674 372.664 363.126 375.116 366.15 375.116H428.463C431.488 375.116 433.94 372.664 433.94 369.639V280.891C433.94 277.866 431.488 275.414 428.463 275.414Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask2_587_4)">
    <path
      opacity="0.2"
      d="M345.574 361.443L464.796 333.48L477.607 388.1C478.297 391.044 476.468 393.992 473.525 394.683L364.968 420.145C362.025 420.835 359.076 419.007 358.385 416.063L345.574 361.443Z"
      fill="#FF3A52"
    />
  </g>
  <path
    d="M321.254 275.865H258.941C255.917 275.865 253.465 278.317 253.465 281.342V370.09C253.465 373.115 255.917 375.567 258.941 375.567H321.254C324.279 375.567 326.731 373.115 326.731 370.09V281.342C326.731 278.317 324.279 275.865 321.254 275.865Z"
    fill="#FEAC18"
  />
  <mask
    id="mask3_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="253"
    y="275"
    width="74"
    height="101"
  >
    <path
      d="M321.254 275.865H258.941C255.917 275.865 253.465 278.317 253.465 281.342V370.09C253.465 373.115 255.917 375.567 258.941 375.567H321.254C324.279 375.567 326.731 373.115 326.731 370.09V281.342C326.731 278.317 324.279 275.865 321.254 275.865Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask3_587_4)">
    <path
      opacity="0.2"
      d="M238.363 361.9L357.585 333.937L370.396 388.557C371.086 391.5 369.257 394.449 366.314 395.14L257.757 420.602C254.814 421.292 251.865 419.464 251.174 416.52L238.363 361.9Z"
      fill="#FEAC18"
    />
  </g>
  <path
    d="M429.573 83.1777H367.26C364.235 83.1777 361.783 85.6297 361.783 88.6543V177.403C361.783 180.427 364.235 182.879 367.26 182.879H429.573C432.597 182.879 435.049 180.427 435.049 177.403V88.6543C435.049 85.6297 432.597 83.1777 429.573 83.1777Z"
    fill="#FEAC18"
  />
  <mask
    id="mask4_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="361"
    y="83"
    width="75"
    height="100"
  >
    <path
      d="M429.573 83.1797H367.26C364.235 83.1797 361.783 85.6316 361.783 88.6563V177.405C361.783 180.429 364.235 182.881 367.26 182.881H429.573C432.597 182.881 435.049 180.429 435.049 177.405V88.6563C435.049 85.6316 432.597 83.1797 429.573 83.1797Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask4_587_4)">
    <path
      opacity="0.2"
      d="M346.688 169.215L465.909 141.252L478.72 195.872C479.41 198.815 477.582 201.764 474.639 202.454L366.081 227.916C363.138 228.606 360.189 226.778 359.499 223.835L346.688 169.215Z"
      fill="white"
    />
  </g>
  <path
    d="M262.834 658.107H422.406C425.429 658.107 427.882 660.561 427.882 663.584V713.114H257.357V663.584C257.357 660.561 259.811 658.107 262.834 658.107Z"
    fill="#FEAC18"
  />
  <mask
    id="mask5_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="257"
    y="658"
    width="171"
    height="56"
  >
    <path
      d="M262.836 658.107H422.408C425.431 658.107 427.884 660.561 427.884 663.584V713.114H257.359V663.584C257.359 660.561 259.813 658.107 262.836 658.107Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask5_587_4)">
    <path
      opacity="0.2"
      d="M237.131 691.139L429.783 645.952L443.96 706.395C444.65 709.338 442.822 712.287 439.878 712.977L257.89 755.662C254.947 756.353 251.998 754.524 251.308 751.581L237.131 691.139Z"
      fill="white"
    />
  </g>
  <path
    d="M322.364 83.625H260.051C257.026 83.625 254.574 86.077 254.574 89.1016V177.85C254.574 180.875 257.026 183.327 260.051 183.327H322.364C325.388 183.327 327.84 180.875 327.84 177.85V89.1016C327.84 86.077 325.388 83.625 322.364 83.625Z"
    fill="#FF3A52"
  />
  <mask
    id="mask6_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="254"
    y="83"
    width="74"
    height="101"
  >
    <path
      d="M322.364 83.625H260.051C257.026 83.625 254.574 86.077 254.574 89.1016V177.85C254.574 180.875 257.026 183.327 260.051 183.327H322.364C325.388 183.327 327.84 180.875 327.84 177.85V89.1016C327.84 86.077 325.388 83.625 322.364 83.625Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask6_587_4)">
    <path
      opacity="0.2"
      d="M239.484 169.66L358.706 141.697L371.517 196.317C372.207 199.26 370.379 202.209 367.435 202.9L258.878 228.361C255.935 229.052 252.986 227.223 252.295 224.28L239.484 169.66Z"
      fill="white"
    />
  </g>
  <path
    d="M246.301 564.062H330.251V574.242C330.251 577.432 327.765 580.02 324.702 580.02H251.85C248.787 580.02 246.301 577.432 246.301 574.242V564.062Z"
    fill="#28103A"
  />
  <path
    d="M253.238 579.326H322.619V589.064C322.619 592.114 320.143 594.59 317.093 594.59H258.764C255.714 594.59 253.238 592.114 253.238 589.064V579.326Z"
    fill="#28103A"
  />
  <path
    d="M355.227 565.449H437.095V574.744C437.095 577.656 434.671 580.019 431.684 580.019H360.638C357.651 580.019 355.227 577.656 355.227 574.744V565.449Z"
    fill="#28103A"
  />
  <path
    d="M362.166 579.326H430.853V589.064C430.853 592.114 428.402 594.59 425.383 594.59H367.636C364.617 594.59 362.166 592.114 362.166 589.064V579.326Z"
    fill="#28103A"
  />
  <path
    d="M357.309 369.104H439.871V378.398C439.871 381.31 437.426 383.673 434.414 383.673H362.766C359.754 383.673 357.309 381.31 357.309 378.398V369.104Z"
    fill="#28103A"
  />
  <path
    d="M362.859 383.674H432.24V392.969C432.24 395.88 429.764 398.244 426.714 398.244H368.385C365.335 398.244 362.859 395.88 362.859 392.969V383.674Z"
    fill="#28103A"
  />
  <path
    d="M247.688 369.797H331.638V379.534C331.638 382.585 329.152 385.061 326.088 385.061H253.237C250.174 385.061 247.688 382.585 247.688 379.534V369.797Z"
    fill="#28103A"
  />
  <path
    d="M255.32 383.674H323.313V393.854C323.313 397.043 320.887 399.631 317.898 399.631H260.735C257.746 399.631 255.32 397.043 255.32 393.854V383.674Z"
    fill="#28103A"
  />
  <path
    d="M357.309 177.613H439.871V186.908C439.871 189.82 437.426 192.183 434.414 192.183H362.766C359.754 192.183 357.309 189.82 357.309 186.908V177.613Z"
    fill="#28103A"
  />
  <path
    d="M362.859 191.49H432.24V201.67C432.24 204.859 429.764 207.448 426.714 207.448H368.385C365.335 207.448 362.859 204.859 362.859 201.67V191.49Z"
    fill="#28103A"
  />
  <path
    d="M213.291 469.484H150.979C147.954 469.484 145.502 471.936 145.502 474.961V563.709C145.502 566.734 147.954 569.186 150.979 569.186H213.291C216.316 569.186 218.768 566.734 218.768 563.709V474.961C218.768 471.936 216.316 469.484 213.291 469.484Z"
    fill="#FEAC18"
  />
  <mask
    id="mask7_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="145"
    y="469"
    width="74"
    height="101"
  >
    <path
      d="M213.293 469.482H150.981C147.956 469.482 145.504 471.934 145.504 474.959V563.707C145.504 566.732 147.956 569.184 150.981 569.184H213.293C216.318 569.184 218.77 566.732 218.77 563.707V474.959C218.77 471.934 216.318 469.482 213.293 469.482Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask7_587_4)">
    <path
      opacity="0.2"
      d="M130.4 555.514L249.622 527.551L262.433 582.171C263.123 585.114 261.295 588.063 258.351 588.753L149.794 614.215C146.851 614.905 143.902 613.077 143.211 610.134L130.4 555.514Z"
      fill="white"
    />
  </g>
  <path
    d="M214.77 274.803H152.457C149.432 274.803 146.98 277.255 146.98 280.279V369.028C146.98 372.052 149.432 374.504 152.457 374.504H214.77C217.794 374.504 220.246 372.052 220.246 369.028V280.279C220.246 277.255 217.794 274.803 214.77 274.803Z"
    fill="#FF3A52"
  />
  <mask
    id="mask8_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="146"
    y="274"
    width="75"
    height="101"
  >
    <path
      d="M214.772 274.803H152.459C149.434 274.803 146.982 277.255 146.982 280.279V369.028C146.982 372.052 149.434 374.504 152.459 374.504H214.772C217.796 374.504 220.248 372.052 220.248 369.028V280.279C220.248 277.255 217.796 274.803 214.772 274.803Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask8_587_4)">
    <path
      opacity="0.2"
      d="M131.883 360.83L251.104 332.867L263.915 387.487C264.605 390.43 262.777 393.379 259.834 394.07L151.276 419.531C148.333 420.222 145.384 418.393 144.694 415.45L131.883 360.83Z"
      fill="#FF3A52"
    />
  </g>
  <path
    d="M215.881 82.5625H153.568C150.544 82.5625 148.092 85.0145 148.092 88.0391V176.787C148.092 179.812 150.544 182.264 153.568 182.264H215.881C218.906 182.264 221.358 179.812 221.358 176.787V88.0391C221.358 85.0145 218.906 82.5625 215.881 82.5625Z"
    fill="#FEAC18"
  />
  <mask
    id="mask9_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="148"
    y="82"
    width="74"
    height="101"
  >
    <path
      d="M215.881 82.5625H153.568C150.544 82.5625 148.092 85.0145 148.092 88.0391V176.787C148.092 179.812 150.544 182.264 153.568 182.264H215.881C218.906 182.264 221.358 179.812 221.358 176.787V88.0391C221.358 85.0145 218.906 82.5625 215.881 82.5625Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask9_587_4)">
    <path
      opacity="0.2"
      d="M132.996 168.598L252.217 140.635L265.028 195.255C265.719 198.198 263.89 201.147 260.947 201.837L152.39 227.299C149.446 227.989 146.497 226.161 145.807 223.218L132.996 168.598Z"
      fill="white"
    />
  </g>
  <path
    d="M141.535 564.836H223.404V574.131C223.404 577.043 220.979 579.406 217.992 579.406H146.947C143.96 579.406 141.535 577.043 141.535 574.131V564.836Z"
    fill="#28103A"
  />
  <path
    d="M148.475 578.711H217.161V588.448C217.161 591.499 214.711 593.975 211.691 593.975H153.945C150.925 593.975 148.475 591.499 148.475 588.448V578.711Z"
    fill="#28103A"
  />
  <path
    d="M143.617 368.49H226.18V377.785C226.18 380.697 223.735 383.06 220.722 383.06H149.075C146.062 383.06 143.617 380.697 143.617 377.785V368.49Z"
    fill="#28103A"
  />
  <path
    d="M149.168 383.059H218.548V392.353C218.548 395.265 216.073 397.628 213.023 397.628H154.693C151.643 397.628 149.168 395.265 149.168 392.353V383.059Z"
    fill="#28103A"
  />
  <path
    d="M143.617 177H226.18V186.295C226.18 189.207 223.735 191.57 220.722 191.57H149.075C146.062 191.57 143.617 189.207 143.617 186.295V177Z"
    fill="#28103A"
  />
  <path
    d="M149.168 190.875H218.548V201.055C218.548 204.244 216.073 206.832 213.023 206.832H154.693C151.643 206.832 149.168 204.244 149.168 201.055V190.875Z"
    fill="#28103A"
  />
  <path
    d="M249.77 177.613H332.332V186.908C332.332 189.82 329.887 192.183 326.875 192.183H255.227C252.215 192.183 249.77 189.82 249.77 186.908V177.613Z"
    fill="#28103A"
  />
  <path
    d="M256.707 191.49H325.394V201.67C325.394 204.859 322.943 207.448 319.924 207.448H262.177C259.158 207.448 256.707 204.859 256.707 201.67V191.49Z"
    fill="#28103A"
  />
  <path
    d="M104 470.873H41.6875C38.6629 470.873 36.2109 473.325 36.2109 476.35V565.098C36.2109 568.123 38.6629 570.575 41.6875 570.575H104C107.025 570.575 109.477 568.123 109.477 565.098V476.35C109.477 473.325 107.025 470.873 104 470.873Z"
    fill="#FF3A52"
  />
  <mask
    id="mask10_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="36"
    y="470"
    width="74"
    height="101"
  >
    <path
      d="M103.998 470.873H41.6856C38.6609 470.873 36.209 473.325 36.209 476.35V565.098C36.209 568.123 38.6609 570.575 41.6856 570.575H103.998C107.023 570.575 109.475 568.123 109.475 565.098V476.35C109.475 473.325 107.023 470.873 103.998 470.873Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask10_587_4)">
    <path
      opacity="0.2"
      d="M21.1074 556.908L140.329 528.945L153.14 583.565C153.83 586.508 152.002 589.457 149.058 590.148L40.5009 615.609C37.5577 616.3 34.6087 614.471 33.9184 611.528L21.1074 556.908Z"
      fill="white"
    />
  </g>
  <path
    d="M105.479 276.189H43.1661C40.1414 276.189 37.6895 278.641 37.6895 281.666V370.414C37.6895 373.439 40.1414 375.891 43.1661 375.891H105.479C108.503 375.891 110.955 373.439 110.955 370.414V281.666C110.955 278.641 108.503 276.189 105.479 276.189Z"
    fill="#FEAC18"
  />
  <mask
    id="mask11_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="37"
    y="276"
    width="74"
    height="100"
  >
    <path
      d="M105.477 276.191H43.1641C40.1395 276.191 37.6875 278.643 37.6875 281.668V370.416C37.6875 373.441 40.1395 375.893 43.1641 375.893H105.477C108.501 375.893 110.953 373.441 110.953 370.416V281.668C110.953 278.643 108.501 276.191 105.477 276.191Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask11_587_4)">
    <path
      opacity="0.2"
      d="M22.5879 362.225L141.809 334.262L154.62 388.881C155.311 391.825 153.482 394.774 150.539 395.464L41.9814 420.926C39.0382 421.616 36.0892 419.788 35.3989 416.845L22.5879 362.225Z"
      fill="#FEAC18"
    />
  </g>
  <path
    d="M106.59 83.9492H44.2774C41.2527 83.9492 38.8008 86.4012 38.8008 89.4258V178.174C38.8008 181.199 41.2527 183.651 44.2774 183.651H106.59C109.615 183.651 112.067 181.199 112.067 178.174V89.4258C112.067 86.4012 109.615 83.9492 106.59 83.9492Z"
    fill="#FF3A52"
  />
  <mask
    id="mask12_587_4"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="38"
    y="83"
    width="75"
    height="101"
  >
    <path
      d="M106.588 83.9512H44.2754C41.2508 83.9512 38.7988 86.4031 38.7988 89.4278V178.176C38.7988 181.201 41.2508 183.653 44.2754 183.653H106.588C109.613 183.653 112.065 181.201 112.065 178.176V89.4278C112.065 86.4031 109.613 83.9512 106.588 83.9512Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask12_587_4)">
    <path
      opacity="0.2"
      d="M23.7109 169.986L142.932 142.023L155.743 196.643C156.434 199.586 154.605 202.535 151.662 203.226L43.1044 228.688C40.1612 229.378 37.2123 227.55 36.5219 224.606L23.7109 169.986Z"
      fill="white"
    />
  </g>
  <path
    d="M30.5273 564.387H114.478V574.567C114.478 577.756 111.991 580.344 108.928 580.344H36.0767C33.0134 580.344 30.5273 577.756 30.5273 574.567V564.387Z"
    fill="#28103A"
  />
  <path
    d="M37.4648 579.65H106.845V589.388C106.845 592.438 104.37 594.914 101.32 594.914H42.9901C39.9401 594.914 37.4648 592.438 37.4648 589.388V579.65Z"
    fill="#28103A"
  />
  <path
    d="M31.9141 370.123H115.864V379.86C115.864 382.911 113.378 385.387 110.315 385.387H37.4634C34.4002 385.387 31.9141 382.911 31.9141 379.86V370.123Z"
    fill="#28103A"
  />
  <path
    d="M39.5469 383.998H107.54V394.178C107.54 397.367 105.114 399.956 102.125 399.956H44.9616C41.9727 399.956 39.5469 397.367 39.5469 394.178V383.998Z"
    fill="#28103A"
  />
  <path
    d="M33.9961 177.939H116.559V187.234C116.559 190.146 114.114 192.509 111.101 192.509H39.4537C36.4411 192.509 33.9961 190.146 33.9961 187.234V177.939Z"
    fill="#28103A"
  />
  <path
    d="M40.9336 191.814H109.62V201.994C109.62 205.184 107.17 207.772 104.15 207.772H46.4036C43.3841 207.772 40.9336 205.184 40.9336 201.994V191.814Z"
    fill="#28103A"
  />
  <path
    d="M439.871 738.9H246.3V729.606C246.3 726.694 248.754 724.331 251.779 724.331H434.393C437.417 724.331 439.871 726.694 439.871 729.606V738.9Z"
    fill="#28103A"
  />
  <path
    d="M93.4722 0H297.138C302.775 0 307.354 4.21056 307.354 9.39364V48.1673C307.354 50.3002 305.467 52.0352 303.148 52.0352H87.4626C85.1429 52.0352 83.2559 50.3002 83.2559 48.1673V9.39364C83.2559 4.21056 87.8352 0 93.4722 0Z"
    fill="#580266"
  />
  <path
    d="M0 42.4453C0 34.7133 6.26801 28.4453 14 28.4453H472.48V69.3797H0V42.4453Z"
    fill="#28103A"
  />
  <g clip-path="url(#clip0_587_4)">
    <path
      d="M346.144 709.898L344.292 713.175L342.439 709.898C336.33 699.101 337.051 685.782 344.288 675.695C351.526 685.782 352.246 699.101 346.141 709.898H346.144Z"
      fill="#185E45"
    />
    <path
      d="M343.26 708.776L344.289 712.393L340.639 711.373C328.62 708.018 319.626 698.095 317.547 685.891C329.861 687.951 339.874 696.864 343.26 708.776Z"
      fill="#185E45"
    />
    <path
      d="M347.932 711.451L344.289 712.501L345.287 708.874C348.573 696.935 358.514 687.939 370.808 685.777C368.832 697.999 359.92 707.993 347.929 711.451H347.932Z"
      fill="#185E45"
    />
  </g>
  <g clip-path="url(#clip1_587_4)">
    <path
      d="M403.351 709.898L401.499 713.175L399.647 709.898C393.537 699.101 394.258 685.782 401.495 675.695C408.733 685.782 409.453 699.101 403.348 709.898H403.351Z"
      fill="#185E45"
    />
    <path
      d="M400.467 708.776L401.496 712.393L397.846 711.373C385.827 708.018 376.833 698.095 374.754 685.891C387.068 687.951 397.081 696.864 400.467 708.776Z"
      fill="#185E45"
    />
    <path
      d="M405.139 711.451L401.496 712.501L402.494 708.874C405.78 696.935 415.721 687.939 428.015 685.777C426.039 697.999 417.128 707.993 405.136 711.451H405.139Z"
      fill="#185E45"
    />
  </g>
  <g clip-path="url(#clip2_587_4)">
    <path
      d="M285.978 709.898L284.126 713.175L282.273 709.898C276.164 699.101 276.885 685.782 284.122 675.695C291.36 685.782 292.08 699.101 285.975 709.898H285.978Z"
      fill="#185E45"
    />
    <path
      d="M283.094 708.776L284.123 712.393L280.473 711.373C268.454 708.018 259.46 698.095 257.381 685.891C269.695 687.951 279.708 696.864 283.094 708.776Z"
      fill="#185E45"
    />
    <path
      d="M287.766 711.451L284.123 712.501L285.121 708.874C288.407 696.935 298.348 687.939 310.642 685.777C308.666 697.999 299.754 707.993 287.762 711.451H287.766Z"
      fill="#185E45"
    />
  </g>
  <path
    d="M432.24 725.023H253.239V714.843C253.239 711.654 255.714 709.066 258.764 709.066H426.716C429.765 709.066 432.24 711.654 432.24 714.843V725.023Z"
    fill="#28103A"
  />
  <defs>
    <clipPath id="clip0_587_4">
      <rect
        width="53.2607"
        height="37.4798"
        fill="white"
        transform="translate(317.547 675.695)"
      />
    </clipPath>
    <clipPath id="clip1_587_4">
      <rect
        width="53.2607"
        height="37.4798"
        fill="white"
        transform="translate(374.754 675.695)"
      />
    </clipPath>
    <clipPath id="clip2_587_4">
      <rect
        width="53.2607"
        height="37.4798"
        fill="white"
        transform="translate(257.381 675.695)"
      />
    </clipPath>
  </defs>
</svg>
