<svg viewBox="0 0 246 484" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M154.724 0H135.52C132.354 0 129.787 2.56666 129.787 5.7328V24.9377C129.787 28.1038 132.354 30.6705 135.52 30.6705H154.724C157.89 30.6705 160.456 28.1038 160.456 24.9377V5.7328C160.456 2.56666 157.89 0 154.724 0Z"
    fill="#2F246B"
  />
  <path
    d="M219.504 100.032C222.82 89.9963 226.136 79.961 229.453 69.9258L219.504 100.032Z"
    fill="#3D2F89"
  />
  <path
    d="M219.504 105.767C218.908 105.767 218.3 105.672 217.704 105.475C214.698 104.48 213.067 101.238 214.061 98.2311L224.01 68.1253C225.005 65.1185 228.244 63.4875 231.253 64.4821C234.26 65.4768 235.891 68.7187 234.896 71.7255L224.945 101.831C224.148 104.239 221.909 105.767 219.501 105.767H219.504Z"
    fill="#45399A"
  />
  <path
    d="M219.504 139.588C222.82 129.553 226.136 119.518 229.453 109.482L219.504 139.588Z"
    fill="#3D2F89"
  />
  <path
    d="M219.504 145.324C218.908 145.324 218.3 145.229 217.704 145.031C214.698 144.037 213.067 140.795 214.061 137.788L224.01 107.682C225.005 104.675 228.244 103.044 231.253 104.039C234.26 105.033 235.891 108.275 234.896 111.282L224.945 141.388C224.148 143.796 221.909 145.324 219.501 145.324H219.504Z"
    fill="#45399A"
  />
  <path
    d="M219.504 177.998C222.82 167.963 226.136 157.928 229.453 147.893L219.504 177.998Z"
    fill="#3D2F89"
  />
  <path
    d="M219.504 183.734C218.908 183.734 218.3 183.639 217.704 183.441C214.698 182.447 213.067 179.205 214.061 176.198L224.01 146.092C225.005 143.085 228.244 141.454 231.253 142.449C234.26 143.444 235.891 146.685 234.896 149.692L224.945 179.798C224.148 182.206 221.909 183.734 219.501 183.734H219.504Z"
    fill="#45399A"
  />
  <path
    d="M220.934 212.682C224.25 202.647 227.566 192.611 230.882 182.576L220.934 212.682Z"
    fill="#3D2F89"
  />
  <path
    d="M220.934 218.417C220.338 218.417 219.73 218.323 219.134 218.125C216.127 217.13 214.496 213.888 215.491 210.882L225.44 180.776C226.434 177.769 229.673 176.138 232.683 177.133C235.69 178.127 237.321 181.369 236.326 184.376L226.374 214.482C225.577 216.889 223.339 218.417 220.931 218.417H220.934Z"
    fill="#45399A"
  />
  <path
    d="M239.85 71.0877H212.047C208.88 71.0877 206.314 68.5222 206.314 65.3549C206.314 62.1875 208.88 59.6221 212.047 59.6221H239.85C243.017 59.6221 245.583 62.1875 245.583 65.3549C245.583 68.5222 243.017 71.0877 239.85 71.0877Z"
    fill="#DD9305"
  />
  <path
    d="M239.85 110.069H212.047C208.88 110.069 206.314 107.504 206.314 104.336C206.314 101.169 208.88 98.6035 212.047 98.6035H239.85C243.017 98.6035 245.583 101.169 245.583 104.336C245.583 107.504 243.017 110.069 239.85 110.069Z"
    fill="#DD9305"
  />
  <path
    d="M239.85 149.053H212.047C208.88 149.053 206.314 146.487 206.314 143.32C206.314 140.152 208.88 137.587 212.047 137.587H239.85C243.017 137.587 245.583 140.152 245.583 143.32C245.583 146.487 243.017 149.053 239.85 149.053Z"
    fill="#DD9305"
  />
  <path
    d="M239.85 188.037H212.047C208.88 188.037 206.314 185.471 206.314 182.304C206.314 179.137 208.88 176.571 212.047 176.571H239.85C243.017 176.571 245.583 179.137 245.583 182.304C245.583 185.471 243.017 188.037 239.85 188.037Z"
    fill="#DD9305"
  />
  <path
    d="M213.091 21.2109H121.29C117.117 21.2109 113.734 24.5938 113.734 28.7668V213.443C113.734 217.616 117.117 220.999 121.29 220.999H213.091C217.264 220.999 220.647 217.616 220.647 213.443V28.7668C220.647 24.5938 217.264 21.2109 213.091 21.2109Z"
    fill="#45399A"
  />
  <mask
    id="mask0_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="113"
    y="21"
    width="108"
    height="200"
  >
    <path
      d="M213.093 21.2119H121.292C117.119 21.2119 113.736 24.5948 113.736 28.7677V213.444C113.736 217.617 117.119 221 121.292 221H213.093C217.266 221 220.649 217.617 220.649 213.444V28.7677C220.649 24.5948 217.266 21.2119 213.093 21.2119Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_909_2)">
    <path
      d="M366.89 110.093L263.424 27.7598L94.8431 239.627L198.309 321.96L366.89 110.093Z"
      fill="#3D2F89"
    />
  </g>
  <path
    d="M140.322 37.5498H128.994C125.632 37.5498 122.906 40.2756 122.906 43.638V54.966C122.906 58.3285 125.632 61.0543 128.994 61.0543H140.322C143.684 61.0543 146.41 58.3285 146.41 54.966V43.638C146.41 40.2756 143.684 37.5498 140.322 37.5498Z"
    fill="#3D2F89"
  />
  <path
    d="M136.539 40.7021H133.059C129.35 40.7021 126.344 43.709 126.344 47.4181V51.3107C126.344 55.0198 129.35 58.0267 133.059 58.0267H136.539C140.248 58.0267 143.255 55.0198 143.255 51.3107V47.4181C143.255 43.709 140.248 40.7021 136.539 40.7021Z"
    fill="#DD9305"
  />
  <path
    d="M169.554 37.5498H158.227C154.864 37.5498 152.139 40.2756 152.139 43.638V54.966C152.139 58.3285 154.864 61.0543 158.227 61.0543H169.554C172.917 61.0543 175.642 58.3285 175.642 54.966V43.638C175.642 40.2756 172.917 37.5498 169.554 37.5498Z"
    fill="#3D2F89"
  />
  <path
    d="M165.777 40.7021H162.298C158.589 40.7021 155.582 43.709 155.582 47.4181V51.3107C155.582 55.0198 158.589 58.0267 162.298 58.0267H165.777C169.486 58.0267 172.493 55.0198 172.493 51.3107V47.4181C172.493 43.709 169.486 40.7021 165.777 40.7021Z"
    fill="#DD9305"
  />
  <path
    d="M198.794 37.5498H187.467C184.105 37.5498 181.379 40.2756 181.379 43.638V54.966C181.379 58.3285 184.105 61.0543 187.467 61.0543H198.794C202.157 61.0543 204.882 58.3285 204.882 54.966V43.638C204.882 40.2756 202.157 37.5498 198.794 37.5498Z"
    fill="#3D2F89"
  />
  <path
    d="M195.012 40.7021H191.532C187.823 40.7021 184.816 43.709 184.816 47.4181V51.3107C184.816 55.0198 187.823 58.0267 191.532 58.0267H195.012C198.721 58.0267 201.728 55.0198 201.728 51.3107V47.4181C201.728 43.709 198.721 40.7021 195.012 40.7021Z"
    fill="#DD9305"
  />
  <path
    d="M216.703 37.5498C213.341 37.5498 210.615 40.2757 210.615 43.638V54.966C210.615 58.3283 213.341 61.0543 216.703 61.0543H220.647V37.5498H216.703Z"
    fill="#3D2F89"
  />
  <path
    d="M220.647 40.707V58.0258C216.995 57.9628 214.055 54.9817 214.055 51.3127V47.4201C214.055 43.7511 216.995 40.7701 220.647 40.707Z"
    fill="#DD9305"
  />
  <path
    d="M140.322 75.959H128.994C125.632 75.959 122.906 78.6848 122.906 82.0472V93.3752C122.906 96.7377 125.632 99.4635 128.994 99.4635H140.322C143.684 99.4635 146.41 96.7377 146.41 93.3752V82.0472C146.41 78.6848 143.684 75.959 140.322 75.959Z"
    fill="#3D2F89"
  />
  <path
    d="M136.539 79.1133H133.059C129.35 79.1133 126.344 82.1201 126.344 85.8293V89.7218C126.344 93.431 129.35 96.4378 133.059 96.4378H136.539C140.248 96.4378 143.255 93.431 143.255 89.7218V85.8293C143.255 82.1201 140.248 79.1133 136.539 79.1133Z"
    fill="#DD9305"
  />
  <path
    d="M169.554 75.959H158.227C154.864 75.959 152.139 78.6848 152.139 82.0472V93.3752C152.139 96.7377 154.864 99.4635 158.227 99.4635H169.554C172.917 99.4635 175.642 96.7377 175.642 93.3752V82.0472C175.642 78.6848 172.917 75.959 169.554 75.959Z"
    fill="#3D2F89"
  />
  <path
    d="M165.777 79.1133H162.298C158.589 79.1133 155.582 82.1201 155.582 85.8293V89.7218C155.582 93.431 158.589 96.4378 162.298 96.4378H165.777C169.486 96.4378 172.493 93.431 172.493 89.7218V85.8293C172.493 82.1201 169.486 79.1133 165.777 79.1133Z"
    fill="#DD9305"
  />
  <path
    d="M198.794 75.959H187.467C184.105 75.959 181.379 78.6848 181.379 82.0472V93.3752C181.379 96.7377 184.105 99.4635 187.467 99.4635H198.794C202.157 99.4635 204.882 96.7377 204.882 93.3752V82.0472C204.882 78.6848 202.157 75.959 198.794 75.959Z"
    fill="#3D2F89"
  />
  <path
    d="M195.012 79.1133H191.532C187.823 79.1133 184.816 82.1201 184.816 85.8293V89.7218C184.816 93.431 187.823 96.4378 191.532 96.4378H195.012C198.721 96.4378 201.728 93.431 201.728 89.7218V85.8293C201.728 82.1201 198.721 79.1133 195.012 79.1133Z"
    fill="#DD9305"
  />
  <path
    d="M216.703 75.959C213.341 75.959 210.615 78.6849 210.615 82.0472V93.3752C210.615 96.7375 213.341 99.4635 216.703 99.4635H220.647V75.959H216.703Z"
    fill="#3D2F89"
  />
  <path
    d="M220.647 79.1162V96.435C216.995 96.3719 214.055 93.3909 214.055 89.7219V85.8293C214.055 82.1603 216.995 79.1793 220.647 79.1162Z"
    fill="#DD9305"
  />
  <path
    d="M140.322 115.516H128.994C125.632 115.516 122.906 118.241 122.906 121.604V132.932C122.906 136.294 125.632 139.02 128.994 139.02H140.322C143.684 139.02 146.41 136.294 146.41 132.932V121.604C146.41 118.241 143.684 115.516 140.322 115.516Z"
    fill="#3D2F89"
  />
  <path
    d="M136.539 118.67H133.059C129.35 118.67 126.344 121.677 126.344 125.386V129.278C126.344 132.988 129.35 135.994 133.059 135.994H136.539C140.248 135.994 143.255 132.988 143.255 129.278V125.386C143.255 121.677 140.248 118.67 136.539 118.67Z"
    fill="#DD9305"
  />
  <path
    d="M169.554 115.516H158.227C154.864 115.516 152.139 118.241 152.139 121.604V132.932C152.139 136.294 154.864 139.02 158.227 139.02H169.554C172.917 139.02 175.642 136.294 175.642 132.932V121.604C175.642 118.241 172.917 115.516 169.554 115.516Z"
    fill="#3D2F89"
  />
  <path
    d="M165.777 118.67H162.298C158.589 118.67 155.582 121.677 155.582 125.386V129.278C155.582 132.988 158.589 135.994 162.298 135.994H165.777C169.486 135.994 172.493 132.988 172.493 129.278V125.386C172.493 121.677 169.486 118.67 165.777 118.67Z"
    fill="#DD9305"
  />
  <path
    d="M198.794 115.516H187.467C184.105 115.516 181.379 118.241 181.379 121.604V132.932C181.379 136.294 184.105 139.02 187.467 139.02H198.794C202.157 139.02 204.882 136.294 204.882 132.932V121.604C204.882 118.241 202.157 115.516 198.794 115.516Z"
    fill="#3D2F89"
  />
  <path
    d="M195.012 118.67H191.532C187.823 118.67 184.816 121.677 184.816 125.386V129.278C184.816 132.988 187.823 135.994 191.532 135.994H195.012C198.721 135.994 201.728 132.988 201.728 129.278V125.386C201.728 121.677 198.721 118.67 195.012 118.67Z"
    fill="#DD9305"
  />
  <path
    d="M216.703 115.516C213.341 115.516 210.615 118.242 210.615 121.604V132.932C210.615 136.294 213.341 139.02 216.703 139.02H220.647V115.516H216.703Z"
    fill="#3D2F89"
  />
  <path
    d="M220.647 118.673V135.992C216.995 135.929 214.055 132.948 214.055 129.279V125.386C214.055 121.717 216.995 118.736 220.647 118.673Z"
    fill="#DD9305"
  />
  <path
    d="M140.322 154.212H128.994C125.632 154.212 122.906 156.938 122.906 160.3V171.628C122.906 174.991 125.632 177.716 128.994 177.716H140.322C143.684 177.716 146.41 174.991 146.41 171.628V160.3C146.41 156.938 143.684 154.212 140.322 154.212Z"
    fill="#3D2F89"
  />
  <path
    d="M136.539 157.365H133.059C129.35 157.365 126.344 160.372 126.344 164.081V167.974C126.344 171.683 129.35 174.69 133.059 174.69H136.539C140.248 174.69 143.255 171.683 143.255 167.974V164.081C143.255 160.372 140.248 157.365 136.539 157.365Z"
    fill="#DD9305"
  />
  <path
    d="M169.554 154.212H158.227C154.864 154.212 152.139 156.938 152.139 160.3V171.628C152.139 174.991 154.864 177.716 158.227 177.716H169.554C172.917 177.716 175.642 174.991 175.642 171.628V160.3C175.642 156.938 172.917 154.212 169.554 154.212Z"
    fill="#3D2F89"
  />
  <path
    d="M165.777 157.365H162.298C158.589 157.365 155.582 160.372 155.582 164.081V167.974C155.582 171.683 158.589 174.69 162.298 174.69H165.777C169.486 174.69 172.493 171.683 172.493 167.974V164.081C172.493 160.372 169.486 157.365 165.777 157.365Z"
    fill="#DD9305"
  />
  <path
    d="M198.794 154.212H187.467C184.105 154.212 181.379 156.938 181.379 160.3V171.628C181.379 174.991 184.105 177.716 187.467 177.716H198.794C202.157 177.716 204.882 174.991 204.882 171.628V160.3C204.882 156.938 202.157 154.212 198.794 154.212Z"
    fill="#3D2F89"
  />
  <path
    d="M195.012 157.365H191.532C187.823 157.365 184.816 160.372 184.816 164.081V167.974C184.816 171.683 187.823 174.69 191.532 174.69H195.012C198.721 174.69 201.728 171.683 201.728 167.974V164.081C201.728 160.372 198.721 157.365 195.012 157.365Z"
    fill="#DD9305"
  />
  <path
    d="M216.703 154.212C213.341 154.212 210.615 156.938 210.615 160.3V171.628C210.615 174.99 213.341 177.716 216.703 177.716H220.647V154.212H216.703Z"
    fill="#3D2F89"
  />
  <path
    d="M220.647 157.368V174.687C216.995 174.624 214.055 171.643 214.055 167.974V164.081C214.055 160.412 216.995 157.431 220.647 157.368Z"
    fill="#DD9305"
  />
  <path
    d="M140.035 192.908H128.707C125.345 192.908 122.619 195.634 122.619 198.996V210.324C122.619 213.687 125.345 216.413 128.707 216.413H140.035C143.397 216.413 146.123 213.687 146.123 210.324V198.996C146.123 195.634 143.397 192.908 140.035 192.908Z"
    fill="#3D2F89"
  />
  <path
    d="M136.252 196.062H132.772C129.063 196.062 126.057 199.068 126.057 202.777V206.67C126.057 210.379 129.063 213.386 132.772 213.386H136.252C139.961 213.386 142.968 210.379 142.968 206.67V202.777C142.968 199.068 139.961 196.062 136.252 196.062Z"
    fill="#DD9305"
  />
  <path
    d="M169.269 192.908H157.942C154.579 192.908 151.854 195.634 151.854 198.996V210.324C151.854 213.687 154.579 216.413 157.942 216.413H169.269C172.631 216.413 175.357 213.687 175.357 210.324V198.996C175.357 195.634 172.631 192.908 169.269 192.908Z"
    fill="#3D2F89"
  />
  <path
    d="M165.49 196.062H162.011C158.302 196.062 155.295 199.068 155.295 202.777V206.67C155.295 210.379 158.302 213.386 162.011 213.386H165.49C169.199 213.386 172.206 210.379 172.206 206.67V202.777C172.206 199.068 169.199 196.062 165.49 196.062Z"
    fill="#DD9305"
  />
  <path
    d="M198.507 192.908H187.18C183.817 192.908 181.092 195.634 181.092 198.996V210.324C181.092 213.687 183.817 216.413 187.18 216.413H198.507C201.87 216.413 204.595 213.687 204.595 210.324V198.996C204.595 195.634 201.87 192.908 198.507 192.908Z"
    fill="#3D2F89"
  />
  <path
    d="M194.727 196.062H191.247C187.538 196.062 184.531 199.068 184.531 202.777V206.67C184.531 210.379 187.538 213.386 191.247 213.386H194.727C198.436 213.386 201.442 210.379 201.442 206.67V202.777C201.442 199.068 198.436 196.062 194.727 196.062Z"
    fill="#DD9305"
  />
  <path
    d="M216.414 192.908C213.052 192.908 210.326 195.634 210.326 198.996V210.324C210.326 213.687 213.052 216.413 216.414 216.413H220.358V192.908H216.414Z"
    fill="#3D2F89"
  />
  <path
    d="M220.358 196.064V213.383C216.706 213.32 213.766 210.339 213.766 206.67V202.778C213.766 199.109 216.706 196.128 220.358 196.064Z"
    fill="#DD9305"
  />
  <mask
    id="mask1_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="129"
    y="0"
    width="32"
    height="31"
  >
    <path
      d="M154.72 0H135.516C132.35 0 129.783 2.56666 129.783 5.7328V24.9377C129.783 28.1038 132.35 30.6705 135.516 30.6705H154.72C157.886 30.6705 160.452 28.1038 160.452 24.9377V5.7328C160.452 2.56666 157.886 0 154.72 0Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask1_909_2)">
    <path
      d="M159.672 -10.3166L140.832 -25.3086L110.135 13.2703L128.975 28.2623L159.672 -10.3166Z"
      fill="#2B2164"
    />
  </g>
  <path
    d="M220.645 30.7273H113.733C110.565 30.7273 108 28.1619 108 24.9945C108 21.8271 110.565 19.2617 113.733 19.2617H220.645C223.812 19.2617 226.378 21.8271 226.378 24.9945C226.378 28.1619 223.812 30.7273 220.645 30.7273Z"
    fill="#2F246B"
  />
  <path
    d="M27.2032 82H7.39073C4.12442 82 1.47656 84.648 1.47656 87.9144V107.728C1.47656 110.994 4.12442 113.642 7.39073 113.642H27.2032C30.4695 113.642 33.1173 110.994 33.1173 107.728V87.9144C33.1173 84.648 30.4695 82 27.2032 82Z"
    fill="#2F246B"
  />
  <path
    d="M94.0352 185.2C97.4565 174.847 100.878 164.494 104.299 154.141L94.0352 185.2Z"
    fill="#3D2F89"
  />
  <path
    d="M94.0356 191.117C93.4205 191.117 92.7936 191.02 92.1785 190.815C89.0765 189.789 87.394 186.445 88.4201 183.343L98.6841 152.283C99.7102 149.181 103.052 147.498 106.157 148.525C109.259 149.551 110.941 152.895 109.915 155.997L99.6481 187.057C98.8261 189.541 96.5166 191.117 94.0326 191.117H94.0356Z"
    fill="#45399A"
  />
  <path
    d="M94.0352 226.011C97.4565 215.657 100.878 205.304 104.299 194.951L94.0352 226.011Z"
    fill="#3D2F89"
  />
  <path
    d="M94.0356 231.928C93.4205 231.928 92.7936 231.83 92.1785 231.626C89.0765 230.6 87.394 227.255 88.4201 224.153L98.6841 193.094C99.7102 189.992 103.052 188.309 106.157 189.335C109.259 190.361 110.941 193.706 109.915 196.808L99.6481 227.867C98.8261 230.351 96.5166 231.928 94.0326 231.928H94.0356Z"
    fill="#45399A"
  />
  <path
    d="M94.0352 265.637C97.4565 255.283 100.878 244.93 104.299 234.577L94.0352 265.637Z"
    fill="#3D2F89"
  />
  <path
    d="M94.0356 271.554C93.4205 271.554 92.7936 271.456 92.1785 271.252C89.0765 270.226 87.394 266.881 88.4201 263.779L98.6841 232.72C99.7102 229.618 103.052 227.935 106.157 228.961C109.259 229.987 110.941 233.332 109.915 236.434L99.6481 267.493C98.8261 269.977 96.5166 271.554 94.0326 271.554H94.0356Z"
    fill="#45399A"
  />
  <path
    d="M95.5117 301.419C98.9331 291.066 102.354 280.713 105.776 270.359L95.5117 301.419Z"
    fill="#3D2F89"
  />
  <path
    d="M95.5121 307.336C94.8971 307.336 94.2702 307.238 93.6551 307.034C90.5531 306.008 88.8705 302.663 89.8967 299.561L100.161 268.502C101.187 265.4 104.528 263.717 107.633 264.743C110.735 265.769 112.418 269.114 111.392 272.216L101.125 303.276C100.303 305.76 97.9931 307.336 95.5092 307.336H95.5121Z"
    fill="#45399A"
  />
  <path
    d="M115.028 155.34H86.3439C83.0763 155.34 80.4297 152.693 80.4297 149.425C80.4297 146.157 83.0763 143.511 86.3439 143.511H115.028C118.295 143.511 120.942 146.157 120.942 149.425C120.942 152.693 118.295 155.34 115.028 155.34Z"
    fill="#DD9305"
  />
  <path
    d="M115.028 195.555H86.3439C83.0763 195.555 80.4297 192.909 80.4297 189.641C80.4297 186.373 83.0763 183.727 86.3439 183.727H115.028C118.295 183.727 120.942 186.373 120.942 189.641C120.942 192.909 118.295 195.555 115.028 195.555Z"
    fill="#DD9305"
  />
  <path
    d="M115.028 235.774H86.3439C83.0763 235.774 80.4297 233.127 80.4297 229.86C80.4297 226.592 83.0763 223.945 86.3439 223.945H115.028C118.295 223.945 120.942 226.592 120.942 229.86C120.942 233.127 118.295 235.774 115.028 235.774Z"
    fill="#DD9305"
  />
  <path
    d="M115.028 275.993H86.3439C83.0763 275.993 80.4297 273.346 80.4297 270.078C80.4297 266.811 83.0763 264.164 86.3439 264.164H115.028C118.295 264.164 120.942 266.811 120.942 270.078C120.942 273.346 118.295 275.993 115.028 275.993Z"
    fill="#DD9305"
  />
  <path
    d="M87.4203 103.883H-7.28908C-11.5941 103.883 -15.084 107.373 -15.084 111.678V302.204C-15.084 306.51 -11.5941 310 -7.28908 310H87.4203C91.7253 310 95.2152 306.51 95.2152 302.204V111.678C95.2152 107.373 91.7253 103.883 87.4203 103.883Z"
    fill="#45399A"
  />
  <mask
    id="mask2_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-16"
    y="103"
    width="112"
    height="207"
  >
    <path
      d="M87.4203 103.884H-7.28908C-11.5941 103.884 -15.084 107.374 -15.084 111.679V302.205C-15.084 306.51 -11.5941 310 -7.28908 310H87.4203C91.7253 310 95.2152 306.51 95.2152 302.205V111.679C95.2152 107.374 91.7253 103.884 87.4203 103.884Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask2_909_2)">
    <path
      d="M246.091 195.581L139.348 110.64L-34.5732 329.218L72.1701 414.16L246.091 195.581Z"
      fill="#3D2F89"
    />
  </g>
  <path
    d="M12.3461 120.739H0.659746C-2.80907 120.739 -5.62109 123.551 -5.62109 127.02V138.707C-5.62109 142.176 -2.80907 144.988 0.659746 144.988H12.3461C15.815 144.988 18.627 142.176 18.627 138.707V127.02C18.627 123.551 15.815 120.739 12.3461 120.739Z"
    fill="#3D2F89"
  />
  <path
    d="M8.44219 123.991H4.85225C1.02578 123.991 -2.07617 127.093 -2.07617 130.92V134.936C-2.07617 138.762 1.02578 141.865 4.85225 141.865H8.44219C12.2687 141.865 15.3706 138.762 15.3706 134.936V130.92C15.3706 127.093 12.2687 123.991 8.44219 123.991Z"
    fill="#DD9305"
  />
  <path
    d="M42.5043 120.739H30.8179C27.3491 120.739 24.5371 123.551 24.5371 127.02V138.707C24.5371 142.176 27.3491 144.988 30.8179 144.988H42.5043C45.9732 144.988 48.7852 142.176 48.7852 138.707V127.02C48.7852 123.551 45.9732 120.739 42.5043 120.739Z"
    fill="#3D2F89"
  />
  <path
    d="M38.6082 123.991H35.0183C31.1918 123.991 28.0898 127.093 28.0898 130.92V134.936C28.0898 138.762 31.1918 141.865 35.0183 141.865H38.6082C42.4347 141.865 45.5366 138.762 45.5366 134.936V130.92C45.5366 127.093 42.4347 123.991 38.6082 123.991Z"
    fill="#DD9305"
  />
  <path
    d="M72.6704 120.739H60.984C57.5152 120.739 54.7031 123.551 54.7031 127.02V138.707C54.7031 142.176 57.5152 144.988 60.984 144.988H72.6704C76.1392 144.988 78.9512 142.176 78.9512 138.707V127.02C78.9512 123.551 76.1392 120.739 72.6704 120.739Z"
    fill="#3D2F89"
  />
  <path
    d="M68.7684 123.991H65.1784C61.3519 123.991 58.25 127.093 58.25 130.92V134.936C58.25 138.762 61.3519 141.865 65.1784 141.865H68.7684C72.5948 141.865 75.6968 138.762 75.6968 134.936V130.92C75.6968 127.093 72.5948 123.991 68.7684 123.991Z"
    fill="#DD9305"
  />
  <path
    d="M91.1461 120.739C87.6774 120.739 84.8652 123.552 84.8652 127.02V138.707C84.8652 142.176 87.6774 144.988 91.1461 144.988H95.215V120.739H91.1461Z"
    fill="#3D2F89"
  />
  <path
    d="M95.2154 123.996V141.863C91.448 141.798 88.4141 138.723 88.4141 134.938V130.922C88.4141 127.137 91.448 124.061 95.2154 123.996Z"
    fill="#DD9305"
  />
  <path
    d="M12.3461 160.365H0.659746C-2.80907 160.365 -5.62109 163.177 -5.62109 166.646V178.333C-5.62109 181.802 -2.80907 184.614 0.659746 184.614H12.3461C15.815 184.614 18.627 181.802 18.627 178.333V166.646C18.627 163.177 15.815 160.365 12.3461 160.365Z"
    fill="#3D2F89"
  />
  <path
    d="M8.44219 163.619H4.85225C1.02578 163.619 -2.07617 166.721 -2.07617 170.548V174.564C-2.07617 178.39 1.02578 181.492 4.85225 181.492H8.44219C12.2687 181.492 15.3706 178.39 15.3706 174.564V170.548C15.3706 166.721 12.2687 163.619 8.44219 163.619Z"
    fill="#DD9305"
  />
  <path
    d="M42.5043 160.365H30.8179C27.3491 160.365 24.5371 163.177 24.5371 166.646V178.333C24.5371 181.802 27.3491 184.614 30.8179 184.614H42.5043C45.9732 184.614 48.7852 181.802 48.7852 178.333V166.646C48.7852 163.177 45.9732 160.365 42.5043 160.365Z"
    fill="#3D2F89"
  />
  <path
    d="M38.6082 163.619H35.0183C31.1918 163.619 28.0898 166.721 28.0898 170.548V174.564C28.0898 178.39 31.1918 181.492 35.0183 181.492H38.6082C42.4347 181.492 45.5366 178.39 45.5366 174.564V170.548C45.5366 166.721 42.4347 163.619 38.6082 163.619Z"
    fill="#DD9305"
  />
  <path
    d="M72.6704 160.365H60.984C57.5152 160.365 54.7031 163.177 54.7031 166.646V178.333C54.7031 181.802 57.5152 184.614 60.984 184.614H72.6704C76.1392 184.614 78.9512 181.802 78.9512 178.333V166.646C78.9512 163.177 76.1392 160.365 72.6704 160.365Z"
    fill="#3D2F89"
  />
  <path
    d="M68.7684 163.619H65.1784C61.3519 163.619 58.25 166.721 58.25 170.548V174.564C58.25 178.39 61.3519 181.492 65.1784 181.492H68.7684C72.5948 181.492 75.6968 178.39 75.6968 174.564V170.548C75.6968 166.721 72.5948 163.619 68.7684 163.619Z"
    fill="#DD9305"
  />
  <path
    d="M91.1461 160.365C87.6774 160.365 84.8652 163.178 84.8652 166.646V178.333C84.8652 181.802 87.6774 184.614 91.1461 184.614H95.215V160.365H91.1461Z"
    fill="#3D2F89"
  />
  <path
    d="M95.2154 163.622V181.489C91.448 181.424 88.4141 178.349 88.4141 174.564V170.548C88.4141 166.763 91.448 163.687 95.2154 163.622Z"
    fill="#DD9305"
  />
  <path
    d="M12.3461 201.175H0.659746C-2.80907 201.175 -5.62109 203.987 -5.62109 207.456V219.143C-5.62109 222.612 -2.80907 225.424 0.659746 225.424H12.3461C15.815 225.424 18.627 222.612 18.627 219.143V207.456C18.627 203.987 15.815 201.175 12.3461 201.175Z"
    fill="#3D2F89"
  />
  <path
    d="M8.44219 204.429H4.85225C1.02578 204.429 -2.07617 207.531 -2.07617 211.357V215.373C-2.07617 219.2 1.02578 222.302 4.85225 222.302H8.44219C12.2687 222.302 15.3706 219.2 15.3706 215.373V211.357C15.3706 207.531 12.2687 204.429 8.44219 204.429Z"
    fill="#DD9305"
  />
  <path
    d="M42.5043 201.175H30.8179C27.3491 201.175 24.5371 203.987 24.5371 207.456V219.143C24.5371 222.612 27.3491 225.424 30.8179 225.424H42.5043C45.9732 225.424 48.7852 222.612 48.7852 219.143V207.456C48.7852 203.987 45.9732 201.175 42.5043 201.175Z"
    fill="#3D2F89"
  />
  <path
    d="M38.6082 204.429H35.0183C31.1918 204.429 28.0898 207.531 28.0898 211.357V215.373C28.0898 219.2 31.1918 222.302 35.0183 222.302H38.6082C42.4347 222.302 45.5366 219.2 45.5366 215.373V211.357C45.5366 207.531 42.4347 204.429 38.6082 204.429Z"
    fill="#DD9305"
  />
  <path
    d="M72.6704 201.175H60.984C57.5152 201.175 54.7031 203.987 54.7031 207.456V219.143C54.7031 222.612 57.5152 225.424 60.984 225.424H72.6704C76.1392 225.424 78.9512 222.612 78.9512 219.143V207.456C78.9512 203.987 76.1392 201.175 72.6704 201.175Z"
    fill="#3D2F89"
  />
  <path
    d="M68.7684 204.429H65.1784C61.3519 204.429 58.25 207.531 58.25 211.357V215.373C58.25 219.2 61.3519 222.302 65.1784 222.302H68.7684C72.5948 222.302 75.6968 219.2 75.6968 215.373V211.357C75.6968 207.531 72.5948 204.429 68.7684 204.429Z"
    fill="#DD9305"
  />
  <path
    d="M91.1461 201.175C87.6774 201.175 84.8652 203.987 84.8652 207.456V219.143C84.8652 222.612 87.6774 225.424 91.1461 225.424H95.215V201.175H91.1461Z"
    fill="#3D2F89"
  />
  <path
    d="M95.2154 204.432V222.299C91.448 222.234 88.4141 219.158 88.4141 215.373V211.357C88.4141 207.572 91.448 204.497 95.2154 204.432Z"
    fill="#DD9305"
  />
  <path
    d="M12.3461 241.097H0.659746C-2.80907 241.097 -5.62109 243.909 -5.62109 247.378V259.065C-5.62109 262.534 -2.80907 265.346 0.659746 265.346H12.3461C15.815 265.346 18.627 262.534 18.627 259.065V247.378C18.627 243.909 15.815 241.097 12.3461 241.097Z"
    fill="#3D2F89"
  />
  <path
    d="M8.44219 244.351H4.85225C1.02578 244.351 -2.07617 247.453 -2.07617 251.279V255.295C-2.07617 259.122 1.02578 262.224 4.85225 262.224H8.44219C12.2687 262.224 15.3706 259.122 15.3706 255.295V251.279C15.3706 247.453 12.2687 244.351 8.44219 244.351Z"
    fill="#DD9305"
  />
  <path
    d="M42.5043 241.097H30.8179C27.3491 241.097 24.5371 243.909 24.5371 247.378V259.065C24.5371 262.534 27.3491 265.346 30.8179 265.346H42.5043C45.9732 265.346 48.7852 262.534 48.7852 259.065V247.378C48.7852 243.909 45.9732 241.097 42.5043 241.097Z"
    fill="#3D2F89"
  />
  <path
    d="M38.6082 244.351H35.0183C31.1918 244.351 28.0898 247.453 28.0898 251.279V255.295C28.0898 259.122 31.1918 262.224 35.0183 262.224H38.6082C42.4347 262.224 45.5366 259.122 45.5366 255.295V251.279C45.5366 247.453 42.4347 244.351 38.6082 244.351Z"
    fill="#DD9305"
  />
  <path
    d="M72.6704 241.097H60.984C57.5152 241.097 54.7031 243.909 54.7031 247.378V259.065C54.7031 262.534 57.5152 265.346 60.984 265.346H72.6704C76.1392 265.346 78.9512 262.534 78.9512 259.065V247.378C78.9512 243.909 76.1392 241.097 72.6704 241.097Z"
    fill="#3D2F89"
  />
  <path
    d="M68.7684 244.351H65.1784C61.3519 244.351 58.25 247.453 58.25 251.279V255.295C58.25 259.122 61.3519 262.224 65.1784 262.224H68.7684C72.5948 262.224 75.6968 259.122 75.6968 255.295V251.279C75.6968 247.453 72.5948 244.351 68.7684 244.351Z"
    fill="#DD9305"
  />
  <path
    d="M91.1461 241.097C87.6774 241.097 84.8652 243.909 84.8652 247.378V259.065C84.8652 262.533 87.6774 265.346 91.1461 265.346H95.215V241.097H91.1461Z"
    fill="#3D2F89"
  />
  <path
    d="M95.2154 244.354V262.221C91.448 262.156 88.4141 259.08 88.4141 255.295V251.279C88.4141 247.494 91.448 244.419 95.2154 244.354Z"
    fill="#DD9305"
  />
  <path
    d="M12.0493 281.019H0.362871C-3.10594 281.019 -5.91797 283.831 -5.91797 287.3V298.986C-5.91797 302.455 -3.10594 305.268 0.362871 305.268H12.0493C15.5181 305.268 18.3301 302.455 18.3301 298.986V287.3C18.3301 283.831 15.5181 281.019 12.0493 281.019Z"
    fill="#3D2F89"
  />
  <path
    d="M8.14727 284.272H4.55733C0.730856 284.272 -2.37109 287.375 -2.37109 291.201V295.217C-2.37109 299.044 0.730856 302.146 4.55733 302.146H8.14727C11.9737 302.146 15.0757 299.044 15.0757 295.217V291.201C15.0757 287.375 11.9737 284.272 8.14727 284.272Z"
    fill="#DD9305"
  />
  <path
    d="M42.2094 281.019H30.523C27.0542 281.019 24.2422 283.831 24.2422 287.3V298.986C24.2422 302.455 27.0542 305.268 30.523 305.268H42.2094C45.6782 305.268 48.4903 302.455 48.4903 298.986V287.3C48.4903 283.831 45.6782 281.019 42.2094 281.019Z"
    fill="#3D2F89"
  />
  <path
    d="M38.3113 284.272H34.7214C30.8949 284.272 27.793 287.375 27.793 291.201V295.217C27.793 299.044 30.8949 302.146 34.7214 302.146H38.3113C42.1378 302.146 45.2398 299.044 45.2398 295.217V291.201C45.2398 287.375 42.1378 284.272 38.3113 284.272Z"
    fill="#DD9305"
  />
  <path
    d="M72.3735 281.019H60.6871C57.2183 281.019 54.4062 283.831 54.4062 287.3V298.986C54.4062 302.455 57.2183 305.268 60.6871 305.268H72.3735C75.8423 305.268 78.6543 302.455 78.6543 298.986V287.3C78.6543 283.831 75.8423 281.019 72.3735 281.019Z"
    fill="#3D2F89"
  />
  <path
    d="M68.4734 284.272H64.8835C61.057 284.272 57.9551 287.375 57.9551 291.201V295.217C57.9551 299.044 61.057 302.146 64.8835 302.146H68.4734C72.2999 302.146 75.4019 299.044 75.4019 295.217V291.201C75.4019 287.375 72.2999 284.272 68.4734 284.272Z"
    fill="#DD9305"
  />
  <path
    d="M90.8492 281.019C87.3805 281.019 84.5684 283.831 84.5684 287.3V298.986C84.5684 302.455 87.3805 305.268 90.8492 305.268H94.9181V281.019H90.8492Z"
    fill="#3D2F89"
  />
  <path
    d="M94.9165 284.275V302.143C91.1492 302.078 88.1152 299.002 88.1152 295.217V291.201C88.1152 287.416 91.1492 284.34 94.9165 284.275Z"
    fill="#DD9305"
  />
  <mask
    id="mask3_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="1"
    y="82"
    width="33"
    height="32"
  >
    <path
      d="M27.1993 82H7.38682C4.12052 82 1.47266 84.648 1.47266 87.9144V107.728C1.47266 110.994 4.12052 113.642 7.38682 113.642H27.1993C30.4656 113.642 33.1134 110.994 33.1134 107.728V87.9144C33.1134 84.648 30.4656 82 27.1993 82Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask3_909_2)">
    <path
      d="M32.308 71.3566L12.8711 55.8896L-18.7981 95.6906L0.638806 111.157L32.308 71.3566Z"
      fill="#2B2164"
    />
  </g>
  <path
    d="M95.2133 113.701H-15.0858C-18.3534 113.701 -21 111.054 -21 107.786C-21 104.519 -18.3534 101.872 -15.0858 101.872H95.2133C98.4809 101.872 101.128 104.519 101.128 107.786C101.128 111.054 98.4809 113.701 95.2133 113.701Z"
    fill="#2F246B"
  />
  <path
    d="M146.85 249.249C150.573 237.982 154.296 226.716 158.019 215.449L146.85 249.249Z"
    fill="#3D2F89"
  />
  <path
    d="M146.85 255.688C146.181 255.688 145.499 255.582 144.83 255.36C141.454 254.243 139.623 250.603 140.74 247.228L151.909 213.428C153.026 210.053 156.665 208.222 160.041 209.338C163.416 210.455 165.247 214.095 164.131 217.47L152.958 251.27C152.064 253.973 149.55 255.688 146.847 255.688H146.85Z"
    fill="#45399A"
  />
  <path
    d="M146.85 293.659C150.573 282.392 154.296 271.126 158.019 259.859L146.85 293.659Z"
    fill="#3D2F89"
  />
  <path
    d="M146.85 300.098C146.181 300.098 145.499 299.992 144.83 299.77C141.454 298.653 139.623 295.014 140.74 291.638L151.909 257.839C153.026 254.463 156.665 252.632 160.041 253.748C163.416 254.865 165.247 258.505 164.131 261.88L152.958 295.68C152.064 298.383 149.55 300.098 146.847 300.098H146.85Z"
    fill="#45399A"
  />
  <path
    d="M146.85 336.779C150.573 325.512 154.296 314.246 158.019 302.979L146.85 336.779Z"
    fill="#3D2F89"
  />
  <path
    d="M146.85 343.218C146.181 343.218 145.499 343.112 144.83 342.89C141.454 341.773 139.623 338.134 140.74 334.758L151.909 300.959C153.026 297.583 156.665 295.752 160.041 296.869C163.416 297.985 165.247 301.625 164.131 305.001L152.958 338.8C152.064 341.503 149.55 343.218 146.847 343.218H146.85Z"
    fill="#45399A"
  />
  <path
    d="M148.463 375.717C152.186 364.451 155.909 353.184 159.632 341.918L148.463 375.717Z"
    fill="#3D2F89"
  />
  <path
    d="M148.464 382.157C147.794 382.157 147.112 382.051 146.443 381.829C143.067 380.712 141.236 377.072 142.353 373.696L153.522 339.897C154.639 336.521 158.278 334.69 161.654 335.807C165.03 336.924 166.861 340.563 165.744 343.939L154.571 377.738C153.677 380.442 151.164 382.157 148.461 382.157H148.464Z"
    fill="#45399A"
  />
  <path
    d="M169.697 260.518H138.483C134.927 260.518 132.047 257.638 132.047 254.082C132.047 250.526 134.927 247.646 138.483 247.646H169.697C173.253 247.646 176.133 250.526 176.133 254.082C176.133 257.638 173.253 260.518 169.697 260.518Z"
    fill="#DD9305"
  />
  <path
    d="M169.697 214.821H138.483C134.927 214.821 132.047 211.941 132.047 208.385C132.047 204.829 134.927 201.949 138.483 201.949H169.697C173.253 201.949 176.133 204.829 176.133 208.385C176.133 211.941 173.253 214.821 169.697 214.821Z"
    fill="#DD9305"
  />
  <path
    d="M169.697 304.284H138.483C134.927 304.284 132.047 301.404 132.047 297.848C132.047 294.292 134.927 291.412 138.483 291.412H169.697C173.253 291.412 176.133 294.292 176.133 297.848C176.133 301.404 173.253 304.284 169.697 304.284Z"
    fill="#DD9305"
  />
  <path
    d="M169.697 348.05H138.483C134.927 348.05 132.047 345.17 132.047 341.614C132.047 338.058 134.927 335.178 138.483 335.178H169.697C173.253 335.178 176.133 338.058 176.133 341.614C176.133 345.17 173.253 348.05 169.697 348.05Z"
    fill="#DD9305"
  />
  <path
    d="M148.138 169.241V376.574C148.138 381.26 145.522 385.057 142.295 385.057H71.2813C68.0537 385.057 65.4375 381.26 65.4375 376.574V169.241C65.4375 164.555 68.0537 160.758 71.2813 160.758H142.295C145.522 160.758 148.138 164.555 148.138 169.241Z"
    fill="#45399A"
  />
  <mask
    id="mask4_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="65"
    y="160"
    width="84"
    height="226"
  >
    <path
      d="M148.137 169.241V376.574C148.137 381.26 145.52 385.057 142.293 385.057H71.2794C68.0518 385.057 65.4355 381.26 65.4355 376.574V169.241C65.4355 164.555 68.0518 160.758 71.2794 160.758H142.293C145.52 160.758 148.137 164.555 148.137 169.241Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask4_909_2)">
    <path
      d="M312.306 260.562L196.146 168.128L6.88346 405.988L123.043 498.422L312.306 260.562Z"
      fill="#3D2F89"
    />
  </g>
  <path
    d="M122.314 179.102H109.597C105.822 179.102 102.762 182.162 102.762 185.937V198.655C102.762 202.429 105.822 205.49 109.597 205.49H122.314C126.089 205.49 129.149 202.429 129.149 198.655V185.937C129.149 182.162 126.089 179.102 122.314 179.102Z"
    fill="#3D2F89"
  />
  <path
    d="M118.073 182.641H114.167C110.003 182.641 106.627 186.016 106.627 190.181V194.551C106.627 198.715 110.003 202.091 114.167 202.091H118.073C122.237 202.091 125.613 198.715 125.613 194.551V190.181C125.613 186.016 122.237 182.641 118.073 182.641Z"
    fill="#DD9305"
  />
  <path
    d="M143.708 179.102C139.933 179.102 136.873 182.162 136.873 185.937V198.655C136.873 202.429 139.933 205.49 143.708 205.49H148.136V179.102H143.708Z"
    fill="#3D2F89"
  />
  <path
    d="M148.138 182.645V202.088C144.038 202.017 140.736 198.67 140.736 194.551V190.181C140.736 186.062 144.038 182.715 148.138 182.645Z"
    fill="#DD9305"
  />
  <path
    d="M122.314 222.224H109.597C105.822 222.224 102.762 225.284 102.762 229.059V241.777C102.762 245.552 105.822 248.612 109.597 248.612H122.314C126.089 248.612 129.149 245.552 129.149 241.777V229.059C129.149 225.284 126.089 222.224 122.314 222.224Z"
    fill="#3D2F89"
  />
  <path
    d="M118.073 225.763H114.167C110.003 225.763 106.627 229.138 106.627 233.303V237.673C106.627 241.837 110.003 245.213 114.167 245.213H118.073C122.237 245.213 125.613 241.837 125.613 237.673V233.303C125.613 229.138 122.237 225.763 118.073 225.763Z"
    fill="#DD9305"
  />
  <path
    d="M143.708 222.224C139.933 222.224 136.873 225.284 136.873 229.059V241.777C136.873 245.551 139.933 248.612 143.708 248.612H148.136V222.224H143.708Z"
    fill="#3D2F89"
  />
  <path
    d="M148.138 225.765V245.208C144.038 245.137 140.736 241.791 140.736 237.671V233.301C140.736 229.182 144.038 225.835 148.138 225.765Z"
    fill="#DD9305"
  />
  <path
    d="M122.314 266.632H109.597C105.822 266.632 102.762 269.692 102.762 273.467V286.185C102.762 289.96 105.822 293.02 109.597 293.02H122.314C126.089 293.02 129.149 289.96 129.149 286.185V273.467C129.149 269.692 126.089 266.632 122.314 266.632Z"
    fill="#3D2F89"
  />
  <path
    d="M118.073 270.172H114.167C110.003 270.172 106.627 273.548 106.627 277.712V282.082C106.627 286.246 110.003 289.622 114.167 289.622H118.073C122.237 289.622 125.613 286.246 125.613 282.082V277.712C125.613 273.548 122.237 270.172 118.073 270.172Z"
    fill="#DD9305"
  />
  <path
    d="M143.708 266.632C139.933 266.632 136.873 269.692 136.873 273.467V286.185C136.873 289.96 139.933 293.02 143.708 293.02H148.136V266.632H143.708Z"
    fill="#3D2F89"
  />
  <path
    d="M148.138 270.175V289.618C144.038 289.548 140.736 286.201 140.736 282.082V277.712C140.736 273.592 144.038 270.246 148.138 270.175Z"
    fill="#DD9305"
  />
  <path
    d="M122.314 310.075H109.597C105.822 310.075 102.762 313.135 102.762 316.91V329.628C102.762 333.403 105.822 336.463 109.597 336.463H122.314C126.089 336.463 129.149 333.403 129.149 329.628V316.91C129.149 313.135 126.089 310.075 122.314 310.075Z"
    fill="#3D2F89"
  />
  <path
    d="M118.073 313.615H114.167C110.003 313.615 106.627 316.991 106.627 321.155V325.525C106.627 329.689 110.003 333.065 114.167 333.065H118.073C122.237 333.065 125.613 329.689 125.613 325.525V321.155C125.613 316.991 122.237 313.615 118.073 313.615Z"
    fill="#DD9305"
  />
  <path
    d="M143.708 310.075C139.933 310.075 136.873 313.136 136.873 316.91V329.628C136.873 333.403 139.933 336.463 143.708 336.463H148.136V310.075H143.708Z"
    fill="#3D2F89"
  />
  <path
    d="M148.138 313.619V333.063C144.038 332.992 140.736 329.645 140.736 325.526V321.156C140.736 317.037 144.038 313.69 148.138 313.619Z"
    fill="#DD9305"
  />
  <path
    d="M121.994 353.519H109.276C105.502 353.519 102.441 356.579 102.441 360.354V373.072C102.441 376.846 105.502 379.907 109.276 379.907H121.994C125.768 379.907 128.828 376.846 128.828 373.072V360.354C128.828 356.579 125.768 353.519 121.994 353.519Z"
    fill="#3D2F89"
  />
  <path
    d="M117.749 357.06H113.842C109.678 357.06 106.303 360.435 106.303 364.599V368.97C106.303 373.134 109.678 376.51 113.842 376.51H117.749C121.913 376.51 125.289 373.134 125.289 368.97V364.599C125.289 360.435 121.913 357.06 117.749 357.06Z"
    fill="#DD9305"
  />
  <path
    d="M143.386 353.519C139.611 353.519 136.551 356.579 136.551 360.354V373.072C136.551 376.846 139.611 379.907 143.386 379.907H147.814V353.519H143.386Z"
    fill="#3D2F89"
  />
  <path
    d="M147.817 357.064V376.508C143.718 376.437 140.416 373.09 140.416 368.971V364.601C140.416 360.482 143.718 357.135 147.817 357.064Z"
    fill="#DD9305"
  />
  <path
    d="M148.137 166.872H65.4359C61.8801 166.872 59 163.992 59 160.436C59 156.88 61.8801 154 65.4359 154H148.137C151.693 154 154.573 156.88 154.573 160.436C154.573 163.992 151.693 166.872 148.137 166.872Z"
    fill="#2F246B"
  />
  <path
    d="M70.2254 206H47.6388C43.9151 206 40.8965 209.019 40.8965 212.743V235.33C40.8965 239.054 43.9151 242.073 47.6388 242.073H70.2254C73.949 242.073 76.9676 239.054 76.9676 235.33V212.743C76.9676 209.019 73.949 206 70.2254 206Z"
    fill="#2F246B"
  />
  <path
    d="M92.1406 318.93C96.041 307.127 99.9416 295.324 103.842 283.521L92.1406 318.93Z"
    fill="#3D2F89"
  />
  <path
    d="M92.1413 325.676C91.4401 325.676 90.7255 325.565 90.0243 325.332C86.4879 324.162 84.5698 320.349 85.7396 316.813L97.4408 281.404C98.6105 277.868 102.42 275.95 105.96 277.119C109.496 278.289 111.414 282.102 110.244 285.639L98.5398 321.047C97.6027 323.879 94.9697 325.676 92.138 325.676H92.1413Z"
    fill="#45399A"
  />
  <path
    d="M92.1406 365.455C96.041 353.652 99.9416 341.85 103.842 330.047L92.1406 365.455Z"
    fill="#3D2F89"
  />
  <path
    d="M92.1413 372.201C91.4401 372.201 90.7255 372.09 90.0243 371.857C86.4879 370.687 84.5698 366.875 85.7396 363.338L97.4408 327.93C98.6105 324.393 102.42 322.475 105.96 323.645C109.496 324.815 111.414 328.628 110.244 332.164L98.5398 367.572C97.6027 370.404 94.9697 372.201 92.138 372.201H92.1413Z"
    fill="#45399A"
  />
  <path
    d="M92.1406 410.629C96.041 398.826 99.9416 387.024 103.842 375.221L92.1406 410.629Z"
    fill="#3D2F89"
  />
  <path
    d="M92.1413 417.375C91.4401 417.375 90.7255 417.264 90.0243 417.031C86.4879 415.861 84.5698 412.048 85.7396 408.512L97.4408 373.104C98.6105 369.567 102.42 367.649 105.96 368.819C109.496 369.989 111.414 373.801 110.244 377.338L98.5398 412.746C97.6027 415.578 94.9697 417.375 92.138 417.375H92.1413Z"
    fill="#45399A"
  />
  <path
    d="M93.8242 451.42C97.7246 439.617 101.625 427.815 105.526 416.012L93.8242 451.42Z"
    fill="#3D2F89"
  />
  <path
    d="M93.8249 458.166C93.1237 458.166 92.4091 458.055 91.7079 457.822C88.1715 456.652 86.2534 452.839 87.4232 449.303L99.1244 413.895C100.294 410.358 104.104 408.44 107.643 409.61C111.18 410.78 113.098 414.592 111.928 418.129L100.223 453.537C99.2863 456.369 96.6533 458.166 93.8216 458.166H93.8249Z"
    fill="#45399A"
  />
  <path
    d="M116.073 284.887H83.3731C79.648 284.887 76.6309 281.87 76.6309 278.145C76.6309 274.42 79.648 271.402 83.3731 271.402H116.073C119.798 271.402 122.815 274.42 122.815 278.145C122.815 281.87 119.798 284.887 116.073 284.887Z"
    fill="#DD9305"
  />
  <path
    d="M116.073 330.737H83.3731C79.648 330.737 76.6309 327.72 76.6309 323.994C76.6309 320.269 79.648 317.252 83.3731 317.252H116.073C119.798 317.252 122.815 320.269 122.815 323.994C122.815 327.72 119.798 330.737 116.073 330.737Z"
    fill="#DD9305"
  />
  <path
    d="M116.073 376.588H83.3731C79.648 376.588 76.6309 373.57 76.6309 369.845C76.6309 366.12 79.648 363.103 83.3731 363.103H116.073C119.798 363.103 122.815 366.12 122.815 369.845C122.815 373.57 119.798 376.588 116.073 376.588Z"
    fill="#DD9305"
  />
  <path
    d="M116.073 422.435H83.3731C79.648 422.435 76.6309 419.418 76.6309 415.693C76.6309 411.967 79.648 408.95 83.3731 408.95H116.073C119.798 408.95 122.815 411.967 122.815 415.693C122.815 419.418 119.798 422.435 116.073 422.435Z"
    fill="#DD9305"
  />
  <path
    d="M93.4875 235.917V472.751C93.4875 478.105 89.5096 482.44 84.6012 482.44H-23.3695C-28.2779 482.44 -32.2559 478.102 -32.2559 472.751V235.917C-32.2559 230.563 -28.2779 226.228 -23.3695 226.228H84.6012C89.5096 226.228 93.4875 230.566 93.4875 235.917Z"
    fill="#45399A"
  />
  <mask
    id="mask5_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="-33"
    y="226"
    width="127"
    height="257"
  >
    <path
      d="M93.4875 235.919V472.753C93.4875 478.107 89.5096 482.442 84.6012 482.442H-23.3695C-28.2779 482.442 -32.2559 478.104 -32.2559 472.753V235.919C-32.2559 230.565 -28.2779 226.229 -23.3695 226.229H84.6012C89.5096 226.229 93.4875 230.568 93.4875 235.919Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask5_909_2)">
    <path
      d="M265.479 330.776L143.789 233.941L-54.4843 483.126L67.2053 579.96L265.479 330.776Z"
      fill="#3D2F89"
    />
  </g>
  <path
    d="M-0.985733 245.443H-14.3085C-18.263 245.443 -21.4688 248.649 -21.4688 252.604V265.927C-21.4688 269.882 -18.263 273.088 -14.3085 273.088H-0.985733C2.96879 273.088 6.17455 269.882 6.17455 265.927V252.604C6.17455 248.649 2.96879 245.443 -0.985733 245.443Z"
    fill="#3D2F89"
  />
  <path
    d="M-5.43463 249.152H-9.52724C-13.8895 249.152 -17.4258 252.689 -17.4258 257.051V261.629C-17.4258 265.992 -13.8895 269.528 -9.52724 269.528H-5.43463C-1.07237 269.528 2.46391 265.992 2.46391 261.629V257.051C2.46391 252.689 -1.07237 249.152 -5.43463 249.152Z"
    fill="#DD9305"
  />
  <path
    d="M33.3971 245.443H20.0743C16.1198 245.443 12.9141 248.649 12.9141 252.604V265.927C12.9141 269.882 16.1198 273.088 20.0743 273.088H33.3971C37.3516 273.088 40.5574 269.882 40.5574 265.927V252.604C40.5574 248.649 37.3516 245.443 33.3971 245.443Z"
    fill="#3D2F89"
  />
  <path
    d="M28.954 249.152H24.8614C20.4992 249.152 16.9629 252.689 16.9629 257.051V261.629C16.9629 265.992 20.4992 269.528 24.8614 269.528H28.954C33.3163 269.528 36.8526 265.992 36.8526 261.629V257.051C36.8526 252.689 33.3163 249.152 28.954 249.152Z"
    fill="#DD9305"
  />
  <path
    d="M67.7857 245.443H54.4631C50.5086 245.443 47.3027 248.649 47.3027 252.604V265.927C47.3027 269.882 50.5086 273.088 54.4631 273.088H67.7857C71.7402 273.088 74.946 269.882 74.946 265.927V252.604C74.946 248.649 71.7402 245.443 67.7857 245.443Z"
    fill="#3D2F89"
  />
  <path
    d="M63.3387 249.152H59.2463C54.884 249.152 51.3477 252.689 51.3477 257.051V261.629C51.3477 265.992 54.884 269.528 59.2463 269.528H63.3387C67.701 269.528 71.2374 265.992 71.2374 261.629V257.051C71.2374 252.689 67.701 249.152 63.3387 249.152Z"
    fill="#DD9305"
  />
  <path
    d="M88.8479 245.443C84.8935 245.443 81.6875 248.649 81.6875 252.604V265.927C81.6875 269.882 84.8935 273.088 88.8479 273.088H93.4865V245.443H88.8479Z"
    fill="#3D2F89"
  />
  <path
    d="M93.488 249.156V269.525C89.1932 269.451 85.7344 265.945 85.7344 261.63V257.052C85.7344 252.737 89.1932 249.23 93.488 249.156Z"
    fill="#DD9305"
  />
  <path
    d="M-0.985733 290.619H-14.3085C-18.263 290.619 -21.4688 293.825 -21.4688 297.78V311.103C-21.4688 315.058 -18.263 318.264 -14.3085 318.264H-0.985733C2.96879 318.264 6.17455 315.058 6.17455 311.103V297.78C6.17455 293.825 2.96879 290.619 -0.985733 290.619Z"
    fill="#3D2F89"
  />
  <path
    d="M-5.43463 294.327H-9.52724C-13.8895 294.327 -17.4258 297.864 -17.4258 302.226V306.804C-17.4258 311.167 -13.8895 314.703 -9.52724 314.703H-5.43463C-1.07237 314.703 2.46391 311.167 2.46391 306.804V302.226C2.46391 297.864 -1.07237 294.327 -5.43463 294.327Z"
    fill="#DD9305"
  />
  <path
    d="M33.3971 290.619H20.0743C16.1198 290.619 12.9141 293.825 12.9141 297.78V311.103C12.9141 315.058 16.1198 318.264 20.0743 318.264H33.3971C37.3516 318.264 40.5574 315.058 40.5574 311.103V297.78C40.5574 293.825 37.3516 290.619 33.3971 290.619Z"
    fill="#3D2F89"
  />
  <path
    d="M28.954 294.327H24.8614C20.4992 294.327 16.9629 297.864 16.9629 302.226V306.804C16.9629 311.167 20.4992 314.703 24.8614 314.703H28.954C33.3163 314.703 36.8526 311.167 36.8526 306.804V302.226C36.8526 297.864 33.3163 294.327 28.954 294.327Z"
    fill="#DD9305"
  />
  <path
    d="M67.7857 290.619H54.4631C50.5086 290.619 47.3027 293.825 47.3027 297.78V311.103C47.3027 315.058 50.5086 318.264 54.4631 318.264H67.7857C71.7402 318.264 74.946 315.058 74.946 311.103V297.78C74.946 293.825 71.7402 290.619 67.7857 290.619Z"
    fill="#3D2F89"
  />
  <path
    d="M63.3387 294.327H59.2463C54.884 294.327 51.3477 297.864 51.3477 302.226V306.804C51.3477 311.167 54.884 314.703 59.2463 314.703H63.3387C67.701 314.703 71.2374 311.167 71.2374 306.804V302.226C71.2374 297.864 67.701 294.327 63.3387 294.327Z"
    fill="#DD9305"
  />
  <path
    d="M88.8479 290.619C84.8935 290.619 81.6875 293.825 81.6875 297.78V311.103C81.6875 315.057 84.8935 318.264 88.8479 318.264H93.4865V290.619H88.8479Z"
    fill="#3D2F89"
  />
  <path
    d="M93.488 294.33V314.699C89.1932 314.625 85.7344 311.119 85.7344 306.804V302.226C85.7344 297.91 89.1932 294.404 93.488 294.33Z"
    fill="#DD9305"
  />
  <path
    d="M-0.985733 337.143H-14.3085C-18.263 337.143 -21.4688 340.348 -21.4688 344.303V357.626C-21.4688 361.581 -18.263 364.787 -14.3085 364.787H-0.985733C2.96879 364.787 6.17455 361.581 6.17455 357.626V344.303C6.17455 340.348 2.96879 337.143 -0.985733 337.143Z"
    fill="#3D2F89"
  />
  <path
    d="M-5.43463 340.851H-9.52724C-13.8895 340.851 -17.4258 344.387 -17.4258 348.749V353.328C-17.4258 357.69 -13.8895 361.227 -9.52724 361.227H-5.43463C-1.07237 361.227 2.46391 357.69 2.46391 353.328V348.749C2.46391 344.387 -1.07237 340.851 -5.43463 340.851Z"
    fill="#DD9305"
  />
  <path
    d="M33.3971 337.143H20.0743C16.1198 337.143 12.9141 340.348 12.9141 344.303V357.626C12.9141 361.581 16.1198 364.787 20.0743 364.787H33.3971C37.3516 364.787 40.5574 361.581 40.5574 357.626V344.303C40.5574 340.348 37.3516 337.143 33.3971 337.143Z"
    fill="#3D2F89"
  />
  <path
    d="M28.954 340.851H24.8614C20.4992 340.851 16.9629 344.387 16.9629 348.749V353.328C16.9629 357.69 20.4992 361.227 24.8614 361.227H28.954C33.3163 361.227 36.8526 357.69 36.8526 353.328V348.749C36.8526 344.387 33.3163 340.851 28.954 340.851Z"
    fill="#DD9305"
  />
  <path
    d="M67.7857 337.143H54.4631C50.5086 337.143 47.3027 340.348 47.3027 344.303V357.626C47.3027 361.581 50.5086 364.787 54.4631 364.787H67.7857C71.7402 364.787 74.946 361.581 74.946 357.626V344.303C74.946 340.348 71.7402 337.143 67.7857 337.143Z"
    fill="#3D2F89"
  />
  <path
    d="M63.3387 340.851H59.2463C54.884 340.851 51.3477 344.387 51.3477 348.749V353.328C51.3477 357.69 54.884 361.227 59.2463 361.227H63.3387C67.701 361.227 71.2374 357.69 71.2374 353.328V348.749C71.2374 344.387 67.701 340.851 63.3387 340.851Z"
    fill="#DD9305"
  />
  <path
    d="M88.8479 337.143C84.8935 337.143 81.6875 340.349 81.6875 344.303V357.626C81.6875 361.581 84.8935 364.787 88.8479 364.787H93.4865V337.143H88.8479Z"
    fill="#3D2F89"
  />
  <path
    d="M93.488 340.854V361.223C89.1932 361.149 85.7344 357.642 85.7344 353.327V348.749C85.7344 344.434 89.1932 340.928 93.488 340.854Z"
    fill="#DD9305"
  />
  <path
    d="M-0.985733 382.654H-14.3085C-18.263 382.654 -21.4688 385.86 -21.4688 389.815V403.138C-21.4688 407.093 -18.263 410.299 -14.3085 410.299H-0.985733C2.96879 410.299 6.17455 407.093 6.17455 403.138V389.815C6.17455 385.86 2.96879 382.654 -0.985733 382.654Z"
    fill="#3D2F89"
  />
  <path
    d="M-5.43463 386.362H-9.52724C-13.8895 386.362 -17.4258 389.899 -17.4258 394.261V398.839C-17.4258 403.202 -13.8895 406.738 -9.52724 406.738H-5.43463C-1.07237 406.738 2.46391 403.202 2.46391 398.839V394.261C2.46391 389.899 -1.07237 386.362 -5.43463 386.362Z"
    fill="#DD9305"
  />
  <path
    d="M33.3971 382.654H20.0743C16.1198 382.654 12.9141 385.86 12.9141 389.815V403.138C12.9141 407.093 16.1198 410.299 20.0743 410.299H33.3971C37.3516 410.299 40.5574 407.093 40.5574 403.138V389.815C40.5574 385.86 37.3516 382.654 33.3971 382.654Z"
    fill="#3D2F89"
  />
  <path
    d="M28.954 386.362H24.8614C20.4992 386.362 16.9629 389.899 16.9629 394.261V398.839C16.9629 403.202 20.4992 406.738 24.8614 406.738H28.954C33.3163 406.738 36.8526 403.202 36.8526 398.839V394.261C36.8526 389.899 33.3163 386.362 28.954 386.362Z"
    fill="#DD9305"
  />
  <path
    d="M67.7857 382.654H54.4631C50.5086 382.654 47.3027 385.86 47.3027 389.815V403.138C47.3027 407.093 50.5086 410.299 54.4631 410.299H67.7857C71.7402 410.299 74.946 407.093 74.946 403.138V389.815C74.946 385.86 71.7402 382.654 67.7857 382.654Z"
    fill="#3D2F89"
  />
  <path
    d="M63.3387 386.362H59.2463C54.884 386.362 51.3477 389.899 51.3477 394.261V398.839C51.3477 403.202 54.884 406.738 59.2463 406.738H63.3387C67.701 406.738 71.2374 403.202 71.2374 398.839V394.261C71.2374 389.899 67.701 386.362 63.3387 386.362Z"
    fill="#DD9305"
  />
  <path
    d="M88.8479 382.654C84.8935 382.654 81.6875 385.86 81.6875 389.815V403.138C81.6875 407.093 84.8935 410.299 88.8479 410.299H93.4865V382.654H88.8479Z"
    fill="#3D2F89"
  />
  <path
    d="M93.488 386.365V406.734C89.1932 406.66 85.7344 403.154 85.7344 398.839V394.261C85.7344 389.946 89.1932 386.439 93.488 386.365Z"
    fill="#DD9305"
  />
  <path
    d="M-1.32362 428.167H-14.6464C-18.6009 428.167 -21.8066 431.373 -21.8066 435.328V448.651C-21.8066 452.605 -18.6009 455.811 -14.6464 455.811H-1.32362C2.6309 455.811 5.83666 452.605 5.83666 448.651V435.328C5.83666 431.373 2.6309 428.167 -1.32362 428.167Z"
    fill="#3D2F89"
  />
  <path
    d="M-5.77057 431.875H-9.86317C-14.2254 431.875 -17.7617 435.411 -17.7617 439.774V444.352C-17.7617 448.714 -14.2254 452.251 -9.86317 452.251H-5.77057C-1.40831 452.251 2.12798 448.714 2.12798 444.352V439.774C2.12798 435.411 -1.40831 431.875 -5.77057 431.875Z"
    fill="#DD9305"
  />
  <path
    d="M33.0611 428.167H19.7384C15.7839 428.167 12.5781 431.373 12.5781 435.328V448.651C12.5781 452.605 15.7839 455.811 19.7384 455.811H33.0611C37.0157 455.811 40.2214 452.605 40.2214 448.651V435.328C40.2214 431.373 37.0157 428.167 33.0611 428.167Z"
    fill="#3D2F89"
  />
  <path
    d="M28.6161 431.875H24.5235C20.1613 431.875 16.625 435.411 16.625 439.774V444.352C16.625 448.714 20.1613 452.251 24.5235 452.251H28.6161C32.9784 452.251 36.5147 448.714 36.5147 444.352V439.774C36.5147 435.411 32.9784 431.875 28.6161 431.875Z"
    fill="#DD9305"
  />
  <path
    d="M67.4478 428.167H54.1252C50.1707 428.167 46.9648 431.373 46.9648 435.328V448.651C46.9648 452.605 50.1707 455.811 54.1252 455.811H67.4478C71.4023 455.811 74.6081 452.605 74.6081 448.651V435.328C74.6081 431.373 71.4023 428.167 67.4478 428.167Z"
    fill="#3D2F89"
  />
  <path
    d="M63.0008 431.875H58.9084C54.5461 431.875 51.0098 435.411 51.0098 439.774V444.352C51.0098 448.714 54.5461 452.251 58.9084 452.251H63.0008C67.3631 452.251 70.8995 448.714 70.8995 444.352V439.774C70.8995 435.411 67.3631 431.875 63.0008 431.875Z"
    fill="#DD9305"
  />
  <path
    d="M88.51 428.167C84.5556 428.167 81.3496 431.373 81.3496 435.328V448.651C81.3496 452.605 84.5556 455.811 88.51 455.811H93.1486V428.167H88.51Z"
    fill="#3D2F89"
  />
  <path
    d="M93.1481 431.877V452.246C88.8533 452.172 85.3945 448.666 85.3945 444.351V439.772C85.3945 435.457 88.8533 431.951 93.1481 431.877Z"
    fill="#DD9305"
  />
  <mask
    id="mask6_909_2"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="40"
    y="206"
    width="37"
    height="37"
  >
    <path
      d="M70.2215 206H47.6348C43.9112 206 40.8926 209.019 40.8926 212.743V235.33C40.8926 239.054 43.9112 242.073 47.6348 242.073H70.2215C73.9451 242.073 76.9637 239.054 76.9637 235.33V212.743C76.9637 209.019 73.9451 206 70.2215 206Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask6_909_2)">
    <path
      d="M76.0393 193.88L53.8809 176.247L17.7773 221.621L39.9358 239.254L76.0393 193.88Z"
      fill="#2B2164"
    />
  </g>
  <path
    d="M93.4856 237.421H-32.2577C-35.9828 237.421 -39 234.403 -39 230.678C-39 226.953 -35.9828 223.936 -32.2577 223.936H93.4856C97.2107 223.936 100.228 226.953 100.228 230.678C100.228 234.403 97.2107 237.421 93.4856 237.421Z"
    fill="#2F246B"
  />
  <path
    d="M87.0796 483.793H-26.1805C-29.5348 483.793 -32.252 480.775 -32.252 477.05C-32.252 473.325 -29.5314 470.308 -26.1805 470.308H87.0796C90.4338 470.308 93.1509 473.325 93.1509 477.05C93.1509 480.775 90.4305 483.793 87.0796 483.793Z"
    fill="#2F246B"
  />
</svg>
