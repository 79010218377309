<svg viewBox="0 0 1019 309" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M973.627 252.943C973.559 252.943 973.517 253.016 973.551 253.075C973.585 253.133 973.543 253.206 973.475 253.206H747.22C747.152 253.206 747.11 253.133 747.144 253.075C747.177 253.016 747.135 252.943 747.068 252.943H722.369C711.324 252.943 704.421 240.987 709.944 231.422L744.017 172.405C749.539 162.84 763.345 162.84 768.867 172.405C774.39 181.97 788.196 181.97 793.718 172.405L843.027 87C850.725 73.6667 869.97 73.6667 877.668 87L908.738 140.815C915.087 151.812 930.959 151.812 937.308 140.815C943.657 129.819 959.529 129.819 965.878 140.815L1016.33 228.201C1022.68 239.197 1014.74 252.943 1002.05 252.943H973.627Z"
    fill="#C9A76B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M786.16 308.121C786.078 308.121 786.026 308.21 786.068 308.281C786.109 308.353 786.057 308.442 785.975 308.442H509.862C509.78 308.442 509.728 308.353 509.77 308.281C509.811 308.21 509.759 308.12 509.677 308.12H479.535C466.057 308.12 457.633 293.529 464.372 281.856L505.953 209.836C512.692 198.163 529.541 198.163 536.28 209.836C543.019 221.509 559.868 221.509 566.607 209.836L630.598 99C638.296 85.6667 657.541 85.6667 665.239 99L707.085 171.479C714.783 184.812 734.14 184.618 741.838 171.285C749.536 157.951 768.893 157.757 776.591 171.091L838.385 278.121C846.083 291.454 836.46 308.121 821.064 308.121H786.16Z"
    fill="#C9A76B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M248.359 270.227C248.259 270.227 248.197 270.335 248.247 270.422C248.297 270.509 248.234 270.617 248.134 270.617H-86.9906C-87.0908 270.617 -87.1534 270.508 -87.1033 270.422C-87.0532 270.335 -87.1158 270.226 -87.216 270.226H-125.97C-141.366 270.226 -150.989 253.56 -143.291 240.226L-90.655 149.059C-82.957 135.725 -63.712 135.725 -56.014 149.059L-53.8447 152.816C-46.1467 166.149 -26.9017 166.149 -19.2037 152.816L63.2512 10C70.9492 -3.33333 90.1942 -3.33333 97.8922 10L156.083 110.79C163.781 124.123 183.026 124.123 190.724 110.79L198.398 97.4985C206.096 84.1652 225.341 84.1651 233.039 97.4985L315.443 240.227C323.141 253.56 313.519 270.227 298.123 270.227H248.359Z"
    fill="#817B9D"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M416.292 278.809C416.23 278.809 416.191 278.876 416.222 278.93C416.253 278.983 416.214 279.05 416.153 279.05H208.923C208.861 279.05 208.822 278.983 208.853 278.929C208.884 278.875 208.846 278.808 208.784 278.808H186.161C176.045 278.808 169.722 267.857 174.78 259.096L205.987 205.044C211.045 196.283 223.691 196.283 228.749 205.044C233.807 213.805 246.453 213.805 251.511 205.044L295.217 129.343C302.915 116.009 322.16 116.009 329.858 129.343L356.859 176.11C362.674 186.181 377.211 186.181 383.026 176.11C388.84 166.038 403.377 166.038 409.192 176.11L455.402 256.148C461.217 266.22 453.949 278.809 442.319 278.809H416.292Z"
    fill="#39344F"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M562.089 280.894C577.485 280.894 587.108 264.227 579.41 250.894L464.578 52C456.88 38.6667 437.635 38.6667 429.937 52L354.316 182.981C349.133 191.957 336.177 191.957 330.995 182.981C325.813 174.005 312.857 174.005 307.674 182.981L263 260.36C257.817 269.336 264.295 280.556 274.66 280.556H297.786C297.872 280.556 297.926 280.65 297.883 280.725C297.84 280.8 297.894 280.894 297.981 280.894H562.089Z"
    fill="#817B9D"
  />
</svg>
