<a
  id="mlh-trust-badge"
  style="display:block;max-width:80px;min-width:40px;position:fixed;right:50px;top:0;width:10%;z-index:10000"
  href="https://mlh.io/seasons/2024/events?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2024-season&utm_content=gray"
  target="_blank"
  ><img
    src="https://s3.amazonaws.com/logged-assets/trust-badge/2024/mlh-trust-badge-2024-gray.svg"
    alt="Major League Hacking 2023 Hackathon Season"
    style="width:100%"
  /></a
>
