<svg viewBox="0 0 1970 889" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_910_26)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25 35.9182C60.9417 47.4137 100.757 59.0922 144.641 70.9635C492.784 165.142 747.842 239.847 868.687 297.87C898.916 312.384 920.26 325.634 932.564 337.589C938.705 343.556 942.281 348.904 943.787 353.608C945.23 358.119 944.849 362.24 942.605 366.316C940.26 370.575 935.706 375.079 928.253 379.665C920.837 384.229 910.864 388.684 898.13 392.985C872.66 401.588 836.762 409.372 789.636 416.327C732.468 424.764 670.505 432.388 610.337 439.792L610.333 439.792C571.051 444.626 532.533 449.365 496.615 454.175C451.237 460.252 410.05 466.438 376.95 473.055C344.109 479.62 318.449 486.745 304.723 494.993C298.081 498.984 292.41 504.33 292.432 511.467C292.454 518.511 298.044 524.303 305.632 529.175C321.282 539.222 352.69 549.955 402.818 561.896C503.494 585.876 682.486 615.319 970.197 652.795C1257.71 690.246 1563.97 750.431 1821.9 825.555C1864.35 837.92 1905.48 850.688 1945 863.822V853.323C1906.32 840.513 1866.12 828.06 1824.7 815.995C1566.19 740.698 1259.41 680.422 971.493 642.919C683.778 605.442 505.254 576.054 405.143 552.208C354.878 540.235 325.075 529.807 311.049 520.802C303.799 516.148 302.435 512.942 302.431 511.436C302.426 510.022 303.657 507.265 309.888 503.522C321.908 496.299 345.883 489.425 378.917 482.821C411.69 476.27 452.618 470.118 497.947 464.047C533.62 459.27 572.046 454.541 611.3 449.71L611.32 449.708C671.622 442.287 733.876 434.625 791.101 426.18C838.462 419.19 875.04 411.301 901.34 402.418C914.492 397.976 925.228 393.235 933.508 388.139C941.753 383.066 947.882 377.445 951.372 371.106C954.963 364.583 955.569 357.631 953.313 350.583C951.12 343.729 946.311 337.032 939.545 330.459C926.037 317.333 903.48 303.518 873.029 288.897C751.155 230.381 495.029 155.428 147.261 61.351C102.192 49.1589 61.5165 37.1938 25 25.4524V35.9182Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_910_26"
      x="0"
      y="0.452148"
      width="1970"
      height="888.37"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="12.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 1 0 0 0 0 0.3125 0 0 0 0 0.624557 0 0 0 1 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_910_26"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_910_26"
        result="shape"
      />
    </filter>
  </defs>
</svg>
