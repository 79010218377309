<svg viewBox="0 0 1920 584" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1389.97 337.348H1389.96C1385.53 337.348 1381.94 340.922 1381.94 345.331V364.41C1381.94 368.819 1385.53 372.393 1389.96 372.393H1389.97C1394.4 372.393 1397.99 368.819 1397.99 364.41V345.331C1397.99 340.922 1394.4 337.348 1389.97 337.348Z"
    fill="#52399A"
  />
  <path
    d="M1447.04 337.348H1447.04C1442.61 337.348 1439.02 340.922 1439.02 345.331V364.41C1439.02 368.819 1442.61 372.393 1447.04 372.393H1447.04C1451.47 372.393 1455.07 368.819 1455.07 364.41V345.331C1455.07 340.922 1451.47 337.348 1447.04 337.348Z"
    fill="#52399A"
  />
  <path
    d="M1690.83 392.681H1339.18C1334.75 392.681 1331.16 396.255 1331.16 400.664V435.975C1331.16 440.384 1334.75 443.958 1339.18 443.958H1690.83C1695.26 443.958 1698.85 440.384 1698.85 435.975V400.664C1698.85 396.255 1695.26 392.681 1690.83 392.681Z"
    fill="#52399A"
  />
  <path
    d="M1460.28 313H1375.13C1370.7 313 1367.11 316.574 1367.11 320.983V340.063C1367.11 344.471 1370.7 348.045 1375.13 348.045H1460.28C1464.71 348.045 1468.3 344.471 1468.3 340.063V320.983C1468.3 316.574 1464.71 313 1460.28 313Z"
    fill="#52399A"
  />
  <path
    d="M1389.86 390.099H1352.52C1348.09 390.099 1344.5 393.673 1344.5 398.082V438.926C1344.5 443.335 1348.09 446.909 1352.52 446.909H1389.86C1394.29 446.909 1397.88 443.335 1397.88 438.926V398.082C1397.88 393.673 1394.29 390.099 1389.86 390.099Z"
    fill="#FF3883"
  />
  <path
    d="M1446.94 389.73H1409.61C1405.18 389.73 1401.59 393.305 1401.59 397.713V438.558C1401.59 442.967 1405.18 446.541 1409.61 446.541H1446.94C1451.37 446.541 1454.96 442.967 1454.96 438.558V397.713C1454.96 393.305 1451.37 389.73 1446.94 389.73Z"
    fill="#FF3883"
  />
  <path
    d="M1524.03 390.099H1486.7C1482.27 390.099 1478.68 393.673 1478.68 398.082V438.926C1478.68 443.335 1482.27 446.909 1486.7 446.909H1524.03C1528.46 446.909 1532.05 443.335 1532.05 438.926V398.082C1532.05 393.673 1528.46 390.099 1524.03 390.099Z"
    fill="#FF3883"
  />
  <path
    d="M1582.6 389.73H1545.27C1540.84 389.73 1537.25 393.305 1537.25 397.713V438.558C1537.25 442.967 1540.84 446.541 1545.27 446.541H1582.6C1587.03 446.541 1590.62 442.967 1590.62 438.558V397.713C1590.62 393.305 1587.03 389.73 1582.6 389.73Z"
    fill="#FF3883"
  />
  <path
    d="M1647.83 387.148H1610.5C1606.07 387.148 1602.48 390.723 1602.48 395.131V435.976C1602.48 440.385 1606.07 443.959 1610.5 443.959H1647.83C1652.26 443.959 1655.85 440.385 1655.85 435.976V395.131C1655.85 390.723 1652.26 387.148 1647.83 387.148Z"
    fill="#FF3883"
  />
  <path
    d="M1684.53 436.581H1332.88C1328.45 436.581 1324.86 440.155 1324.86 444.564V449.256C1324.86 453.665 1328.45 457.239 1332.88 457.239H1684.53C1688.96 457.239 1692.55 453.665 1692.55 449.256V444.564C1692.55 440.155 1688.96 436.581 1684.53 436.581Z"
    fill="#37246B"
  />
  <path
    d="M1837.98 332.551H1660.17C1655.74 332.551 1652.15 336.125 1652.15 340.534V455.527C1652.15 459.936 1655.74 463.51 1660.17 463.51H1837.98C1842.41 463.51 1846 459.936 1846 455.527V340.534C1846 336.125 1842.41 332.551 1837.98 332.551Z"
    fill="#52399A"
  />
  <path
    d="M1666.23 398.215H1318.56L1329.61 365.383H1655.18L1666.23 398.215Z"
    fill="#37246B"
  />
  <path
    d="M1666.23 403.749H1318.56C1316.77 403.749 1315.09 402.894 1314.05 401.448C1313 400.001 1312.72 398.146 1313.29 396.456L1324.34 363.624C1325.1 361.367 1327.22 359.847 1329.61 359.847H1655.18C1657.57 359.847 1659.7 361.367 1660.45 363.624L1671.5 396.456C1672.07 398.146 1671.79 400.001 1670.74 401.448C1669.7 402.894 1668.02 403.749 1666.23 403.749ZM1326.28 392.682H1658.5L1651.18 370.917H1333.61L1326.28 392.682Z"
    fill="#37246B"
  />
  <circle
    cx="36.3568"
    cy="36.3568"
    r="36.3568"
    transform="matrix(-1 0 0 1 1719.94 353)"
    fill="#185E45"
  />
  <circle
    cx="36.3568"
    cy="36.3568"
    r="36.3568"
    transform="matrix(-1 0 0 1 1700.71 378.239)"
    fill="#069461"
  />
  <ellipse
    cx="33.6526"
    cy="33.9531"
    rx="33.6526"
    ry="33.9531"
    transform="matrix(-1 0 0 1 1740.38 365.619)"
    fill="#185E45"
  />
  <rect
    width="18"
    height="68.507"
    rx="4"
    transform="matrix(-1 0 0 1 1700.71 450.953)"
    fill="url(#paint0_linear_909_4)"
  />
  <circle
    cx="36.3568"
    cy="36.3568"
    r="36.3568"
    transform="matrix(-1 0 0 1 1700.71 423.31)"
    fill="#069461"
  />
  <ellipse
    cx="36.3568"
    cy="36.0563"
    rx="36.3568"
    ry="36.0563"
    transform="matrix(-1 0 0 1 1756 406.483)"
    fill="#D9D9D9"
  />
  <ellipse
    cx="36.3568"
    cy="36.0563"
    rx="36.3568"
    ry="36.0563"
    transform="matrix(-1 0 0 1 1756 406.483)"
    fill="#069461"
  />
  <path
    d="M1969.71 475.471H1716.56C1713.17 475.471 1710.43 478.336 1710.43 481.87V544.448C1710.43 547.982 1713.17 550.847 1716.56 550.847H1969.71C1973.09 550.847 1975.84 547.982 1975.84 544.448V481.87C1975.84 478.336 1973.09 475.471 1969.71 475.471Z"
    fill="#43246B"
  />
  <path
    d="M1974.25 414.951H1706.9C1703.51 414.951 1700.77 417.816 1700.77 421.35V483.929C1700.77 487.463 1703.51 490.328 1706.9 490.328H1974.25C1977.64 490.328 1980.38 487.463 1980.38 483.929V421.35C1980.38 417.816 1977.64 414.951 1974.25 414.951Z"
    fill="#64399A"
  />
  <path
    d="M1818.79 526.938H1682.13C1678.74 526.938 1676 529.802 1676 533.337V554.852C1676 558.386 1678.74 561.251 1682.13 561.251H1818.79C1822.17 561.251 1824.92 558.386 1824.92 554.852V533.337C1824.92 529.802 1822.17 526.938 1818.79 526.938Z"
    fill="#64399A"
  />
  <path
    d="M1915.97 504.004H1906.8C1902.86 504.004 1899.67 507.336 1899.67 511.446V527.186C1899.67 531.296 1902.86 534.628 1906.8 534.628H1915.97C1919.91 534.628 1923.1 531.296 1923.1 527.186V511.446C1923.1 507.336 1919.91 504.004 1915.97 504.004Z"
    fill="#64399A"
  />
  <path
    d="M1787.09 504.004H1777.91C1773.98 504.004 1770.78 507.336 1770.78 511.446V527.186C1770.78 531.296 1773.98 534.628 1777.91 534.628H1787.09C1791.02 534.628 1794.22 531.296 1794.22 527.186V511.446C1794.22 507.336 1791.02 504.004 1787.09 504.004Z"
    fill="#64399A"
  />
  <path
    d="M1849.21 499.324H1869.68C1873.62 499.324 1876.81 502.66 1876.81 506.766V550.848H1842.08V506.766C1842.08 502.657 1845.27 499.324 1849.21 499.324H1849.21Z"
    fill="#64399A"
  />
  <path
    d="M1980.38 422.902H1700.68C1696.47 422.902 1693.06 419.343 1693.06 414.951C1693.06 410.559 1696.47 407 1700.68 407H1980.38C1984.59 407 1988 410.559 1988 414.951C1988 419.343 1984.59 422.902 1980.38 422.902Z"
    fill="#43246B"
  />
  <path
    d="M1964.42 435.081H1915.61C1911.67 435.081 1908.48 438.413 1908.48 442.523V462.942C1908.48 467.052 1911.67 470.384 1915.61 470.384H1964.42C1968.36 470.384 1971.55 467.052 1971.55 462.942V442.523C1971.55 438.413 1968.36 435.081 1964.42 435.081Z"
    fill="#FF3883"
  />
  <path
    d="M1855.64 434.988H1819.93C1815.99 434.988 1812.8 438.32 1812.8 442.431V462.849C1812.8 466.959 1815.99 470.291 1819.93 470.291H1855.64C1859.58 470.291 1862.77 466.959 1862.77 462.849V442.431C1862.77 438.32 1859.58 434.988 1855.64 434.988Z"
    fill="#FF3883"
  />
  <path
    d="M1763.63 434.988H1727.92C1723.98 434.988 1720.79 438.32 1720.79 442.431V462.849C1720.79 466.959 1723.98 470.291 1727.92 470.291H1763.63C1767.57 470.291 1770.76 466.959 1770.76 462.849V442.431C1770.76 438.32 1767.57 434.988 1763.63 434.988Z"
    fill="#FF3883"
  />
  <path
    d="M1980.38 483.332H1700.68C1696.47 483.332 1693.06 479.773 1693.06 475.381C1693.06 470.989 1696.47 467.43 1700.68 467.43H1980.38C1984.59 467.43 1988 470.989 1988 475.381C1988 479.773 1984.59 483.332 1980.38 483.332Z"
    fill="#43246B"
  />
  <path
    d="M1977.64 557.119H1786.29C1782.09 557.119 1778.68 553.56 1778.68 549.168C1778.68 544.776 1782.09 541.217 1786.29 541.217H1977.64C1981.85 541.217 1985.26 544.776 1985.26 549.168C1985.26 553.56 1981.85 557.119 1977.64 557.119Z"
    fill="#64399A"
  />
  <path
    d="M1878.92 499.552H1840.23C1837.71 499.552 1835.66 497.415 1835.66 494.781C1835.66 492.148 1837.71 490.011 1840.23 490.011H1878.92C1881.45 490.011 1883.49 492.148 1883.49 494.781C1883.49 497.415 1881.45 499.552 1878.92 499.552Z"
    fill="#43246B"
  />
  <path
    d="M739.91 366.527H739.913C744.23 366.527 747.729 369.79 747.729 373.815V427.133C747.729 431.158 744.23 434.421 739.913 434.421H739.91C735.594 434.421 732.095 431.158 732.095 427.133V373.815C732.095 369.79 735.594 366.527 739.91 366.527Z"
    fill="#FEAC18"
  />
  <path
    d="M725.07 382.1H810.904L802.351 365.594H733.624L725.07 382.1Z"
    fill="#2F246B"
  />
  <path
    d="M725.073 386.77H810.907C812.63 386.77 814.233 385.942 815.152 384.581C816.067 383.22 816.17 381.513 815.419 380.065L806.866 363.559C806.031 361.946 804.277 360.921 802.354 360.921H733.63C731.709 360.921 729.956 361.946 729.117 363.559L720.564 380.065C719.813 381.513 719.916 383.22 720.831 384.581C721.746 385.942 723.349 386.77 725.076 386.77H725.073ZM802.921 377.427H733.058L736.772 370.264H799.211L802.925 377.427H802.921Z"
    fill="#2F246B"
  />
  <path
    d="M761.181 298.012H874.655C878.366 298.012 881.375 300.817 881.375 304.278V428.156C881.375 431.617 878.366 434.422 874.655 434.422H761.181C757.47 434.422 754.461 431.617 754.461 428.156V304.278C754.461 300.817 757.47 298.012 761.181 298.012Z"
    fill="#3D2F89"
  />
  <path
    d="M754.459 313.585H881.373L868.725 280.884H767.107L754.459 313.585Z"
    fill="#2F246B"
  />
  <path
    d="M754.459 318.255H881.373C883.006 318.255 884.535 317.514 885.471 316.268C886.409 315.023 886.636 313.431 886.085 311.998L873.437 279.297C872.719 277.444 870.835 276.211 868.725 276.211H767.107C764.996 276.211 763.109 277.444 762.394 279.297L749.746 311.998C749.192 313.431 749.422 315.023 750.361 316.268C751.299 317.514 752.829 318.255 754.459 318.255ZM874.239 308.912H761.589L770.623 285.554H865.204L874.239 308.912Z"
    fill="#2F246B"
  />
  <path
    d="M774.869 310.778H862.306C865.645 310.778 868.352 313.302 868.352 316.415V435.634C868.352 438.748 865.645 441.271 862.306 441.271H774.869C771.531 441.271 768.824 438.748 768.824 435.634V316.415C768.824 313.302 771.531 310.778 774.869 310.778Z"
    fill="#45399A"
  />
  <path
    d="M818.591 272.785L868.357 313.646H768.82L818.591 272.785Z"
    fill="#45399A"
  />
  <path
    d="M768.828 321.43C770.789 321.43 772.756 320.792 774.342 319.487L818.592 283.155L862.842 319.487C866.302 322.327 871.579 322.016 874.624 318.789C877.67 315.563 877.336 310.642 873.876 307.802L824.106 266.941C820.953 264.353 816.224 264.353 813.071 266.941L763.301 307.802C759.841 310.642 759.504 315.563 762.553 318.789C764.203 320.537 766.507 321.43 768.822 321.43H768.828Z"
    fill="#2F246B"
  />
  <path
    d="M809.04 321.991H827.81C832.126 321.991 835.625 325.254 835.625 329.279V345.536C835.625 349.561 832.126 352.824 827.81 352.824H809.04C804.724 352.824 801.225 349.561 801.225 345.536V329.279C801.225 325.254 804.724 321.991 809.04 321.991Z"
    fill="#FEAC18"
  />
  <path
    d="M827.807 390.819H805.366C801.051 390.819 797.551 394.086 797.551 398.107V441.273H835.625V398.107C835.625 394.083 832.122 390.819 827.81 390.819H827.807Z"
    fill="#FEAC18"
  />
  <path
    d="M775.836 390.508H861.67L853.117 374.002H784.389L775.836 390.508Z"
    fill="#2F246B"
  />
  <path
    d="M775.839 395.18H861.672C863.396 395.18 864.999 394.351 865.917 392.99C866.832 391.629 866.936 389.922 866.184 388.474L857.631 371.968C856.796 370.355 855.043 369.33 853.119 369.33H784.395C782.475 369.33 780.721 370.355 779.883 371.968L771.33 388.474C770.578 389.922 770.682 391.629 771.597 392.99C772.512 394.351 774.115 395.18 775.842 395.18H775.839ZM853.687 385.836H783.824L787.538 378.673H849.976L853.69 385.836H853.687Z"
    fill="#2F246B"
  />
  <path
    d="M707.172 427.007H883.185C887.501 427.007 891 430.27 891 434.295V434.298C891 438.323 887.501 441.585 883.185 441.585H707.172C702.856 441.585 699.357 438.323 699.357 434.298V434.295C699.357 430.27 702.856 427.007 707.172 427.007Z"
    fill="#2F246B"
  />
  <path
    d="M329.567 83.7041H329.564C326.006 83.7041 323.121 86.3941 323.121 89.7124V133.671C323.121 136.989 326.006 139.679 329.564 139.679H329.567C333.126 139.679 336.01 136.989 336.01 133.671V89.7124C336.01 86.3941 333.126 83.7041 329.567 83.7041Z"
    fill="#FEAC18"
  />
  <path
    d="M341.801 96.5431H271.035L278.087 82.9346H334.749L341.801 96.5431Z"
    fill="#3B246B"
  />
  <path
    d="M341.799 100.394H271.034C269.613 100.394 268.291 99.7106 267.534 98.5885C266.78 97.4664 266.694 96.0594 267.314 94.8654L274.366 81.2568C275.054 79.9268 276.5 79.082 278.086 79.082H334.745C336.328 79.082 337.774 79.9268 338.465 81.2568L345.517 94.8654C346.136 96.0594 346.051 97.4664 345.296 98.5885C344.542 99.7106 343.22 100.394 341.797 100.394H341.799ZM277.618 92.6906H335.216L332.154 86.785H280.677L277.615 92.6906H277.618Z"
    fill="#3B246B"
  />
  <path
    d="M312.029 27.2158H218.476C215.416 27.2158 212.936 29.5288 212.936 32.382V134.513C212.936 137.366 215.416 139.679 218.476 139.679H312.029C315.089 139.679 317.569 137.366 317.569 134.513V32.382C317.569 29.5288 315.089 27.2158 312.029 27.2158Z"
    fill="#4C2F89"
  />
  <path
    d="M317.571 40.0561H212.938L223.365 13.0957H307.144L317.571 40.0561Z"
    fill="#3B246B"
  />
  <path
    d="M317.572 43.9065H212.938C211.591 43.9065 210.33 43.2954 209.559 42.2684C208.785 41.2413 208.598 39.9292 209.052 38.7481L219.48 11.7877C220.072 10.2599 221.625 9.24316 223.365 9.24316H307.144C308.884 9.24316 310.44 10.2599 311.029 11.7877L321.457 38.7481C321.914 39.9292 321.724 41.2413 320.95 42.2684C320.176 43.2954 318.915 43.9065 317.572 43.9065ZM218.819 36.2035H311.693L304.244 16.9461H226.267L218.819 36.2035Z"
    fill="#3B246B"
  />
  <path
    d="M300.743 37.7422H228.656C225.903 37.7422 223.672 39.8229 223.672 42.3897V140.679C223.672 143.246 225.903 145.327 228.656 145.327H300.743C303.496 145.327 305.727 143.246 305.727 140.679V42.3897C305.727 39.8229 303.496 37.7422 300.743 37.7422Z"
    fill="#58399A"
  />
  <path
    d="M264.698 6.41797L223.668 40.1056H305.731L264.698 6.41797Z"
    fill="#58399A"
  />
  <path
    d="M305.724 46.524C304.107 46.524 302.485 45.9976 301.177 44.9218L264.696 14.9675L228.215 44.9218C225.362 47.2635 221.011 47.0067 218.5 44.3466C215.989 41.6865 216.264 37.6296 219.117 35.2879L260.15 1.60029C262.749 -0.53343 266.648 -0.53343 269.248 1.60029L310.281 35.2879C313.133 37.6296 313.411 41.6865 310.897 44.3466C309.537 45.7871 307.637 46.524 305.729 46.524H305.724Z"
    fill="#3B246B"
  />
  <path
    d="M272.572 46.9863H257.098C253.539 46.9863 250.654 49.6763 250.654 52.9946V66.3978C250.654 69.7161 253.539 72.4061 257.098 72.4061H272.572C276.131 72.4061 279.016 69.7161 279.016 66.3978V52.9946C279.016 49.6763 276.131 46.9863 272.572 46.9863Z"
    fill="#FEAC18"
  />
  <path
    d="M257.1 103.731H275.601C279.159 103.731 282.044 106.425 282.044 109.74V145.327H250.654V109.74C250.654 106.422 253.543 103.731 257.098 103.731H257.1Z"
    fill="#FEAC18"
  />
  <path
    d="M299.947 103.475H229.182L236.233 89.8662H292.895L299.947 103.475Z"
    fill="#3B246B"
  />
  <path
    d="M299.946 107.326H229.18C227.759 107.326 226.438 106.643 225.681 105.521C224.926 104.399 224.841 102.992 225.46 101.798L232.512 88.1894C233.2 86.8594 234.646 86.0146 236.232 86.0146H292.891C294.475 86.0146 295.92 86.8594 296.611 88.1894L303.663 101.798C304.283 102.992 304.197 104.399 303.443 105.521C302.688 106.643 301.367 107.326 299.943 107.326H299.946ZM235.764 99.6232H293.362L290.3 93.7176H238.823L235.761 99.6232H235.764Z"
    fill="#3B246B"
  />
  <path
    d="M356.557 133.566H211.443C207.885 133.566 205 136.256 205 139.575V139.577C205 142.896 207.885 145.586 211.443 145.586H356.557C360.115 145.586 363 142.896 363 139.577V139.575C363 136.256 360.115 133.566 356.557 133.566Z"
    fill="#3B246B"
  />
  <path
    d="M1173.73 300.449H1169.42C1166.38 300.449 1163.92 302.916 1163.92 305.958V327.673C1163.92 330.715 1166.38 333.182 1169.42 333.182H1173.73C1176.77 333.182 1179.23 330.715 1179.23 327.673V305.958C1179.23 302.916 1176.77 300.449 1173.73 300.449Z"
    fill="#2F246B"
  />
  <path
    d="M1211.58 362.966H969.887C966.252 362.966 963.305 365.914 963.305 369.552V433.956C963.305 437.593 966.252 440.541 969.887 440.541H1211.58C1215.22 440.541 1218.16 437.593 1218.16 433.956V369.552C1218.16 365.914 1215.22 362.966 1211.58 362.966Z"
    fill="#3D2F89"
  />
  <path
    d="M1222.09 362.968H958.906L978.192 328.599H1202.8L1222.09 362.968Z"
    fill="#2F246B"
  />
  <path
    d="M1222.09 367.877H958.908C957.165 367.877 955.555 366.951 954.672 365.448C953.792 363.943 953.775 362.083 954.629 360.565L973.915 326.196C974.786 324.648 976.421 323.688 978.195 323.688H1202.81C1204.58 323.688 1206.21 324.648 1207.08 326.196L1226.37 360.565C1227.22 362.087 1227.21 363.943 1226.33 365.448C1225.45 366.954 1223.84 367.877 1222.09 367.877ZM967.29 358.057H1213.71L1199.93 333.508H981.067L967.29 358.057Z"
    fill="#2F246B"
  />
  <path
    d="M1112.46 345.947H1026.81C1023.54 345.947 1020.89 348.6 1020.89 351.872V438.547C1020.89 441.819 1023.54 444.471 1026.81 444.471H1112.46C1115.73 444.471 1118.38 441.819 1118.38 438.547V351.872C1118.38 348.6 1115.73 345.947 1112.46 345.947Z"
    fill="#45399A"
  />
  <path
    d="M1069.63 309.94L1020.88 352.885H1118.38L1069.63 309.94Z"
    fill="#45399A"
  />
  <path
    d="M1118.38 357.795C1116.46 357.795 1114.53 357.124 1112.98 355.752L1069.63 317.567L1026.29 355.752C1022.9 358.737 1017.73 358.41 1014.75 355.019C1011.76 351.628 1012.09 346.456 1015.48 343.471L1064.23 300.526C1067.32 297.806 1071.95 297.806 1075.04 300.526L1123.8 343.471C1127.18 346.456 1127.51 351.628 1124.53 355.019C1122.91 356.855 1120.65 357.795 1118.39 357.795H1118.38Z"
    fill="#2F246B"
  />
  <path
    d="M1070.45 340.379H1069.14C1060.2 340.379 1052.95 347.633 1052.95 356.581C1052.95 365.53 1060.2 372.784 1069.14 372.784H1070.45C1079.39 372.784 1086.65 365.53 1086.65 356.581C1086.65 347.633 1079.39 340.379 1070.45 340.379Z"
    fill="#FEAC18"
  />
  <path
    d="M1093.71 380.969H1045.23C1041 380.969 1037.57 384.398 1037.57 388.628V416.189C1037.57 420.419 1041 423.848 1045.23 423.848H1093.71C1097.94 423.848 1101.37 420.419 1101.37 416.189V388.628C1101.37 384.398 1097.94 380.969 1093.71 380.969Z"
    fill="#FEAC18"
  />
  <path
    d="M1003.09 383.586H984.048C979.82 383.586 976.393 387.015 976.393 391.245V413.896C976.393 418.126 979.82 421.555 984.048 421.555H1003.09C1007.32 421.555 1010.74 418.126 1010.74 413.896V391.245C1010.74 387.015 1007.32 383.586 1003.09 383.586Z"
    fill="#FEAC18"
  />
  <path
    d="M1203.12 398.154C1203.12 392.278 1198.36 387.516 1192.48 387.516C1186.61 387.516 1181.85 392.278 1181.85 398.154V398.481C1181.85 404.356 1186.61 409.119 1192.48 409.119C1198.36 409.119 1203.12 404.356 1203.12 398.481V398.154Z"
    fill="#FEAC18"
  />
  <path
    d="M1140.87 377.726H1163.03C1167.29 377.726 1170.74 381.825 1170.74 386.869V441.027H1133.16V386.869C1133.16 381.821 1136.61 377.726 1140.87 377.726H1140.87Z"
    fill="#FEAC18"
  />
  <path
    d="M1171.56 392.489C1169.63 392.489 1167.71 391.818 1166.15 390.446L1152.16 378.119L1138.17 390.446C1134.78 393.431 1129.61 393.104 1126.63 389.713C1123.64 386.322 1123.97 381.15 1127.36 378.165L1146.76 361.079C1149.84 358.355 1154.48 358.355 1157.57 361.079L1176.96 378.165C1180.35 381.15 1180.68 386.322 1177.7 389.713C1176.08 391.549 1173.82 392.489 1171.56 392.489Z"
    fill="#2F246B"
  />
  <path
    d="M793.646 196.131H674.834C671.636 196.131 669.043 198.725 669.043 201.925V258.589C669.043 261.789 671.636 264.384 674.834 264.384H793.646C796.844 264.384 799.437 261.789 799.437 258.589V201.925C799.437 198.725 796.844 196.131 793.646 196.131Z"
    fill="#3D2F89"
  />
  <path
    d="M717.572 194.403H717.57C713.85 194.403 710.834 197.42 710.834 201.142V261.101C710.834 264.823 713.85 267.84 717.57 267.84H717.572C721.292 267.84 724.308 264.823 724.308 261.101V201.142C724.308 197.42 721.292 194.403 717.572 194.403Z"
    fill="#2F246B"
  />
  <path
    d="M779.746 194.403H779.743C776.023 194.403 773.008 197.42 773.008 201.142V261.101C773.008 264.823 776.023 267.84 779.743 267.84H779.746C783.466 267.84 786.482 264.823 786.482 261.101V201.142C786.482 197.42 783.466 194.403 779.746 194.403Z"
    fill="#2F246B"
  />
  <path
    d="M799.681 196.132H651.48L668.449 165.894H782.709L799.681 196.132Z"
    fill="#2F246B"
  />
  <path
    d="M799.683 200.451H651.483C649.948 200.451 648.532 199.636 647.755 198.315C646.981 196.99 646.966 195.354 647.718 194.018L664.686 163.779C665.452 162.417 666.891 161.573 668.451 161.573H782.711C784.272 161.573 785.711 162.417 786.477 163.779L803.445 194.018C804.196 195.357 804.182 196.99 803.408 198.315C802.633 199.639 801.214 200.451 799.68 200.451H799.683ZM658.86 191.812H792.308L780.187 170.213H670.981L658.86 191.812Z"
    fill="#2F246B"
  />
  <path
    d="M739.393 217.729H758.733C762.452 217.729 765.469 220.75 765.469 224.468V264.383H732.654V224.468C732.654 220.748 735.674 217.729 739.39 217.729H739.393Z"
    fill="#FEAC18"
  />
  <path
    d="M693.769 169.924H618.411C615.534 169.924 613.201 172.258 613.201 175.136V262.627C613.201 265.505 615.534 267.839 618.411 267.839H693.769C696.647 267.839 698.979 265.505 698.979 262.627V175.136C698.979 172.258 696.647 169.924 693.769 169.924Z"
    fill="#45399A"
  />
  <path
    d="M677.275 217.729H634.616C630.896 217.729 627.881 220.747 627.881 224.468V242.957C627.881 246.679 630.896 249.696 634.616 249.696H677.275C680.995 249.696 684.011 246.679 684.011 242.957V224.468C684.011 220.747 680.995 217.729 677.275 217.729Z"
    fill="#FEAC18"
  />
  <path
    d="M656.085 132.196L613.193 169.98H698.98L656.085 132.196Z"
    fill="#45399A"
  />
  <path
    d="M698.976 177.181C697.286 177.181 695.591 176.591 694.223 175.384L656.087 141.787L617.95 175.384C614.968 178.01 610.42 177.722 607.795 174.739C605.17 171.755 605.458 167.205 608.44 164.579L651.334 126.795C654.052 124.402 658.128 124.402 660.845 126.795L703.74 164.579C706.722 167.205 707.012 171.755 704.384 174.739C702.962 176.354 700.976 177.181 698.981 177.181H698.976Z"
    fill="#2F246B"
  />
  <path
    d="M664.319 164.739H648.142C644.422 164.739 641.406 167.756 641.406 171.478V186.511C641.406 190.233 644.422 193.25 648.142 193.25H664.319C668.039 193.25 671.054 190.233 671.054 186.511V171.478C671.054 167.756 668.039 164.739 664.319 164.739Z"
    fill="#FEAC18"
  />
  <path
    d="M764.948 148.324H761.163C758.487 148.324 756.318 150.494 756.318 153.171V172.276C756.318 174.953 758.487 177.123 761.163 177.123H764.948C767.623 177.123 769.792 174.953 769.792 172.276V153.171C769.792 150.494 767.623 148.324 764.948 148.324Z"
    fill="#45399A"
  />
  <path
    d="M581.1 122.54H426.41C423.278 122.54 420.738 125.081 420.738 128.214V183.706C420.738 186.84 423.278 189.381 426.41 189.381H581.1C584.232 189.381 586.771 186.84 586.771 183.706V128.214C586.771 125.081 584.232 122.54 581.1 122.54Z"
    fill="#3D2F89"
  />
  <path
    d="M586.769 122.541H403.541L420.158 92.9277H570.152L586.769 122.541Z"
    fill="#2F246B"
  />
  <path
    d="M586.77 126.77H403.542C402.039 126.77 400.652 125.972 399.891 124.677C399.133 123.38 399.119 121.778 399.855 120.469L416.472 90.8566C417.222 89.5226 418.631 88.6963 420.159 88.6963H570.153C571.681 88.6963 573.09 89.5226 573.84 90.8566L590.457 120.469C591.193 121.781 591.179 123.38 590.421 124.677C589.662 125.975 588.273 126.77 586.77 126.77ZM410.764 118.309H579.545L567.675 97.1571H422.634L410.764 118.309Z"
    fill="#2F246B"
  />
  <path
    d="M444.952 96.876H371.153C368.335 96.876 366.051 99.1615 366.051 101.981V187.661C366.051 190.48 368.335 192.765 371.153 192.765H444.952C447.77 192.765 450.054 190.48 450.054 187.661V101.981C450.054 99.1615 447.77 96.876 444.952 96.876Z"
    fill="#45399A"
  />
  <path
    d="M408.049 59.9307L366.045 96.9327H450.057L408.049 59.9307Z"
    fill="#45399A"
  />
  <path
    d="M450.052 103.983C448.397 103.983 446.737 103.405 445.398 102.223L408.05 69.3219L370.703 102.223C367.782 104.795 363.329 104.513 360.758 101.592C358.187 98.6697 358.469 94.2137 361.389 91.6416L403.396 54.6396C406.057 52.2959 410.049 52.2959 412.71 54.6396L454.717 91.6416C457.638 94.2137 457.922 98.6697 455.349 101.592C453.956 103.174 452.011 103.983 450.058 103.983H450.052Z"
    fill="#2F246B"
  />
  <path
    d="M416.116 91.7988H400.274C396.631 91.7988 393.678 94.7535 393.678 98.3982V113.12C393.678 116.765 396.631 119.72 400.274 119.72H416.116C419.759 119.72 422.712 116.765 422.712 113.12V98.3982C422.712 94.7535 419.759 91.7988 416.116 91.7988Z"
    fill="#FEAC18"
  />
  <path
    d="M428.802 143.692H387.026C383.383 143.692 380.43 146.647 380.43 150.292V168.398C380.43 172.043 383.383 174.997 387.026 174.997H428.802C432.445 174.997 435.398 172.043 435.398 168.398V150.292C435.398 146.647 432.445 143.692 428.802 143.692Z"
    fill="#DAAD10"
  />
  <path
    d="M564.671 143.692H522.895C519.252 143.692 516.299 146.647 516.299 150.292V168.398C516.299 172.043 519.252 174.997 522.895 174.997H564.671C568.314 174.997 571.267 172.043 571.267 168.398V150.292C571.267 146.647 568.314 143.692 564.671 143.692Z"
    fill="#FEAC18"
  />
  <path
    d="M469.619 143.692H488.559C492.201 143.692 495.155 146.651 495.155 150.292V189.381H463.02V150.292C463.02 146.648 465.977 143.692 469.616 143.692H469.619Z"
    fill="#FEAC18"
  />
  <path
    d="M135.951 210.509H291.387C294.534 210.509 297.086 213.062 297.086 216.21V271.968C297.086 275.117 294.534 277.67 291.387 277.67H135.951C132.803 277.67 130.252 275.117 130.252 271.968V216.21C130.252 213.062 132.803 210.509 135.951 210.509Z"
    fill="#3D2F89"
  />
  <path
    d="M130.249 210.509H314.361L297.664 180.754H146.946L130.249 210.509Z"
    fill="#2F246B"
  />
  <path
    d="M130.247 214.759H314.36C315.869 214.759 317.263 213.957 318.028 212.657C318.79 211.353 318.804 209.744 318.065 208.429L301.367 178.674C300.614 177.333 299.197 176.503 297.662 176.503H146.945C145.409 176.503 143.993 177.333 143.24 178.674L126.542 208.429C125.803 209.746 125.817 211.353 126.579 212.657C127.341 213.96 128.737 214.759 130.247 214.759ZM307.103 206.258H137.507L149.434 185.004H295.175L307.103 206.258Z"
    fill="#2F246B"
  />
  <path
    d="M272.757 184.721H346.912C349.744 184.721 352.039 187.017 352.039 189.85V275.941C352.039 278.774 349.744 281.07 346.912 281.07H272.757C269.926 281.07 267.63 278.774 267.63 275.941V189.85C267.63 187.017 269.926 184.721 272.757 184.721Z"
    fill="#45399A"
  />
  <path
    d="M309.836 147.599L352.043 184.778H267.626L309.836 147.599Z"
    fill="#45399A"
  />
  <path
    d="M267.63 191.862C269.292 191.862 270.961 191.281 272.306 190.094L309.834 157.035L347.362 190.094C350.296 192.678 354.772 192.395 357.355 189.459C359.938 186.523 359.655 182.046 356.72 179.461L314.51 142.282C311.837 139.927 307.826 139.927 305.152 142.282L262.942 179.461C260.007 182.046 259.721 186.523 262.307 189.459C263.707 191.049 265.661 191.862 267.624 191.862H267.63Z"
    fill="#2F246B"
  />
  <path
    d="M301.731 179.619H317.649C321.31 179.619 324.277 182.588 324.277 186.25V201.043C324.277 204.705 321.31 207.674 317.649 207.674H301.731C298.07 207.674 295.103 204.705 295.103 201.043V186.25C295.103 182.588 298.07 179.619 301.731 179.619Z"
    fill="#FEAC18"
  />
  <path
    d="M288.984 231.762H330.962C334.622 231.762 337.59 234.731 337.59 238.393V256.586C337.59 260.248 334.622 263.217 330.962 263.217H288.984C285.324 263.217 282.356 260.248 282.356 256.586V238.393C282.356 234.731 285.324 231.762 288.984 231.762Z"
    fill="#FEAC18"
  />
  <path
    d="M152.457 231.762H194.434C198.095 231.762 201.062 234.731 201.062 238.393V256.586C201.062 260.248 198.095 263.217 194.434 263.217H152.457C148.796 263.217 145.829 260.248 145.829 256.586V238.393C145.829 234.731 148.796 231.762 152.457 231.762Z"
    fill="#FEAC18"
  />
  <path
    d="M247.967 231.762H228.935C225.276 231.762 222.307 234.734 222.307 238.393V277.669H254.598V238.393C254.598 234.732 251.626 231.762 247.97 231.762H247.967Z"
    fill="#FEAC18"
  />
  <circle cx="415.413" cy="150.357" r="36.3568" fill="#185E45" />
  <circle cx="434.644" cy="175.596" r="36.3568" fill="#069461" />
  <ellipse cx="392.276" cy="160.572" rx="33.6526" ry="33.9531" fill="#185E45" />
  <rect
    x="398.287"
    y="211.953"
    width="27.6432"
    height="68.507"
    rx="13.8216"
    fill="url(#paint1_linear_909_4)"
  />
  <circle cx="434.644" cy="220.666" r="36.3568" fill="#069461" />
  <ellipse cx="379.357" cy="203.54" rx="36.3568" ry="36.0563" fill="#D9D9D9" />
  <ellipse cx="379.357" cy="203.54" rx="36.3568" ry="36.0563" fill="#069461" />
  <path
    d="M598.904 294.37H425.546C422.035 294.37 419.189 297.217 419.189 300.729V362.918C419.189 366.43 422.035 369.277 425.546 369.277H598.904C602.415 369.277 605.261 366.43 605.261 362.918V300.729C605.261 297.217 602.415 294.37 598.904 294.37Z"
    fill="#4C2F89"
  />
  <path
    d="M605.258 294.37H399.916L418.539 261.184H586.635L605.258 294.37Z"
    fill="#3B246B"
  />
  <path
    d="M605.259 299.11H399.917C398.234 299.11 396.679 298.216 395.826 296.765C394.977 295.311 394.961 293.516 395.785 292.049L414.408 258.862C415.248 257.367 416.828 256.441 418.54 256.441H586.636C588.348 256.441 589.928 257.367 590.768 258.862L609.391 292.049C610.216 293.519 610.2 295.311 609.35 296.765C608.5 298.219 606.943 299.11 605.259 299.11ZM408.011 289.628H597.162L583.859 265.923H421.314L408.011 289.628Z"
    fill="#3B246B"
  />
  <path
    d="M446.326 265.608H363.62C360.462 265.608 357.902 268.17 357.902 271.329V367.35C357.902 370.509 360.462 373.07 363.62 373.07H446.326C449.484 373.07 452.044 370.509 452.044 367.35V271.329C452.044 268.17 449.484 265.608 446.326 265.608Z"
    fill="#58399A"
  />
  <path
    d="M404.968 224.204L357.895 265.672H452.045L404.968 224.204Z"
    fill="#58399A"
  />
  <path
    d="M452.041 273.573C450.186 273.573 448.326 272.925 446.825 271.601L404.97 234.729L363.115 271.601C359.842 274.483 354.851 274.167 351.97 270.893C349.089 267.619 349.405 262.625 352.677 259.742L399.754 218.275C402.737 215.648 407.21 215.648 410.192 218.275L457.269 259.742C460.542 262.625 460.861 267.619 457.977 270.893C456.416 272.666 454.236 273.573 452.047 273.573H452.041Z"
    fill="#3B246B"
  />
  <path
    d="M414.01 259.919H396.256C392.173 259.919 388.863 263.23 388.863 267.315V283.813C388.863 287.898 392.173 291.209 396.256 291.209H414.01C418.092 291.209 421.402 287.898 421.402 283.813V267.315C421.402 263.23 418.092 259.919 414.01 259.919Z"
    fill="#FEAC18"
  />
  <path
    d="M428.226 318.075H381.408C377.325 318.075 374.016 321.386 374.016 325.471V345.762C374.016 349.847 377.325 353.158 381.408 353.158H428.226C432.308 353.158 435.618 349.847 435.618 345.762V325.471C435.618 321.386 432.308 318.075 428.226 318.075Z"
    fill="#FEAC18"
  />
  <path
    d="M580.493 318.075H533.676C529.593 318.075 526.283 321.386 526.283 325.471V345.762C526.283 349.847 529.593 353.158 533.676 353.158H580.493C584.576 353.158 587.886 349.847 587.886 345.762V325.471C587.886 321.386 584.576 318.075 580.493 318.075Z"
    fill="#FEAC18"
  />
  <path
    d="M473.97 318.075H495.196C499.277 318.075 502.588 321.391 502.588 325.471V369.278H466.574V325.471C466.574 321.388 469.888 318.075 473.967 318.075H473.97Z"
    fill="#FEAC18"
  />
  <path
    d="M127.045 45.4854H123.657C121.262 45.4854 119.32 47.3518 119.32 49.6541V66.0862C119.32 68.3885 121.262 70.2549 123.657 70.2549H127.045C129.44 70.2549 131.381 68.3885 131.381 66.0862V49.6541C131.381 47.3518 129.44 45.4854 127.045 45.4854Z"
    fill="#2F246B"
  />
  <path
    d="M156.856 92.7939H-33.4898C-36.3528 92.7939 -38.6738 95.0252 -38.6738 97.7776V146.514C-38.6738 149.266 -36.3528 151.498 -33.4898 151.498H156.856C159.719 151.498 162.04 149.266 162.04 146.514V97.7776C162.04 95.0252 159.719 92.7939 156.856 92.7939Z"
    fill="#3D2F89"
  />
  <path
    d="M165.132 92.7961H-42.1367L-26.9479 66.7881H149.943L165.132 92.7961Z"
    fill="#2F246B"
  />
  <path
    d="M165.134 96.5111H-42.1344C-43.5077 96.5111 -44.7753 95.8102 -45.471 94.6733C-46.1641 93.5339 -46.177 92.1269 -45.5045 90.9776L-30.3157 64.9696C-29.6304 63.798 -28.3421 63.0723 -26.9456 63.0723H149.946C151.342 63.0723 152.63 63.798 153.316 64.9696L168.505 90.9776C169.177 92.1294 169.164 93.5339 168.471 94.6733C167.778 95.8127 166.508 96.5111 165.134 96.5111ZM-35.5333 89.0803H158.533L147.683 70.5031H-24.6833L-35.5333 89.0803Z"
    fill="#2F246B"
  />
  <path
    d="M78.7936 79.915H11.3394C8.76376 79.915 6.67578 81.9223 6.67578 84.3983V149.988C6.67578 152.464 8.76376 154.471 11.3394 154.471H78.7936C81.3693 154.471 83.4572 152.464 83.4572 149.988V84.3983C83.4572 81.9223 81.3693 79.915 78.7936 79.915Z"
    fill="#45399A"
  />
  <path
    d="M45.0613 52.667L6.66797 85.1646H83.4572L45.0613 52.667Z"
    fill="#45399A"
  />
  <path
    d="M83.4567 88.8805C81.9443 88.8805 80.4267 88.3728 79.2028 87.3349L45.066 58.4388L10.9291 87.3349C8.25982 89.5939 4.18885 89.3462 1.83903 86.7801C-0.510794 84.214 -0.253141 80.3004 2.41617 78.0414L40.8121 45.5438C43.2443 43.4854 46.8928 43.4854 49.325 45.5438L87.7209 78.0414C90.3902 80.3004 90.6505 84.214 88.2981 86.7801C87.0253 88.1696 85.2474 88.8805 83.4619 88.8805H83.4567Z"
    fill="#2F246B"
  />
  <path
    d="M45.7084 75.7021H44.6778C37.634 75.7021 31.9238 81.1915 31.9238 87.9631C31.9238 94.7346 37.634 100.224 44.6778 100.224H45.7084C52.7522 100.224 58.4624 94.7346 58.4624 87.9631C58.4624 81.1915 52.7522 75.7021 45.7084 75.7021Z"
    fill="#FEAC18"
  />
  <path
    d="M64.0282 106.417H25.8436C22.5138 106.417 19.8145 109.012 19.8145 112.213V133.069C19.8145 136.27 22.5138 138.865 25.8436 138.865H64.0282C67.358 138.865 70.0574 136.27 70.0574 133.069V112.213C70.0574 109.012 67.358 106.417 64.0282 106.417Z"
    fill="#FEAC18"
  />
  <path
    d="M150.191 119.421C150.191 114.975 146.442 111.371 141.817 111.371C137.192 111.371 133.443 114.975 133.443 119.421V119.669C133.443 124.115 137.192 127.719 141.817 127.719C146.442 127.719 150.191 124.115 150.191 119.669V119.421Z"
    fill="#FEAC18"
  />
  <path
    d="M101.172 103.963H118.618C121.973 103.963 124.694 107.065 124.694 110.882V151.865H95.0938V110.882C95.0938 107.062 97.8175 103.963 101.17 103.963H101.172Z"
    fill="#FEAC18"
  />
  <path
    d="M125.334 115.135C123.822 115.135 122.304 114.627 121.08 113.589L110.06 104.261L99.0405 113.589C96.3711 115.848 92.3002 115.6 89.9504 113.034C87.6005 110.468 87.8582 106.555 90.5275 104.296L105.804 91.3659C108.236 89.3051 111.885 89.3051 114.317 91.3659L129.593 104.296C132.263 106.555 132.523 110.468 130.17 113.034C128.898 114.424 127.12 115.135 125.334 115.135Z"
    fill="#2F246B"
  />
  <path
    d="M1133.8 188.043H950.211L968.509 158.664H1115.51L1133.8 188.043Z"
    fill="#3B246B"
  />
  <path
    d="M1133.8 192.24H950.21C948.686 192.24 947.282 191.411 946.544 190.08C945.803 188.748 945.845 187.117 946.65 185.824L964.948 156.445C965.715 155.214 967.06 154.467 968.508 154.467H1115.5C1116.95 154.467 1118.3 155.214 1119.06 156.445L1137.36 185.824C1138.17 187.117 1138.21 188.748 1137.47 190.08C1136.73 191.411 1135.32 192.24 1133.8 192.24H1133.8ZM957.767 183.846H1126.24L1113.17 162.861H970.835L957.767 183.846Z"
    fill="#3B246B"
  />
  <path
    d="M1128.32 188.045H1029.95C1026.84 188.045 1024.32 190.565 1024.32 193.675V242.852C1024.32 245.961 1026.84 248.481 1029.95 248.481H1128.32C1131.43 248.481 1133.95 245.961 1133.95 242.852V193.675C1133.95 190.565 1131.43 188.045 1128.32 188.045Z"
    fill="#58399A"
  />
  <path
    d="M1024.57 132.643H871.105C867.998 132.643 865.479 135.163 865.479 138.272V249.565C865.479 252.674 867.998 255.194 871.105 255.194H1024.57C1027.68 255.194 1030.2 252.674 1030.2 249.565V138.272C1030.2 135.163 1027.68 132.643 1024.57 132.643Z"
    fill="#4C2F89"
  />
  <path
    d="M1030.62 132.644H865.195L881.681 103.265H1014.13L1030.62 132.644Z"
    fill="#3B246B"
  />
  <path
    d="M1030.62 136.84H865.196C863.705 136.84 862.329 136.048 861.574 134.764C860.822 133.477 860.808 131.888 861.538 130.589L878.024 101.211C878.768 99.8872 880.166 99.0674 881.682 99.0674H1014.14C1015.65 99.0674 1017.05 99.8872 1017.79 101.211L1034.28 130.589C1035.01 131.891 1035 133.477 1034.24 134.764C1033.49 136.051 1032.11 136.84 1030.62 136.84ZM872.361 128.446H1023.46L1011.68 107.461H884.137L872.361 128.446Z"
    fill="#3B246B"
  />
  <path
    d="M982.906 132.643H909.691C906.895 132.643 904.629 134.91 904.629 137.707V256.286C904.629 259.083 906.895 261.35 909.691 261.35H982.906C985.702 261.35 987.968 259.083 987.968 256.286V137.707C987.968 134.91 985.702 132.643 982.906 132.643Z"
    fill="#58399A"
  />
  <path
    d="M946.293 95.9902L904.621 132.7H987.969L946.293 95.9902Z"
    fill="#58399A"
  />
  <path
    d="M987.965 139.695C986.324 139.695 984.676 139.121 983.348 137.949L946.296 105.307L909.243 137.949C906.346 140.5 901.927 140.221 899.377 137.322C896.826 134.423 897.106 130.002 900.003 127.451L941.678 90.7411C944.318 88.4131 948.278 88.4131 950.918 90.7411L992.594 127.451C995.491 130.002 995.773 134.423 993.22 137.322C991.838 138.892 989.909 139.695 987.971 139.695H987.965Z"
    fill="#3B246B"
  />
  <path
    d="M954.294 140.197H938.577C934.963 140.197 932.033 143.129 932.033 146.745V161.35C932.033 164.966 934.963 167.897 938.577 167.897H954.294C957.909 167.897 960.838 164.966 960.838 161.35V146.745C960.838 143.129 957.909 140.197 954.294 140.197Z"
    fill="#FEAC18"
  />
  <path
    d="M1013.03 154.188H1004.3C1000.69 154.188 997.756 157.119 997.756 160.735V175.34C997.756 178.956 1000.69 181.888 1004.3 181.888H1013.03C1016.64 181.888 1019.57 178.956 1019.57 175.34V160.735C1019.57 157.119 1016.64 154.188 1013.03 154.188Z"
    fill="#FEAC18"
  />
  <path
    d="M1013.03 201.194H1004.3C1000.69 201.194 997.756 204.126 997.756 207.742V222.347C997.756 225.963 1000.69 228.894 1004.3 228.894H1013.03C1016.64 228.894 1019.57 225.963 1019.57 222.347V207.742C1019.57 204.126 1016.64 201.194 1013.03 201.194Z"
    fill="#FEAC18"
  />
  <path
    d="M890.533 154.188H881.808C878.194 154.188 875.264 157.119 875.264 160.735V175.34C875.264 178.956 878.194 181.888 881.808 181.888H890.533C894.147 181.888 897.077 178.956 897.077 175.34V160.735C897.077 157.119 894.147 154.188 890.533 154.188Z"
    fill="#FEAC18"
  />
  <path
    d="M890.533 201.194H881.808C878.194 201.194 875.264 204.126 875.264 207.742V222.347C875.264 225.963 878.194 228.894 881.808 228.894H890.533C894.147 228.894 897.077 225.963 897.077 222.347V207.742C897.077 204.126 894.147 201.194 890.533 201.194Z"
    fill="#FEAC18"
  />
  <path
    d="M938.58 216.023H957.37C960.984 216.023 963.915 218.958 963.915 222.571V261.351H932.033V222.571C932.033 218.956 934.967 216.023 938.577 216.023H938.58Z"
    fill="#FEAC18"
  />
  <path
    d="M1052.4 203.152H1104.19C1107.81 203.152 1110.74 206.087 1110.74 209.7V248.48H1045.86V209.7C1045.86 206.085 1048.79 203.152 1052.4 203.152H1052.4Z"
    fill="#FEAC18"
  />
  <path
    d="M906.355 82H902.677C900.078 82 897.971 84.1083 897.971 86.709V105.271C897.971 107.872 900.078 109.98 902.677 109.98H906.355C908.954 109.98 911.062 107.872 911.062 105.271V86.709C911.062 84.1083 908.954 82 906.355 82Z"
    fill="#58399A"
  />
  <path
    d="M982.096 204.552H910.223L917.385 189.723H974.934L982.096 204.552Z"
    fill="#3B246B"
  />
  <path
    d="M982.095 208.749H910.222C908.778 208.749 907.436 208.004 906.667 206.782C905.901 205.559 905.814 204.026 906.443 202.725L913.605 187.895C914.305 186.446 915.773 185.525 917.384 185.525H974.93C976.538 185.525 978.006 186.446 978.708 187.895L985.87 202.725C986.499 204.026 986.413 205.559 985.646 206.782C984.88 208.004 983.538 208.749 982.092 208.749H982.095ZM916.908 200.355H975.408L972.298 193.919H920.015L916.905 200.355H916.908Z"
    fill="#3B246B"
  />
  <circle cx="1535.02" cy="475.662" r="34.6526" fill="#069461" />
  <circle cx="1512.68" cy="469.361" r="32.3615" fill="#069461" />
  <rect
    x="1518.7"
    y="518.334"
    width="26.3474"
    height="65.2957"
    rx="13.1737"
    fill="url(#paint2_linear_909_4)"
  />
  <circle cx="1553.35" cy="504.3" r="34.6526" fill="#185E45" />
  <ellipse cx="1500.65" cy="510.314" rx="34.6526" ry="34.3662" fill="#D9D9D9" />
  <ellipse cx="1500.65" cy="510.314" rx="34.6526" ry="34.3662" fill="#069461" />
  <defs>
    <linearGradient
      id="paint0_linear_909_4"
      x1="9"
      y1="0"
      x2="9"
      y2="68.507"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_909_4"
      x1="412.109"
      y1="211.953"
      x2="412.109"
      y2="280.46"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_909_4"
      x1="1531.87"
      y1="518.334"
      x2="1531.87"
      y2="583.63"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.630208" stop-color="#0E0E1A" />
      <stop offset="1" stop-color="#0E0E1A" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>
