<svg viewBox="0 0 257 288" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M127.195 29.8702C134.899 16.5715 154.101 16.5716 161.805 29.8702L252.245 185.974C259.97 199.307 250.349 216 234.939 216H54.0605C38.6512 216 29.0303 199.307 36.755 185.974L127.195 29.8702Z"
    fill="#C27D00"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M317.195 218.972C332.591 218.972 342.213 202.305 334.515 188.972L269.164 75.7803C261.466 62.4469 242.221 62.4469 234.523 75.7803L189.668 153.471C186.201 159.476 177.533 159.476 174.066 153.471C170.599 147.466 161.931 147.466 158.464 153.471L128.58 205.233C125.113 211.238 129.446 218.744 136.381 218.744H151.851C151.91 218.744 151.946 218.808 151.917 218.858C151.888 218.909 151.924 218.972 151.983 218.972H317.195Z"
    fill="#C9A76B"
  />
</svg>
