<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 2000 2000"
  enable-background="new 0 0 2000 2000"
  xml:space="preserve"
>
  <path
    fill="#002D64"
    opacity="1.000000"
    stroke="none"
    d="
M1808.000000,2001.000000 
	C1205.333374,2001.000000 603.166687,2001.000000 1.000000,2001.000000 
	C1.000000,1334.333374 1.000000,667.666687 1.000000,1.000000 
	C667.666687,1.000000 1334.333374,1.000000 2001.000000,1.000000 
	C2001.000000,667.666687 2001.000000,1334.333374 2001.000000,2001.000000 
	C1936.833374,2001.000000 1872.666626,2001.000000 1808.000000,2001.000000 
M596.327820,992.832153 
	C596.213440,992.557983 596.099060,992.283752 595.937500,991.336853 
	C595.930481,990.887329 595.923462,990.437744 596.444885,989.398865 
	C596.476501,986.931763 597.164001,984.243347 596.419800,982.038025 
	C593.938721,974.685730 591.200378,967.394653 588.091431,960.285400 
	C582.296387,947.033997 574.657043,934.935974 564.640076,924.392151 
	C553.596436,912.767761 541.349304,902.816895 527.164307,895.135437 
	C512.013306,886.930847 496.062561,882.309021 478.832458,882.223206 
	C469.373535,882.176086 459.921387,881.773010 450.723480,885.027527 
	C441.455872,888.306702 431.734497,890.433533 422.717407,894.257324 
	C411.871857,898.856445 402.177277,905.572388 393.352570,913.545959 
	C381.651123,924.118958 371.648499,935.978333 364.446930,950.039307 
	C361.478455,955.835205 361.443665,955.809021 356.175323,951.925354 
	C346.919586,945.102356 336.733612,940.219971 325.618286,936.879822 
	C314.793457,933.626953 303.809357,933.372009 292.965851,934.481384 
	C284.216034,935.376526 275.315948,937.722839 267.210266,941.147644 
	C259.563995,944.378357 252.365631,949.232483 245.849426,954.466919 
	C227.667908,969.072021 215.962830,988.375122 212.716858,1011.188965 
	C210.463440,1027.026611 209.925323,1043.802368 217.701935,1059.014771 
	C223.078812,1069.532959 227.823486,1080.700317 235.045242,1089.844482 
	C240.673309,1096.970825 249.515411,1101.668091 257.231415,1107.008179 
	C266.738373,1113.587891 277.792603,1116.587402 288.955750,1118.572754 
	C297.416382,1120.077515 306.102325,1120.571289 314.712463,1120.932251 
	C341.074463,1122.037964 367.445007,1122.983398 393.820374,1123.684692 
	C398.546509,1123.810547 403.304230,1121.964722 408.059570,1121.897705 
	C441.860077,1121.421875 475.664093,1121.200562 509.466309,1120.835815 
	C522.289124,1120.697388 534.613892,1118.884888 546.188477,1112.474121 
	C557.409973,1106.258911 566.226074,1097.721558 572.844727,1087.090454 
	C581.065613,1073.886108 587.773376,1059.941162 591.413940,1044.583862 
	C593.764648,1034.667480 596.743713,1024.941162 596.921997,1014.634583 
	C596.999512,1010.154175 597.568665,1005.680725 597.602417,1001.201782 
	C597.612976,999.803223 596.601746,998.396912 596.083801,996.150085 
	C595.883972,995.049927 595.684204,993.949707 595.719482,993.025146 
	C595.719482,993.025146 596.012268,993.005310 596.327820,992.832153 
M1603.748657,1097.545654 
	C1607.184082,1100.801880 1611.173462,1102.245605 1615.988037,1101.998535 
	C1621.139526,1101.734375 1626.316895,1101.944214 1631.482666,1101.995850 
	C1660.548706,1102.285889 1689.701294,1104.286377 1718.530151,1098.928711 
	C1728.440674,1097.086670 1738.152344,1094.185181 1746.644165,1088.221069 
	C1764.325562,1075.802734 1771.239014,1057.222168 1775.926514,1037.379028 
	C1780.107666,1019.679871 1779.395874,1001.635254 1779.352051,983.656616 
	C1779.303589,963.798279 1775.544067,944.784729 1766.471313,926.924744 
	C1757.697021,909.652222 1741.798462,902.528442 1724.440918,899.347656 
	C1709.335327,896.579590 1693.707275,895.992432 1678.289917,895.756226 
	C1656.661987,895.424805 1635.015869,896.346680 1613.376587,896.659546 
	C1604.429321,896.788879 1602.651611,898.476196 1602.650269,907.496826 
	C1602.641479,967.825134 1602.630981,1028.153442 1602.668945,1088.481812 
	C1602.670776,1091.290161 1603.130371,1094.098389 1603.748657,1097.545654 
M1513.692505,1100.323364 
	C1520.996948,1097.413452 1529.092407,1095.670410 1535.448730,1091.361694 
	C1550.760498,1080.982544 1555.335205,1064.577271 1556.281250,1047.204346 
	C1557.277466,1028.911133 1552.251953,1012.079468 1541.317871,997.225403 
	C1539.048340,994.142395 1538.972412,991.417419 1540.679810,988.020508 
	C1547.029053,975.387939 1550.635132,961.999207 1550.820557,947.796631 
	C1550.972656,936.146240 1549.158325,924.781799 1543.039917,914.755066 
	C1535.740234,902.792419 1523.728394,897.312256 1510.537354,896.302490 
	C1484.181763,894.285034 1457.752808,892.907349 1431.462158,898.208557 
	C1418.441772,900.833862 1411.104736,909.185059 1406.125610,920.462524 
	C1404.313721,924.566528 1407.241211,928.311035 1411.775513,927.868713 
	C1421.853149,926.885742 1431.888916,925.181335 1441.984253,924.737732 
	C1459.075806,923.986633 1476.212158,923.493408 1493.304565,923.914124 
	C1499.860718,924.075500 1506.755615,925.725037 1512.122925,930.745911 
	C1519.314453,937.473511 1520.805298,946.470215 1519.878784,955.082764 
	C1517.709717,975.248413 1513.065674,983.568726 1491.467529,983.236389 
	C1470.998779,982.921387 1450.515991,983.606812 1430.038696,983.707336 
	C1425.659058,983.728821 1422.314087,985.430664 1419.805298,988.765015 
	C1417.629395,991.656982 1415.455444,994.632385 1413.846924,997.853638 
	C1409.443604,1006.671021 1411.604248,1009.900879 1421.643311,1009.920593 
	C1434.142944,1009.945190 1446.643555,1009.820618 1459.141846,1009.947754 
	C1474.962769,1010.108582 1490.785034,1010.338135 1506.600098,1010.779297 
	C1513.959351,1010.984619 1518.038940,1015.788818 1520.931763,1022.044189 
	C1525.570190,1032.074585 1524.543335,1042.569092 1523.270874,1052.949707 
	C1522.827637,1056.567139 1520.830444,1060.183838 1518.869873,1063.389282 
	C1513.457153,1072.239014 1503.788940,1074.067139 1495.004272,1074.573608 
	C1476.277832,1075.653076 1457.438477,1075.415894 1438.669556,1074.875610 
	C1427.393799,1074.550903 1416.168945,1072.586548 1404.911011,1071.460693 
	C1399.269897,1070.896606 1397.724976,1072.492676 1398.760254,1077.936401 
	C1400.074463,1084.846680 1408.692261,1097.732178 1418.372192,1099.122192 
	C1431.170410,1100.960083 1443.959839,1103.473511 1456.826782,1104.048218 
	C1470.207397,1104.645996 1483.683594,1103.429443 1497.101440,1102.698975 
	C1502.388916,1102.411133 1507.631226,1101.291870 1513.692505,1100.323364 
M1070.515503,968.201233 
	C1083.306763,968.358582 1096.133057,968.002319 1108.875366,968.875000 
	C1115.974487,969.361267 1122.532715,972.956848 1124.914429,980.352783 
	C1126.772705,986.122986 1128.131836,992.320679 1128.217651,998.349609 
	C1128.633179,1027.505371 1128.542358,1056.668213 1128.633789,1085.828613 
	C1128.641113,1088.161133 1128.561768,1090.500488 1128.709351,1092.824829 
	C1129.087769,1098.782227 1131.616333,1101.195190 1137.525635,1101.276123 
	C1141.690918,1101.333130 1145.857666,1101.278564 1150.023804,1101.276611 
	C1158.779297,1101.272583 1159.309937,1100.824341 1159.324341,1092.051636 
	C1159.361450,1069.554688 1159.435303,1047.056885 1159.276855,1024.560913 
	C1159.166748,1008.905640 1158.974243,993.239563 1158.225586,977.606689 
	C1157.985352,972.591125 1156.716064,967.219360 1154.423462,962.782715 
	C1147.224243,948.851746 1134.672852,942.587463 1119.600220,941.605957 
	C1098.473145,940.230164 1077.393677,941.135437 1056.391113,944.208801 
	C1047.879883,945.454285 1046.359741,943.629089 1046.366577,935.157837 
	C1046.381348,916.993591 1046.292847,898.828552 1046.443359,880.665527 
	C1046.481934,876.016724 1044.848755,873.919373 1040.053833,874.153870 
	C1035.232422,874.389587 1030.390869,874.225159 1025.558228,874.218262 
	C1016.685059,874.205566 1016.060547,874.804016 1016.060364,883.473816 
	C1016.059326,937.966614 1016.060974,992.459473 1016.060669,1046.952271 
	C1016.060547,1062.616821 1016.034607,1078.281494 1016.067017,1093.946045 
	C1016.078735,1099.597534 1017.882019,1101.297241 1023.609253,1101.296509 
	C1028.608521,1101.295776 1033.607788,1101.266479 1038.607056,1101.276245 
	C1045.887329,1101.290405 1046.329468,1100.897339 1046.333862,1093.744263 
	C1046.355835,1058.415649 1046.348145,1023.086975 1046.396851,987.758362 
	C1046.401123,984.601196 1046.499390,981.408081 1046.977051,978.296631 
	C1047.582397,974.354980 1049.043701,970.902283 1053.808472,970.320557 
	C1059.056030,969.679871 1064.287720,968.909119 1070.515503,968.201233 
M782.238770,1074.951660 
	C772.142822,1075.730469 762.051941,1077.030273 751.949341,1077.128906 
	C743.367554,1077.212769 734.731750,1076.232178 726.191406,1075.154785 
	C708.982178,1072.983643 701.854309,1067.391968 697.200745,1051.783936 
	C692.275635,1035.265259 695.189880,1031.296997 712.007446,1031.296997 
	C713.173401,1031.296997 714.339417,1031.296509 715.505371,1031.296997 
	C742.988892,1031.309937 770.472473,1031.320068 797.955994,1031.337280 
	C807.033997,1031.343018 809.853149,1029.285889 809.375183,1020.503845 
	C808.590820,1006.092896 807.710144,991.585754 805.241333,977.402771 
	C803.530884,967.576294 799.813049,957.528931 790.707703,951.703613 
	C784.992920,948.047607 778.512573,944.906433 771.948547,943.419006 
	C761.985657,941.161316 751.681702,939.946533 741.464905,939.428528 
	C734.271667,939.063782 726.895935,940.107483 719.755615,941.383728 
	C707.848633,943.512085 696.295959,946.634766 686.742432,954.949524 
	C674.217590,965.850098 669.116272,980.488831 666.465088,995.909729 
	C662.655945,1018.066284 662.882874,1040.345459 668.835571,1062.246338 
	C671.413452,1071.730591 675.249451,1080.574097 682.814453,1087.440674 
	C694.125061,1097.707031 708.149475,1101.289429 722.543884,1102.129761 
	C739.107666,1103.096680 755.819153,1102.634644 772.407532,1101.721924 
	C785.063293,1101.025635 796.704407,1096.967285 801.544006,1083.419189 
	C802.617737,1080.413452 802.471252,1076.596069 801.606201,1073.511475 
	C801.359924,1072.633423 796.568604,1072.663940 793.921875,1072.914307 
	C790.306885,1073.256226 786.749451,1074.206909 782.238770,1074.951660 
M969.298279,1095.796509 
	C972.259644,1091.062988 975.577576,1086.509644 978.084839,1081.546631 
	C980.733459,1076.303833 977.937012,1072.647705 971.971497,1073.185547 
	C967.182007,1073.617432 962.438965,1075.015869 957.664001,1075.075195 
	C941.560364,1075.275635 925.432556,1075.519897 909.352478,1074.839844 
	C900.665161,1074.472656 892.451416,1071.408203 886.428040,1064.382202 
	C881.962280,1059.172974 879.558716,1053.061646 878.868652,1046.311768 
	C876.985596,1027.894043 876.192261,1009.588318 881.495911,991.420227 
	C884.192200,982.183838 888.920532,974.519348 897.876221,971.635132 
	C907.032104,968.686401 916.886414,966.724548 926.472290,966.563782 
	C941.710266,966.308411 956.986389,967.797729 972.230347,968.794067 
	C976.321838,969.061523 978.207825,967.219543 978.760681,963.662170 
	C979.668274,957.821533 972.999329,946.409912 967.603149,944.397827 
	C954.246155,939.417297 940.095581,940.628723 926.283325,939.522888 
	C919.434937,938.974548 912.400452,940.354736 905.482849,941.147766 
	C889.961426,942.927246 875.652710,947.558044 864.993103,959.831604 
	C856.173706,969.986328 851.538574,982.278625 848.974548,995.151428 
	C845.099487,1014.606567 845.741150,1034.292847 849.055359,1053.761230 
	C850.264954,1060.866455 852.718140,1067.982422 855.849060,1074.495117 
	C864.477539,1092.443115 880.595825,1100.243774 899.143616,1101.704224 
	C916.982300,1103.109009 935.044067,1102.314819 952.970581,1101.579346 
	C958.284485,1101.361206 963.472290,1098.072144 969.298279,1095.796509 
M1362.742920,1014.687622 
	C1361.838867,1007.137451 1360.674194,999.607300 1360.094360,992.032288 
	C1359.306030,981.731995 1355.690918,972.495422 1350.696777,963.620117 
	C1344.519897,952.643250 1334.770996,946.710388 1322.934814,943.810669 
	C1316.383301,942.205750 1309.768066,940.723816 1303.097290,939.784668 
	C1296.890503,938.910767 1290.561401,938.385986 1284.306152,938.537781 
	C1279.081177,938.664612 1273.901245,940.517517 1268.673340,940.674377 
	C1259.165649,940.959595 1250.357300,943.362732 1241.500000,946.714539 
	C1228.174316,951.757080 1219.233765,961.693176 1213.921753,973.734192 
	C1209.214355,984.404602 1207.579834,996.552856 1205.443604,1008.205444 
	C1202.520630,1024.149536 1204.983032,1040.093018 1207.579834,1055.831055 
	C1208.689819,1062.557983 1211.122559,1069.327881 1214.249390,1075.412842 
	C1224.255981,1094.885986 1242.421631,1102.421021 1262.551025,1104.809692 
	C1280.164795,1106.899902 1298.187744,1107.022583 1315.462646,1101.901611 
	C1323.355713,1099.561890 1331.463623,1096.128296 1337.940674,1091.182617 
	C1355.018799,1078.141968 1359.010864,1058.347900 1361.958252,1038.587158 
	C1363.079956,1031.066162 1362.530518,1023.295715 1362.742920,1014.687622 
z"
  />
  <path
    fill="#F7F9FA"
    opacity="1.000000"
    stroke="none"
    d="
M1603.562622,1097.226074 
	C1603.130371,1094.098389 1602.670776,1091.290161 1602.668945,1088.481812 
	C1602.630981,1028.153442 1602.641479,967.825134 1602.650269,907.496826 
	C1602.651611,898.476196 1604.429321,896.788879 1613.376587,896.659546 
	C1635.015869,896.346680 1656.661987,895.424805 1678.289917,895.756226 
	C1693.707275,895.992432 1709.335327,896.579590 1724.440918,899.347656 
	C1741.798462,902.528442 1757.697021,909.652222 1766.471313,926.924744 
	C1775.544067,944.784729 1779.303589,963.798279 1779.352051,983.656616 
	C1779.395874,1001.635254 1780.107666,1019.679871 1775.926514,1037.379028 
	C1771.239014,1057.222168 1764.325562,1075.802734 1746.644165,1088.221069 
	C1738.152344,1094.185181 1728.440674,1097.086670 1718.530151,1098.928711 
	C1689.701294,1104.286377 1660.548706,1102.285889 1631.482666,1101.995850 
	C1626.316895,1101.944214 1621.139526,1101.734375 1615.988037,1101.998535 
	C1611.173462,1102.245605 1607.184082,1100.801880 1603.562622,1097.226074 
M1633.129761,1040.500000 
	C1633.128296,1048.331665 1633.100464,1056.163452 1633.132935,1063.994995 
	C1633.164673,1071.669556 1635.328491,1074.240967 1643.009644,1074.379150 
	C1665.598145,1074.785767 1688.252686,1076.998169 1710.674805,1072.012085 
	C1720.122803,1069.911011 1727.917358,1065.513550 1733.519287,1057.454102 
	C1741.049072,1046.620972 1744.106934,1034.132812 1745.388184,1021.448914 
	C1746.670166,1008.758118 1746.819214,995.862366 1746.276855,983.107056 
	C1745.863037,973.374695 1744.556030,963.439758 1741.831665,954.125671 
	C1739.814331,947.228394 1735.661133,940.548279 1731.066895,934.905212 
	C1723.675171,925.826294 1712.254272,924.305908 1701.611206,923.760986 
	C1682.012207,922.757385 1662.338745,923.110352 1642.696289,923.101135 
	C1634.813599,923.097412 1633.152466,925.013977 1633.146729,933.024048 
	C1633.121460,968.516052 1633.132202,1004.007996 1633.129761,1040.500000 
z"
  />
  <path
    fill="#F4F7F9"
    opacity="1.000000"
    stroke="none"
    d="
M1513.293213,1100.437256 
	C1507.631226,1101.291870 1502.388916,1102.411133 1497.101440,1102.698975 
	C1483.683594,1103.429443 1470.207397,1104.645996 1456.826782,1104.048218 
	C1443.959839,1103.473511 1431.170410,1100.960083 1418.372192,1099.122192 
	C1408.692261,1097.732178 1400.074463,1084.846680 1398.760254,1077.936401 
	C1397.724976,1072.492676 1399.269897,1070.896606 1404.911011,1071.460693 
	C1416.168945,1072.586548 1427.393799,1074.550903 1438.669556,1074.875610 
	C1457.438477,1075.415894 1476.277832,1075.653076 1495.004272,1074.573608 
	C1503.788940,1074.067139 1513.457153,1072.239014 1518.869873,1063.389282 
	C1520.830444,1060.183838 1522.827637,1056.567139 1523.270874,1052.949707 
	C1524.543335,1042.569092 1525.570190,1032.074585 1520.931763,1022.044189 
	C1518.038940,1015.788818 1513.959351,1010.984619 1506.600098,1010.779297 
	C1490.785034,1010.338135 1474.962769,1010.108582 1459.141846,1009.947754 
	C1446.643555,1009.820618 1434.142944,1009.945190 1421.643311,1009.920593 
	C1411.604248,1009.900879 1409.443604,1006.671021 1413.846924,997.853638 
	C1415.455444,994.632385 1417.629395,991.656982 1419.805298,988.765015 
	C1422.314087,985.430664 1425.659058,983.728821 1430.038696,983.707336 
	C1450.515991,983.606812 1470.998779,982.921387 1491.467529,983.236389 
	C1513.065674,983.568726 1517.709717,975.248413 1519.878784,955.082764 
	C1520.805298,946.470215 1519.314453,937.473511 1512.122925,930.745911 
	C1506.755615,925.725037 1499.860718,924.075500 1493.304565,923.914124 
	C1476.212158,923.493408 1459.075806,923.986633 1441.984253,924.737732 
	C1431.888916,925.181335 1421.853149,926.885742 1411.775513,927.868713 
	C1407.241211,928.311035 1404.313721,924.566528 1406.125610,920.462524 
	C1411.104736,909.185059 1418.441772,900.833862 1431.462158,898.208557 
	C1457.752808,892.907349 1484.181763,894.285034 1510.537354,896.302490 
	C1523.728394,897.312256 1535.740234,902.792419 1543.039917,914.755066 
	C1549.158325,924.781799 1550.972656,936.146240 1550.820557,947.796631 
	C1550.635132,961.999207 1547.029053,975.387939 1540.679810,988.020508 
	C1538.972412,991.417419 1539.048340,994.142395 1541.317871,997.225403 
	C1552.251953,1012.079468 1557.277466,1028.911133 1556.281250,1047.204346 
	C1555.335205,1064.577271 1550.760498,1080.982544 1535.448730,1091.361694 
	C1529.092407,1095.670410 1520.996948,1097.413452 1513.293213,1100.437256 
z"
  />
  <path
    fill="#F4F7F9"
    opacity="1.000000"
    stroke="none"
    d="
M1070.020874,968.198120 
	C1064.287720,968.909119 1059.056030,969.679871 1053.808472,970.320557 
	C1049.043701,970.902283 1047.582397,974.354980 1046.977051,978.296631 
	C1046.499390,981.408081 1046.401123,984.601196 1046.396851,987.758362 
	C1046.348145,1023.086975 1046.355835,1058.415649 1046.333862,1093.744263 
	C1046.329468,1100.897339 1045.887329,1101.290405 1038.607056,1101.276245 
	C1033.607788,1101.266479 1028.608521,1101.295776 1023.609253,1101.296509 
	C1017.882019,1101.297241 1016.078735,1099.597534 1016.067017,1093.946045 
	C1016.034607,1078.281494 1016.060547,1062.616821 1016.060669,1046.952271 
	C1016.060974,992.459473 1016.059326,937.966614 1016.060364,883.473816 
	C1016.060547,874.804016 1016.685059,874.205566 1025.558228,874.218262 
	C1030.390869,874.225159 1035.232422,874.389587 1040.053833,874.153870 
	C1044.848755,873.919373 1046.481934,876.016724 1046.443359,880.665527 
	C1046.292847,898.828552 1046.381348,916.993591 1046.366577,935.157837 
	C1046.359741,943.629089 1047.879883,945.454285 1056.391113,944.208801 
	C1077.393677,941.135437 1098.473145,940.230164 1119.600220,941.605957 
	C1134.672852,942.587463 1147.224243,948.851746 1154.423462,962.782715 
	C1156.716064,967.219360 1157.985352,972.591125 1158.225586,977.606689 
	C1158.974243,993.239563 1159.166748,1008.905640 1159.276855,1024.560913 
	C1159.435303,1047.056885 1159.361450,1069.554688 1159.324341,1092.051636 
	C1159.309937,1100.824341 1158.779297,1101.272583 1150.023804,1101.276611 
	C1145.857666,1101.278564 1141.690918,1101.333130 1137.525635,1101.276123 
	C1131.616333,1101.195190 1129.087769,1098.782227 1128.709351,1092.824829 
	C1128.561768,1090.500488 1128.641113,1088.161133 1128.633789,1085.828613 
	C1128.542358,1056.668213 1128.633179,1027.505371 1128.217651,998.349609 
	C1128.131836,992.320679 1126.772705,986.122986 1124.914429,980.352783 
	C1122.532715,972.956848 1115.974487,969.361267 1108.875366,968.875000 
	C1096.133057,968.002319 1083.306763,968.358582 1070.020874,968.198120 
z"
  />
  <path
    fill="#F4F7F9"
    opacity="1.000000"
    stroke="none"
    d="
M782.703064,1074.924072 
	C786.749451,1074.206909 790.306885,1073.256226 793.921875,1072.914307 
	C796.568604,1072.663940 801.359924,1072.633423 801.606201,1073.511475 
	C802.471252,1076.596069 802.617737,1080.413452 801.544006,1083.419189 
	C796.704407,1096.967285 785.063293,1101.025635 772.407532,1101.721924 
	C755.819153,1102.634644 739.107666,1103.096680 722.543884,1102.129761 
	C708.149475,1101.289429 694.125061,1097.707031 682.814453,1087.440674 
	C675.249451,1080.574097 671.413452,1071.730591 668.835571,1062.246338 
	C662.882874,1040.345459 662.655945,1018.066284 666.465088,995.909729 
	C669.116272,980.488831 674.217590,965.850098 686.742432,954.949524 
	C696.295959,946.634766 707.848633,943.512085 719.755615,941.383728 
	C726.895935,940.107483 734.271667,939.063782 741.464905,939.428528 
	C751.681702,939.946533 761.985657,941.161316 771.948547,943.419006 
	C778.512573,944.906433 784.992920,948.047607 790.707703,951.703613 
	C799.813049,957.528931 803.530884,967.576294 805.241333,977.402771 
	C807.710144,991.585754 808.590820,1006.092896 809.375183,1020.503845 
	C809.853149,1029.285889 807.033997,1031.343018 797.955994,1031.337280 
	C770.472473,1031.320068 742.988892,1031.309937 715.505371,1031.296997 
	C714.339417,1031.296509 713.173401,1031.296997 712.007446,1031.296997 
	C695.189880,1031.296997 692.275635,1035.265259 697.200745,1051.783936 
	C701.854309,1067.391968 708.982178,1072.983643 726.191406,1075.154785 
	C734.731750,1076.232178 743.367554,1077.212769 751.949341,1077.128906 
	C762.051941,1077.030273 772.142822,1075.730469 782.703064,1074.924072 
M777.245850,985.632446 
	C774.388428,971.019043 764.357483,965.801941 750.652771,964.163574 
	C740.064148,962.897766 729.746887,963.754150 719.649963,966.235657 
	C714.890198,967.405396 709.810669,970.192017 706.506470,973.757019 
	C699.714844,981.084961 696.516968,990.341492 696.290222,1000.490906 
	C696.158020,1006.405579 697.412170,1007.933533 703.390747,1008.020386 
	C710.872742,1008.129089 718.357605,1008.043335 725.841187,1008.045654 
	C740.642090,1008.050232 755.443298,1008.111877 770.243774,1008.036987 
	C776.190369,1008.006897 778.438171,1005.743347 778.342407,999.852966 
	C778.269836,995.383545 777.782593,990.920776 777.245850,985.632446 
z"
  />
  <path
    fill="#F4F7F9"
    opacity="1.000000"
    stroke="none"
    d="
M969.008057,1095.993408 
	C963.472290,1098.072144 958.284485,1101.361206 952.970581,1101.579346 
	C935.044067,1102.314819 916.982300,1103.109009 899.143616,1101.704224 
	C880.595825,1100.243774 864.477539,1092.443115 855.849060,1074.495117 
	C852.718140,1067.982422 850.264954,1060.866455 849.055359,1053.761230 
	C845.741150,1034.292847 845.099487,1014.606567 848.974548,995.151428 
	C851.538574,982.278625 856.173706,969.986328 864.993103,959.831604 
	C875.652710,947.558044 889.961426,942.927246 905.482849,941.147766 
	C912.400452,940.354736 919.434937,938.974548 926.283325,939.522888 
	C940.095581,940.628723 954.246155,939.417297 967.603149,944.397827 
	C972.999329,946.409912 979.668274,957.821533 978.760681,963.662170 
	C978.207825,967.219543 976.321838,969.061523 972.230347,968.794067 
	C956.986389,967.797729 941.710266,966.308411 926.472290,966.563782 
	C916.886414,966.724548 907.032104,968.686401 897.876221,971.635132 
	C888.920532,974.519348 884.192200,982.183838 881.495911,991.420227 
	C876.192261,1009.588318 876.985596,1027.894043 878.868652,1046.311768 
	C879.558716,1053.061646 881.962280,1059.172974 886.428040,1064.382202 
	C892.451416,1071.408203 900.665161,1074.472656 909.352478,1074.839844 
	C925.432556,1075.519897 941.560364,1075.275635 957.664001,1075.075195 
	C962.438965,1075.015869 967.182007,1073.617432 971.971497,1073.185547 
	C977.937012,1072.647705 980.733459,1076.303833 978.084839,1081.546631 
	C975.577576,1086.509644 972.259644,1091.062988 969.008057,1095.993408 
z"
  />
  <path
    fill="#0E6AA6"
    opacity="1.000000"
    stroke="none"
    d="
M596.058350,996.994141 
	C596.601746,998.396912 597.612976,999.803223 597.602417,1001.201782 
	C597.568665,1005.680725 596.999512,1010.154175 596.921997,1014.634583 
	C596.743713,1024.941162 593.764648,1034.667480 591.413940,1044.583862 
	C587.773376,1059.941162 581.065613,1073.886108 572.844727,1087.090454 
	C566.226074,1097.721558 557.409973,1106.258911 546.188477,1112.474121 
	C534.613892,1118.884888 522.289124,1120.697388 509.466309,1120.835815 
	C475.664093,1121.200562 441.860077,1121.421875 408.059570,1121.897705 
	C403.304230,1121.964722 398.546509,1123.810547 393.820374,1123.684692 
	C367.445007,1122.983398 341.074463,1122.037964 314.712463,1120.932251 
	C306.102325,1120.571289 297.416382,1120.077515 288.955750,1118.572754 
	C277.792603,1116.587402 266.738373,1113.587891 257.231415,1107.008179 
	C249.515411,1101.668091 240.673309,1096.970825 235.045242,1089.844482 
	C227.823486,1080.700317 223.078812,1069.532959 217.701935,1059.014771 
	C209.925323,1043.802368 210.463440,1027.026611 212.716858,1011.188965 
	C215.962830,988.375122 227.667908,969.072021 245.849426,954.466919 
	C252.365631,949.232483 259.563995,944.378357 267.210266,941.147644 
	C275.315948,937.722839 284.216034,935.376526 292.965851,934.481384 
	C303.809357,933.372009 314.793457,933.626953 325.618286,936.879822 
	C336.733612,940.219971 346.919586,945.102356 356.175323,951.925354 
	C361.443665,955.809021 361.478455,955.835205 364.446930,950.039307 
	C371.648499,935.978333 381.651123,924.118958 393.352570,913.545959 
	C402.177277,905.572388 411.871857,898.856445 422.717407,894.257324 
	C431.734497,890.433533 441.455872,888.306702 450.723480,885.027527 
	C459.921387,881.773010 469.373535,882.176086 478.832458,882.223206 
	C496.062561,882.309021 512.013306,886.930847 527.164307,895.135437 
	C541.349304,902.816895 553.596436,912.767761 564.640076,924.392151 
	C574.657043,934.935974 582.296387,947.033997 588.091431,960.285400 
	C591.200378,967.394653 593.938721,974.685730 596.419800,982.038025 
	C597.164001,984.243347 596.476501,986.931763 595.932983,989.421753 
	C594.527161,987.017456 593.633240,984.590271 592.789307,981.769165 
	C592.444885,979.571960 592.050354,977.768555 591.692261,975.624573 
	C590.881531,973.745361 590.034424,972.206848 589.223755,970.284180 
	C589.011597,968.916687 588.763062,967.933228 588.544922,966.646545 
	C587.719482,965.596252 586.863586,964.849304 586.044739,963.703857 
	C584.228088,959.370422 582.535645,955.349609 580.493530,951.514954 
	C569.928101,931.675110 555.179199,915.593689 536.238586,903.531677 
	C526.784851,897.511230 517.141785,891.647400 505.830688,889.556396 
	C499.221863,888.334717 492.608887,887.135620 485.850647,885.579834 
	C478.219971,885.049988 470.736633,884.795410 463.252808,884.781433 
	C462.395966,884.779785 461.536591,886.124451 460.410034,886.788086 
	C459.779266,886.765198 459.416840,886.799011 458.793610,886.658447 
	C457.371643,886.665833 456.210510,886.847473 454.640839,887.004517 
	C448.530579,888.344055 442.776215,889.525818 437.148682,891.147583 
	C434.220215,891.991516 431.515594,893.612122 428.399048,895.008667 
	C422.360046,898.389038 416.631805,901.642822 410.919922,904.874390 
	C410.936218,904.852295 410.881439,904.846985 410.530090,904.853882 
	C409.390198,905.853516 408.601654,906.846313 407.904053,907.865112 
	C407.994965,907.891174 407.824371,907.809082 407.463379,907.775635 
	C405.539307,908.777710 403.976166,909.813232 402.074402,910.818604 
	C395.693359,916.363220 389.313660,921.624146 383.729767,927.625488 
	C380.147980,931.475037 377.738983,936.415833 374.838196,940.902954 
	C374.872192,940.938538 374.867706,940.840271 374.521912,940.883545 
	C372.113159,944.286194 370.050262,947.645508 367.784485,951.293457 
	C363.646301,960.302063 359.494598,968.932617 355.842285,977.769531 
	C352.771179,985.200195 350.055359,992.798584 347.596375,1000.455627 
	C345.026489,1008.457886 342.951294,1016.619019 340.329834,1024.845825 
	C339.401276,1025.748413 338.586517,1026.428833 338.237061,1027.296631 
	C333.948334,1037.945923 330.556122,1049.053345 325.287109,1059.187012 
	C318.136230,1072.939941 306.990021,1082.966309 291.616028,1088.149414 
	C286.848083,1089.812866 282.461884,1091.462402 277.630127,1093.098633 
	C274.815491,1093.203003 272.446472,1093.320679 269.628296,1093.336426 
	C264.665588,1092.800659 260.151947,1092.366699 255.638336,1091.932739 
	C255.539566,1091.310669 255.440796,1090.688599 255.342041,1090.066528 
	C257.977478,1089.086792 260.537933,1087.730835 263.260529,1087.188599 
	C280.305115,1083.793457 292.160370,1073.015625 300.803741,1059.025024 
	C306.296844,1050.133667 309.728821,1039.895508 313.519196,1030.043579 
	C318.619202,1016.787781 322.906555,1003.218567 328.039307,989.976257 
	C332.979523,977.230835 338.463196,964.691895 343.912384,952.151001 
	C345.195343,949.198425 344.781036,947.416382 341.696930,945.897827 
	C340.648499,945.437683 339.817017,945.220337 338.786072,944.786926 
	C338.027924,944.435059 337.469208,944.299255 336.908417,944.139709 
	C336.906311,944.115967 336.859619,944.106384 336.723328,943.790894 
	C334.674164,942.804749 332.761261,942.133972 330.534180,941.414734 
	C329.797119,941.336304 329.374298,941.306335 328.747253,940.967407 
	C324.989227,939.881592 321.435425,939.104797 317.562500,938.289368 
	C316.815216,938.240051 316.387024,938.229431 315.680603,937.932861 
	C306.567474,935.597290 297.769897,935.309814 288.649597,938.190918 
	C287.224060,938.262817 286.188782,938.349670 284.865051,938.352112 
	C284.050018,938.494751 283.523438,938.721802 282.625427,939.000366 
	C280.718475,939.670654 279.223206,940.428162 277.641296,940.887146 
	C259.176971,946.244263 244.153061,956.573792 232.790726,972.081970 
	C231.102051,974.386719 230.145813,977.227966 228.547195,979.892700 
	C227.543625,980.666687 226.843597,981.369080 226.064697,982.435425 
	C224.876862,985.799683 223.767883,988.800049 222.313446,991.894409 
	C221.129684,993.345093 219.915863,994.599060 219.511780,996.074707 
	C215.853027,1009.435242 214.382706,1022.836060 215.683197,1036.863159 
	C217.651123,1058.089233 227.488571,1075.101929 240.969116,1091.067505 
	C243.414886,1093.968384 245.453751,1096.933960 248.183716,1098.975342 
	C258.275391,1106.521362 269.892090,1111.584106 282.033447,1113.658203 
	C300.448547,1116.804077 319.159027,1119.150513 337.812012,1119.761353 
	C367.570526,1120.735962 397.385010,1120.036743 427.175537,1119.946899 
	C430.130310,1119.938110 433.083038,1119.248779 436.500214,1118.930420 
	C447.311035,1118.942505 457.658295,1118.899536 468.423401,1118.901733 
	C475.920074,1118.558838 482.998962,1118.170776 490.479492,1117.826904 
	C492.227142,1117.839966 493.573151,1117.808838 495.386108,1117.854492 
	C501.538788,1117.660400 507.224579,1117.389404 513.382812,1117.134521 
	C517.819397,1117.116455 521.820740,1117.391235 525.732422,1116.920654 
	C527.740967,1116.678833 529.589478,1115.107056 531.889282,1114.207764 
	C534.204285,1113.891479 536.279541,1113.827637 538.054932,1113.065430 
	C554.019714,1106.210693 565.657349,1094.778442 573.562866,1078.988037 
	C575.914185,1074.804688 578.452820,1071.055786 580.121155,1066.953003 
	C583.148132,1059.508911 585.657104,1051.854126 588.551880,1044.067383 
	C588.836304,1042.884033 588.950989,1041.920776 589.062866,1040.575439 
	C589.099670,1039.175171 589.139282,1038.157104 589.432007,1036.874512 
	C591.305969,1027.194702 592.926819,1017.779358 594.705200,1008.033447 
	C595.261353,1004.133301 595.659851,1000.563721 596.058350,996.994141 
z"
  />
  <path
    fill="#36C5FD"
    opacity="1.000000"
    stroke="none"
    d="
M596.071045,996.572144 
	C595.659851,1000.563721 595.261353,1004.133301 594.378052,1007.781982 
	C592.834412,1002.089233 591.775574,996.317322 590.650146,990.182617 
	C589.404663,990.782166 588.361511,991.194763 587.408752,991.759766 
	C584.642761,993.400146 582.399170,992.373108 582.283081,989.568359 
	C582.014893,983.085571 577.655884,982.121887 572.719910,980.863464 
	C568.979370,979.909851 563.366394,978.675781 562.367493,976.100220 
	C559.908936,969.760864 554.859009,969.746338 550.091125,968.605713 
	C546.920532,967.847229 545.034851,966.425598 544.801697,963.060242 
	C544.390869,957.130249 540.076843,955.762207 535.299927,953.865051 
	C529.424500,951.531677 523.030762,948.697876 518.924805,944.197510 
	C514.378052,939.213745 509.572235,937.228577 503.467041,937.054260 
	C500.250244,936.962280 498.020905,936.534851 497.899109,932.527588 
	C497.788788,928.897095 495.748444,927.084534 491.978577,925.698364 
	C486.917725,923.837341 480.466705,922.684204 479.615509,915.216736 
	C479.516144,914.345154 477.172638,913.528137 475.747742,913.045105 
	C470.611389,911.303711 465.403137,909.773804 460.274536,908.011230 
	C459.460114,907.731384 458.507843,906.763000 458.312988,905.947205 
	C457.066986,900.730774 453.715515,900.425110 449.536346,902.001038 
	C446.078400,903.304993 443.866211,902.063110 442.785492,899.951843 
	C443.467346,897.224304 443.901306,895.488342 444.335266,893.752380 
	C442.423645,893.720825 440.499207,893.544739 438.605194,893.712280 
	C437.485779,893.811279 436.436005,894.702087 435.316925,894.799133 
	C433.128632,894.988892 430.913757,894.871948 428.709869,894.881958 
	C431.515594,893.612122 434.220215,891.991516 437.148682,891.147583 
	C442.776215,889.525818 448.530579,888.344055 454.879883,887.226196 
	C456.703094,887.259216 457.878754,887.046021 459.054413,886.832764 
	C459.416840,886.799011 459.779266,886.765198 460.904663,886.832642 
	C468.715118,886.948120 475.763092,887.006409 482.809601,886.929382 
	C483.875854,886.917725 484.935242,886.276428 485.997803,885.926392 
	C492.608887,887.135620 499.221863,888.334717 505.830688,889.556396 
	C517.141785,891.647400 526.784851,897.511230 536.238586,903.531677 
	C555.179199,915.593689 569.928101,931.675110 580.493530,951.514954 
	C582.535645,955.349609 584.228088,959.370422 586.093933,964.000732 
	C586.908875,965.447327 587.711670,966.198547 588.514465,966.949829 
	C588.763062,967.933228 589.011597,968.916687 589.175903,970.681091 
	C589.946289,972.963135 590.801086,974.464172 591.655884,975.965210 
	C592.050354,977.768555 592.444885,979.571960 592.610718,982.040771 
	C593.416199,986.087341 594.450317,989.468445 595.484375,992.849548 
	C595.684204,993.949707 595.883972,995.049927 596.071045,996.572144 
z"
  />
  <path
    fill="#36C5FD"
    opacity="1.000000"
    stroke="none"
    d="
M1362.742920,1015.163330 
	C1362.530518,1023.295715 1363.079956,1031.066162 1361.958252,1038.587158 
	C1359.010864,1058.347900 1355.018799,1078.141968 1337.940674,1091.182617 
	C1331.463623,1096.128296 1323.355713,1099.561890 1315.462646,1101.901611 
	C1298.187744,1107.022583 1280.164795,1106.899902 1262.551025,1104.809692 
	C1242.421631,1102.421021 1224.255981,1094.885986 1214.249390,1075.412842 
	C1211.122559,1069.327881 1208.689819,1062.557983 1207.579834,1055.831055 
	C1204.983032,1040.093018 1202.520630,1024.149536 1205.443604,1008.205444 
	C1207.579834,996.552856 1209.214355,984.404602 1213.921753,973.734192 
	C1219.233765,961.693176 1228.174316,951.757080 1241.500000,946.714539 
	C1250.357300,943.362732 1259.165649,940.959595 1268.673340,940.674377 
	C1273.901245,940.517517 1279.081177,938.664612 1284.306152,938.537781 
	C1290.561401,938.385986 1296.890503,938.910767 1303.097290,939.784668 
	C1309.768066,940.723816 1316.383301,942.205750 1322.934814,943.810669 
	C1334.770996,946.710388 1344.519897,952.643250 1350.696777,963.620117 
	C1355.690918,972.495422 1359.306030,981.731995 1360.094360,992.032288 
	C1360.674194,999.607300 1361.838867,1007.137451 1362.742920,1015.163330 
M1285.494507,941.095825 
	C1271.163818,941.128540 1257.033325,942.195312 1243.477295,947.561340 
	C1235.570312,950.691223 1228.463135,955.063965 1223.261230,961.604919 
	C1213.344116,974.074829 1208.872070,989.134216 1207.876709,1004.560181 
	C1206.479614,1026.213745 1205.253052,1048.113159 1213.521606,1069.110352 
	C1216.698853,1077.178345 1221.086548,1084.203857 1227.807739,1089.640259 
	C1237.126831,1097.177856 1248.085693,1100.720703 1259.808350,1102.176025 
	C1282.614502,1105.007202 1305.134644,1104.803711 1326.878906,1095.954346 
	C1339.344727,1090.880981 1347.351562,1081.319458 1352.480103,1069.473633 
	C1359.332397,1053.646851 1360.780396,1036.613403 1360.651611,1019.637878 
	C1360.527100,1003.210083 1359.558716,986.726501 1352.970093,971.212646 
	C1349.500732,963.043640 1344.503784,955.595459 1337.012085,951.319946 
	C1326.052124,945.065186 1313.765991,941.898010 1300.989380,941.403442 
	C1296.162476,941.216614 1291.329346,941.189758 1285.494507,941.095825 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M595.601929,992.937378 
	C594.450317,989.468445 593.416199,986.087341 592.560730,982.434631 
	C593.633240,984.590271 594.527161,987.017456 595.668762,989.716431 
	C595.923462,990.437744 595.930481,990.887329 595.894348,991.924194 
	C595.904846,992.676147 595.958557,992.840698 596.012268,993.005310 
	C596.012268,993.005310 595.719482,993.025146 595.601929,992.937378 
z"
  />
  <path
    fill="#0E6AA6"
    opacity="1.000000"
    stroke="none"
    d="
M596.170044,992.918701 
	C595.958557,992.840698 595.904846,992.676147 595.917969,992.260559 
	C596.099060,992.283752 596.213440,992.557983 596.170044,992.918701 
z"
  />
  <path
    fill="#043066"
    opacity="1.000000"
    stroke="none"
    d="
M1633.129761,1040.000000 
	C1633.132202,1004.007996 1633.121460,968.516052 1633.146729,933.024048 
	C1633.152466,925.013977 1634.813599,923.097412 1642.696289,923.101135 
	C1662.338745,923.110352 1682.012207,922.757385 1701.611206,923.760986 
	C1712.254272,924.305908 1723.675171,925.826294 1731.066895,934.905212 
	C1735.661133,940.548279 1739.814331,947.228394 1741.831665,954.125671 
	C1744.556030,963.439758 1745.863037,973.374695 1746.276855,983.107056 
	C1746.819214,995.862366 1746.670166,1008.758118 1745.388184,1021.448914 
	C1744.106934,1034.132812 1741.049072,1046.620972 1733.519287,1057.454102 
	C1727.917358,1065.513550 1720.122803,1069.911011 1710.674805,1072.012085 
	C1688.252686,1076.998169 1665.598145,1074.785767 1643.009644,1074.379150 
	C1635.328491,1074.240967 1633.164673,1071.669556 1633.132935,1063.994995 
	C1633.100464,1056.163452 1633.128296,1048.331665 1633.129761,1040.000000 
z"
  />
  <path
    fill="#043066"
    opacity="1.000000"
    stroke="none"
    d="
M777.362793,986.043762 
	C777.782593,990.920776 778.269836,995.383545 778.342407,999.852966 
	C778.438171,1005.743347 776.190369,1008.006897 770.243774,1008.036987 
	C755.443298,1008.111877 740.642090,1008.050232 725.841187,1008.045654 
	C718.357605,1008.043335 710.872742,1008.129089 703.390747,1008.020386 
	C697.412170,1007.933533 696.158020,1006.405579 696.290222,1000.490906 
	C696.516968,990.341492 699.714844,981.084961 706.506470,973.757019 
	C709.810669,970.192017 714.890198,967.405396 719.649963,966.235657 
	C729.746887,963.754150 740.064148,962.897766 750.652771,964.163574 
	C764.357483,965.801941 774.388428,971.019043 777.362793,986.043762 
z"
  />
  <path
    fill="#0CB9FC"
    opacity="1.000000"
    stroke="none"
    d="
M573.357788,1079.262939 
	C565.657349,1094.778442 554.019714,1106.210693 538.054932,1113.065430 
	C536.279541,1113.827637 534.204285,1113.891479 531.422241,1114.189331 
	C526.146606,1114.268311 521.708252,1114.326050 517.291870,1114.667480 
	C515.797180,1114.783081 514.357910,1115.615845 512.436768,1116.101074 
	C506.359436,1116.083496 500.738647,1116.085938 494.843658,1115.846191 
	C493.006622,1115.765503 491.443817,1115.927002 489.428589,1116.088013 
	C485.332520,1116.079102 481.688873,1116.070801 477.944946,1115.732300 
	C476.160492,1113.341797 474.880707,1113.654541 473.591248,1116.085938 
	C471.484772,1117.024048 469.745178,1117.940308 468.005585,1118.856567 
	C457.658295,1118.899536 447.311035,1118.942505 436.634216,1118.575806 
	C439.335663,1117.761719 442.439606,1117.624756 445.363098,1116.826782 
	C446.603333,1116.488159 447.464996,1114.762695 448.498718,1113.667725 
	C447.546600,1112.789795 446.656311,1111.243652 445.631744,1111.148926 
	C441.048370,1110.724854 436.421844,1110.793335 431.821655,1110.509399 
	C430.324036,1110.416992 428.859009,1109.795532 427.379089,1109.415894 
	C427.434387,1108.873657 427.489655,1108.331299 427.544952,1107.788940 
	C431.246796,1107.266968 434.948608,1106.744873 438.650421,1106.222778 
	C438.606781,1105.663330 438.563141,1105.103882 438.519501,1104.544312 
	C437.145447,1104.362427 435.775024,1104.058960 434.396729,1104.017456 
	C421.200867,1103.619873 418.332611,1101.585815 415.169861,1089.149170 
	C414.416534,1086.186890 412.848389,1086.161011 410.572113,1085.935425 
	C407.847595,1085.665283 405.116699,1085.102295 402.486206,1084.332520 
	C399.307800,1083.402466 396.215363,1082.165283 393.116730,1080.980225 
	C390.754700,1080.076904 386.890167,1079.550537 386.382172,1077.984497 
	C384.488495,1072.146729 379.633026,1071.801880 375.255005,1071.246582 
	C370.985962,1070.705078 369.012848,1069.278809 368.836304,1064.840088 
	C368.663727,1060.500244 367.127106,1057.302856 361.519958,1057.784546 
	C359.179932,1057.985718 355.692322,1056.917725 354.422394,1055.184448 
	C350.827911,1050.278320 346.339783,1048.675537 340.605194,1048.908569 
	C339.177887,1048.966553 336.553528,1048.083984 336.480652,1047.415649 
	C336.289215,1045.659668 337.063385,1043.733154 337.714600,1041.971191 
	C338.251190,1040.519409 339.513397,1039.282593 339.832306,1037.815308 
	C340.435211,1035.041016 340.682709,1032.176514 340.896698,1029.335938 
	C341.011780,1027.808838 340.751007,1026.253418 340.659851,1024.710693 
	C342.951294,1016.619019 345.026489,1008.457886 347.596375,1000.455627 
	C350.055359,992.798584 352.771179,985.200195 355.842285,977.769531 
	C359.494598,968.932617 363.646301,960.302063 368.176208,951.288940 
	C370.374451,949.872742 372.575378,949.081482 373.430176,947.542542 
	C374.491516,945.631836 374.439087,943.102417 374.867737,940.840271 
	C374.867706,940.840271 374.872192,940.938538 375.199707,940.867432 
	C377.300385,939.792358 379.073578,938.788391 380.846771,937.784485 
	C379.996674,944.445435 381.728821,949.535645 383.643768,955.509583 
	C385.386658,953.453186 386.394470,952.264038 387.099121,951.432617 
	C390.516327,952.494446 393.560516,953.235657 396.425903,954.412354 
	C397.988098,955.053833 400.008667,956.165161 400.491730,957.534180 
	C401.671539,960.878052 403.983398,961.006409 406.797058,961.027954 
	C415.924072,961.097900 416.417084,961.580750 418.138123,970.504822 
	C418.858246,974.238586 420.309082,976.398254 424.981384,975.460022 
	C426.969879,975.060791 430.369965,976.670776 431.572296,978.430847 
	C434.010651,982.000183 437.294769,982.997192 441.018280,984.133545 
	C443.711365,984.955261 447.134857,986.363403 448.299072,988.542847 
	C450.223572,992.145630 452.503143,993.267151 456.205658,993.032104 
	C460.065308,992.787109 463.670898,994.341431 464.579559,997.991150 
	C465.901489,1003.300598 468.378754,1006.829102 473.033630,1009.941711 
	C476.836151,1012.484375 479.512451,1014.511963 484.258911,1009.086304 
	C483.721313,1016.064514 487.674500,1017.284119 491.905212,1017.937988 
	C494.443298,1018.330139 497.599762,1017.390686 499.519196,1018.587646 
	C504.224731,1021.522400 509.655487,1024.567261 512.382690,1029.038696 
	C515.045471,1033.404663 518.057556,1034.327271 522.202454,1035.081177 
	C524.138550,1035.433472 526.856201,1036.280396 527.546997,1037.710938 
	C529.091248,1040.908936 530.959839,1043.086548 534.325378,1044.371948 
	C535.660156,1044.881714 536.442505,1046.791992 537.524963,1048.021851 
	C538.489746,1049.118164 539.329407,1050.667603 540.557678,1051.128296 
	C545.351501,1052.926636 550.183533,1055.093140 555.181091,1055.745117 
	C560.145508,1056.392578 561.986938,1058.719482 561.938965,1063.124023 
	C561.902649,1066.454468 563.196899,1067.884888 566.543091,1068.223999 
	C568.772583,1068.449829 570.908997,1069.593994 574.318237,1070.741699 
	C568.573181,1074.356812 570.564148,1076.842529 573.357788,1079.262939 
M348.819122,1038.334717 
	C348.309479,1037.697144 347.799866,1037.059448 347.290222,1036.421875 
	C346.922852,1037.045288 346.555511,1037.668823 346.188202,1038.292358 
	C346.995331,1038.518433 347.802429,1038.744385 348.819122,1038.334717 
M346.257202,1023.493286 
	C346.538666,1023.773132 346.820099,1024.052979 347.101532,1024.332764 
	C347.298798,1023.986755 347.496094,1023.640686 347.693359,1023.294678 
	C347.254395,1023.196167 346.815460,1023.097656 346.257202,1023.493286 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M573.562866,1078.988037 
	C570.564148,1076.842529 568.573181,1074.356812 574.318237,1070.741699 
	C570.908997,1069.593994 568.772583,1068.449829 566.543091,1068.223999 
	C563.196899,1067.884888 561.902649,1066.454468 561.938965,1063.124023 
	C561.986938,1058.719482 560.145508,1056.392578 555.181091,1055.745117 
	C550.183533,1055.093140 545.351501,1052.926636 540.557678,1051.128296 
	C539.329407,1050.667603 538.489746,1049.118164 537.524963,1048.021851 
	C536.442505,1046.791992 535.660156,1044.881714 534.325378,1044.371948 
	C530.959839,1043.086548 529.091248,1040.908936 527.546997,1037.710938 
	C526.856201,1036.280396 524.138550,1035.433472 522.202454,1035.081177 
	C518.057556,1034.327271 515.045471,1033.404663 512.382690,1029.038696 
	C509.655487,1024.567261 504.224731,1021.522400 499.519196,1018.587646 
	C497.599762,1017.390686 494.443298,1018.330139 491.905212,1017.937988 
	C487.674500,1017.284119 483.721313,1016.064514 484.258911,1009.086304 
	C479.512451,1014.511963 476.836151,1012.484375 473.033630,1009.941711 
	C468.378754,1006.829102 465.901489,1003.300598 464.579559,997.991150 
	C463.670898,994.341431 460.065308,992.787109 456.205658,993.032104 
	C452.503143,993.267151 450.223572,992.145630 448.299072,988.542847 
	C447.134857,986.363403 443.711365,984.955261 441.018280,984.133545 
	C437.294769,982.997192 434.010651,982.000183 431.572296,978.430847 
	C430.369965,976.670776 426.969879,975.060791 424.981384,975.460022 
	C420.309082,976.398254 418.858246,974.238586 418.138123,970.504822 
	C416.417084,961.580750 415.924072,961.097900 406.797058,961.027954 
	C403.983398,961.006409 401.671539,960.878052 400.491730,957.534180 
	C400.008667,956.165161 397.988098,955.053833 396.425903,954.412354 
	C393.560516,953.235657 390.516327,952.494446 387.099121,951.432617 
	C386.394470,952.264038 385.386658,953.453186 383.643768,955.509583 
	C381.728821,949.535645 379.996674,944.445435 380.846771,937.784485 
	C379.073578,938.788391 377.300385,939.792358 375.165710,940.831848 
	C377.738983,936.415833 380.147980,931.475037 383.729767,927.625488 
	C389.313660,921.624146 395.693359,916.363220 402.527283,910.853271 
	C405.542542,910.927917 408.183777,911.542908 407.824371,907.809082 
	C407.824371,907.809082 407.994965,907.891174 408.262451,907.864258 
	C409.313782,906.840515 410.097595,905.843750 410.881439,904.846985 
	C410.881439,904.846985 410.936218,904.852295 411.345825,904.907104 
	C412.588379,904.982361 413.521912,904.767395 414.233734,905.071472 
	C415.785553,905.734314 417.224518,906.661377 418.709656,907.480408 
	C417.590759,908.561401 416.541595,909.729858 415.330536,910.695251 
	C414.393616,911.442017 413.254028,911.934387 411.987152,912.665344 
	C418.802795,916.852112 425.102020,920.721680 431.309631,924.534912 
	C432.567108,923.854980 435.563232,921.480469 436.229980,922.014404 
	C438.306793,923.677673 439.573792,926.399048 441.014343,928.794495 
	C442.102081,930.603394 442.700470,932.767700 444.015747,934.362183 
	C445.468323,936.123047 447.352081,937.630737 449.291046,938.867920 
	C454.160950,941.975098 459.159424,944.882019 464.144958,947.803345 
	C464.395599,947.950195 465.010651,947.475037 465.577454,947.238953 
	C465.577454,945.672668 465.577454,944.152283 465.577454,942.631897 
	C467.260223,943.316406 468.275848,944.333313 468.528473,945.513550 
	C469.425385,949.703979 477.656891,957.826538 481.740143,959.038086 
	C484.190338,959.764954 486.837097,961.124390 488.493988,962.997131 
	C494.452209,969.731873 500.527344,975.451721 510.559448,975.293823 
	C513.735229,975.243835 517.195007,977.853943 520.108948,979.852844 
	C525.635742,983.644165 530.858582,987.878052 536.215942,991.917236 
	C537.521790,992.901794 539.349731,993.560608 540.098083,994.854736 
	C543.398315,1000.561340 548.070557,1002.962708 554.618958,1002.037354 
	C558.899231,1001.432556 561.494812,1003.760803 561.831116,1007.796570 
	C562.212769,1012.377258 564.904907,1013.461243 568.743408,1014.050171 
	C572.285156,1014.593628 575.869568,1015.363892 579.204102,1016.638672 
	C584.669739,1018.728149 585.127563,1020.984924 581.142761,1025.285522 
	C578.445679,1028.196289 579.142212,1030.013062 582.590942,1031.838501 
	C583.129822,1030.728638 583.447449,1029.815674 583.974548,1029.046387 
	C584.885254,1027.717041 585.926025,1026.476929 586.912170,1025.199341 
	C587.601135,1026.818604 588.617371,1028.383057 588.899414,1030.070312 
	C589.285034,1032.377563 589.112244,1034.778320 589.178833,1037.138916 
	C589.139282,1038.157104 589.099670,1039.175171 588.738220,1040.662842 
	C588.404968,1042.183960 588.393555,1043.235718 588.382202,1044.287354 
	C585.657104,1051.854126 583.148132,1059.508911 580.121155,1066.953003 
	C578.452820,1071.055786 575.914185,1074.804688 573.562866,1078.988037 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M341.913879,946.140686 
	C344.781036,947.416382 345.195343,949.198425 343.912384,952.151001 
	C338.463196,964.691895 332.979523,977.230835 328.039307,989.976257 
	C322.906555,1003.218567 318.619202,1016.787781 313.519196,1030.043579 
	C309.728821,1039.895508 306.296844,1050.133667 300.803741,1059.025024 
	C292.160370,1073.015625 280.305115,1083.793457 263.260529,1087.188599 
	C260.537933,1087.730835 257.977478,1089.086792 255.342041,1090.066528 
	C255.440796,1090.688599 255.539566,1091.310669 255.638336,1091.932739 
	C260.151947,1092.366699 264.665588,1092.800659 269.439240,1093.641846 
	C268.130493,1094.361816 266.541473,1095.020020 264.996277,1094.929565 
	C256.804901,1094.450684 248.542938,1094.243530 240.879395,1090.708008 
	C227.488571,1075.101929 217.651123,1058.089233 215.683197,1036.863159 
	C214.382706,1022.836060 215.853027,1009.435242 219.511780,996.074707 
	C219.915863,994.599060 221.129684,993.345093 222.580978,992.100464 
	C224.667633,991.425049 226.066437,990.383484 227.635849,989.921204 
	C229.214783,989.456177 231.922791,988.837769 232.468185,989.554932 
	C234.593597,992.349731 236.294052,992.100159 238.634445,990.035767 
	C239.421188,989.341919 240.750229,989.027832 241.854752,988.967468 
	C249.140594,988.569336 256.445831,987.845886 263.720245,988.060608 
	C278.632202,988.500854 293.635468,986.313660 308.472473,989.803955 
	C311.943512,990.620483 315.916229,990.554382 319.381989,989.731323 
	C321.959076,989.119446 325.176270,987.140015 326.234650,984.888733 
	C331.207550,974.311218 335.573700,963.446045 340.053467,952.640991 
	C340.911011,950.572693 341.305695,948.312378 341.913879,946.140686 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M240.969116,1091.067505 
	C248.542938,1094.243530 256.804901,1094.450684 264.996277,1094.929565 
	C266.541473,1095.020020 268.130493,1094.361816 269.888367,1093.743652 
	C272.446472,1093.320679 274.815491,1093.203003 277.885559,1093.282471 
	C283.056976,1091.698242 287.527374,1089.916748 291.997772,1088.135254 
	C306.990021,1082.966309 318.136230,1072.939941 325.287109,1059.187012 
	C330.556122,1049.053345 333.948334,1037.945923 338.237061,1027.296631 
	C338.586517,1026.428833 339.401276,1025.748413 340.329834,1024.845825 
	C340.751007,1026.253418 341.011780,1027.808838 340.896698,1029.335938 
	C340.682709,1032.176514 340.435211,1035.041016 339.832306,1037.815308 
	C339.513397,1039.282593 338.251190,1040.519409 337.714600,1041.971191 
	C337.063385,1043.733154 336.289215,1045.659668 336.480652,1047.415649 
	C336.553528,1048.083984 339.177887,1048.966553 340.605194,1048.908569 
	C346.339783,1048.675537 350.827911,1050.278320 354.422394,1055.184448 
	C355.692322,1056.917725 359.179932,1057.985718 361.519958,1057.784546 
	C367.127106,1057.302856 368.663727,1060.500244 368.836304,1064.840088 
	C369.012848,1069.278809 370.985962,1070.705078 375.255005,1071.246582 
	C379.633026,1071.801880 384.488495,1072.146729 386.382172,1077.984497 
	C386.890167,1079.550537 390.754700,1080.076904 393.116730,1080.980225 
	C396.215363,1082.165283 399.307800,1083.402466 402.486206,1084.332520 
	C405.116699,1085.102295 407.847595,1085.665283 410.572113,1085.935425 
	C412.848389,1086.161011 414.416534,1086.186890 415.169861,1089.149170 
	C418.332611,1101.585815 421.200867,1103.619873 434.396729,1104.017456 
	C435.775024,1104.058960 437.145447,1104.362427 438.519501,1104.544312 
	C438.563141,1105.103882 438.606781,1105.663330 438.650421,1106.222778 
	C434.948608,1106.744873 431.246796,1107.266968 427.544952,1107.788940 
	C427.489655,1108.331299 427.434387,1108.873657 427.379089,1109.415894 
	C428.859009,1109.795532 430.324036,1110.416992 431.821655,1110.509399 
	C436.421844,1110.793335 441.048370,1110.724854 445.631744,1111.148926 
	C446.656311,1111.243652 447.546600,1112.789795 448.498718,1113.667725 
	C447.464996,1114.762695 446.603333,1116.488159 445.363098,1116.826782 
	C442.439606,1117.624756 439.335663,1117.761719 436.170715,1118.520752 
	C433.083038,1119.248779 430.130310,1119.938110 427.175537,1119.946899 
	C397.385010,1120.036743 367.570526,1120.735962 337.812012,1119.761353 
	C319.159027,1119.150513 300.448547,1116.804077 282.033447,1113.658203 
	C269.892090,1111.584106 258.275391,1106.521362 248.183716,1098.975342 
	C245.453751,1096.933960 243.414886,1093.968384 240.969116,1091.067505 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M589.432007,1036.874512 
	C589.112244,1034.778320 589.285034,1032.377563 588.899414,1030.070312 
	C588.617371,1028.383057 587.601135,1026.818604 586.912170,1025.199341 
	C585.926025,1026.476929 584.885254,1027.717041 583.974548,1029.046387 
	C583.447449,1029.815674 583.129822,1030.728638 582.590942,1031.838501 
	C579.142212,1030.013062 578.445679,1028.196289 581.142761,1025.285522 
	C585.127563,1020.984924 584.669739,1018.728149 579.204102,1016.638672 
	C575.869568,1015.363892 572.285156,1014.593628 568.743408,1014.050171 
	C564.904907,1013.461243 562.212769,1012.377258 561.831116,1007.796570 
	C561.494812,1003.760803 558.899231,1001.432556 554.618958,1002.037354 
	C548.070557,1002.962708 543.398315,1000.561340 540.098083,994.854736 
	C539.349731,993.560608 537.521790,992.901794 536.215942,991.917236 
	C530.858582,987.878052 525.635742,983.644165 520.108948,979.852844 
	C517.195007,977.853943 513.735229,975.243835 510.559448,975.293823 
	C500.527344,975.451721 494.452209,969.731873 488.493988,962.997131 
	C486.837097,961.124390 484.190338,959.764954 481.740143,959.038086 
	C477.656891,957.826538 469.425385,949.703979 468.528473,945.513550 
	C468.275848,944.333313 467.260223,943.316406 465.577454,942.631897 
	C465.577454,944.152283 465.577454,945.672668 465.577454,947.238953 
	C465.010651,947.475037 464.395599,947.950195 464.144958,947.803345 
	C459.159424,944.882019 454.160950,941.975098 449.291046,938.867920 
	C447.352081,937.630737 445.468323,936.123047 444.015747,934.362183 
	C442.700470,932.767700 442.102081,930.603394 441.014343,928.794495 
	C439.573792,926.399048 438.306793,923.677673 436.229980,922.014404 
	C435.563232,921.480469 432.567108,923.854980 431.309631,924.534912 
	C425.102020,920.721680 418.802795,916.852112 411.987152,912.665344 
	C413.254028,911.934387 414.393616,911.442017 415.330536,910.695251 
	C416.541595,909.729858 417.590759,908.561401 418.709656,907.480408 
	C417.224518,906.661377 415.785553,905.734314 414.233734,905.071472 
	C413.521912,904.767395 412.588379,904.982361 411.329529,904.929199 
	C416.631805,901.642822 422.360046,898.389038 428.399048,895.008667 
	C430.913757,894.871948 433.128632,894.988892 435.316925,894.799133 
	C436.436005,894.702087 437.485779,893.811279 438.605194,893.712280 
	C440.499207,893.544739 442.423645,893.720825 444.335266,893.752380 
	C443.901306,895.488342 443.467346,897.224304 442.785492,899.951843 
	C443.866211,902.063110 446.078400,903.304993 449.536346,902.001038 
	C453.715515,900.425110 457.066986,900.730774 458.312988,905.947205 
	C458.507843,906.763000 459.460114,907.731384 460.274536,908.011230 
	C465.403137,909.773804 470.611389,911.303711 475.747742,913.045105 
	C477.172638,913.528137 479.516144,914.345154 479.615509,915.216736 
	C480.466705,922.684204 486.917725,923.837341 491.978577,925.698364 
	C495.748444,927.084534 497.788788,928.897095 497.899109,932.527588 
	C498.020905,936.534851 500.250244,936.962280 503.467041,937.054260 
	C509.572235,937.228577 514.378052,939.213745 518.924805,944.197510 
	C523.030762,948.697876 529.424500,951.531677 535.299927,953.865051 
	C540.076843,955.762207 544.390869,957.130249 544.801697,963.060242 
	C545.034851,966.425598 546.920532,967.847229 550.091125,968.605713 
	C554.859009,969.746338 559.908936,969.760864 562.367493,976.100220 
	C563.366394,978.675781 568.979370,979.909851 572.719910,980.863464 
	C577.655884,982.121887 582.014893,983.085571 582.283081,989.568359 
	C582.399170,992.373108 584.642761,993.400146 587.408752,991.759766 
	C588.361511,991.194763 589.404663,990.782166 590.650146,990.182617 
	C591.775574,996.317322 592.834412,1002.089233 594.220459,1008.112549 
	C592.926819,1017.779358 591.305969,1027.194702 589.432007,1036.874512 
z"
  />
  <path
    fill="#0CB9FC"
    opacity="1.000000"
    stroke="none"
    d="
M341.696930,945.897827 
	C341.305695,948.312378 340.911011,950.572693 340.053467,952.640991 
	C335.573700,963.446045 331.207550,974.311218 326.234650,984.888733 
	C325.176270,987.140015 321.959076,989.119446 319.381989,989.731323 
	C315.916229,990.554382 311.943512,990.620483 308.472473,989.803955 
	C293.635468,986.313660 278.632202,988.500854 263.720245,988.060608 
	C256.445831,987.845886 249.140594,988.569336 241.854752,988.967468 
	C240.750229,989.027832 239.421188,989.341919 238.634445,990.035767 
	C236.294052,992.100159 234.593597,992.349731 232.468185,989.554932 
	C231.922791,988.837769 229.214783,989.456177 227.635849,989.921204 
	C226.066437,990.383484 224.667633,991.425049 222.926453,992.006470 
	C223.767883,988.800049 224.876862,985.799683 226.402374,982.410400 
	C227.496185,981.288025 228.173477,980.554504 228.850739,979.821045 
	C230.145813,977.227966 231.102051,974.386719 232.790726,972.081970 
	C244.153061,956.573792 259.176971,946.244263 277.641296,940.887146 
	C279.223206,940.428162 280.718475,939.670654 282.790894,939.185852 
	C283.936371,939.025452 284.544922,938.731018 285.153473,938.436523 
	C286.188782,938.349670 287.224060,938.262817 288.975250,938.404053 
	C290.827423,938.759399 291.963196,938.990845 293.100128,938.996826 
	C299.459198,939.030151 305.819061,939.050781 312.177338,938.970703 
	C313.440796,938.954773 314.698486,938.481323 315.958862,938.218811 
	C316.387024,938.229431 316.815216,938.240051 317.733032,938.592896 
	C321.798950,939.715515 325.375214,940.495972 328.951447,941.276367 
	C329.374298,941.306335 329.797119,941.336304 330.693542,941.695496 
	C333.064606,942.718567 334.962128,943.412476 336.859619,944.106384 
	C336.859619,944.106384 336.906311,944.115967 336.978577,944.387573 
	C337.695740,944.773743 338.340637,944.888367 338.985565,945.003052 
	C339.817017,945.220337 340.648499,945.437683 341.696930,945.897827 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M485.850647,885.579834 
	C484.935242,886.276428 483.875854,886.917725 482.809601,886.929382 
	C475.763092,887.006409 468.715118,886.948120 461.173004,886.889343 
	C461.536591,886.124451 462.395966,884.779785 463.252808,884.781433 
	C470.736633,884.795410 478.219971,885.049988 485.850647,885.579834 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M315.680603,937.932861 
	C314.698486,938.481323 313.440796,938.954773 312.177338,938.970703 
	C305.819061,939.050781 299.459198,939.030151 293.100128,938.996826 
	C291.963196,938.990845 290.827423,938.759399 289.365479,938.418945 
	C297.769897,935.309814 306.567474,935.597290 315.680603,937.932861 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M468.423401,1118.901733 
	C469.745178,1117.940308 471.484772,1117.024048 474.000519,1116.088501 
	C475.866211,1116.067017 476.955719,1116.064819 478.045258,1116.062500 
	C481.688873,1116.070801 485.332520,1116.079102 489.471069,1116.369629 
	C490.003296,1117.028809 490.040558,1117.405640 490.077820,1117.782593 
	C482.998962,1118.170776 475.920074,1118.558838 468.423401,1118.901733 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M512.893250,1116.120850 
	C514.357910,1115.615845 515.797180,1114.783081 517.291870,1114.667480 
	C521.708252,1114.326050 526.146606,1114.268311 531.043335,1114.117920 
	C529.589478,1115.107056 527.740967,1116.678833 525.732422,1116.920654 
	C521.820740,1117.391235 517.819397,1117.116455 513.387085,1116.884521 
	C512.918945,1116.618652 512.893250,1116.120850 512.893250,1116.120850 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M374.521912,940.883545 
	C374.439087,943.102417 374.491516,945.631836 373.430176,947.542542 
	C372.575378,949.081482 370.374451,949.872742 368.379089,951.000366 
	C370.050262,947.645508 372.113159,944.286194 374.521912,940.883545 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M512.436768,1116.101074 
	C512.893250,1116.120850 512.918945,1116.618652 512.914673,1116.868530 
	C507.224579,1117.389404 501.538788,1117.660400 495.408173,1117.573730 
	C495.014832,1116.840210 495.066345,1116.464233 495.117859,1116.088379 
	C500.738647,1116.085938 506.359436,1116.083496 512.436768,1116.101074 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M291.616028,1088.149414 
	C287.527374,1089.916748 283.056976,1091.698242 278.331146,1093.295776 
	C282.461884,1091.462402 286.848083,1089.812866 291.616028,1088.149414 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M328.747253,940.967407 
	C325.375214,940.495972 321.798950,939.715515 318.052185,938.631592 
	C321.435425,939.104797 324.989227,939.881592 328.747253,940.967407 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M494.843658,1115.846191 
	C495.066345,1116.464233 495.014832,1116.840210 494.941223,1117.496826 
	C493.573151,1117.808838 492.227142,1117.839966 490.479492,1117.826904 
	C490.040558,1117.405640 490.003296,1117.028809 489.923523,1116.370239 
	C491.443817,1115.927002 493.006622,1115.765503 494.843658,1115.846191 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M407.463379,907.775635 
	C408.183777,911.542908 405.542542,910.927917 402.865906,910.883362 
	C403.976166,909.813232 405.539307,908.777710 407.463379,907.775635 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M336.723328,943.790894 
	C334.962128,943.412476 333.064606,942.718567 331.007721,941.743958 
	C332.761261,942.133972 334.674164,942.804749 336.723328,943.790894 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M591.692261,975.624573 
	C590.801086,974.464172 589.946289,972.963135 589.139404,971.065186 
	C590.034424,972.206848 590.881531,973.745361 591.692261,975.624573 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M458.793610,886.658447 
	C457.878754,887.046021 456.703094,887.259216 455.288391,887.250854 
	C456.210510,886.847473 457.371643,886.665833 458.793610,886.658447 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M410.530090,904.853882 
	C410.097595,905.843750 409.313782,906.840515 408.171539,907.838196 
	C408.601654,906.846313 409.390198,905.853516 410.530090,904.853882 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M588.551880,1044.067383 
	C588.393555,1043.235718 588.404968,1042.183960 588.740967,1041.044922 
	C588.950989,1041.920776 588.836304,1042.884033 588.551880,1044.067383 
z"
  />
  <path
    fill="#29C1FD"
    opacity="1.000000"
    stroke="none"
    d="
M588.544922,966.646545 
	C587.711670,966.198547 586.908875,965.447327 586.056824,964.399170 
	C586.863586,964.849304 587.719482,965.596252 588.544922,966.646545 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M228.547211,979.892700 
	C228.173477,980.554504 227.496185,981.288025 226.481232,982.046509 
	C226.843597,981.369080 227.543625,980.666687 228.547211,979.892700 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M338.786072,944.786987 
	C338.340637,944.888367 337.695740,944.773743 336.980682,944.411255 
	C337.469208,944.299255 338.027924,944.435059 338.786072,944.786987 
z"
  />
  <path
    fill="#0298E4"
    opacity="1.000000"
    stroke="none"
    d="
M284.865051,938.352112 
	C284.544922,938.731018 283.936371,939.025452 283.162354,939.134399 
	C283.523438,938.721802 284.050018,938.494751 284.865051,938.352112 
z"
  />
  <path
    fill="#F7F9FA"
    opacity="1.000000"
    stroke="none"
    d="
M1285.996826,941.092896 
	C1291.329346,941.189758 1296.162476,941.216614 1300.989380,941.403442 
	C1313.765991,941.898010 1326.052124,945.065186 1337.012085,951.319946 
	C1344.503784,955.595459 1349.500732,963.043640 1352.970093,971.212646 
	C1359.558716,986.726501 1360.527100,1003.210083 1360.651611,1019.637878 
	C1360.780396,1036.613403 1359.332397,1053.646851 1352.480103,1069.473633 
	C1347.351562,1081.319458 1339.344727,1090.880981 1326.878906,1095.954346 
	C1305.134644,1104.803711 1282.614502,1105.007202 1259.808350,1102.176025 
	C1248.085693,1100.720703 1237.126831,1097.177856 1227.807739,1089.640259 
	C1221.086548,1084.203857 1216.698853,1077.178345 1213.521606,1069.110352 
	C1205.253052,1048.113159 1206.479614,1026.213745 1207.876709,1004.560181 
	C1208.872070,989.134216 1213.344116,974.074829 1223.261230,961.604919 
	C1228.463135,955.063965 1235.570312,950.691223 1243.477295,947.561340 
	C1257.033325,942.195312 1271.163818,941.128540 1285.996826,941.092896 
M1331.549805,1035.076660 
	C1331.691895,1028.917480 1331.884033,1022.758911 1331.965576,1016.598938 
	C1332.116211,1005.217651 1330.550903,994.159058 1325.751343,983.694641 
	C1322.576660,976.772827 1317.793823,971.082336 1310.618774,968.725098 
	C1295.457397,963.744141 1280.042603,964.095764 1264.511230,967.765564 
	C1245.215210,972.324951 1241.059448,984.460266 1237.939819,998.617859 
	C1234.326538,1015.015991 1234.651123,1031.747192 1237.932251,1048.208374 
	C1241.799438,1067.609619 1248.948608,1074.774536 1266.809204,1077.536377 
	C1277.519043,1079.192627 1288.744873,1078.371704 1299.660767,1077.583740 
	C1309.829590,1076.849487 1318.171387,1071.923706 1323.748291,1062.962769 
	C1328.896606,1054.690430 1329.579346,1045.163086 1331.549805,1035.076660 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M348.714355,1038.652588 
	C347.802429,1038.744385 346.995331,1038.518433 346.188171,1038.292358 
	C346.555511,1037.668823 346.922852,1037.045288 347.290222,1036.421875 
	C347.799866,1037.059448 348.309479,1037.697144 348.714355,1038.652588 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M477.944946,1115.732300 
	C476.955719,1116.064819 475.866211,1116.067017 474.367371,1116.066772 
	C474.880707,1113.654541 476.160492,1113.341797 477.944946,1115.732300 
z"
  />
  <path
    fill="#19BEFD"
    opacity="1.000000"
    stroke="none"
    d="
M346.316833,1023.246216 
	C346.815460,1023.097656 347.254395,1023.196167 347.693359,1023.294678 
	C347.496094,1023.640686 347.298798,1023.986755 347.101532,1024.332764 
	C346.820099,1024.052979 346.538666,1023.773132 346.316833,1023.246216 
z"
  />
  <path
    fill="#043066"
    opacity="1.000000"
    stroke="none"
    d="
M1331.535645,1035.530518 
	C1329.579346,1045.163086 1328.896606,1054.690430 1323.748291,1062.962769 
	C1318.171387,1071.923706 1309.829590,1076.849487 1299.660767,1077.583740 
	C1288.744873,1078.371704 1277.519043,1079.192627 1266.809204,1077.536377 
	C1248.948608,1074.774536 1241.799438,1067.609619 1237.932251,1048.208374 
	C1234.651123,1031.747192 1234.326538,1015.015991 1237.939819,998.617859 
	C1241.059448,984.460266 1245.215210,972.324951 1264.511230,967.765564 
	C1280.042603,964.095764 1295.457397,963.744141 1310.618774,968.725098 
	C1317.793823,971.082336 1322.576660,976.772827 1325.751343,983.694641 
	C1330.550903,994.159058 1332.116211,1005.217651 1331.965576,1016.598938 
	C1331.884033,1022.758911 1331.691895,1028.917480 1331.535645,1035.530518 
z"
  />
</svg>
