<svg
  preserveAspectRatio="none"
  viewBox="0 0 1465 904"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect x="42" y="42" width="1381" height="820" rx="49" fill="#FFF5EA" />
  <g filter="url(#filter0_f_544_7303)">
    <rect
      x="42"
      y="42"
      width="1381"
      height="820"
      rx="49"
      stroke="#FEAC18"
      stroke-width="24"
    />
  </g>
  <rect
    x="42"
    y="42"
    width="1381"
    height="820"
    rx="49"
    stroke="white"
    stroke-width="24"
    stroke-linecap="round"
    stroke-dasharray="1 40"
  />
  <defs>
    <filter
      id="filter0_f_544_7303"
      x="0"
      y="0"
      width="1465"
      height="904"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feGaussianBlur
        stdDeviation="15"
        result="effect1_foregroundBlur_544_7303"
      />
    </filter>
  </defs>
</svg>
