<svg
  width="540"
  height="182"
  viewBox="0 0 540 182"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    width="78"
    height="69"
    rx="34.5"
    transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 462 69)"
    fill="#39344F"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M-55.7567 160.316C-72.2841 160.316 -85.6823 146.918 -85.6823 130.391V130.391C-85.6823 121.682 -91.8025 109.017 -100.511 109.017L-128.432 109.017C-144.959 109.017 -158.357 95.6188 -158.357 79.0914V79.0914C-158.357 70.8174 -163.979 59.8518 -172.253 59.8518L-216.073 59.8518C-232.601 59.8518 -245.999 46.4537 -245.999 29.9263C-245.999 13.3989 -232.601 0.000775603 -216.074 0.000774158L245.635 0.000733795C262.162 0.00073235 275.56 13.3988 275.56 29.9263V29.9263C275.56 38.2002 281.182 49.1658 289.456 49.1658L333.276 49.1658C349.804 49.1658 363.202 62.5639 363.202 79.0913V79.0913C363.202 87.8 369.322 100.465 378.031 100.465L405.951 100.465C422.479 100.465 435.877 113.863 435.877 130.391C435.877 146.918 422.479 160.316 405.951 160.316L-55.7567 160.316Z"
    fill="#39344F"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M-191.538 181.844C-204.82 181.844 -215.588 171.076 -215.588 157.793V157.793C-215.588 150.794 -220.507 140.615 -227.506 140.615L-249.748 140.615C-263.031 140.615 -273.799 129.847 -273.799 116.564V116.564C-273.799 109.915 -278.317 101.102 -284.966 101.102L-319.95 101.102C-333.232 101.102 -344 90.3345 -344 77.0517C-344 63.7689 -333.232 53.001 -319.95 53.001L49.7325 53.001C63.0153 53.001 73.7832 63.7688 73.7832 77.0517V77.0517C73.7832 83.7011 78.3012 92.5134 84.9506 92.5134L119.934 92.5134C133.217 92.5134 143.984 103.281 143.984 116.564V116.564C143.984 123.563 148.903 133.742 155.902 133.742L178.145 133.742C191.427 133.742 202.195 144.51 202.195 157.793C202.195 171.076 191.427 181.844 178.145 181.844L-191.538 181.844Z"
    fill="#817B9D"
  />
</svg>
