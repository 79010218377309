<svg viewBox="0 0 390 517" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.3809 424.351H63.9297V509.003C63.9297 513.297 60.449 516.778 56.1553 516.778V516.778C51.8616 516.778 48.3809 513.297 48.3809 509.003V424.351Z" fill="#201857"/>
<path d="M77.1484 424.351H92.6973V509.003C92.6973 513.297 89.2166 516.778 84.9229 516.778V516.778C80.6292 516.778 77.1484 513.297 77.1484 509.003V424.351Z" fill="#201857"/>
<path d="M291.332 429.841H306.881V509.003C306.881 513.297 303.4 516.777 299.106 516.777V516.777C294.813 516.777 291.332 513.297 291.332 509.003V429.841Z" fill="#201857"/>
<path d="M320.1 429.841H335.648V509.003C335.648 513.297 332.168 516.777 327.874 516.777V516.777C323.58 516.777 320.1 513.297 320.1 509.003V429.841Z" fill="#201857"/>
<g filter="url(#filter0_di_551_2660)">
<rect x="37.5" y="43.5" width="313" height="383.047" rx="21.5" stroke="white" stroke-width="7" shape-rendering="crispEdges"/>
</g>
<g filter="url(#filter1_di_551_2660)">
<rect x="40" y="45.5" width="308" height="378.733" rx="18" fill="#FF509F" fill-opacity="0.1" shape-rendering="crispEdges"/>
<rect x="43.5" y="49" width="301" height="371.733" rx="14.5" stroke="white" stroke-width="7" shape-rendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_di_551_2660" x="-6" y="0" width="400" height="470.048" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="20"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.227451 0 0 0 0 0.321569 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_551_2660"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_551_2660" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.227451 0 0 0 0 0.321569 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_551_2660"/>
</filter>
<filter id="filter1_di_551_2660" x="0" y="5.5" width="388" height="458.733" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="20"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.796078 0 0 0 0 0.0941176 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_551_2660"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_551_2660" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="7.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.796078 0 0 0 0 0.0941176 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_551_2660"/>
</filter>
</defs>
</svg>
