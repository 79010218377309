<svg viewBox="0 0 626 626" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_f_930_2)">
    <circle
      cx="312.5"
      cy="314.5"
      r="218.5"
      stroke="#FF509F"
      stroke-width="30"
    />
  </g>
  <circle cx="312.5" cy="314.5" r="218.5" stroke="white" stroke-width="13" />
  <g filter="url(#filter1_f_930_2)">
    <circle cx="313" cy="313" r="263" stroke="#AC59FF" stroke-width="30" />
  </g>
  <circle cx="313" cy="313" r="263" stroke="white" stroke-width="13" />
  <defs>
    <filter
      id="filter0_f_930_2"
      x="44"
      y="46"
      width="537"
      height="537"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feGaussianBlur
        stdDeviation="17.5"
        result="effect1_foregroundBlur_930_2"
      />
    </filter>
    <filter
      id="filter1_f_930_2"
      x="0"
      y="0"
      width="626"
      height="626"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feGaussianBlur
        stdDeviation="17.5"
        result="effect1_foregroundBlur_930_2"
      />
    </filter>
  </defs>

  <foreignObject x="94" y="96" width="437" height="437">
    <div
      xmlns="http://www.w3.org/1999/xhtml"
      style="width: 100%; height: 100%;"
    >
      <img
        src="/img/speaker.png"
        alt="Speaker"
        style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;"
      />
    </div>
  </foreignObject>
</svg>
